import React from 'react';
import { CustomDatePicker, CustomInputLabel, InputProps } from '../../../custom/components';
import { Stack } from '@mui/material';
import styles from '../../../custom/style.module.css';
import theme from 'custom/theme';
import dayjs from 'dayjs';
import { DateFormat } from 'custom/constants';

const dateFilter = (props) => {
  return (
    <Stack direction='row' spacing={3} justifyContent='center' sx={{ marginBottom: theme.spacing(2) }} className={styles.headerFieldContainer}>
      <Stack direction='row' alignItems='center'>
        <CustomInputLabel className={styles.minWidth40}>From</CustomInputLabel>
        <CustomDatePicker
          slotProps={{
            textField: {
              ...InputProps,
              fullWidth: true,
              placeholder: 'From Date'
            }, 
          }}
          format={DateFormat}
          name='from_date'
          className={`${styles.brInput} ${styles.w100}`}
          value={dayjs(props.searchOptions.start)}
          // helperText={}
          onChange={(e) => props.handleChange(e, 'start')}
        // error={}
        // helperText={}
        />
      </Stack>

      <Stack direction='row' alignItems='center'>
        <CustomInputLabel className={styles.minWidth40}>To</CustomInputLabel>
        <CustomDatePicker
          slotProps={{
            textField: {
              ...InputProps,
              fullWidth: true,
              placeholder: 'To Date'
            }, 
          }}
          format={DateFormat}
          name='to_date'
          className={`${styles.brInput} ${styles.w100}`}
          value={dayjs(props.searchOptions.end)}
          // helperText={}
          onChange={(e) => props.handleChange(e, 'end')}
        // error={}
        // helperText={}
        />
      </Stack>
    </Stack>
  );
};

export default dateFilter;