import React from 'react';
import ConfirmModal from './ConfirmModal';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
class ConfirmModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClose = () => {
    this.props.clearDelete();
  };

  render() {
    return (
      <>
        <ConfirmModal {...this.props.delete} onClose={this.onClose} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { delete: state.delete };
};

const mapDispatchToProps = (dispatch) => {
  return { clearDelete: () => dispatch(setDeleteAction()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModalContainer);