import React from 'react';
import { Drawer, Typography, Grid, Stack, Divider, Box } from '@mui/material';
import styles from '../../../../custom/style.module.css';
import { PopoverHead, PopoverContent, StyledCard } from '../../../../custom/components';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import PDFImg from '../../../../assets/images/pdf.svg';
import { EditIcon, DeleteIcon, AddIcon } from '../../../../custom/constants';
import theme from '../../../../custom/theme';
import userStyles from './../../admin/tabs/manageusers/users.module.css';
import AddDocModal from '../../common/addDocModal';
import moment from 'moment';
import environment from '../../../../custom/environments';

const SectionDivider = styled(Divider)(() => ({
  borderColor: 'white',
  borderBottomWidth: '12px',
  marginLeft: 'calc(var(--splayout) * -1)',
  marginRight: 'calc(var(--splayout) * -1)',
}));

const detailFontSize = '0.7rem';

const DocModal = (props) => {
  const { drawer, closeDrawer, docs } = props;
  return (
    <div>
      <Drawer
        anchor='right'
        open={drawer}
        onClose={closeDrawer}
        className={userStyles.trainingDrawer}
        classes={{ paper: userStyles.trainingPaper }}
      >
        <PopoverHead>
          <Typography variant='h6' component='h6' fontSize={'1.1rem'} fontWeight={600}>Attachments</Typography>
          <CloseIcon fontSize='small' className={styles.pointer} onClick={closeDrawer} />
        </PopoverHead>
        <PopoverContent>
          <Grid container spacing={2}>
            <Grid item xs={12} className={styles.fSpaceBtw}>
              {props.transfer.number}
            </Grid>
            <Grid container item xs={12}>
              <StyledCard className={`${styles.w100} ${styles.attListWrapper}`}>
                <Box className={styles.fSpaceBtw} sx={{
                  marginBottom: '0.6rem',
                  marginTop: '-0.8rem',
                  padding: '4px 0px',
                }}>
                  <Typography variant='subtitle1' component='div' fontWeight={600} fontSize={'0.9rem'} 
                    color={theme.palette.primary.main} lineHeight={'normal'}>List of Attachments</Typography>
                  <AddIcon title='Add attachment' onClick={() => props.handleOpen(drawer, 'add')} />
                </Box>
                <SectionDivider className={styles.mb3} />
                {docs?.map((list, i, self) => {
                  return <Stack key={list.id} direction={'column'} spacing={2}>
                    <Typography variant='body2' component='div' color='primary' fontWeight={600} textTransform={'capitalize'} paddingBottom={'12px'} borderBottom={'2px solid white'}>{list.name}</Typography>
                    <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                      <Stack direction={'column'} spacing={1}>
                        <Stack direction={'column'} spacing={0}>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Date Added:</Typography>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold'>{moment(list.created).format('DD MMM YYYY')}</Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={0}>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Added By:</Typography>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold' className={styles.dInlineFlex} >{list.added_by.first_name} {list.added_by.last_name}</Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={0}>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Remarks:</Typography>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold'>
                            {list.data.remark}
                          </Typography>
                        </Stack>
                      </Stack>
                      <div className={`${styles.pdfWrapper} ${styles.mb3}`}>
                        {list.src.includes('.pdf') && (<img src={PDFImg} alt='pdf' className={styles.w100} onClick={() => { window.open(environment.apiService + 'document?key=' + list.src); }} />)}
                        {!list.src.includes('.pdf') && (<img src={list.src} alt={'attachment'} className={styles.w100} />)}
                      </div>
                      <Stack direction='row' spacing={2} className={userStyles.trainingAction} alignItems={'flex-start'} justifyContent={'space-between'} >
                        <EditIcon onClick={() => props.handleOpen('transfer', 'edit', list)} />
                        <DeleteIcon onClick={(e) => props.handleDelete(list.id, e.target)} />
                      </Stack>
                    </Stack>
                    {i === self.length - 1 ? '' : <SectionDivider className={styles.mb3} sx={{
                      marginRight: '-24px !important',
                      marginLeft: '-24px !important'
                    }} />}
                  </Stack>;
                })}
              </StyledCard>
            </Grid>
          </Grid>
        </PopoverContent>
        <AddDocModal
          handleOpen={props.handleOpen}
          onClose={props.onClose}
          modal={props.modal}
          action={props.action}
          fields={props.k9Fields}
          errors={props.k9Errors}
          onChange={props.onChangeK9}
          add={props.addK9}
          edit={props.editK9}
        />
      </Drawer>
    </div>
  );
};
export default DocModal;