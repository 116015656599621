import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Admin from '../assets/images/menuIcons/settings.svg';
import Docs from '../assets/images/menuIcons/docs.svg';
import K9 from '../assets/images/menuIcons/transferK9.svg';
import Screening from '../assets/images/menuIcons/screening.svg';
import DocArchive from '../assets/images/menuIcons/docArchive.svg';
import Reports from '../assets/images/menuIcons/reports.svg';
import Analytics from '../assets/images/menuIcons/analytics.svg';

import Add from '../assets/images/actionIcons/add.svg';
import Lock from '../assets/images/actionIcons/lock.svg';
import UnLock from '../assets/images/actionIcons/unlock.svg';
import Key from '../assets/images/actionIcons/key.svg';
import Location from '../assets/images/actionIcons/globe.svg';
import Edit from '../assets/images/actionIcons/edit.svg';
import Delete from '../assets/images/actionIcons/delete.svg';
import SaveTick from '../assets/images/actionIcons/tick.svg';
import Tick from '../assets/images/actionIcons/greentick.svg';
import UnTick from '../assets/images/actionIcons/roundeduntick.svg';
import Doc from '../assets/images/actionIcons/doc.svg';
import ViewGreen from '../assets/images/actionIcons/show.svg';
import View from '../assets/images/actionIcons/showBlue.svg';
import Search from '../assets/images/actionIcons/search.svg';
import ArrowTop from '../assets/images/actionIcons/arrowTop.svg';
import WhiteCamera from '../assets/images/actionIcons/whiteCamera.svg';
import WhiteEdit from '../assets/images/actionIcons/whiteEdit.svg';
import WhiteDoc from '../assets/images/actionIcons/whiteDoc.svg';
import Bell from '../assets/images/actionIcons/bell.svg';
// import Page from '../assets/images/actionIcons/page.svg';
// import Camera from '../assets/images/actionIcons/camera.svg';
// import Eco from '../assets/images/actionIcons/eco.svg';
import Excel from '../assets/images/excel.svg';
import Download from '../assets/images/actionIcons/download.svg';
import { CloseIconProps } from './components';
import CloseIcon from '@mui/icons-material/Close';
import { MoreHoriz } from '@mui/icons-material';

import styles from './style.module.css';

export const appName = 'UNISEC';

export const roles = [
  {
    id: 1,
    value: 'Office Agent'
  },
  {
    id: 2,
    value: 'Screener'
  },
  {
    id: 3,
    value: 'Supervisor'
  },
  {
    id: 4,
    value: 'Manager'
  },
  {
    id: 5,
    value: 'System Admin'
  },
  {
    id: 6,
    value: 'TSA Inspector'
  }
];

export const menulist = [
  {
    key: '1',
    link: '/admin/manage_users',
    title: 'Admin',
    icon: <img src={Admin} alt='admin' />,
    className: 'sider-menu-item',
    roles: ['Supervisor', 'Manager', 'System Admin'],
    // is_menu: true
  },
  {
    key: '2',
    link: '/docs_stream',
    title: 'Docs Stream',
    icon: <img src={Docs} alt='Docs Stream' />,
    className: 'sider-menu-item',
    roles: ['Office Agent', 'Screener', 'Supervisor', 'Manager', 'System Admin'],
    // is_menu: true
  },
  {
    key: '3',
    link: '/screening',
    title: 'Screening',
    icon: <img src={Screening} alt='Screening' />,
    className: 'sider-menu-item',
    roles: ['Screener', 'Supervisor', 'Manager', 'System Admin'],
    // is_menu: true
  },
  {
    key: '4',
    link: '/screen',
    title: 'Screening Input',
    icon: <img src={Screening} alt='Screening' />,
    className: 'sider-menu-item',
    roles: ['Screener', 'Supervisor', 'Manager', 'System Admin'],
    // is_menu: true
  },
  {
    key: '5',
    link: '/transfer_k9',
    title: 'Transfer to K9',
    icon: <img src={K9} alt='K9' />,
    className: 'sider-menu-item',
    roles: ['Screener', 'Supervisor', 'Manager', 'System Admin'],
    // is_menu: true 
  },
  {
    key: '6',
    link: '/screening_reports',
    title: 'Reports',
    icon: <img src={Reports} alt='Reports' />,
    className: 'sider-menu-item',
    roles: ['Supervisor', 'Manager', 'System Admin'],
    // is_menu: true
  },
  {
    key: '7',
    link: '/doc_archive',
    title: 'Doc Archive',
    icon: <img src={DocArchive} alt='Document Archive' />,
    className: 'sider-menu-item',
    roles: ['Office Agent', 'Screener', 'Supervisor', 'Manager', 'System Admin'],
    // is_menu: true
  },
  {
    key: '8',
    link: '/analytics',
    title: 'Analytics',
    icon: <img src={Analytics} alt='Analytics' />,
    className: 'sider-menu-item',
    roles: ['Supervisor', 'Manager', 'System Admin'],
    // is_menu: true
  }

];

export const adminTabs = {
  manage_users: 'Manage Users',
  handled_airlines: 'Handled Airlines',
  flight_schedule: 'Flight Schedule',
  measure_methods: 'Measure & Methods',
  comp_transfers: <p className={styles.my0}>K9 Companies &<br /> Transfer Checklists</p>,
  white_list: 'White List - Docs Stream',
};

export const preScreened = [{
  label: 'No',
  value: 'no'
}, {
  label: 'CCSF',
  value: 'ccsf'
}, {
  label: 'Transfer Received Screened From Interline',
  value: 'interline'
}, {
  label: 'Transfer Received Screened From Origin',
  value: 'origin'
}];

export const screeningTabs = {
  screening_details: 'Screening Details',
  consignment_security: 'Consignment Security Declaration',
};

export const docArchiveTabs = {
  awbs: 'Airwaybills',
  flight_manifest: 'Flight Manifest',
  user_training_records: 'User Training Records',
  other_docs: 'Other Documents'
};

export const AddIcon = (props) => <Tooltip title={props.title} {...props}>
  <IconButton disableRipple aria-label='delete' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
    <img src={Add} alt={props.title} className={`${styles.pointer} ${styles.addIcon}`} />
  </IconButton>
</Tooltip>;
export const EditIcon = (props) =>
  <Tooltip title='Edit record' {...props}>
    <IconButton disableRipple aria-label='edit' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
      <img src={Edit} alt='Edit' className={styles.pointer} />
    </IconButton>
  </Tooltip>;
export const LockIcon = ({ title, ...props }) => <Tooltip title={title} {...props}><img src={Lock} alt={title} className={styles.pointer} /></Tooltip>;
export const UnlockIcon = ({ title, ...props }) => <Tooltip title={title} {...props}><img src={UnLock} alt={title} className={styles.pointer} /></Tooltip>;
export const KeyIcon = () => <Tooltip title='Change Password'><img src={Key} alt='Change Password' className={styles.pointer} /></Tooltip>;
export const LocationIcon = (props) =>
  <Tooltip title='Location' {...props}>
    <IconButton disableRipple aria-label='Location' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
      <img src={Location} alt='Location' className={styles.pointer} />
    </IconButton>
  </Tooltip>;
export const DeleteIcon = (props) => <Tooltip title='Delete record' {...props}>
  <IconButton disableRipple aria-label='delete' size='small' disabled={props.disabled} onClick={props.onClick} className={styles.p0} classes={{ disabled: styles.disabledImg }}>
    <img src={Delete} alt='Delete' className={`${styles.pointer} ${styles.primarySvg}`} />
  </IconButton>
</Tooltip>;
export const CloseIconBtn = (props) =>
  <IconButton
    aria-label='close'
    onClick={props.onClick}
    sx={{ ...CloseIconProps }}
    size='small'
  >
    <CloseIcon />
  </IconButton>;
export const DocIcon = (props) => <Tooltip title={props.title} {...props}><img src={Doc} alt='doc' className={`${styles.pointer} ${styles.primarySvg}`} /></Tooltip>;
export const ViewGreenIcon = () => <Tooltip title=''><img src={ViewGreen} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const ViewIcon = () => <Tooltip title=''><img src={View} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const SearchIcon = () => <Tooltip title=''><img src={Search} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const ArrowTopIcon = (props) => <Tooltip title='' {...props}><img src={ArrowTop} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const SaveTickIcon = () => <Tooltip title=''><img src={SaveTick} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const ClearXIcon = () => <Tooltip title='Clear'><span className={`${styles.pointer} ${styles.formClear}`}>x</span></Tooltip>;
export const TickIcon = () => <Tooltip title=''><img src={Tick} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const UnTickIcon = () => <Tooltip title=''><img src={UnTick} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const WhiteCameraIcon = () => <Tooltip title=''><img src={WhiteCamera} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const WhiteEditIcon = () => <Tooltip title=''><img src={WhiteEdit} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const WhiteDocIcon = () => <Tooltip title=''><img src={WhiteDoc} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const BellIcon = () => <Tooltip title=''><img src={Bell} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const ExcelIcon = () => <Tooltip title='Download File'><img src={Excel} alt='download-file' className={`${styles.pointer}`} /></Tooltip>;
export const DownloadIcon = () => <Tooltip title='Download'><img src={Download} alt='download' className={`${styles.pointer}`} /></Tooltip>;
// export const PageIcon = () => <Tooltip title=''><img src={Page} alt='' className={`${styles.pointer}`} /></Tooltip>;
// export const CameraIcon = () => <Tooltip title=''><img src={Camera} alt='' className={`${styles.pointer}`} /></Tooltip>;
// export const EcoIcon = () => <Tooltip title=''><img src={Eco} alt='' className={`${styles.pointer}`} /></Tooltip>;
export const BrowseIcon = () => <Tooltip title='Browse file'><MoreHoriz /></Tooltip>;

export const Required = <span className={styles.textDanger}>&nbsp;*</span>;
export const DateFormat = 'DD MMM YY';

