import React from 'react';
import { Grid, IconButton, MenuItem, InputAdornment, FormControl, Checkbox, Stack, Typography, Box } from '@mui/material';
import styles from '../../../custom/style.module.css';
import k9styles from './k9transfer.module.css';
import { CustomSelectRounded, CustomInputLabel, StyledCard, StyledWhiteCard, StyledBlueCard, StyledCardTitle, CustomSearch, CustomCheckbox, CustomButton, SelectInputProps, InputProps } from '../../../custom/components';
import { Close, Search, Clear } from '@mui/icons-material';
import cookies from 'utils/cookies';
import RightIcon from './../../../assets/images/greenRight.svg';
import dayjs from 'dayjs';

const AddTransferDoc = (props) => {

  const pendingScreening = props.pendingAwbs.map((awb, i) => (
    <StyledBlueCard spacing={4} key={i}>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.code}-{awb.number}
          </Typography>
          <Typography variant='caption'>{awb.airline?.code + awb.flight_no}/{dayjs(awb.flight_date).format('DDMMMYY')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.pcs}
          </Typography>
          <Typography variant='caption'>{awb.forwarder}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.wgt}K
          </Typography>
          {/* <Typography variant='caption'>Forwarding
          </Typography> */}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.org}-{awb.dest}
          </Typography>
          <Typography variant='caption'>{awb.shipper_profile}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={10} lg={10} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.commodity}
          </Typography>
          <Typography variant='caption'>{awb.pre_screened}
          </Typography>
        </Grid>

        <Grid item className={styles.mlauto}>
          <img src={RightIcon} alt='' onClick={() => props.transferAwb('pending', i)} className={styles.pointer} />
        </Grid>
      </Grid>
    </StyledBlueCard>
  ));

  const transferList = props.fields.transfer_awbs.map((awb, i) => (
    <StyledBlueCard spacing={4} key={i}>
      <Grid container spacing={3}>
        <Grid item>
          <Box sx={{ transform: 'rotate(180deg)' }}>
            <img src={RightIcon} alt='' onClick={() => props.transferAwb('list', i)} className={styles.pointer} />
          </Box>
        </Grid>

        <Grid item xs={12} sm={10} md={10} lg={10} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.code}-{awb.number}
          </Typography>
          <Typography variant='caption'>{awb.airline?.code + awb.flight_no}/{dayjs(awb.flight_date).format('DDMMMYY')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.pcs}
          </Typography>
          <Typography variant='caption'>{awb.forwarder}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.wgt}K
          </Typography>
          {/* <Typography variant='caption'>Forwarding
          </Typography> */}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.org}-{awb.dest}
          </Typography>
          <Typography variant='caption'>{awb.shipper_profile}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={10} lg={10} xl={2}>
          <Typography variant='subtitle2' component='div' fontWeight={900}>{awb.commodity}
          </Typography>
          <Typography variant='caption'>{awb.pre_screened}
          </Typography>
        </Grid>

      </Grid>
    </StyledBlueCard>
  ));
  const totalPcs = props.fields.transfer_awbs.reduce((acc, awb) => acc + awb.pcs, 0);
  const totalWgt = props.fields.transfer_awbs.reduce((acc, awb) => acc + awb.wgt, 0);
  const isButtonEnabled = props.fields.k9_companyId && props.fields.transfer_awbs.length > 0;
  return (
    <Box sx={{
      position: 'absolute',
      top: '0px',
      width: '100%',
      zIndex: 20
    }}>
      {
        (props.modal === 'transferDoc' || props.modal === 'transferDocEdit') &&
        <StyledCard>

          <Grid container spacing={3}>
            <Grid item xs={12} className={[styles.positionRelative, k9styles.popUp].join('')}>
              <Stack direction='row' spacing={2} justifyContent='space-between' sx={{ marginRight: '60px', }}>
                <StyledCardTitle gutterBottom variant='cardTitle'>Create New Transfer Document</StyledCardTitle>
                <div>
                  <CustomInputLabel id='k9_transfer_label'>Select K9 Company to Transfer</CustomInputLabel>
                  <FormControl variant='standard'
                    fullWidth
                  // error={}
                  >
                    <CustomSelectRounded
                      {...SelectInputProps}
                      id='k9_companyId'
                      labelId='k9_transfer_label'
                      name='k9_companyId'
                      variant='outlined'
                      value={props.fields.k9_companyId}
                      onChange={(e) => props.handleChange(e, 'k9_companyId')}
                      classes={{ select: !props.fields.k9_companyId ? styles.colorPlaceholder : '' }}
                      // InputProps={{ classes: { select: styles.whitebkg, } }}
                    >
                      <MenuItem value='placeholder' disabled>Select K9 Company to Transfer</MenuItem>
                      {props.k9Companies.map((k9, i) => (
                        <MenuItem key={i} value={k9.id}>
                          {k9.name}
                        </MenuItem>
                      ))}
                    </CustomSelectRounded>
                    {/* <FormHelperText>{}</FormHelperText> */}
                  </FormControl>
                </div>
                <StyledCardTitle gutterBottom variant='cardTitle'>Issued By: {props.fields.issued_by ? `${props.fields.issued_by.first_name} ${props.fields.issued_by.last_name}` : cookies.get('name')}</StyledCardTitle>
                <IconButton
                  aria-label='close'
                  onClick={props.onClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledWhiteCard>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={`${styles.mb2} ${styles.fSpaceBtw}`}>
                      <StyledCardTitle gutterBottom variant=''>Shipments Pending Screening</StyledCardTitle>
                      <CustomSearch
                        {...InputProps} 
                        id='searchText'
                        variant='outlined'
                        name='searchText'
                        placeholder='Enter Awb, Flight or Commodity'
                        type='search'
                        InputProps={{
                          endAdornment: <InputAdornment position='end'>
                            {props.filterOptions.searchtext && (
                              <IconButton size='small' aria-label='clear' edge='end' onClick={props.clearSearch}>
                                <Clear fontSize='inherit' />
                              </IconButton>
                            )}
                            <IconButton
                              size='small'
                              aria-label='search'
                              onClick={() => props.applyFilters()}
                              edge='end'
                            >
                              <Search fontSize='inherit' />
                            </IconButton>
                          </InputAdornment>,
                        }}
                        onChange={(e) => props.handleFilterChange(e, 'filter')}
                        value={props.filterOptions.searchtext}
                        className={styles.brInput}

                      // error={}
                      // helperText={}
                      />
                    </div>
                  </Grid>

                  <Grid item>
                    <Stack direction='row' spacing={2} justifyContent='flex-start'>
                      <div>
                        <CustomInputLabel>Filter By Departing Airline Code</CustomInputLabel>
                        <FormControl variant='standard'
                          fullWidth
                        // error={}
                        >
                          <CustomSelectRounded
                            id=''
                            name='airlineCode'
                            variant='outlined'
                            value={props.filterOptions.airlineCode}
                            onChange={(e) => props.handleFilterChange(e, 'filter')}
                          >
                            { }
                            <MenuItem value=''></MenuItem>
                            {props.airlines.map((airline, i) => (
                              <MenuItem key={i} value={airline.code}>{airline.code}
                              </MenuItem>
                            ))}
                          </CustomSelectRounded>
                          {/* <FormHelperText>{}</FormHelperText> */}
                        </FormControl>
                      </div>
                      <CustomCheckbox name='' control={<Checkbox size='small' onChange={(e) => props.handleFilterChange(e, 'filter')} name='preScreened' checked={props.filterOptions.preScreened} />}
                        label='Exclude Pre-screened' />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction='column' spacing={2}>
                      {pendingScreening}
                    </Stack>
                  </Grid>
                </Grid>

              </StyledWhiteCard>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledWhiteCard>

                <Grid container spacing={3}>
                  <Grid item xs={12} className={styles.dFlex} flexWrap={'wrap'}>
                    <StyledCardTitle gutterBottom variant='' className={styles.minWidth150} >Transfer List</StyledCardTitle>
                    <Stack direction='row' spacing={2} justifyContent='space-between' flexGrow={1}>

                      <div className={k9styles.transferDetails}>
                        <Typography variant='caption' component='span' fontWeight={900} className={k9styles.details}>{props.fields.transfer_awbs.length}
                        </Typography>
                        <Typography variant='caption' component='span' fontWeight={900} className={k9styles.title}>Awbs
                        </Typography>
                      </div>

                      <div className={k9styles.transferDetails}>
                        <Typography variant='caption' component='span' fontWeight={900} className={k9styles.details}>{totalPcs}
                        </Typography>
                        <Typography variant='caption' component='span' fontWeight={900} className={k9styles.title}>Pieces
                        </Typography>
                      </div>

                      <div className={k9styles.transferDetails}>
                        <Typography variant='caption' component='span' fontWeight={900} className={k9styles.details}>{totalWgt}
                        </Typography>
                        <Typography variant='caption' component='span' fontWeight={900} className={k9styles.title}>Kilos
                        </Typography>
                      </div>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction='column' spacing={2}>
                      {transferList}
                    </Stack>
                  </Grid>
                </Grid>

              </StyledWhiteCard>
            </Grid>

            <Grid item container justifyContent='flex-end' xs={12}><CustomButton variant='contained' disabled={!isButtonEnabled} onClick={props.createTransfer}> Update </CustomButton> </Grid>
          </Grid>

        </StyledCard>
      }
    </Box>
  );
};
export default AddTransferDoc;