import React from 'react';
import {
  IconButton,
  Dialog,
  InputAdornment,
  Grid,
  Tooltip,
  DialogContentText,
} from '@mui/material';
import { StyledCard, CustomButton, CustomTextField, InputProps, CustomDialogAction, CustomDialogTitle, CustomDialogContent } from '../../../../../custom/components';
import { Check, Clear, RotateLeft } from '@mui/icons-material';
import userStyle from './users.module.css';
import styles from '../../../../../custom/style.module.css';
import admin from '../admin.module.css';
import { CloseIconBtn } from 'custom/constants';

const ChangePassword = (props) => {

  return (
    <div>
      <Dialog
        open={props.modal == 'user_pass'}
        onClose={props.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth={'sm'}
        fullWidth={true}
      >
        <CustomDialogTitle id='form-dialog-title'>
          Manage Password
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <CustomDialogContent>
          <StyledCard>
            <Grid container className={userStyle.gridContainer}>
              <Grid item xs={4} className={userStyle.titleColor}>
                Name:
              </Grid>
              <Grid item xs={6} className={userStyle.gridData}>
                {props.userFields.first_name} {props.userFields.last_name}
              </Grid>
            </Grid>
            <Grid container className={userStyle.gridContainer}>
              <Grid item xs={4} className={userStyle.titleColor}>
                Email/Username:
              </Grid>
              <Grid item xs={6} className={userStyle.gridData}>
                {props.userFields.username}
              </Grid>
            </Grid>
            <Grid container className={userStyle.gridContainer}>
              <Grid item xs={4} className={userStyle.titleColor}>
                Role:
              </Grid>
              <Grid item xs={6} className={userStyle.gridData}>
                {props.userFields.role}
              </Grid>
            </Grid>
            <CustomTextField
              {...InputProps}
              fullWidth
              id='password'
              className={`${admin.addTextField} ${styles.mb3}`}
              placeholder='Password'
              InputProps={{
                disableUnderline: true,
                endAdornment:
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Generate-random-password'
                      onClick={() => {props.onChange({
                        target: {
                          name: 'password',
                          value: props.generatePassword(),
                        } 
                      });}}
                    >
                      <Tooltip title={'Generate Password'}>
                        <RotateLeft fontSize='small' />
                      </Tooltip>
                    </IconButton>
                  </InputAdornment>
              }}
              inputProps={{
                autoComplete: 'new-password',
                form: { autocomplete: 'off', },
                'aria-label': 'Generate-password'
              }}
              onChange={props.onChange}
              name='password'
              value={props.userFields.password}
              error={props.userErrors.password}
              helperText={props.userErrors.password}
            />
          </StyledCard>
          <br />
          <div className={userStyle.passwordPolicyContainer}>
            <div className={userStyle.passwordPolicyHeader}>
              Password Policy:
            </div>
            <div className={userStyle.passwordPolicyConditions}>
              {props.userFields.passcheck1 ?
                <Check style={{
                  color: 'green',
                  fontSize: '14px'
                }} /> :
                <Clear style={{
                  color: 'red',
                  fontSize: '14px'
                }} />
              }
              <span className={userStyle.greyText}>Must be at least 8 characters</span>
            </div>
            <div className={userStyle.passwordPolicyConditions}>
              {props.userFields.passcheck2 ?
                <Check style={{
                  color: 'green',
                  fontSize: '14px'
                }} />
                :
                <Clear style={{
                  color: 'red',
                  fontSize: '14px'
                }} />}
              <span className={userStyle.greyText}>Must contain one upper case letter, one number and a special character</span>
            </div>
          </div>
        </CustomDialogContent>
        <CustomDialogAction className={`${styles.fSpaceBtw}`}>
          <CustomButton variant='contained'
            onClick={props.onClose} sx={{ minWidth: '130px' }}
          >Close</CustomButton>
          <CustomButton variant='contained'
            onClick={props.handleChangePassword}
          >Update and Print</CustomButton>
        </CustomDialogAction>
      </Dialog>
      <Dialog
        open={props.successModal}
        onClose={props.onClose}
        aria-labelledby='Password-changed-successfully'
        aria-describedby='Password Change'
      >
        <CustomDialogTitle id='Password-changed-successfully' className={styles.mb3}  >
          {'Password Changed'}
        </CustomDialogTitle>
        <CustomDialogContent>
          <DialogContentText>
          Successfully updated user password. A new tab 
              was opened for printing the new credentials.
          </DialogContentText>
        </CustomDialogContent>
        <CustomDialogAction>
          <CustomButton onClick={props.onClose} autoFocus variant='contained'>
            Okay
          </CustomButton>
        </CustomDialogAction>
      </Dialog>
    </div>
  );
};
export default ChangePassword;