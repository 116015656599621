import React from 'react';
import ScreeningDetails from './ScreeningDetails';
import http from 'utils/http';
import moment from 'moment';
import cookies from 'utils/cookies';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

class ScreeningDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pcs: 0,
      add: false,
      addMoadl: true,
      measures: [],
      methods: [],
      users: [],
      alarms: [],
      screenFields: {},
      screenErrors: {},
      alarmFields: {},
      screens: [],
      anchorEl: null,
      alarmModal: false,
    };
  }

  componentDidMount() {
    this.props.screen.airlineId && this.getMeasures();
    this.checkPCS();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.screen != this.props.screen)
      this.checkPCS();
  }

  onChange = (e) => {
    let fields = {};
    let name = e.target.name;
    let value = e.target?.type == 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.type == 'date') {
      value = dayjs(e.target.value).format('YYYY-MM-DD');
    }
    if (name == 'measureId') {
      if (value) {
        this.state.measures.map(m => {
          if (m.id == value) {
            this.setState({ methods: m.methods });
          }
        });
      } else {
        this.setState({ method: [] });
      }
    }
    if (name == 'else' && !e.target.checked) {
      fields.user = 'user_' + cookies.get('user');
    }
    if (name == 'book' && e.target.checked) {
      fields.flight_date = this.props.screen.flight_date;
      fields.flight_no = this.props.screen.airline.code + this.props.screen.flight_no;
    }
    this.setState({
      screenFields: {
        ...this.state.screenFields,
        ...fields,
        [name]: value
      }
    });
  };

  onChangeAlarm = (e) => {
    let name = e.target.name;
    let value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      alarmFields: {
        ...this.state.alarmFields,
        [name]: value
      }
    });
  };

  onEdit = (id) => {
    let screnn = id ? { ...this.props.screen.screens?.filter((s) => s.id === id)?.[0] } : {};
    if (screnn) {
      if (screnn.user) {
        screnn.user = 'user_' + screnn.user.id;
      }
      if (screnn.k9_company) {
        screnn.user = 'k9_' + screnn.k9_company.id;
      }
    }
    if (screnn.measureId) {
      this.state.measures.map(m => {
        if (m.id == screnn.measureId) {
          this.setState({ methods: m.methods });
        }
      });
    } else {
      this.setState({ method: [] });
    }
    this.setState({ screenFields: screnn });
  };

  validation = (e) => {
    // let pcs = typeof e == 'number' ? this.state.screens[e].pcs + this.state.pcs : this.state.pcs;
    const screenFields = typeof e == 'number' ? { ...this.state.screens[e].screenFields } : { ...this.state.screenFields };
    const error = {};
    let formValid = true;
    if (!screenFields.pcs) {
      formValid = false;
      error.pcs = 'Please enter pcs.';
    }
    if (screenFields.pcs && !(screenFields.pcs > 0 )) {
      formValid = false;
      error.pcs = 'Please enter valid pcs.';
    }
    if (!screenFields.measureId || (screenFields.measureId.length === 0)) {
      formValid = false;
      error.measure = 'Please select measure.';
    }
    if (!screenFields.methodId || (screenFields.methodId.length === 0)) {
      formValid = false;
      error.method = 'Please select method.';
    }
    if (!screenFields.flight_no || (screenFields.flight_no.trim().length === 0)) {
      formValid = false;
      error.flight_no = 'Please select flight no.';
    }
    if (!screenFields.flight_date || (screenFields.flight_date.trim().length === 0)) {
      formValid = false;
      error.flight_date = 'Please select flight date.';
    }
    if (!screenFields.user || (screenFields.user.trim().length === 0)) {
      formValid = false;
      error.user = 'Please select screener.';
    }
    if (!screenFields.screen_date || (screenFields.screen_date.trim().length === 0)) {
      formValid = false;
      error.screen_date = 'Please select screening date.';
    }
    if (!screenFields.screen_time || (screenFields.screen_time.trim().length === 0)) {
      formValid = false;
      error.screen_time = 'Please select screening time.';
    }
    if (screenFields.alarmed && (!screenFields.alarmId || (screenFields.alarmId.length === 0))) {
      formValid = false;
      error.alarm = 'Please select alarm resolution.';
    }
    if (screenFields.alarmed && (!screenFields.resolved)) {
      formValid = false;
      error.resolved = 'Please select option.';
    }
    if (typeof e == 'number') {
      const screens = this.state.screens;
      screens[e].screenErrors = error;
      this.setState({ screens });
    } else {
      this.setState({ screenErrors: error });
    }
    return formValid;
  };

  addScreen = (e) => {
    e?.preventDefault();
    if (this.validation(false)) {
      let formdata = { ...this.state.screenFields };
      http.post('/screen/' + this.props.screen.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            addMoadl: false,
            screenFields: {},
            screenErrors: {},
            add: false,
          });
        }
      });
    }
  };


  editScreen = () => {
    let screenFields = { ...this.state.screenFields };
    let awbId = screenFields.awbId;
    let id = screenFields.id;
    let formdata = {
      flight_date: this.state.screenFields.flight_date,
      flight_no: this.state.screenFields.flight_no,
      measureId: this.state.screenFields.measureId,
      methodId: this.state.screenFields.methodId,
      pcs: this.state.screenFields.pcs,
      screen_date: this.state.screenFields.screen_date,
      screen_time: this.state.screenFields.screen_time,
      user: this.state.screenFields.user,
      book: this.state.screenFields?.book,
      alarmId: this.state.screenFields?.alarmId,
      alarmed: this.state.screenFields?.alarmed,
      resolved: this.state.screenFields?.resolved,
      remark: this.state.screenFields?.remark
    };

    // if (this.validation(e)) {
    http.put(`/screen/${awbId}/${id}`, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          addMoadl: false,
          screenFields: {},
          screenErrors: {},
          add: false,
        });
      }
    });
    // }
  };

  updateAlarm = () => {
    let formdata = {
      alarmId: this.state.alarmFields.alarmId,
      resolved: this.state.alarmFields.resolved,
      remark: this.state.alarmFields.remark
    };
    http.put(`/screen_alarm/${this.props.screen.id}/${this.state.alarmFields.id}`, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.handleAlert();
      }
    });
  };

  deleteScreen = (id) => {
    http.delete(`/screen/${this.props.screen.id}/${id}`);
  };

  getMeasures = () => {
    http.get('/screen/details?airline=' + this.props.screen.airlineId).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ ...data.data });
      }
    });
  };

  checkPCS = () => {
    if (this.props.screen) {
      let pcs = this.props.screen.pcs;
      this.props.screen.screens && this.props.screen.screens.map(s => {
        if (pcs - s.pcs > 0) {
          pcs -= s.pcs;
        } else {
          pcs = 1;
        }
      });
      this.setState({
        add: pcs > 0 ? true : false,
        pcs: pcs,
        screenFields: {
          book: true,
          pcs,
          flight_no: this.props.screen?.airline?.code+this.props.screen.flight_no,
          flight_date: this.props.screen.flight_date,
          screen_date: moment().format('YYYY-MM-DD'),
          screen_time: moment().format('HH:mm'),
          user: 'user_' + cookies.get('user')
        }
      });
    }
  };

  handleAlert = (alarmModal = false, alarmFields = {}, anchorEl = false) => {
    this.setState({
      alarmModal,
      alarmFields,
      anchorEl
    });
  };

  render() {
    return (
      <>
        <ScreeningDetails
          add={this.state.add}
          measures={this.state.measures}
          methods={this.state.methods}
          alarms={this.state.alarms}
          users={this.state.users}
          screens={this.props.screen.screens || []}
          screenFields={this.state.screenFields}
          screenErrors={this.state.screenErrors}
          onChange={this.onChange}
          onEdit={this.onEdit}
          addScreen={this.addScreen}
          editScreen={this.editScreen}
          deleteScreen={this.deleteScreen}
          handleAlert={this.handleAlert}
          anchorEl={this.state.anchorEl}
          alarmModal={this.state.alarmModal}
          alarmFields={this.state.alarmFields}
          onChangeAlarm={this.onChangeAlarm}
          updateAlarm={this.updateAlarm}
        />
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return { screen: state.screen };
};

export default connect(mapStateToProps)(ScreeningDetailsContainer);
