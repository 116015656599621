import { Axios } from 'axios';
import { toast } from 'react-toastify';
import environment from '../custom/environments';

const trypodHttp = new Axios({
  baseURL: environment.trypodGateway,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': environment.trypod_api_key,
  }
});


trypodHttp.interceptors.response.use((response) => {
  response.data = response.data ? JSON.parse(response.data) : {};
  // if (response && response.config && response.config.method != 'get') {
  //   toast.success(response.data.message);
  // }
  return response;
}, error => {
  console.log('API ERROR=====>', error);
  if (error) {
    toast.error(error.response && error.response.data.message || error.message);
  }
  return Promise.reject(error);
});

export default trypodHttp;