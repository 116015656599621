import React from 'react';
import Consignment from './Consignment';
import { connect } from 'react-redux';
import http from 'utils/http';
// import moment from "moment";

class ConsignmentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editRecord: false,
      consignmentFields: {},
      consignmentFieldErrors: {}
    };
  }

  componentDidMount() {
    this.setState(() => ({
      consignmentFields: {
        ...this.props.screen?.csd || {},
        contents_consignment: '' || this.props.screen?.csd?.contents_consignment || this.props.screen?.commodity,
      }
    }));
  }

  componentWillUnmount() { }

  componentDidUpdate(prevProps) {
    if (prevProps?.screen?.csd != this.props.screen.csd) {
      this.setState({ consignmentFields: { ...this.props.screen.csd || {} } });
    }
  }

  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      consignmentFields: {
        ...this.state.consignmentFields,
        [name]: value
      }
    });
  };

  handleEditRecord = (record = null) => {
    this.setState((prev) => ({
      editRecord: {
        ...prev.editRecord,
        [record]: true,
      }
    }));
  };

  closeEditRecord = (record) => {
    this.setState((prev) => ({
      editRecord: {
        ...prev.editRecord,
        [record]: false,
      }
    }));
  };

  validation = () => {
    const consignmentFields = { ...this.state.consignmentFields };
    const error = {};
    let formValid = true;
    if (!consignmentFields.security_status || (consignmentFields.security_status.trim().length === 0)) {
      formValid = false;
      error.security_status = 'Please select security status';
    }
    this.setState({ consignmentFieldErrors: error });
    return formValid;
  };

  onSave = () => {
    if (this.validation()) {
      let data = { ...this.state.consignmentFields };
      http.put('consignment/' + this.props.screen.id, data);
    }
  };

  onEmail = () => {
    http.get('consignment/mail/' + this.props.screen.id);
  };

  onPrint = () => {
    http.get('consignment/pdf/' + this.props.screen.id, { responseType: 'blob' }).then((response) => {
      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  render() {
    return (
      <>
        <Consignment
          handleEditRecord={this.handleEditRecord}
          closeEditRecord={this.closeEditRecord}
          onSave={this.onSave}
          onEmail={this.onEmail}
          onPrint={this.onPrint}
          onChange={this.onChange}
          screen={this.props.screen}
          editRecord={this.state.editRecord}
          consignmentFields={this.state.consignmentFields}
          consignmentFieldErrors={this.state.consignmentFieldErrors}
        />
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return { screen: state.screen };
};

export default connect(mapStateToProps)(ConsignmentContainer);
