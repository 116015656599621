import React from 'react';
// import cookies from '../../../utils/cookies';
import ChangeDefaultPassword from './ChangeDefaultPassword';
import http from 'utils/http';
import cookies from 'utils/cookies';
// import http from '../../../utils/http';
// import auth from '../../../utils/auth';
// import { withRouter } from 'react-router-dom';
class ChangeDefaultPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userFields: {},
      userErrors: {},
    };
    // fields: {
    //   username: '',
    //   company: '',
    //   password: ''
    // },
    // errors: {
    //   company: false,
    //   username: false,
    //   password: false
    // },
    // submit: false,
    
  }

  componentDidMount() {
    // console.log(process.env);
    // if (auth()) {
    //   this.props.history.push('/');
    // }
  }

  // onChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   this.setState({
  //     fields: {
  //       ...this.state.fields,
  //       [name]: value
  //     }
  //   });
  // };

  validation = () => {

    const fields = { ...this.state.userFields };
    const errors = {
      new_password: false,
      password: false,
    };
    let formIsValid = true;

    if (!fields.new_password || (fields.new_password).trim().length === 0) {
      formIsValid = false;
      errors.new_password = 'Please enter a password.';
    }

    if (!fields.password || (fields.password).trim().length === 0) {
      formIsValid = false;
      errors.password = 'Please enter a password.';
    }

    if (fields.new_password != fields.password) {
      formIsValid = false;
      errors.password = 'The passwords dont match.';
    }

    this.setState({ userErrors: errors });

    return formIsValid;
  };

  checkPassword = () => {
    console.log(this.state.userFields.new_password, 'this.state.userFields.new_password');
    let valid = false;
    let fields = {
      passcheck1: true,
      passcheck2: true
    };
    let passwordInputValue = this.state.userFields.new_password;
    if (passwordInputValue) {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = '';
      if (passwordLength === 0) {
        fields.passcheck2 = false;
        fields.passcheck1 = false;
        errMsg = 'Password is empty! Password must contain minumum 8 characters with at least one Uppercase, one Lowercase, one digit, one Special Characters  ';
      } else if (!uppercasePassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one Uppercase';
      } else if (!lowercasePassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one Lowercase';
      } else if (!digitsPassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one digit';
      } else if (!specialCharPassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one Special Characters';
      }
      if (!minLengthPassword) {
        fields.passcheck1 = false;
        errMsg = 'At least minumum 8 characters';
      }
      if (errMsg == '') {
        valid = true;
      }
      this.setState({
        userFields: {
          ...this.state.userFields,
          ...fields
        },
        userErrors: { new_password: errMsg }
      });
    } else {
      this.setState({
        userFields: {
          ...this.state.userFields,
          passcheck1: false,
          passcheck2: false
        },
        userErrors: {}
      });
    }
    return valid;
  };

  onChange = (e) => {
    this.setState({
      userFields: {
        ...this.state.userFields,
        [e.target.name]: e.target.value
      }
    }, () => {
      console.log(this.state.userFields, 'userFields');

      if (e.target.name == 'new_password') {
        console.log('new_password');
        this.checkPassword();
      }
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let formdata = {
      password: this.state.userFields.password,
      login: true
    };
    const userId = cookies.get('user');
    console.log(formdata, 'formdata');
    if (this.validation()) {
    // this.setState({ submit: true });
      await http.put('/user/' + userId, formdata).then(({ data, status }) => {
        if (status == 200 && data.status == 'success') {
          cookies.remove('password');
          // const station = data.data.station;
          // cookies.set('login', true, { path: '/' });
          // cookies.set('name', data.data.user.first_name +' '+data.data.user.last_name, { path: '/' });
          // cookies.set('user', data.data.user.id, { path: '/' });
          // cookies.set('company', data.data.user.companyId, { path: '/' });
          // cookies.set('role', data.data.user.role, { path: '/' });
          // cookies.set('station', station && station[0] && station[0].id, { path: '/' });
          // cookies.set('stations', station, { path: '/' });
          this.props.history.push('/');
        }
      // else {
      //   this.setState({ submit: false });
      // }
      })
        .catch(() => {
        // this.setState({ submit: false });
        });
    }
  };

  render() {
    return (
      <ChangeDefaultPassword
        fields={this.state.userFields}
        errors={this.state.userErrors}
        submit={this.state.submit}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default ChangeDefaultPasswordContainer;
// export default withRouter(ChangeDefaultPasswordContainer);