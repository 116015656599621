import { SET_DOC } from '../actions/actionTypes';

const initialState = {
  modal: false,
  id: false,
};

export const docReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOC:
      return { ...action.data };
    default:
      return state;
  }
};