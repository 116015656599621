import React from 'react';
import Search from './Search';
import http from 'utils/http';
import { validateAwb } from 'utils/awb';

class SearchContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exAwbs: [],
      awb: null,
      modal: false,
      scrapperModal: false,
      isLoading: false
    };
  }

  handleChangeAwb = (value) => {

    if (value.length === 3 && (this.state.awb?.length === 2)) {
      value = value + '-';
    }
    if (value.length === 11) {
      if (value.indexOf('-') < 0) {
        value = value.slice(0, 3) + '-' + value.slice(3);
      }
    }
    if (value.split('-').length > 2) {
      return;
    }

    return value;
  };

  handleAddAwbChange = (e) => {
    let value = this.handleChangeAwb(e);
    this.setState({ awb: value });
  };

  fetchAwb = (id) => {
    this.props.fetchAwb(id);
    this.setState({
      awb: null,
      modal: false,
      scrapperModal: false,
      exAwbs: []
    });
  };

  searchAwb = (add = false, manual = false, version = 1) => {
    const awb_no = this.state.awb;
    if (awb_no && validateAwb(awb_no)) {
      this.setState({ isLoading: true }, () => {
        http.get(`/add_awb/${awb_no}?add=${add}&manual=${manual}&version=${version}`).then(({ data }) => {
          if (data && data.data && data.data.found) {
            if (data.data.awbs.length == 1) {
              this.fetchAwb(data.data.awbs[0].id);
            } else {
              this.setState({
                exAwbs: data.data.awbs,
                modal: true
              });
            }
          } else if (data && data.data && data.data.awb) {
            this.fetchAwb(data.data.awb.id);
          } else if (data && data.data && data.data.scrapper) {
            this.setState({ scrapperModal: true });
          }
          this.setState({ isLoading: false });
        });
      });
    }
  };

  onClose = () => {
    this.setState({
      modal: false,
      scrapperModal: false
    });
  };

  render() {
    return (
      <>
        <Search
          fetchAwb={this.fetchAwb}
          exAwbs={this.state.exAwbs}
          modal={this.state.modal}
          scrapperModal={this.state.scrapperModal}
          onClose={this.onClose}
          awb={this.state.awb}
          handleAddAwbChange={this.handleAddAwbChange}
          searchAwb={this.searchAwb}
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
}


export default SearchContainer; 