export function extractAwbCodeAndNumber(awbNo) {

  const awbData = awbNo.split('-');
  const awbCode = awbData[0];
  const awbNumber = awbData[1];

  return {
    awbCode,
    awbNumber
  };
}

export function validateAwb(awb) {
  const awbRegex = /^\d{3}-\d{8}$/;

  if (awbRegex.test(awb)) {
    const { awbNumber } = extractAwbCodeAndNumber(awb);
    if (parseInt(awbNumber.substr(0, 7)) % 7 == parseInt(awbNumber[7])) {
      return true;
    }
  }

  return false;
}
