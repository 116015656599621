import { toast } from 'react-toastify';
import cookies from './cookies';
import environment from '../custom/environments';

export default (type, sub) => {
  const e = new EventSource(`${environment.apiService}events?company=${cookies.get('company')}&station=${cookies.get('station')}&type=${type}&sub=${sub}`);
  e.addEventListener('global', (msg) => {
    const data = JSON.parse(msg.data || '{}');
    // global events
    if (data.type == 'admin') {
      if (data.sub == 'user' && data.action == 'update') {
        let user = data.data;
        if (user.id == cookies.get('user')) {
          let reload = false;
          if (user.role != cookies.get('role')) {
            cookies.set('role', user.role, { path: '/' });
            toast.warning('User Role Changed.');
            reload = true;
          }
          if (user.password) {
            cookies.remove('login', { path: '/' });
            toast.warning('Password Changed Logging Out.');
            reload = true;
          }
          if (!user.active) {
            cookies.remove('login', { path: '/' });
            toast.warning('User Inactive Logging Out.');
            reload = true;
          }
          if (user.station) {
            if (user.stations.length == 0) {
              cookies.remove('station', { path: '/' });
              cookies.remove('stations', { path: '/' });
              toast.warning('User Stations Not found.');
            } else {
              let stn = false;
              user.stations.map((s) => {
                if (s.id == cookies.get('station')) {
                  stn = true;
                }
              });
              if (!stn) {
                cookies.set('station', user.stations[0].id, { path: '/' });
                toast.warning('User Station Changed.');
              }
              toast.warning('User Stations Updated.');
              cookies.set('stations', user.stations, { path: '/' });
            }
            reload = true;
          }

          if (reload) {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }
      }
    }
  });
  return e;
};