import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Popover, Typography, Button, } from '@mui/material';
import { PopoverHead, PopoverContent, PopoverFooter } from '../../../../custom/components';
import { Close as CloseIcon } from '@mui/icons-material';
import userStyles from '../../admin/tabs/manageusers/users.module.css';
import styles from '../../../../custom/style.module.css';
import theme from '../../../../custom/theme';

const Location = (props) => {
  const { handleClose, anchorEl, id, open, stations, station, onChange, onUpdate, error, name, type } = props;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={() => handleClose(false)}
    >
      <PopoverHead>
        <Typography variant='subtitle2' component='span'>Multi location Access</Typography>
        <CloseIcon fontSize='small' className={styles.pointer} onClick={() => handleClose(false)} />
      </PopoverHead>
      <PopoverContent className={styles.textCenter}>
        <Typography variant='subtitle2' component='p' color={theme.palette.primary.main} gutterBottom fontWeight='bold'>{name}</Typography>
        <Typography variant='caption' component='p' gutterBottom>{type == 'user' ? 'Check all locations this user should have access to.' : `Check all the location that handles ${name}`}</Typography>
        <FormGroup aria-label='stations' className={`${userStyles.checkboxWrapper} ${userStyles.locCheckWrapper} `}>
          {stations && stations.map(s => {
            return (
              <FormControlLabel key={s.id} control={<Checkbox size='small' />} value={s.id} onChange={onChange} checked={station && station.includes(s.id)} label={s.code} labelPlacement='start' />
            );
          })}
        </FormGroup>
        {error}
      </PopoverContent>
      <PopoverFooter className={styles.textCenter}>
        <Button variant='contained' size='small' className={styles.minWidth150} onClick={onUpdate}>Update</Button>
      </PopoverFooter>
    </Popover>
  );
};

export default Location;