import React from 'react';
import {
  IconButton,
  Grid,
  InputAdornment,
  Checkbox,
  RadioGroup,
  Radio,
  Typography,
} from '@mui/material';
import {
  StyledCard, StyledCardTitle,
  CustomInputLabel,
  CustomDatePicker,
  CustomCheckbox,
  CustomRadio,
  CustomSearch,
  SectionDivider,
  InputProps
} from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import docStyle from './docs.module.css';
import { AddIcon, DateFormat, DeleteIcon } from '../../../custom/constants';
import { CheckCircleOutline, Search } from '@mui/icons-material';
import moment from 'moment';
import dayjs from 'dayjs';
// import moment from 'moment';


const docStreamListComponent = (props) => {
  const docs = props.docs.map((doc, i) => {
    let name = doc.name || 'Unknown';
    if (doc.type == 'AWB') {
      if (doc.awb?.code && doc.awb?.number) {
        name = `${doc.awb.code}-${doc.awb.number}`;
      } else {
        name = 'Unknown';
      }
    } else if (doc.type == 'FFM') {
      if (doc.flight?.airline && doc.flight?.flight_no && doc.flight?.flight_date) {
        name = `${doc.flight.airline}${doc.flight.flight_no}/${moment(doc.flight.flight_date).format('DDMMMYY').toUpperCase()}`;
      } else {
        name = 'Unknown';
      }
    }
    return (
      <Grid container key={i} justifyContent='space-between' spacing={0} className={`${docStyle.px4} ${styles.colorPrimary}`}>
        <RadioGroup aria-labelledby='documents' name='user-status' defaultValue='all' row className={`${styles.fSpaceBtw} ${styles.w100}`} onChange={() => props.selectDoc(doc)} value={props.docId} >
          <Grid item container>
            <Grid item sx={{ width: '30px' }}>
              <CustomRadio control={<Radio size='small' inputProps={{ 'aria-label': 'documents' }} />} value={doc.id} label='' />
            </Grid>
            <Grid item xs container spacing={2}>
              <Grid item xs={12} sm={5}>
                <Typography variant='subtitle2' component='div' fontWeight={900} flexWrap={'nowrap'}>
                  {name}
                </Typography>
                <Typography variant='caption' color='text.secondary' lineHeight='normal'>
                  {dayjs(doc.created).format('DD MMM YY HH:mm')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant='subtitle2' component='div' fontWeight={900}>
                  {doc.awb ? `${doc.awb.pcs}` : <span className={styles.vHidden}>-</span>}
                </Typography>
                <Typography variant='caption' color='text.secondary' lineHeight='normal'>
                  {doc.numPages} Pages
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant='subtitle2' component='div' fontWeight={900}>
                  {doc.awb ? `${doc.awb.wgt}K` : <span className={styles.vHidden}>-</span>}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              {doc.verified ? <CheckCircleOutline color='success' /> : <DeleteIcon className={docStyle.delIcon} onClick={() => props.deleteDoc(doc.id)} />}
            </Grid>
            {doc.awb ? <Grid item xs={12}>
              <Typography variant='caption' color='text.secondary' lineHeight='normal' sx={{ paddingLeft: '30px' }}>
                {doc.awb.forwarder} 
              </Typography>
            </Grid>
              : <span className={styles.vHidden}>-</span>}
            <Grid item xs={12}>
              <SectionDivider className={styles.my3} />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>
    );

  });
  return (
    <div className={docStyle.h100}>
      <div className={styles.headerFieldContainer}>
        <CustomSearch
          {...InputProps} 
          id='search'
          placeholder='Enter Awb Number'
          type='search'
          fullWidth
          InputProps={{
            disableUnderline: true,
            endAdornment: <InputAdornment position='end'>
              <IconButton
                size='small'
                aria-label='search'
                onClick={() => props.search(true)}
                edge='end'
              >
                <Search fontSize='inherit' />
              </IconButton>
            </InputAdornment>,
          }}
          InputLabelProps={{ shrink: false }}
          onChange={(e) => props.changeAwb(e, 'awb')}
          name='awb'
          value={props.searchOptions.awb}
          className={`${styles.brInput}`}
        // error={}
        // helperText={}
        />
      </div>

      <StyledCard>
        <div className={docStyle.incomingDocHead}>
          <div className={`${styles.mb3} ${styles.fSpaceBtw}`}>
            <StyledCardTitle gutterBottom>Incoming Documents</StyledCardTitle>
            <AddIcon title='Add' onClick={() => props.handleOpen()} />
          </div>

          <Grid container rowSpacing={1} columnSpacing={{
            xs: 2,
            lg: 2
          }}>
            <Grid item>
              <CustomInputLabel>From</CustomInputLabel>
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    fullWidth: true,
                    placeholder: 'From Date'
                  }, 
                }}
                format={DateFormat}
                name='start'
                // error={}
                value={dayjs(props.searchOptions.start)}
                // helperText={}
                onChange={(e) => props.changeAwb(e, 'start')}
                className={docStyle.datePicker}
              />
            </Grid>
            <Grid item>
              <CustomInputLabel>To</CustomInputLabel>
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    fullWidth: true,
                    placeholder: 'To Date'
                  }, 
                }}
                format={DateFormat}
                name='end'
                value={dayjs(props.searchOptions.end)}
                // // error={}
                // // helperText={}
                onChange={(e) => props.changeAwb(e, 'end')}
                className={docStyle.datePicker}
              />
            </Grid>
            <Grid item xs className={styles.mt3}>
              <CustomCheckbox control={<Checkbox size='small' />} classes={{ root: `${styles.mr0} ${docStyle.checkWrapper}` }}
                label={'Show Verified Docs'} value={props.searchOptions.verified} name='verified' onChange={(e) => props.changeAwb(e, 'verified')} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <RadioGroup aria-labelledby='doc-status' name='user-status' defaultValue='all' row className={`${styles.fSpaceBtw} ${docStyle.mdmt}`} value={props.searchOptions.identity} onChange={(e) => props.changeAwb(e, 'identity')}>
                <CustomRadio control={<Radio size='small' />} value='all' label='All ' />
                <CustomRadio control={<Radio size='small' />} value='unknown' label='Unknown' />
                <CustomRadio control={<Radio size='small' />} value='identified' label='Identified' />
              </RadioGroup>
            </Grid>
          </Grid>
          <SectionDivider  className={styles.mt2} />
        </div>
        <div className={docStyle.listDocBody}>
          {docs}
        </div>
      </StyledCard>
    </div>
  );
};

export default (docStreamListComponent);
