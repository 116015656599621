import React from 'react';
import { Grid, FormControl, Typography, MenuItem } from '@mui/material';
import { CustomButton, CustomTextField, CustomSelect, InputProps, SelectInputProps } from '../../../.././custom/components';
import styles from '../../../../custom/style.module.css';
import docStyles from '../docs.module.css';
import { Stack } from '@mui/system';
import FlightManifest from './FlightManifest';
import DataTable from './DataTable';

const OtherDoc = (props) => {
  const { formFields, handleChangeForm, docId, submit, userList, k9, airlines, checkAwb } = props;

  const renderTextField = (label, name, placeholder, value) => (
    <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
      <Typography variant='caption' component='label' htmlFor={name}>{label}</Typography>
      <div className={docStyles.addTextField}>
        <CustomTextField
          {...InputProps}
          id={name}
          autoFocus
          fullWidth
          placeholder={placeholder}
          type='text'
          onChange={(e) => handleChangeForm(e, name)}
          onBlur={name == 'awb_no' ? checkAwb : () => { }}
          name={name}
          value={value}
        />
      </div>
    </Stack>
  );

  const renderSelectField = (label, name, value, options) => (
    <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
      <Typography variant='caption' component='label' id={label}>{label}</Typography>
      <div className={docStyles.addTextField}>
        <FormControl variant='standard' fullWidth>
          <CustomSelect
            {...SelectInputProps}
            labelId={label}
            id=''
            name={name}
            value={value}
            onChange={(e) => handleChangeForm(e, name)}
            defaultValue='OTHER'
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
      </div>
    </Stack>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        {renderTextField('Document Name', 'name', 'Document Name', formFields.name)}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        {renderSelectField('Attach To', 'sub_type', formFields.sub_type, [
          {
            value: 'OTHER',
            label: 'Do not attach: File as "Other"'
          },
          {
            value: 'AWB',
            label: 'Airwaybill'
          },
          {
            value: 'FFM',
            label: 'Flight'
          },
          {
            value: 'USER',
            label: 'User'
          },
          {
            value: 'K9',
            label: 'K9 company'
          }
        ])}
      </Grid>
      {formFields.sub_type !== 'AWB' && formFields.sub_type !== 'FFM' && formFields.sub_type !== 'USER' && formFields.sub_type !== 'k9K9_company' && (
        <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
          {renderTextField('Ref # (optional)', 'ref', 'Ref', formFields.ref)}
        </Grid>
      )}
      {formFields.sub_type === 'AWB' && (
        <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
          {renderTextField('Airwaybill Number', 'awb_no', 'Airwaybill Number', formFields.awb_no)}
        </Grid>
      )}
      {formFields.sub_type === 'AWB' && props.awbs && props.awbs.length > 0 && (
        <Grid item xs={12} className={styles.w100}>
          Select awb
          <DataTable type='AWB' data={props.awbs || []} input={true} value={formFields.awbId} onChange={props.handleChangeForm} />
        </Grid>
      )}
      {formFields.sub_type === 'USER' && (
        <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
          {renderSelectField('Select User', 'userId', Number(formFields.userId), userList.map((user) => ({
            value: user.id,
            label: `${user.first_name} ${user.last_name}`
          })))}
        </Grid>
      )}
      {formFields.sub_type === 'K9' && (
        <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
          {renderSelectField('Select K9 Company', 'k9_companyId', Number(formFields.k9_companyId), k9.map((company) => ({
            value: company.id,
            label: company.name
          })))}
        </Grid>
      )}
      {formFields.sub_type === 'FFM' && (
        <FlightManifest
          handleChangeForm={handleChangeForm}
          formFields={formFields}
          docId={docId}
          submit={submit}
          airlines={airlines}
          type='other'
        />
      )}
      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        {renderTextField('Remarks (Optional)', 'remarks', 'Remarks', formFields.remarks)}
      </Grid>
      <Grid item xs={12} className={`${docStyles.docBtn} ${docStyles.docBtnOnly}`}>
        <CustomButton variant='contained' disabled={!docId} onClick={submit}> Update </CustomButton>
      </Grid>
    </Grid>
  );
};

export default OtherDoc;
