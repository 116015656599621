import React from 'react';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AirwaybillContainer from './tabs/airwaybills/AirwaybillContainer';
import FlightManifestContainer from './tabs/flightManifest/FlightManifestContainer';
import UserTrainingContainer from './tabs/userTraining/UserTrainingContainer';
import OtherDocContainer from './tabs/otherDoc/OtherDocContainer';
import FlightManifestIcon from './../../../assets/images/docArchiveTabIcons/flightmanifest.svg';
import UserTrainingIcon from './../../../assets/images/docArchiveTabIcons/usertraining.svg';
import { docArchiveTabs } from '../../../custom/constants';
import { IconTab } from '../../../custom/components';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';
import adminStyles from '../admin/tabs/admin.module.css';
import docStyles from './tabs/archive.module.css';
import { IconButton } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import theme from 'custom/theme';
import cookies from 'utils/cookies';

const DocArchive = (props) => {
  const role = cookies.get('role');
  const { value } = props;
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 0 }}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Tabs value={value} onChange={(_, v) => props.handleChange(v)}
            indicatorColor='red'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
            classes={{ flexContainer: `${adminStyles.fJcAround} ${docStyles.docTabWrapper}` }}
            ScrollButtonComponent={(props) => {
              if (
                props.direction === 'left'
              ) {
                return (
                  <IconButton {...props} className={adminStyles.tabArrows} disableRipple>
                    <ArrowLeftIcon
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else if (
                props.direction === 'right'
              ) {
                return (
                  <IconButton {...props} className={adminStyles.tabArrows} disableRipple>
                    <ArrowRightIcon
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else {
                return null;
              }
            }}
            className={`${adminStyles.headerTab} ${docStyles.docArchiveTabs}`}>
            {['Office Agent', 'Screener', 'Supervisor', 'Manager', 'System Admin'].includes(role) && (<IconTab disableRipple icon={<div className={docStyles.tabheadName}>AWB</div>} label={docArchiveTabs.awbs} {...a11yProps('awbs')} />)}
            {['Office Agent', 'Screener', 'Supervisor', 'Manager', 'System Admin'].includes(role) && (<IconTab disableRipple icon={<img src={FlightManifestIcon} alt='flight_manifest' />} label={docArchiveTabs.flight_manifest} {...a11yProps('flight_manifest')} />)}
            {['Supervisor', 'Manager', 'System Admin'].includes(role) && (<IconTab disableRipple icon={<img src={UserTrainingIcon} alt='user_training_records' />} label={docArchiveTabs.user_training_records} {...a11yProps('user_training_records')} />)}
            {['Supervisor', 'Manager', 'System Admin'].includes(role) && (<IconTab disableRipple icon={<div className={docStyles.tabheadName}>OTH</div>} label={docArchiveTabs.other_docs} {...a11yProps('other_docs')} />)}
          </Tabs>
        </Box>

        <TabPanel value={value} index={'awbs'}>
          <AirwaybillContainer />
        </TabPanel>

        <TabPanel value={value} index={'flight_manifest'}>
          <FlightManifestContainer />
        </TabPanel>

        <TabPanel value={value} index={'user_training_records'}>
          <UserTrainingContainer />
        </TabPanel>

        <TabPanel value={value} index={'other_docs'}>
          <OtherDocContainer />
        </TabPanel>
      </Box>
      <ConfirmModalContainer />
    </>
  );
};

export default DocArchive;
