import { SET_DOC } from './actionTypes';

function setDoc(data) {
  return {
    type: SET_DOC,
    data
  };
}

function setDocAction(data) {
  return (dispatch) => {
    dispatch(setDoc(data));
  };
}


export { setDocAction };