import React from 'react';
import HandledAirlines from './HandledAirlines';
import http from '../../../../../utils/http';
import { connect } from 'react-redux';
import events from '../../../../../utils/evemts';
import cookies from 'utils/cookies';

class HandledAirlinesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airlineList: [],
      locationModal: false,
      airlineFields: {},
      airlineErrors: {},
      airlineId: 0,
      openAddAirline: false,
      type: 'add',
      location: false,
      locationType: false,
      station: [],
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchAirlines();
    this.eventSource = events('admin', 'airline');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      let station = parseInt(cookies.get('station'));
      if (msg.action == 'add' && msg.data?.stations?.some(stn => stn.id === station)) {
        this.setState({ airlineList: [...this.state.airlineList, msg.data] });
      }
      if (msg.action == 'update') {
        const airlineList = [...this.state.airlineList];
        const index = airlineList.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          airlineList[index] = {
            ...msg.data,
            stations: msg.data?.stations && msg.data?.stations?.length > 0 ? msg.data.stations : airlineList[index].stations
          };
          if (!airlineList[index].stations?.some(stn => stn.id === station)) {
            airlineList.splice(index, 1);
          }
          this.setState({ airlineList });
        } else {
          if (msg.data?.stations && msg.data?.stations?.length > 0) {
            if (msg.data?.stations?.some(stn => stn.id === station)) {
              this.fetchAirlines();
            }
          }
        }
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  fetchAirlines = () => {
    http.get('/airline?all=true')
      .then((response) => {
        // handle success
        this.setState({ airlineList: response.data.data });
      });
  };

  handleClose = () => {
    this.setState({
      openAddAirline: false,
      airlineId: false,
      airlineFields: {},
      airlineErrors: {},
      type: false
    });
  };

  onChange = (e) => {
    let value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;
    this.setState({
      airlineFields: {
        ...this.state.airlineFields,
        [name]: value
      }
    });
  };

  updateAirline = async (id, data) => {
    await http.put('/airline/update/' + id, data)
      .then(() => {
        this.setState({
          airlineFields: {
            name: '',
            code: '',
            awb_pre: ''
          },
          openAddAirline: false,
          type: 'add'
        });
      });
  };

  addEditAirline = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.airlineFields };
    if (this.validation()) {
      if (this.state.airlineId) {
        this.updateAirline(this.state.airlineId, formdata);
      } else {
        await http.post('/airline/create', formdata)
          .then(() => {
            this.setState({
              airlineFields: {
                name: '',
                code: '',
                awb_pre: ''
              },
              openAddAirline: false
            });
          });
      }

    }
  };

  validation = () => {
    const airlineFields = { ...this.state.airlineFields };
    const error = {};
    let formValid = true;
    if (!airlineFields.name || (airlineFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter an airline name';
    }
    else if (!airlineFields.code || (airlineFields.code.trim().length === 0)) {
      formValid = false;
      error.code = 'Please enter a code';
    }
    else if (airlineFields.code && (airlineFields.code.trim().length > 2 || (airlineFields.code.trim().length < 2))) {
      formValid = false;
      error.code = 'Airline code should be two digits';
    }
    else if (!airlineFields.awb_pre || (airlineFields.awb_pre.trim().length === 0)) {
      formValid = false;
      error.awb_pre = 'Please enter an AWB prefix';
    }
    else if (airlineFields.awb_pre && (airlineFields.awb_pre.trim().length > 3 || airlineFields.awb_pre.trim().length < 3)) {
      formValid = false;
      error.code = 'Prefix should be three digits';
    }
    this.setState({ airlineErrors: error });
    return formValid;
  };

  handleOpen = (type, airlineFields = {}) => {
    this.setState({
      openAddAirline: true,
      airlineId: airlineFields.id || false,
      airlineFields,
      type: type
    });
  };

  setActive = (id, active) => {
    this.updateAirline(id, { active });
  };

  handleLocation = (locationType, v, data = {}, anchorEl = null) => {
    this.setState({
      location: v,
      locationType,
      station: data,
      anchorEl
    });
  };


  render() {
    return (
      <>
        <HandledAirlines
          airlineList={this.state.airlineList}
          handleClose={this.handleClose}
          locationModal={this.state.locationModal}
          handleOpen={this.handleOpen}
          onChange={this.onChange}
          openAddAirline={this.state.openAddAirline}
          airlineFields={this.state.airlineFields}
          airlineErrors={this.state.airlineErrors}
          addAirline={this.addEditAirline}
          type={this.state.type}
          anchorEl={this.state.anchorEl}
          location={this.state.location}
          locationType={this.state.locationType}
          handleLocation={this.handleLocation}
          stations={this.props.stations}
          station={this.state.station}
          setActive={this.setActive}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { stations: state.stations };
};

export default connect(mapStateToProps)(HandledAirlinesContainer);
