import { combineReducers } from 'redux';
import { companyReducer } from './companyReducer';
import { deleteReducer } from './deleteReducer';
import { docReducer } from './docReducer ';
import { screenReducer } from './screenReducer';


export const rootReducer = combineReducers({
  stations: companyReducer,
  delete: deleteReducer,
  doc: docReducer,
  screen: screenReducer
});