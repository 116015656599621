import React from 'react';
import { Dialog } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import admin from '../admin.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomDialogContent } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const AlarmResModal = (props) => {
  const { action, alarmFields, alarmErrors, onChangeAlarm, addAlarm, editAlarm } = props;
  return (
    <div>
      <Dialog 
        aria-labelledby='form-dialog-title'
        open={props.modal === 'alarm'} 
        onClose={props.onClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add' : 'Edit'} Alarm Resolution
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100} onSubmit={action == 'add' ? addAlarm : editAlarm} onReset={props.onClose}>
          <CustomDialogContent className={admin.inputForm}>
            <StyledCard>
              <div>
                <CustomInputLabel htmlFor='resolution'>Resolution</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='resolution'
                  className={`${admin.addTextField} ${styles.mb3}`}
                  placeholder='Resolution'
                  type='text'
                  name='name'
                  onChange={onChangeAlarm}
                  value={alarmFields.name}
                  error={alarmErrors.name}
                  helperText={alarmErrors.name}
                />
              </div>
              <div>
                <CustomInputLabel htmlFor='code'>Code</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='code'
                  className={`${admin.addTextField} ${styles.mb3}`}
                  placeholder='Code'
                  type='text'
                  onChange={onChangeAlarm}
                  name='code'
                  value={alarmFields.code}
                  error={alarmErrors.code}
                  helperText={alarmErrors.code}
                />
              </div>
            </StyledCard>
          </CustomDialogContent>
          
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' type='reset'>Close</CustomButton>
            <CustomButton variant='contained' type='submit'> Submit </CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AlarmResModal;