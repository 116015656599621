import React from 'react';
import K9Companies from './K9Companies';
import events from 'utils/evemts';
import http from 'utils/http';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
import { setDocAction } from 'store/actions/docAction';
import cookies from 'utils/cookies';
class K9CompaniesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      k9: [],
      checklist: [],
      anchorEl: null,
      modal: false,
      location: false,
      locationType: false,
      checklistFields: {},
      checklistErrors: {},
      k9Fields: {},
      k9Errors: {},
      action: null,
      station: []
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchK9();
    this.fetchChecklist();
    this.eventSource = events('admin', 'ccsf');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      let station = parseInt(cookies.get('station'));
      if (msg.action == 'add_k9' && msg.data?.stations?.some(stn => stn.id === station)) {
        this.setState({ k9: [...this.state.k9, msg.data] });
      }
      if (msg.action == 'update_k9' || msg.action == 'delete_k9') {
        const k9 = [...this.state.k9];
        const index = k9.findIndex(c => msg.data && c.id == msg.data.id);
        if (msg.action == 'update_k9') {
          if (index > -1) {
            if (msg.data.station) {
              k9[index] = {
                ...k9[index],
                stations: msg.data.stations
              };
            }
            k9[index] = {
              ...msg.data,
              stations: k9[index].stations,
              docs: k9[index].docs
            };
            if (!k9[index].stations?.some(stn => stn.id === station)) {
              k9.splice(index, 1);
            }
            this.setState({ k9 });
          } else {
            if (msg.data?.stations && msg.data?.stations?.length > 0) {
              if (msg.data?.stations?.some(stn => stn.id === station)) {
                this.fetchK9();
              }
            }
          }
        }
        if (msg.action == 'delete_k9') {
          if (index > -1) {
            k9.splice(index, 1);
            this.setState({ k9 });
          }
        }
      }

      if (msg.action == 'add_k9_doc' || msg.action == 'update_k9_doc' || msg.action == 'delete_k9_doc') {
        const k9 = [...this.state.k9];
        const index = k9.findIndex(c => msg.data && c.id == msg.data.k9_companyId);
        if (msg.action == 'add_k9_doc' && index > -1) {
          k9[index] = {
            ...k9[index],
            docs: [...k9[index].docs, msg.data]
          };
          this.setState({ k9 });
        }
        if ((msg.action == 'update_k9_doc' || msg.action == 'delete_k9_doc') && index > -1) {
          let docs = [...k9[index].docs];
          const i = docs.findIndex(c => c.id == msg.data.id);
          if (msg.action == 'update_k9_doc') {
            if (i > -1) {
              docs[i] = {
                ...msg.data,
                added_by: docs[i].added_by
              };
              k9[index].docs = docs;
              this.setState({ k9 });
            } else {
              docs.push({
                ...msg.data,
                added_by: {}
              });
              k9[index].docs = docs;
              this.setState({ k9 });
            }
          }
          if (msg.action == 'delete_k9_doc') {
            if (i > -1) {
              docs.splice(i, 1);
              k9[index].docs = docs;
              this.setState({ k9 });
            }
          }
        }

        if (this.props.doc.modal == 'k9' && this.props.doc.id == msg.data.k9_companyId && index > -1) {
          this.props.setDoc({
            modal: 'k9',
            id: k9[index].id,
            name: k9[index].name,
            data: k9[index].docs
          });
        }
      }

      if (msg.action == 'add_checklist' && msg.data?.stations?.some(stn => stn.id === station)) {
        this.setState({ checklist: [...this.state.checklist, msg.data] });
      }
      if (msg.action == 'update_checklist' || msg.action == 'delete_checklist') {
        const checklist = [...this.state.checklist];
        const index = checklist.findIndex(c => msg.data && c.id == msg.data.id);
        if (msg.action == 'update_checklist') {
          if (index > -1) {
            if (msg.data.station) {
              checklist[index] = {
                ...checklist[index],
                stations: msg.data.stations
              };
            }
            checklist[index] = {
              ...msg.data,
              stations: checklist[index].stations
            };
            if (!checklist[index].stations?.some(stn => stn.id === station)) {
              checklist.splice(index, 1);
            }
            this.setState({ checklist });
          } else {
            if (msg.data?.stations && msg.data?.stations?.length > 0) {
              if (msg.data?.stations?.some(stn => stn.id === station)) {
                this.fetchChecklist();
              }
            }
          }
        }
        if (msg.action == 'delete_checklist') {
          if (index > -1) {
            checklist.splice(index, 1);
            this.setState({ checklist });
          }
        }
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  fetchK9 = () => {
    http.get('/k9_company').then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ k9: data.data });
      }
    });
  };

  fetchChecklist = () => {
    http.get('/checklist').then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ checklist: data.data });
      }
    });
  };

  onChangeK9 = (e) => {
    this.setState({
      k9Fields: {
        ...this.state.k9Fields,
        [e.target.name]: e.target.type == 'checkbox' ? e.target.checked : e.target.value
      }
    });
  };

  onChangeChecklist = (e) => {

    this.setState({
      checklistFields: {
        ...this.state.checklistFields,
        [e.target.name]: e.target.type == 'checkbox' ? e.target.checked : e.target.value
      }
    });
  };

  handleOpen = (modal, action, data = {}) => {
    this.setState({
      modal,
      action,
      k9Fields: modal && modal.includes('k9') ? data : {},
      checklistFields: modal == 'checklist' ? data : {},
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
      checklistFields: {},
    });
  };

  handleLocation = (locationType, v, data = {}, anchorEl = null) => {
    this.setState({
      location: v,
      locationType,
      station: data,
      anchorEl
    });
  };

  validationK9 = () => {
    const k9Fields = { ...this.state.k9Fields };
    const error = {};
    let formValid = true;
    if (!k9Fields.name || (k9Fields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter company name.';
    }
    this.setState({ k9Errors: error });
    return formValid;
  };

  validationK9_deactivate = () => {
    const k9Fields = { ...this.state.k9Fields };
    const error = {};
    let formValid = true;
    if (!k9Fields.reason || (k9Fields.reason.trim().length === 0)) {
      formValid = false;
      error.reason = 'Please enter reason.';
    }
    this.setState({ k9Errors: error });
    return formValid;
  };

  addK9 = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.k9Fields };
    if (this.validationK9()) {
      http.post('/k9_company', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            k9Fields: {},
            k9Errors: {},
            action: null,
          });
        }
      });
    }
  };

  editK9 = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.k9Fields };
    delete formdata.station;
    if (this.validationK9()) {
      http.put('/k9_company/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            k9Fields: {},
            k9Errors: {},
            action: null,
          });
        }
      });
    }
  };

  active = async (e) => {
    e.preventDefault();
    let formdata = { active: true };
    http.put('/k9_company/' + this.state.k9Fields.id, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          modal: false,
          k9Fields: {},
          k9Errors: {},
          action: null,
        });
      }
    });
  };

  deactive = async (e) => {
    e.preventDefault();
    if (this.validationK9_deactivate()) {
      let formdata = {
        active: false,
        reason: this.state.k9Fields.reason
      };
      http.put('/k9_company/' + this.state.k9Fields.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            k9Fields: {},
            k9Errors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteK9 = (id) => {
    http.delete('/k9_company/' + id);
  };


  validationChecklist = () => {
    const checklistFields = { ...this.state.checklistFields };
    const error = {};
    let formValid = true;
    if (!checklistFields.txt || (checklistFields.txt.trim().length === 0)) {
      formValid = false;
      error.txt = 'Please enter description.';
    }
    this.setState({ checklistErrors: error });
    return formValid;
  };

  addChecklist = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.checklistFields };
    if (this.validationChecklist()) {
      http.post('/checklist', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            checklistFields: {},
            checklistErrors: {},
            action: null,
          });
        }
      });
    }
  };

  editChecklist = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.checklistFields };
    delete formdata.station;
    if (this.validationChecklist()) {
      http.put('/checklist/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            checklistFields: {},
            checklistErrors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteChecklist = (id) => {
    http.delete('/checklist/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleDelete = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteChecklist(id),
    });
  };

  handleDoc = (row) => {
    this.props.setDoc({
      modal: 'k9',
      id: row.id,
      name: row.name,
      data: row.docs
    });
  };

  render() {
    return (
      <>
        <K9Companies
          stations={this.props.stations}
          station={this.state.station}
          anchorEl={this.state.anchorEl}
          modal={this.state.modal}
          action={this.state.action}
          location={this.state.location}
          locationType={this.state.locationType}
          checklist={this.state.checklist}
          k9={this.state.k9}
          k9Fields={this.state.k9Fields}
          k9Errors={this.state.k9Errors}
          checklistFields={this.state.checklistFields}
          checklistErrors={this.state.checklistErrors}
          handleLocation={this.handleLocation}
          addK9={this.addK9}
          editK9={this.editK9}
          active={this.active}
          deactive={this.deactive}
          addChecklist={this.addChecklist}
          editChecklist={this.editChecklist}
          handleDelete={this.handleDelete}
          onChangeK9={this.onChangeK9}
          onChangeChecklist={this.onChangeChecklist}
          handleOpen={this.handleOpen}
          handleDoc={this.handleDoc}
          onClose={this.onClose}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    doc: state.doc,
    stations: state.stations
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDelete: (data) => dispatch(setDeleteAction(data)),
    setDoc: (data) => dispatch(setDocAction(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(K9CompaniesContainer);
