import React, { Component } from 'react';
import { Dialog, Grid, IconButton, Typography, InputAdornment, Tooltip } from '@mui/material';
import styles from 'custom/style.module.css';
import admin from '../admin/tabs/admin.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction,CustomDialogContent, CustomInputLabel, StyledCard, InputProps } from 'custom/components';
import { BrowseIcon, CloseIconBtn } from 'custom/constants';
import CloseIcon from '@mui/icons-material/Close';
class AddDocModal extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      selectedFile: null,
      fileError:'',
    };
  }
  
  handleFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({ 
      selectedFile: file, 
      fileError:'',
    });
    this.props.onChange(e);
  };

  clearFile = () => {
    const file = document.getElementById('file');
    file.value = '';
    this.setState({ selectedFile: null });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedFile } = this.state;
    const { action, add, edit } = this.props;
    if (selectedFile) {
      if (action === 'add'){
        add(e,(success)=>{
          if(success){
            this.handleClose(e);
          }
        });
      } 
    } else {
      this.setState({ fileError: 'Please select file.' });
    }   
    if (action === 'edit') {
      edit(e);
    }
  };

  handleClose = (e) => {
    this.setState({ 
      selectedFile: null, 
      fileError:'' 
    });
    this.props.onChange({ 
      target: { 
        name: 'name', 
        value: '' 
      } 
    });
    this.props.errors.name = '';
    this.props.onClose(e);
  };

  render() {
    const { action, modal, fields, errors, add, edit, onChange } = this.props;
    const { selectedFile, fileError } = this.state;
    
    return (
      <div>
        <Dialog 
          aria-labelledby='form-dialog-title'
          open={modal} 
          onClose={this.handleClose}
          maxWidth={'sm'}
          fullWidth={true} 
        >
          <CustomDialogTitle id='form-dialog-title'>
            {action == 'add' ? 'Add New Attachment' : 'Edit Attachment'}
            <CloseIconBtn onClick={this.handleClose} />
          </CustomDialogTitle>
          <form className={styles.w100} onSubmit={action == 'add' ? add : edit}>
            <CustomDialogContent>
              <StyledCard>
                <Grid container spacing={2}>
                  <Grid item xs={12} >
                    <CustomInputLabel htmlFor='name'>Name</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='name'
                      placeholder='Name'
                      type='text'
                      name='name'
                      onChange={onChange}
                      value={fields.name}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position='end'>
                            {selectedFile && (<Tooltip title={fields?.file?.name}>
                              <IconButton size='small' variant='contained' color='text.secondary' onClick={this.clearFile}>
                                <CloseIcon fontSize='inherit' />
                              </IconButton>
                              <span className={styles.fileName}>{fields?.file?.name}</span>
                            </Tooltip>)}
                            {action == 'add' && (
                              <IconButton size='small'>
                                <input
                                  accept='application/pdf,image/*'
                                  style={{ display: 'none' }}
                                  id='file'
                                  type='file'
                                  name='file'
                                  onChange={this.handleFileChange}
                                />
                                <label htmlFor='file' className={`${styles.fs0} ${styles.pointer}`}>
                                  <BrowseIcon />
                                </label>
                              </IconButton> 
                            )}
                          </InputAdornment>
                        )
                      }}
                      error={errors.name}
                      helperText={errors.name}
                    />
                    {action == 'add' && (
                      fileError && (
                        <Typography variant='caption' color='error'>
                          {fileError}
                        </Typography>
                      )
                    )}
                  </Grid>
                  <Grid item xs={12} >
                    <CustomInputLabel htmlFor='remark'>Remark</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='remark'
                      className={`${admin.addTextField}`}
                      placeholder='Remark'
                      type='text'
                      name='remark'
                      onChange={onChange}
                      value={fields.remark}
                      error={errors.remark}
                      helperText={errors.remark}
                    />
                  </Grid>
                  {/* {action == 'add' && (<Grid item>
                    <input
                      accept='application/pdf,image/*'
                      style={{ display: 'none' }}
                      id='file'
                      type='file'
                      name='file'
                      onChange={onChange}
                    />
                    <label htmlFor='file'>
                      <Button variant='contained' component='span'>
                        Choose filex
                      </Button>
                    </label>
                  </Grid>)} */}
                </Grid>
              </StyledCard>
            </CustomDialogContent>
            <CustomDialogAction className={`${styles.fSpaceBtw}`}>
              <CustomButton variant='contained' type='reset' onClick={this.handleClose}> Close </CustomButton>
              <CustomButton variant='contained' type='submit' onClick={this.handleSubmit}> Submit </CustomButton>
            </CustomDialogAction>
          </form>
        </Dialog>
      </div>
    );
  }
}
export default AddDocModal;