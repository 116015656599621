import React from 'react';
import {
  Grid,
  FormControl,
  Typography,
  MenuItem,
  RadioGroup,
  Radio,
  InputAdornment,
  IconButton,
  CircularProgress
} from '@mui/material';
import {
  CustomButton,
  CustomTextField,
  CustomDatePicker,
  CustomSelect,
  InputProps,
  CustomRadio,
  SelectInputProps
} from '../../../.././custom/components';
import styles from '../../../../custom/style.module.css';
import docStyles from '../docs.module.css';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { Search } from '@mui/icons-material';
import { DateFormat, preScreened } from 'custom/constants';

const awbDoc = (props) => {
  const { airlines } = props;
  const flightDates = props.flightDates ? props.flightDates.map(flight => flight.flight_date) : [];

  const minDate = flightDates
    .map(date => dayjs(date))
    .reduce((min, date) => (date.isBefore(min) ? date : min), dayjs(flightDates[0]));

  const maxDate = flightDates
    .map(date => dayjs(date))
    .reduce((max, date) => (date.isAfter(max) ? date : max), dayjs(flightDates[0]));


  return (
    <Grid container spacing={2} flexWrap='wrap'>
      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='awb_no'>Awb Number</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='awb_no'
              autoFocus
              fullWidth
              placeholder='Airwaybill Number'
              disabled={props.isLoading}
              type='text'
              name='awb_no'
              onChange={(e) => props.handleChangeForm(e, 'awb_no')}
              value={props.formFields.awb_no || ''}
              // error={}
              InputProps={{
                disableUnderline: true,
                endAdornment: <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    aria-label='search'
                    edge='end'
                    onClick={() => props.searchAwb()}
                  >
                    <Search fontSize='inherit' />
                  </IconButton>
                </InputAdornment>,
              }}
              InputLabelProps={{ shrink: false }}
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='pieces'>Pieces/Weight</Typography>
          <Stack direction='row' spacing={1} className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='pieces'
              autoFocus
              fullWidth
              placeholder='Pieces'
              type='text'
              disabled={props.isLoading}
              onChange={(e) => props.handleChangeForm(e, 'pcs')}
              value={props.formFields.pcs || ''}
              name='pcs'
              inputProps={{ 'aria-label': 'Pieces' }}
            // error={}
            // helperText={}
            />
            <CustomTextField
              {...InputProps}
              id='weight'
              autoFocus
              fullWidth
              placeholder='Weight'
              type='text'
              disabled={props.isLoading}
              name='wgt'
              onChange={(e) => props.handleChangeForm(e, 'wgt')}
              value={props.formFields.wgt || ''}
              inputProps={{ 'aria-label': 'Weight' }}
            // error={}
            // helperText={}
            />
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='origin'>Origin/Dest</Typography>
          <Stack direction='row' spacing={1} className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='origin'
              autoFocus
              fullWidth
              disabled={props.isLoading}
              placeholder='Origin'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'org')}
              value={props.formFields.org || ''}
              name='org'
              inputProps={{ 'aria-label': 'Org' }}
            // value={}
            // error={}
            // helperText={}
            />
            <CustomTextField
              {...InputProps}
              id='destination'
              autoFocus
              fullWidth
              disabled={props.isLoading}
              placeholder='Dest'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'dest')}
              value={props.formFields.dest || ''}
              name='dest'
              inputProps={{ 'aria-label': 'Dest' }}
            // error={}
            // helperText={}
            />
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='commodity'>Commodity</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='commodity'
              autoFocus
              disabled={props.isLoading}
              fullWidth
              placeholder='Commodity'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'commodity')}
              value={props.formFields.commodity || ''}
              name='commodity'
            // error={}
            // helperText={}
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' id='airline-label'>Airline</Typography>
          <div className={docStyles.addTextField}>
            <FormControl variant='standard'
              fullWidth
            // error={}
            >
              <CustomSelect
                {...SelectInputProps}
                labelId='airline-label'
                id=''
                name='airlineId'
                disabled={props.isLoading}
                onChange={(e) => props.handleChangeForm(e, 'airlineId')}
                value={Number(props.formFields.airlineId) || ''}
              >
                {airlines && airlines.map((airline, i) => {
                  if (props.flightSettings?.limit_input) {
                    let find = props.scheduleList?.find((schdl) => schdl.airline == airline.code);
                    if (find) {
                      return (<MenuItem key={i} value={airline.id}>{airline.code}</MenuItem>);
                    }
                  } else {
                    return (<MenuItem key={i} value={airline.id}>{airline.code}</MenuItem>);
                  }
                })}
              </CustomSelect>
              {/* <FormHelperText>{}</FormHelperText> */}
            </FormControl>
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='flight_no'>Flight Number</Typography>
          <div className={docStyles.addTextField}>
            {props.flightSettings?.limit_input && (
              <CustomSelect
                {...SelectInputProps}
                labelId='airline-label'
                id=''
                name='flight_no'
                disabled={props.isLoading}
                onChange={(e) => props.handleChangeForm(e, 'flight_no')}
                value={props.formFields.flight_no || ''}
              >
                {props.scheduleList && props.scheduleList.map((schdl, i) => {
                  let airline = airlines?.find((airline) => airline.id == Number(props.formFields.airlineId));
                  if (airline?.code == schdl.airline)
                    return (<MenuItem key={i} value={schdl.flight_no}>{schdl.flight_no}</MenuItem>);
                })}

              </CustomSelect>)}
            {!props.flightSettings?.limit_input && (<CustomTextField
              {...InputProps}
              id='flight_no'
              autoFocus
              fullWidth
              placeholder='Flight Number'
              type='text'
              disabled={props.isLoading}
              onChange={(e) => props.handleChangeForm(e, 'flight_no')}
              value={props.formFields.flight_no || ''}
              name='flight_no'
              onBlur={props.getFlightDates}
            // error={}
            // helperText={}
            />)}
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor=''>Flight Date</Typography>
          <div className={docStyles.addTextField}>
            <CustomDatePicker
              slotProps={{
                textField: {
                  ...InputProps,
                  fullWidth: true,
                  placeholder: 'Flight Date'
                },
              }}
              format={DateFormat}
              name='flight_date'
              className={styles.w100}
              disabled={props.isLoading}
              onChange={(e) => props.handleChangeForm(e, 'flight_date')}
              value={props.formFields.flight_date ? dayjs(props.formFields.flight_date, 'YYYY-MM-DD') : null}
              // error={ }
              // helperText={ }
              minDate={props.flightSettings?.limit_input ? minDate : null}
              maxDate={props.flightSettings?.limit_input ? maxDate : null}
              shouldDisableDate={(date) => {
                if (props.flightSettings?.limit_input) {
                  !flightDates.some((flightDate) =>
                    dayjs(flightDate).isSame(date, 'day')
                  );
                } else {
                  return false;
                }
              }

              }
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='forwarder'>Forwarder</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='forwarder'
              autoFocus
              fullWidth
              disabled={props.isLoading}
              placeholder='Forwarder'
              type='text'
              name='forwarder'
              onChange={(e) => props.handleChangeForm(e, 'forwarder')}
              value={props.formFields.forwarder || ''}
            // error={}
            // helperText={}
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='flex-start' useFlexGap flexWrap='wrap'>
          <RadioGroup value={props.formFields.security?.transportation_profile || ''} onChange={(e) => props.handleSecurity(e, 'transportation_profile')} aria-labelledby='' name='' defaultValue='IAC' row className={`${docStyles.docRadioWrapper} ${styles.ml0}`}>
            <CustomRadio control={<Radio size='small' />} value='iac' label='IAC' />
            <CustomRadio control={<Radio size='small' />} value='ds' label='Direct Shipper' className={`${styles.mr0}`} />
          </RadioGroup>
          <Stack direction='row' className={`${docStyles.addTextField}`}>
            <div>
              <CustomTextField
                {...InputProps}
                id=''
                autoFocus
                fullWidth
                placeholder='Approval Number'
                onChange={(e) => props.handleSecurity(e, 'transportation_number')}
                value={props.formFields.security?.transportation_number || ''}
                disabled={!props.formFields.security?.transportation_profile || props.isLoading}
                type='text'
                onBlur={props.checkTransporationNumber}
                name=''
                inputProps={{ 'aria-label': 'Approval Number' }}
              />
              <div className={styles.fvMiddle}>
                {props.formFields?.security?.transportation_profile == 'iac' && props.formFields?.security?.transportation_number &&
                  <div className={`${docStyles.check}`}>{props.checkElement(props.formFields?.security?.valid_transportation)}</div>
                }
              </div>
            </div>
          </Stack>
        </Stack>
        {props.formFields.security?.transportation_number && props.formFields.security?.iac_name ? <Typography variant='caption' component='span' color='primary' className={docStyles.iacName}>IAC Name: {props.formFields.security?.iac_name}</Typography> : ''}
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' id='pre_screened_label'>Pre Screened?</Typography>
          <div className={docStyles.addTextField}>
            <FormControl variant='standard' fullWidth>
              <CustomSelect
                {...SelectInputProps}
                labelId='pre_screened_label'
                id='pre_screened'
                name='pre_screened'
                disabled={props.isLoading}
                onChange={(e) => props.handleChangeForm(e, 'pre_screened')}
                value={props.formFields.pre_screened || ''}
              >
                {preScreened.map((pre, i) => (
                  <MenuItem value={pre.value} key={i}>{pre.label}</MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </div>
        </Stack>
      </Grid>

      {props.formFields.pre_screened === 'interline' &&
        <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
            <Typography variant='caption' component='label' htmlFor='transferring_airlines'>Transferring Airline</Typography>
            <div className={docStyles.addTextField}>
              <FormControl variant='standard' fullWidth>
                <CustomTextField
                  {...InputProps}
                  id='transferring_airlines'
                  autoFocus
                  fullWidth
                  placeholder=''
                  value={props.formFields.security?.transferring_airline || ''}
                  type='text'
                  onChange={(e) => props.handleSecurity(e, 'transferring_airline')}
                  name=''
                  disabled={props.formFields.pre_screened !== 'interline' || props.isLoading}
                />
              </FormControl>
            </div>
          </Stack>
        </Grid>
      }

      {props.formFields.pre_screened === 'ccsf' &&
        <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
          <Stack direction='row' justifyContent='space-between' alignItems='flex-start' useFlexGap flexWrap='wrap'>
            <Typography variant='caption' component='label' htmlFor='ccsf_nbr'>CCSF Approval Nbr</Typography>
            <div className={docStyles.addTextField}>
              <FormControl variant='standard' fullWidth>
                <Stack direction='row'>
                  <div>
                    <CustomTextField
                      {...InputProps}
                      id='ccsf_nbr'
                      disabled={props.isLoading}
                      autoFocus
                      fullWidth
                      placeholder=''
                      value={props.formFields.security?.ccsf_approval_number || ''}
                      type='text'
                      onChange={(e) => props.handleSecurity(e, 'ccsf_approval_number')}
                      onBlur={props.checkTransporationNumber}
                      name=''
                    />
                    <div className={styles.fvMiddle}>
                      {props.formFields?.security?.ccsf_approval_number &&
                        <div className={docStyles.check}>{props.checkElement(props.formFields?.security?.valid_transportation)}</div>
                      }
                    </div>
                  </div>
                </Stack>
              </FormControl>
            </div>
          </Stack>
          {props.formFields.security?.ccsf_approval_number && props.formFields.security?.ccsf_name ? <Typography variant='caption' component='span' color='primary' fontWeight={600} className={`${docStyles.iacName} ${styles.mt1}`}> CCSF Name: {props.formFields.security?.ccsf_name} </Typography> : ''}
        </Grid>
      }

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' id='shipper-profile-label'>Shipper Profile</Typography>
          <div className={docStyles.addTextField}>
            <FormControl variant='standard'
              fullWidth
            // error={}
            >
              <CustomSelect
                {...SelectInputProps}
                labelId='shipper-profile-label'
                id='shipper_profile'
                name='station'
                disabled={props.isLoading}
                onChange={(e) => props.handleChangeForm(e, 'shipper_profile')}
                value={props.formFields.shipper_profile || ''}
              >
                <MenuItem value='known'>Known</MenuItem>
                <MenuItem value='unknown'>Unknown</MenuItem>

              </CustomSelect>
              {/* <FormHelperText>{}</FormHelperText> */}
            </FormControl>
          </div>

        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' id='unknown-ex-label'>Unknown Exceptions</Typography>
          <div className={docStyles.addTextField}>
            <FormControl variant='standard' fullWidth>
              <CustomSelect
                {...SelectInputProps}
                labelId='unknown-ex-label'
                id='unknown_ex'
                name=''
                disabled={props.isLoading}
                onChange={(e) => props.handleChangeForm(e, 'unk_exceptions')}
                value={props.formFields.unk_exceptions || ''}
              >
                <MenuItem value=''></MenuItem>
                <MenuItem value='Unknown On Freighter'>Unknown On Freighter</MenuItem>
                <MenuItem value='Avi (Live Animals)'>Avi (Live Animals)</MenuItem>
                <MenuItem value='LHO (Live Human Organs)'>LHO (Live Human Organs)</MenuItem>
                <MenuItem value='Dept Of Defence'>Dept Of Defence</MenuItem>
                <MenuItem value='CBP Order Of Export'>CBP Order Of Export</MenuItem>
                <MenuItem value='Interline Transfer'>Interline Transfer</MenuItem>

              </CustomSelect>
            </FormControl>
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} className={`${docStyles.docBtn} ${docStyles.docBtnOnly}`}>
        {props.isLoading ? (
          <CircularProgress size={24} /> // Replace with your desired loader/spinner component
        ) : (
          <CustomButton variant='contained' disabled={!props.docId || props.disableButton} onClick={props.submit}> Update </CustomButton>

        )}
      </Grid>
    </Grid>
  );
};

export default awbDoc;