import React, { Component } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Document, Page } from 'react-pdf';
import { isImg, isDoc } from '../../../utils/attachments';
import unrecognizedDocsStyles from './docs.module.css';
import { StyledCard, StyledCardTitle, } from '../../../custom/components';
import environment from '../../../custom/environments';

class ViewDocuments extends Component {

  constructor(props) {
    super(props);
    this.state = { numPages: {} };
  }


  openLink = (link) => {
    window.open(link);
  };

  onDocumentLoadSuccess = (num, index) => {
    const numPages = this.state.numPages;
    numPages[`pdf_${index}`] = num._pdfInfo.numPages;
    this.setState({ numPages });
  };

  onDocumentLoadError = (error) => {
    console.log('🚀 ~ file: DocViewer.js:87 ~ ViewDocuments ~ error:', error);
  };

  render() {

    return (
      <StyledCard classes={{ paper: unrecognizedDocsStyles.h100 }}>
        <div className={unrecognizedDocsStyles.incomingDocHead}>
          <StyledCardTitle gutterBottom variant='cardTitle'>Document Viewer</StyledCardTitle>
        </div>
        <div className={unrecognizedDocsStyles.viewDocBody}>
          {this.props.docData ? this.props.docData.map((item, i) => {
            const itemLink = environment.apiService + 'document?key=' + item.src;
            const fileName = item.src.split('/').pop();
            return (
              <div key={i} className={`${unrecognizedDocsStyles.canvasDoc} ${unrecognizedDocsStyles.px4}`}>

                {isImg(fileName) && (
                  <img
                    src={itemLink}
                    alt={fileName}
                    className={unrecognizedDocsStyles.image}
                  />
                )}
                {isDoc(fileName) && (
                  <Document
                    file={itemLink}
                    onItemClick={() => {
                      this.openLink(itemLink);
                    }}
                    options={{ withCredentials: true }}
                    onLoadSuccess={(page) => this.onDocumentLoadSuccess(page, i)}
                    onLoadError={this.onDocumentLoadError}
                    className={unrecognizedDocsStyles.docDoc}
                  > {
                      Array.from(
                        new Array(this.state.numPages[`pdf_${i}`]),
                        (el, index) => (
                          <Page
                            className={unrecognizedDocsStyles.docPage}
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            renderTextLayer={false}
                          />
                        )
                      )
                    }
                  </Document>
                )}
              </div>
            );
          }) : <div></div>}
        </div>
      </StyledCard>

    );
  }

}

export default (ViewDocuments);
