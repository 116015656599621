import React from 'react';
import FlightSchedule from './FlightSchedule';
import flightHttp from 'utils/flightHttp';
import http from 'utils/http';
import events from 'utils/evemts';
import cookies from 'utils/cookies';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
// import moment from "moment";

class FlightScheduleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleList: [],
      anchorEl: null,
      locationModal: false,
      scheduleFields: { org: cookies.get('station_code') || null },
      scheduleErrors: {},
      scheduleId: 0,
      openAddSchedule: false,
      type: 'add',
      flightSettings: {
        mandatory_number: false,
        limit_input: false
      },
      airlineList: []
    };
    this.eventSource = null;
    this.station_code = cookies.get('station_code') || null;
  }

  componentDidMount() {
    this.fetchAirlines();
    this.getConfigValues();
    this.fetchSchedules();
    this.setState({ scheduleFields: { org: this.station_code } });
    this.eventSource = events('admin', 'flight_schedule');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.action == 'ADD') {
        this.setState({ scheduleList: [...this.state.scheduleList, msg.data] });
      }
      if (msg.action == 'UPDATE') {
        const scheduleList = [...this.state.scheduleList];
        const index = scheduleList.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          scheduleList[index] = { ...msg.data };
          this.setState({ scheduleList });
        }
      }
      if (msg.action == 'DELETE') {
        const scheduleList = [...this.state.scheduleList];
        const index = scheduleList.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          scheduleList.splice(index, 1);
          this.setState({ scheduleList });
        }
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  fetchSchedules = () => {
    flightHttp.get('/flight_schedule?org='+this.station_code)
      .then((response) => {
        response.data.data ? this.setState({ scheduleList: response.data.data }) : {};
      });
  };

  handleClose = (type) => {
    switch (type) {
      case 'add':
        this.setState({
          openAddSchedule: false,
          scheduleErrors: {},
        });
        break;
      default:
        break;
    }
  };

  fetchAirlines = () => {
    http.get('/airline')
      .then((response) => {
        this.setState({ airlineList: response.data.data });
      });
  };

  onChange = (e) => {
    let value = e.target ? e.target.value : e;
    const name = e.target.name;
    this.setState({
      scheduleFields: {
        ...this.state.scheduleFields,
        [name]: value
      }
    });
  };

  changeDateAndTime = (element, type) => {
    this.setState({
      scheduleFields: {
        ...this.state.scheduleFields,
        [type]: (type == 'sta' || type == 'std') ? element.target.value : element.format('YYYY-MM-DD')
      }
    });
  };

  changeCheck = (e) => {
    this.setState({
      scheduleFields: {
        ...this.state.scheduleFields,
        [e.target.name]: e.target.checked
      }
    });
  };

  updateSchedule = async (id, data) => {
    await flightHttp.put('/flight_schedule/' + id, data)
      .then(() => {
        this.setState({
          scheduleFields: {
            airlineId: 0,
            platform: '',
            number: '',
            org: this.station_code,
            dest: '',
            type: '',
            std: '',
            sta: '',
            from: '',
            to: ''
          },
          openAddSchedule: false,
          type: 'add'
        });
      });
  };

  deleteSchedule = (id) => {
    flightHttp.delete('/flight_schedule/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleDelete = (id,anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteSchedule(id),
    });
  };

  getConfigValues = async () => {
    http.get('/settings?key=flight_schedule')
      .then((response) => {
        // handle success
        if (response.data.data) {
          this.setState({ flightSettings: response.data.data.value });
        }
      });
  };

  changeSettings = (e) => {
    const name = e.target.name;
    this.setState({
      flightSettings: {
        ...this.state.flightSettings,
        [name]: !this.state.flightSettings[name]
      }
    }, () => this.setSettingsValues(this.state.flightSettings));
  };

  setSettingsValues = async (formData) => {
    await http.put('/settings?key=flight_schedule', formData);
  };

  addEditSchedule = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.scheduleFields };
    if (this.validation()) {
      if (this.state.scheduleId) {
        this.updateSchedule(this.state.scheduleId, JSON.stringify(formdata));
      } else {
        await flightHttp.post('/flight_schedule', JSON.stringify(formdata))
          .then(() => {
            this.setState({
              scheduleFields: {
                airlineId: 0,
                platform: '',
                number: '',
                org: this.station_code,
                dest: '',
                type: '',
                std: '',
                sta: '',
                from: '',
                to: ''
              },
              openAddSchedule: false
            });
          });
      }
      this.fetchSchedules();
    }
  };

  validation = () => {
    const scheduleFields = { ...this.state.scheduleFields };
    const error = {};
    let formValid = true;
    if (!scheduleFields.airline || (scheduleFields.airline == '')) {
      formValid = false;
      error.airlineId = 'Please select a code';
    }
    else if (!scheduleFields.flight_no || (scheduleFields.flight_no.trim().length === 0)) {
      formValid = false;
      error.flight_no = 'Please enter a flight number';
    }
    else if (!scheduleFields.org || (scheduleFields.org.trim().length === 0)) {
      formValid = false;
      error.org = 'Please enter an origin';
    }
    else if (!scheduleFields.dest || (scheduleFields.dest.trim().length === 0)) {
      formValid = false;
      error.dest = 'Please enter a destination';
    }
    else if (!scheduleFields.platform || (scheduleFields.platform.trim().length === 0)) {
      formValid = false;
      error.platform = 'Please select a platform';
    }
    else if (!scheduleFields.ac_type || (scheduleFields.ac_type.trim().length === 0)) {
      formValid = false;
      error.ac_type = 'Please enter an aircraft type';
    }
    else if (!scheduleFields.std || (scheduleFields.std.trim().length === 0)) {
      formValid = false;
      error.std = 'Please enter a STD';
    }
    else if (!scheduleFields.sta || (scheduleFields.sta.trim().length === 0)) {
      formValid = false;
      error.sta = 'Please enter a STA';
    }
    else if (!scheduleFields.from || (scheduleFields.from.trim().length === 0)) {
      formValid = false;
      error.from = 'Please enter a from date';
    }
    else if (!scheduleFields.to || (scheduleFields.to.trim().length === 0)) {
      formValid = false;
      error.to = 'Please enter a to date';
    }
    this.setState({ scheduleErrors: error });
    return formValid;
  };

  handleOpen = (type, scheduleFields) => {
    switch (type) {
      case 'add':
        this.setState({
          openAddSchedule: true,
          scheduleFields: { org: this.station_code },
          type: 'add',
          scheduleId: 0
        });
        break;
      case 'edit':
        this.setState({
          openAddSchedule: true,
          scheduleId: scheduleFields.id,
          scheduleFields,
          type: 'edit'
        });
        break;
      default:
        break;
    }
  };

  setActive = (id, active) => {
    this.updateSchedule(id, { active });
  };


  render() {
    return (
      <>
        <FlightSchedule
          scheduleList={this.state.scheduleList}
          anchorEl={this.state.anchorEl}
          handleClose={this.handleClose}
          locationModal={this.state.locationModal}
          handleOpen={this.handleOpen}
          onChange={this.onChange}
          openAddSchedule={this.state.openAddSchedule}
          scheduleFields={this.state.scheduleFields}
          scheduleErrors={this.state.scheduleErrors}
          addSchedule={this.addEditSchedule}
          type={this.state.type}
          changeDateTime={this.changeDateAndTime}
          changeCheck={this.changeCheck}
          deleteSchedule={this.deleteSchedule}
          handleDelete={this.handleDelete}
          changeSettings={this.changeSettings}
          flightSettings={this.state.flightSettings}
          airlineList={this.state.airlineList}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(null, mapDispatchToProps) (FlightScheduleContainer);