import React from 'react';
import { Popover, Typography, Grid, MenuItem } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { PopoverHead, PopoverContent, CustomInputLabel, CustomTextField, InputProps, CustomSelect, CustomButton, SelectInputProps } from '../../../../../custom/components';
import CloseIcon from '@mui/icons-material/Close';
const AlarmDetails = (props) => {
  const { anchorEl, handleClose, alarmModal, alarmFields, alarms, onChangeAlarm, updateAlarm } = props;
  return (
    <div>
      <Popover
        open={alarmModal}
        anchorEl={anchorEl}
        onClose={() => { handleClose(); }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverHead>
          <Typography variant='subtitle2' component='span'>Alarm Details</Typography>
          <CloseIcon fontSize='small' onClick={() => { handleClose(); }} className={`${styles.pointer}`} />
        </PopoverHead>
        <PopoverContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomInputLabel id='alarm-res-attempt-label'>Alarm Resolution Attempted</CustomInputLabel>
              <CustomSelect
                {...SelectInputProps}
                labelId='alarm-res-attempt-label'
                fullWidth
                name='alarmId'
                onChange={onChangeAlarm}
                value={alarmFields.alarmId}
              >
                {alarms && alarms.map((m) => (
                  <MenuItem
                    key={m.name}
                    value={m.id}
                  >
                    {m.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel id='is-alarm-resolved-label'>Alarm Resolved?</CustomInputLabel>
              <CustomSelect
                {...SelectInputProps}
                labelId='is-alarm-resolved-label'
                fullWidth
                name='resolved'
                onChange={onChangeAlarm}
                value={alarmFields.resolved}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </CustomSelect>
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel htmlFor='remarks'>Remarks</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                id='remarks'
                fullWidth
                type='text'
                name='remark'
                value={alarmFields?.remark}
                onChange={onChangeAlarm}
                onl
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton type='button' variant='contained' className={styles.w100} onClick={() => updateAlarm()} >Update</CustomButton>
            </Grid>
          </Grid>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default AlarmDetails;