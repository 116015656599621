import React from 'react';
import {
  Typography, 
  IconButton,
  Popover,
  Card,
  CardHeader,
  Divider,
  CardContent
} from '@mui/material';
import { StyledCard, } from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import analyticsStyle from './analytics.module.css';
// import { SCREENING_MEASURES_COLOR } from '../../../custom/constants';
import { Info } from '@mui/icons-material';
import {  ExcelIcon } from 'custom/constants';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, ChartDataLabels);

const Measures = (props) => {
  const data = {
    labels: props.measureObj.measureName,
    datasets: [
      {
        label: 'Security Measures Applied',
        data: props.measureObj.measureArray,
        backgroundColor: [
          '#00325E',
          '#FFC001',
          '#70AD47',
          '#AAAAAA',
          '#8c03fc',
          '#1fbcdb',
          '#D9001B'
        ],
        // backgroundColor: props.measureObj.measureName.map((m) => {
        //   return SCREENING_MEASURES_COLOR[m] || '#000';
        // }),
        borderColor: '#fff',
        borderWidth: 2,
      },
    ],
  };

  const pieOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label;
          }
        }
      },
      legend: {
        fullSize: true,
        display: true,
        position: 'top'
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            if (typeof data == 'number')
              sum += data;
          });
          let result = value * 100 / sum;
          let percentage = (result).toFixed(0) + '%';
          if (result > 0) {
            return percentage;
          } else {
            return '';
          }
        },
        color: '#fff',
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    onClick: props.handleChartClick
  };

  return (

    <StyledCard> 
      <div className={`${styles.fSpaceBtw} ${styles.flexWrap}`}>
        <div className={`${styles.fvMiddle} ${styles.gap10}`}>  
          <Typography
            component='div'
            aria-owns={props.type === 'measure' ? 'mouse-over-popover' : undefined}
            aria-haspopup='true'
            onMouseEnter={(e) => props.handlePopover('measure', e.currentTarget)}
            onMouseLeave={props.handlePopoverClose}
          >
            <Info color='primary' />
          </Typography>
          <h5 className={analyticsStyle.heading}>Security Measures Applied</h5>
        </div>
        <IconButton aria-label='download-excel' className={`${analyticsStyle.fileImg}`} onClick={() => props.handleExport('measure')}>
          <ExcelIcon />
        </IconButton>
      </div>
      <div className={analyticsStyle.graphWrapper}>
        <div className={analyticsStyle.pieWrapper}>
          <Pie data={data} options={pieOptions} />
        </div>
      </div>
      <Popover
        id='mouse-over-popover'
        sx={{ pointerEvents: 'none', }}
        open={props.type === 'measure'}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Card>
          <CardHeader
            title={<Typography variant='body2'>Note</Typography>}>
          </CardHeader>
          <Divider />
          <CardContent>
            <Typography variant='body2' gutterBottom color='textSecondary' component='p'>
                          Note : Click the graph to see methods applied.
            </Typography>
          </CardContent>
        </Card>
      </Popover>
    </StyledCard>
  );
};

export default Measures;
