import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';
import {
  CustomButton,
  CustomDatePicker,
  CustomTextField,
  InputProps
} from '../../../.././custom/components';
import styles from '../../../../custom/style.module.css';
import docStyles from '../docs.module.css';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { DateFormat } from 'custom/constants';

const flightManifest = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='airline'>Airline</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='airline'
              autoFocus
              fullWidth
              placeholder='Airline'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'airline')}
              value={props.formFields.airline}
              name='airline'
            // error={}
            // helperText={}
            />

          </div>

        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='flight_no'>Flight Number</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='flight_no'             
              autoFocus
              fullWidth
              placeholder='Flight Number'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'flight_no')}
              value={props.formFields.flight_no}
              name='flight_no'
            // error={}
            // helperText={}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='span'>Flight Date</Typography>
          <div className={docStyles.addTextField}>
            <CustomDatePicker
              slotProps={{
                textField: {
                  ...InputProps,
                  fullWidth: true,
                  placeholder: 'Flight Date'
                }, 
              }}
              format={DateFormat}
              name='flight_date'
              className={styles.w100}
              onChange={(e) => props.handleChangeForm(e, 'flight_date')}
              value={props.formFields.flight_date ? dayjs(props.formFields.flight_date, 'YYYY-MM-DD') : null}
            // error={}
            // helperText={}
            />
          </div>
        </Stack>
      </Grid>
      {
        props.type != 'other' &&
        <Grid item xs={12} className={`${docStyles.docBtn} ${docStyles.docBtnOnly}`}>
          <CustomButton variant='contained' onClick={props.submit}> Update </CustomButton>
        </Grid>
      }
    </Grid>
  );
};

export default flightManifest;