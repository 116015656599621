import { Axios } from 'axios';
import cookies from './cookies';
import { toast } from 'react-toastify';
import environment from '../custom/environments';

const flightHttp = new Axios({
  baseURL: environment.flightScheduleService,
  headers: {
    'Content-Type': 'application/json',
    name: environment.name,
    key: environment.api_key,
    company: cookies.get('company') || null,
    user: cookies.get('user') || null,
  },
  withCredentials: true
});


flightHttp.interceptors.response.use((response) => {
  response.data = response.data ? JSON.parse(response.data) : {};
  // if (response && response.config && response.config.method != 'get') {
  //   toast.success(response.data.message);
  // }
  return response;
}, error => {
  console.log('API ERROR=====>', error);
  if (error) {
    toast.error(error.response && error.response.data.message || error.message);
  }
  return Promise.reject(error);
});

export default flightHttp;