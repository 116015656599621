import React from 'react';
import ManageUser from './ManageUser';
import http from 'utils/http';
import events from 'utils/evemts';
import { connect } from 'react-redux';
import { setDocAction } from 'store/actions/docAction';
import moment from 'moment/moment';
import cookies from 'utils/cookies';
import dayjs from 'dayjs';

class ManageUserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      filteredData: [],
      filter: {
        type: 'ALL',
        expiry: false,
        day: 1,
      },
      modal: false,
      action: false,
      userFields: {},
      userErrors: {},
      location: false,
      locationType: false,
      anchorEl: null,
      station: [],
      training: false,
      doc: {},
      passwordVal: '',
      successModal: false,
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchUsers();
    this.eventSource = events('admin', 'user');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      let station = parseInt(cookies.get('station'));
      if (msg.action == 'add' && msg.data?.stations?.some(stn => stn.id === station)) {
        this.setState({ userList: [...this.state.userList, msg.data] }, () => {
          this.filterData();
        });
      }
      if (msg.action == 'update') {
        const userList = [...this.state.userList];
        const index = userList.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          userList[index] = {
            ...msg.data,
            stations: msg.data?.stations && msg.data?.stations?.length > 0 ? msg.data.stations : userList[index].stations,
            docs: userList[index].docs
          };
          if (!userList[index].stations?.some(stn => stn.id === station)) {
            userList.splice(index, 1);
          }
          this.setState({ userList }, () => {
            this.filterData();
          });
        } else {
          if (msg.data?.stations && msg.data?.stations?.length > 0) {
            if (msg.data?.stations?.some(stn => stn.id === station)) {
              this.fetchUsers();
            }
          }
        }
      }

      if (msg.action == 'doc_add' || msg.action == 'doc_update' || msg.action == 'doc_delete') {
        const userList = [...this.state.userList];
        const index = userList.findIndex(c => msg.data && c.id == msg.data.userId);
        if (msg.action == 'doc_add' && index > -1) {
          userList[index] = {
            ...userList[index],
            docs: [...userList[index].docs, msg.data]
          };
          this.setState({ userList }, () => {
            this.filterData();
          });
        }
        if ((msg.action == 'doc_update' || msg.action == 'doc_delete') && index > -1) {
          let docs = [...userList[index].docs];
          const i = docs.findIndex(c => c.id == msg.data.id);
          if (msg.action == 'doc_update') {
            if (i > -1) {
              docs[i] = {
                ...msg.data,
                added_by: docs[i].added_by
              };
              userList[index].docs = docs;
              this.setState({ userList });
            } else {
              docs.push({
                ...msg.data,
                added_by: {}
              });
              userList[index].docs = docs;
              this.setState({ userList }, () => {
                this.filterData();
              });
            }
          }
          if (msg.action == 'doc_delete') {
            if (i > -1) {
              docs.splice(i, 1);
              userList[index].docs = docs;
              this.setState({ userList }, () => {
                this.filterData();
              });
            }
          }
        }

        if (this.props.doc.modal == 'user' && this.props.doc.id == msg.data.userId && index > -1) {
          this.props.setDoc({
            modal: 'user',
            id: userList[index].id,
            name: userList[index].name,
            data: userList[index].docs
          });
        }
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  fetchUsers = () => {
    http.get('/user')
      .then((response) => {
        // handle success
        this.setState({ userList: response.data.data }, () => {
          this.filterData();
        });
      });
  };

  // filter
  filterData = () => {
    let filteredData = [...this.state.userList];
    if (this.state.filter.type === 'ACTIVE') {
      filteredData = filteredData.filter((item) => item.active === true);
    }
    if (this.state.filter.type === 'INACTIVE') {
      filteredData = filteredData.filter((item) => item.active === false);
    }

    if (this.state.filter.expiry) {
      filteredData = filteredData.filter((item) => {
        if (item.docs && item.docs.length > 0) {
          let expiry = false;
          for (let doc of item.docs) {
            let diff = moment(doc.data.expiry_date).diff(moment.utc(), 'days');
            if (diff >= 0 && diff <= this.state.filter.day) {
              expiry = true;
            }
          }
          return expiry;
        } else {
          return false;
        }
      });
    }
    this.setState({ filteredData: filteredData });
  };


  onChangeFilter = (e) => {
    let filter = { ...this.state.filter };
    filter[e.target.name] = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ filter }, () => {
      this.filterData();
    });
  };


  handleLocation = (locationType, v, data = {}, anchorEl = null) => {
    this.setState({
      location: v,
      locationType,
      station: data,
      anchorEl
    });
  };

  handleDoc = (row) => {
    this.props.setDoc({
      modal: 'user',
      id: row.id,
      name: row.name,
      data: row.docs
    });
  };

  handleOpen = (modal, action, data = {}) => {
    this.setState({
      modal,
      action,
      userFields: {
        ...data,
        password: null
      }
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      successModal: false,
      action: false,
      userFields: {},
      userErrors: {},
    });
  };

  checkPassword = () => {
    let valid = false;
    let fields = {
      passcheck1: true,
      passcheck2: true
    };
    let passwordInputValue = this.state.userFields.password;
    if (passwordInputValue) {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = '';
      if (passwordLength === 0) {
        fields.passcheck2 = false;
        fields.passcheck1 = false;
        errMsg = 'Password is empty! Password must contain minumum 8 characters with at least one Uppercase, one Lowercase, one digit, one Special Characters  ';
      } else if (!uppercasePassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one Uppercase';
      } else if (!lowercasePassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one Lowercase';
      } else if (!digitsPassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one digit';
      } else if (!specialCharPassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one Special Characters';
      }
      if (!minLengthPassword) {
        fields.passcheck1 = false;
        errMsg = 'At least minumum 8 characters';
      }
      if (errMsg == '') {
        valid = true;
      }
      this.setState({
        userFields: {
          ...this.state.userFields,
          ...fields
        },
        userErrors: { password: errMsg }
      });
    } else {
      this.setState({
        userFields: {
          ...this.state.userFields,
          passcheck1: false,
          passcheck2: false
        },
        userErrors: {}
      });
    }
    return valid;
  };
  
  onChange = (e) => {
    this.setState({
      userFields: {
        ...this.state.userFields,
        [e.target.name]: e.target.value
      }
    }, () => {
      if (e.target.name == 'password') {
        this.checkPassword();
      }
    });
  };

  validation = () => {
    const userFields = { ...this.state.userFields };
    const error = {};
    let formValid = true;
    if (!userFields.first_name || (userFields.first_name.trim().length === 0)) {
      formValid = false;
      error.first_name = 'Please enter your firstname';
    }
    if (!userFields.last_name || (userFields.last_name.trim().length === 0)) {
      formValid = false;
      error.last_name = 'Please enter your lastname';
    }
    if (!userFields.username || (userFields.username.trim().length === 0)) {
      formValid = false;
      error.username = 'Please enter your username/email';
    }
    if (!userFields.role || (userFields.role.length === 0)) {
      formValid = false;
      error.role = 'Please select the role';
    }
    // if (this.state.action === 'add') {
    //   if (!userFields.password || (userFields.password.length === 0)) {
    //     formValid = false;
    //     error.password = 'Please enter the password';
    //   }
    // }
    this.setState({ userErrors: error });
    return formValid;
  };

  validationDeactivate = () => {
    const userFields = { ...this.state.userFields };
    const error = {};
    let formValid = true;
    if (!userFields.reason || (userFields.reason.trim().length === 0)) {
      formValid = false;
      error.reason = 'Please enter reason';
    }
    this.setState({ userErrors: error });
    return formValid;
  };

  addUser = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.userFields };
    if (this.validation()) {
      http.post('/user', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            userFields: {},
            userErrors: {},
            action: null,
          });
        }
      });
    }
  };

  editUser = async (e) => {
    e.preventDefault();
    if (this.validation()) {
      let formdata = {
        first_name: this.state.userFields.first_name,
        last_name: this.state.userFields.last_name,
        username: this.state.userFields.username,
        role: this.state.userFields.role
      };
      http.put('/user/' + this.state.userFields.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            userFields: {},
            userErrors: {},
            action: null,
          });
        }
      });
    }
  };

  handleChangePassword = () => {
    if (this.checkPassword()) {
      let formdata = { 
        password: this.state.userFields.password,
        login: false
      };
      http.put('/user/' + this.state.userFields.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          localStorage.setItem('user_details', JSON.stringify(this.state.userFields));
          this.setState({
            modal: false,
            successModal: true,
            userFields: {},
            userErrors: {},
            action: null,
          });
          window.open('/print_user_details', '_blank');
        }
      });
    }
  };

  active = async (e) => {
    e.preventDefault();
    let formdata = { active: true };
    http.put('/user/' + this.state.userFields.id, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          modal: false,
          userFields: {},
          userErrors: {},
          action: null,
        });
      }
    });
  };

  deactive = async (e) => {
    e.preventDefault();
    let formdata = {
      active: false,
      reason: this.state.userFields.reason
    };
    if (this.validationDeactivate()) {
      http.put('/user/' + this.state.userFields.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            userFields: {},
            userErrors: {},
            action: null,
          });
        }
      });
    }
  };

  handleTraining = (training, doc = {}, anchorEl = null) => {
    this.setState({
      training,
      doc,
      anchorEl
    });
  };

  handleRetraining = () => {
    // e.preventDefault();
    // if (this.validateUser()) {
    let formdata = { expiry_date: this.state.userFields.expiry_date };
    let id = this.state.doc.id;
    http.put('/document/user/' + id, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          modal: false,
          userFields: {},
          userErrors: {},
          action: null,
          training: false
        });
        // toast.success(data.message);
      }
    });
    // .catch((error) => {
    //   toast.error(error.response.data.message);
    // });
    // }
  };

  onChangeUser = (e) => {
    let value = e.target.value;
    if (e.target.type == 'date') {
      value = dayjs(e.target.value).format('YYYY-MM-DD');
    }
    this.setState({
      userFields: {
        ...this.state.userFields,
        [e.target.name]: value
      }
    });
  };


  generatePassword = () => {
    let length = 12,
      charset = 'abcdefghijklmnopqrstuvwxyz ABCDEFGHIJKLMNOPQRSTUVWXYZ 0123456789 #?!@$%^&*-',
      charsetArr = charset.split(' ');
    let retVal = '';
    charsetArr.forEach( chars => {
      for (let i = 0, n = chars.length; i < length/charsetArr.length; ++i) {
        retVal += chars.charAt(Math.floor(Math.random() * n));
        retVal = [...retVal].sort(() => Math.random() - 0.5).join('');
      }
    });
    this.setState({ passwordVal: retVal });
    return retVal;
  };


  render() {
    return (
      <ManageUser
        stations={this.props.stations}
        filteredData={this.state.filteredData}
        filter={this.state.filter}
        modal={this.state.modal}
        action={this.state.action}
        userFields={this.state.userFields}
        userErrors={this.state.userErrors}
        anchorEl={this.state.anchorEl}
        location={this.state.location}
        locationType={this.state.locationType}
        training={this.state.training}
        doc={this.state.doc}
        onChangeFilter={this.onChangeFilter}
        handleDoc={this.handleDoc}
        handleLocation={this.handleLocation}
        station={this.state.station}
        handleOpen={this.handleOpen}
        onClose={this.onClose}
        addUser={this.addUser}
        editUser={this.editUser}
        onChange={this.onChange}
        handleChangePassword={this.handleChangePassword}
        active={this.active}
        deactive={this.deactive}
        handleTraining={this.handleTraining}
        generatePassword={this.generatePassword}
        passwordVal={this.state.passwordVal}
        successModal={this.state.successModal}
        handleRetraining={this.handleRetraining}
        onChangeUser={this.onChangeUser}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doc: state.doc,
    stations: state.stations
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setDoc: (data) => dispatch(setDocAction(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserContainer);
