import React from 'react';
import { IconButton, InputAdornment, Stack, CircularProgress } from '@mui/material';
import { CustomSearch, InputProps } from '../../../../custom/components';
import styles from '../../../../custom/style.module.css';
import { SearchRounded } from '@mui/icons-material';
import AwbModal from './AwbModal';
import ScrapperModal from './ScrapperModal';
import { setData } from '../tabs/awbinfo/AwbInfo';

const Search = (props) => {
  return (
    <>
      <AwbModal
        fetchAwb={props.fetchAwb}
        searchAwb={props.searchAwb}
        exAwbs={props.exAwbs}
        modal={props.modal}
        onClose={props.onClose}
      />
      <ScrapperModal
        searchAwb={props.searchAwb}
        exAwbs={props.exAwbs}
        modal={props.scrapperModal}
        onClose={props.onClose}
      />
      <Stack direction='row' spacing={3} justifyContent='center'>
        <Stack direction='row' alignItems='center' className={styles.headerFieldContainer}>
          <CustomSearch
            {...InputProps} 
            id='addAwb'
            name='awb'
            value={props.awb}
            placeholder='Enter Awb Number'
            // onClick={ setData(false) }
            onChange={(e) => props.handleAddAwbChange(e.target.value)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                setData(false);
                event.preventDefault();
                props.searchAwb();
              }
            }}
            type='search'
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <>
                  {props.isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <InputAdornment position='end'>
                      <IconButton
                        size='small'
                        aria-label='search'
                        onClick={() => {
                          setData(false);
                          props.searchAwb();
                        }}
                        edge='end'
                      >
                        <SearchRounded fontSize='inherit' />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            className={`${styles.brInput}`}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Search;