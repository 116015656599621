import React from 'react';
import { Stack, Grid, Box, InputLabel } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, StyledDataGrid, DataGridProps, InputProps } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import docStyles from './otherdoc.module.css';
import { AttachFile as AttachFileIcon, Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DateFormat } from 'custom/constants';

const OtherDoc = (props) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Document Name',
      type: 'text',
      minWidth: 80,
      flex: 1
    },
    {
      field: 'sub_type',
      headerName: 'Type',
      type: 'text',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      width: 90,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => props.handlePopover('attached_docs', true, params.row.id)}>
              <AttachFileIcon fontSize='small' className={`${styles.pointer} ${docStyles.attIcon}`} />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledCard>
          <StyledCardTitle gutterBottom variant='' component='div'>Filter Other Documents List</StyledCardTitle>
          <Stack direction='row'>
            <Grid container spacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel>From</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      fullWidth: true,
                      placeholder: 'From Date'
                    }, 
                  }}
                  className={styles.w100}
                  format={DateFormat}
                  name='from'
                  value={dayjs(props.searchOptions.from)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'from')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel>To</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      fullWidth: true,
                      placeholder: 'To Date'
                    }, 
                  }}
                  className={styles.w100}
                  format={DateFormat}
                  name='to'
                  value={dayjs(props.searchOptions.to)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'to')}
                />
              </Grid>
              <Grid item className={styles.sqIconsContainer}>
                <InputLabel className={styles.colorTransparent}>-</InputLabel>
                <div onClick={() => props.search()} >
                  <Search fontSize='1.7rem' classes={{ root: styles.sqIcons }} />
                </div>
              </Grid>
            </Grid>
          </Stack>
        </StyledCard>
      </Grid>

      <Grid item xs={12}>
        <StyledCard className={styles.positionRelative}>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter} ${docStyles.docTableOuter}`}>
              <StyledDataGrid
                {...DataGridProps}
                rows={props.data}
                columns={columns}
              />
            </Box>
          </div>
        </StyledCard>
      </Grid>

    </Grid>
  );
};

export default OtherDoc;
