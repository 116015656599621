import React from 'react';
import FlightManifest from './FlightManifest';
import { Box } from '@mui/material';
import moment from 'moment';
import http from 'utils/http';
import dayjs from 'dayjs';
import AttachmentContainer from '../attachment/AttachmentContainer';

class FlightManifestContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      modal: false,
      screeningModal: false,
      search: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      },
      data: [],
      originalData:[],
      awbId: 0,
      airline: [],
      flight: [],
      preScreen: [],
      filter: {
        airline: null,
        flight: null,
        flight_search: null,
      },
    };
  }

  componentDidMount() {
    this.fetchFlight();
  }


  handlePopover = (modal, anchorEl, id) => {
    if (modal == 'screeningdetails') {
      this.setState({
        screeningModal: anchorEl,
        awbId: id
      });
    } else {
      this.setState({
        modal,
        anchorEl,
        awbId: id
      });
    }

  };

  fetchFlight = () => {
    let query = '';
    query = `from=${dayjs(this.state.search.from).format('YYYY-MM-DD')}&to=${dayjs(this.state.search.to).format('YYYY-MM-DD')}`;
    http.get('/archive/flight?' + query).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ 
          data: data.data,
          originalData: data.data
        }, () => {
          this.getFilter();
          this.clear();
        });
      }
    });
  };

  clear = () => {
    this.setState({
      filter: {
        airline: null,
        flight: null,
        preScreen: null,
        measure:null,
        method:null
      },
    }, () => { this.setState({ awbs: [...this.state.data] });
    });
  };

  handleFilterChange = (event, type) => {
    let value = event.target ? event.target.value : event;
    // const name = event.target ? event.target.name : event;

    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [type]: value
      }
    }));
  };

  getFilter = () => {
    let airline = [];
    let flight = [];
    this.state.data.map(a => {
      let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
      if (!flight.includes(f)) {
        flight.push(f);
      }
      if (a.airline) {
        if (!airline.includes(a.airline)) {
          airline.push(a.airline);
        }
      }
    });
    this.setState({
      airline,
      flight
    });
  };

  filterData = () => {
    let data = [...this.state.originalData];
    if (this.state.filter.airline && this.state.filter.airline != '') {
      data = data.filter((a) => a.airline === this.state.filter.airline);
    }
    if (this.state.filter.flight && this.state.filter.flight != '') {
      data = data.filter((a) => {
        let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
        return f === this.state.filter.flight;
      });
    }
    this.setState({ data });
  };

  onChangefilter = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value
      }
    }, () => { this.filterData(); });
  };

  handlePopoverClose = (modal) => {
    if (modal == 'screeningdetails') {
      this.setState({ screeningModal: false });
    } else {
      this.setState({
        attachment: false,
        anchorEl: false
      });
    }

  };

  render() {
    return (
      <Box sx={{ position: 'relative' }}>
        <FlightManifest
          handlePopover={this.handlePopover}
          handlePopoverClose={this.handlePopoverClose}
          anchorEl={this.state.anchorEl}
          modal={this.state.modal}
          data={this.state.data}
          searchOptions={this.state.search}
          handleChange={this.handleFilterChange}
          search={this.fetchFlight}
          airline={this.state.airline}
          flight={this.state.flight}
          preScreen={this.state.preScreen}
          onChangefilter={this.onChangefilter}
          filter={this.state.filter}
        />
        {this.state.modal === 'attached_docs' &&
          <AttachmentContainer
            handlePopover={this.handlePopover}
            anchorEl={this.anchorEl}
            modal={this.state.modal}
            type={'FLIGHT'}
            id={this.state.awbId}
          />
        }
      </Box>
    );
  }
}

export default FlightManifestContainer;
