import React from 'react';
import { Dialog } from '@mui/material';
import styles from '../../../custom/style.module.css';
import { CustomDialogTitle, CustomDialogContent } from '../../../custom/components';
import PDFViewer from '../common/PDFViewer';
import { CloseIconBtn } from 'custom/constants';
import environment from 'custom/environments';

const AttachmentModal = (props) => {
  return (
    <div>
      <Dialog 
        aria-labelledby='form-dialog-title'
        open={props.modal == 'attachment'}
        onClose={() => props.handleModal(false)} 
        fullWidth={true}
        maxWidth={false}
        PaperProps={{ style: { width: '660px' } }}
      >
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          Attachments
          <CloseIconBtn onClick={() => props.handleModal(false)} />
        </CustomDialogTitle>
        <CustomDialogContent>
          {props.docs && props.docs.map((doc, i) => {
            return (
              <div key={i}>
                {doc.src && doc.src.includes('.pdf') && <PDFViewer src={doc.src} />}
                {doc.src && !doc.src.includes('.pdf') && <img src={environment.apiService + 'document?key=' + doc.src} alt='attachment' className={`${styles.w100}`} crossOrigin='use-credentials' />}
                {i < props.docs.length - 1 && <hr className={styles.hrStyle} />}
              </div>
            );
          })}
        </CustomDialogContent>
      </Dialog>
    </div>
  );
};

export default AttachmentModal;