import React from 'react';
import { Divider, Dialog, Stack } from '@mui/material';
import styles from 'custom/style.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, StyledDataGrid, DataGridProps, CustomDialogContent } from 'custom/components';
import moment from 'moment';
import { ArrowTopIcon, CloseIconBtn } from 'custom/constants';

const AwbModal = (props) => {
  const columns = [
    {
      field: 'awb',
      headerName: 'Airwaybill',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row.code + '-' + row.number;
      }
    },
    {
      field: 'pcs',
      headerName: 'Pcs',
      minWidth: 100,
      flex: 1,
      // width: 130
    },
    {
      field: 'wgt',
      headerName: 'Weight',
      minWidth: 100,
      flex: 1,
      // editable: true,
      // width: 130 
    },
    // {
    //   field: 'commodity',
    //   headerName: 'Commodity',
    //   // type: 'number',
    //   minWidth: 100,
    //   flex: 1,
    // },
    {
      field: 'flight_no',
      headerName: 'Booked Flight Number',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.airline?.code + '/' + row.flight_no + '/' + moment(row.flight_date || null).format('DDMMMYY').toUpperCase();
      }
    },
    // {
    //   field: 'shipper_profile',
    //   headerName: 'Shipper Profile',
    //   minWidth: 100,
    //   flex: 1,
    //   // editable: true,
    //   // width: 130 
    // },
    // {
    //   field: 'pre_screened',
    //   headerName: 'Prescreened',
    //   minWidth: 100,
    //   flex: 1,
    //   // editable: true,
    //   // width: 130 
    // },
    {
      field: 'action',
      headerName: 'Select',
      align: 'center',
      headerAlign: 'center',
      // minWidth: 100, 
      flex: 1,
      sortable: false,
      // width: 90,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <ArrowTopIcon onClick={() => props.fetchAwb(row.id)} />
          </Stack>
        );
      }
    },
  ];
  return (
    <div>
      <Dialog 
        aria-labelledby='form-dialog-title'
        open={props.modal} 
        onClose={props.onClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <CustomDialogTitle id='form-dialog-title'>
          AWB
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <Divider />
        <CustomDialogContent>
          <StyledDataGrid
            {...DataGridProps}
            rows={props.exAwbs}
            columns={columns}
          />
        </CustomDialogContent>
        <CustomDialogAction className={`${styles.fSpaceBtw}`}>
          <CustomButton variant='contained' onClick={props.onClose}>Close</CustomButton>
          <CustomButton variant='contained' onClick={() => props.searchAwb(true, false, props.exAwbs[0].version + 1)}>Create New</CustomButton>
        </CustomDialogAction>
      </Dialog>
    </div>
  );
};
export default AwbModal;