import React from 'react';
import {
  Typography, 
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Popover,
  Divider,
} from '@mui/material';
import { StyledCard, } from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import analyticsStyle from './analytics.module.css';
import { Info } from '@mui/icons-material';
import {  ExcelIcon } from 'custom/constants';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, ChartDataLabels);

const Methods = (props) => {
  const bardata = {
    labels: props.methodObj.methodCode,
    datasets: [
      {
        label: 'Security Methods Applied',
        data: props.methodObj.methodArray,
        backgroundColor: [
          props.measureSelect.measureColor ? props.measureSelect.measureColor : '#00325E'
        ],
      },
    ],
  };

  const bargraphOptions = {
    plugins: {
      legend: {
        fullSize: true,
        display: false,
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            if (typeof data == 'number')
              sum += data;
          });
          let result = value * 100 / sum;
          let percentage = (result).toFixed(0) + '%';
          if (result > 0) {
            return percentage;
          } else {
            return '';
          }
        },
        color: '#fff',
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { grid: { drawOnChartArea: false, }, },
      y: {
        title: {
          display: true,
          text: props.report == 'awb' ? 'Air Way Bills' : props.report == 'wgt' ? 'Weight' : 'Pieces'
        }
      }
    }
  };

  return (
    <StyledCard>
      <div className={`${styles.fSpaceBtw} ${styles.flexWrap}`}>
        <div className={`${styles.fvMiddle} ${styles.gap10}`}>
          <Typography 
            aria-owns={props.type === 'method' ? 'mouse-over-popover' : undefined}
            aria-haspopup='true'
            onMouseEnter={(e) => props.handlePopover('method', e.currentTarget)}
            onMouseLeave={props.handlePopoverClose}
            component='div'
          >
            <Info color='primary' />
          </Typography>
          <h5 className={analyticsStyle.heading}>Screening Methods - {props.measureSelect.measure}</h5>
        </div>
        <IconButton aria-label='download-excel' className={`${analyticsStyle.fileImg}`} onClick={() => props.handleExport('method')}>
          <ExcelIcon />
        </IconButton>
      </div>
      <div className={analyticsStyle.graphWrapper}>
        <div className={analyticsStyle.barWrapper}>
          <Bar height={300} data={bardata} options={bargraphOptions} />
        </div>
      </div>
      <Popover
        id='mouse-over-popover'
        sx={{ pointerEvents: 'none', }}
        open={props.type === 'method'}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Card>
          <CardHeader
            title={<Typography variant='body2'>
                            Screening Method Legend
            </Typography>}>
          </CardHeader>
          <Divider />
          <CardContent>
            {props.filterMethods && props.filterMethods.map((l, index) => {
              return <Typography key={index} variant='caption' gutterBottom component='p'>{`${l.method} = ${l.name}`}</Typography>;
            })}
          </CardContent>
        </Card>
      </Popover>
    </StyledCard>
  );
};

export default Methods;
