import React from 'react';
import { Box, Stack, Grid, MenuItem, InputAdornment, TableBody, TableHead, TableCell, TableRow, Table, IconButton, CircularProgress, Tooltip } from '@mui/material';
import {
  StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, CustomSelect, CustomSearch, CustomTimePicker,
  TableBadge, StyledTableContainer, NestedTableCellContainer, SelectInputProps, InputProps, CustomButton
} from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import reportStyle from './report.module.css';
import { Search } from '@mui/icons-material';
import { WhiteCameraIcon, ExcelIcon, DateFormat } from 'custom/constants';
import Attachment from './attachment';
import dayjs from 'dayjs';
import moment from 'moment';

const ScreeningReport = (props) => {

  return (
    <Grid container spacing={3}>
      <Stack direction='row' spacing={3} justifyContent='center'>
        <Stack direction='row' alignItems='center' className={styles.headerFieldContainer}>
          <CustomSearch
            {...InputProps}
            id='search'
            type='search'
            placeholder='Enter Awb Number'
            fullWidth
            name='awb'
            value={props.search.awb}
            onChange={props.onChangeSearch}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <>
                  {props.isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <InputAdornment position='end'>
                      <IconButton
                        size='small'
                        aria-label='search'
                        onClick={() => props.fetchReport(true)}
                        edge='end'
                      >
                        <Search fontSize='inherit' />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            className={`${styles.brInput}`}
          />
        </Stack>
      </Stack>
      <Grid item xs={12}>
        <StyledCard>
          <StyledCardTitle gutterBottom variant='' component='div'>Reports Filter</StyledCardTitle>
          <Stack direction='row' sx={{ mb: 2 }}>
            {/* <CustomInputLabel className={reportsStyles.ipLabel}> Filters:</CustomInputLabel> */}
            <Grid container spacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} direction='row' container columnSpacing={1} rowSpacing={{
                xs: 2,
                sm: 0,
              }}>
                <Grid item xs={12}>
                  <CustomInputLabel>From</CustomInputLabel>
                </Grid>
                <Grid item xs={12} sm={6} className={styles.pt0}>
                  <CustomDatePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        fullWidth: true,
                        placeholder: 'From Date'
                      },
                    }}
                    className={styles.w100}
                    onChange={(v) => props.onChangeSearch({
                      target: {
                        type: 'date',
                        name: 'from',
                        value: v
                      }
                    })}
                    format={DateFormat}
                    name='from'
                    value={dayjs(props.search.from)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTimePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        fullWidth: true,
                        placeholder: 'From Time'
                      },
                    }}
                    className={styles.w100}
                    ampm={false}
                    onChange={(v) => {
                      props.onChangeSearch({
                        target: {
                          type: 'time',
                          name: 'f_time',
                          value: v ? v.format('HH:mm') : null
                        }
                      });
                    }}
                    format='HH:mm'
                    name='f_time'
                    value={dayjs(props.search.f_time || moment().format('HH:mm'), 'HH:mm')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} direction='row' container columnSpacing={1} rowSpacing={{
                xs: 2,
                sm: 0,
              }}>
                <Grid item xs={12}>
                  <CustomInputLabel>To</CustomInputLabel>
                </Grid>
                <Grid item xs={12} sm={6} className={styles.pt0}>
                  <CustomDatePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        fullWidth: true,
                        placeholder: 'To Date'
                      },
                    }}
                    className={styles.w100}
                    onChange={(v) => props.onChangeSearch({
                      target: {
                        type: 'date',
                        name: 'to',
                        value: v
                      }
                    })}
                    format={DateFormat}
                    name='to'
                    value={dayjs(props.search.to)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTimePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        fullWidth: true,
                        placeholder: 'To Time'
                      },
                    }}
                    className={styles.w100}
                    ampm={false}
                    onChange={(v) => {
                      props.onChangeSearch({
                        target: {
                          type: 'time',
                          name: 't_time',
                          value: v ? v.format('HH:mm') : null
                        }
                      });
                    }}
                    format='HH:mm'
                    name='t_time'
                    value={dayjs(props.search.t_time || moment().format('HH:mm'), 'HH:mm')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl>
                <CustomInputLabel id='screener-label'>Screener</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='screener-label'
                  fullWidth
                  id='screener'
                  name='screener'
                  value={props.filter.screener}
                  onChange={props.onChangefilter}
                >
                  {props.screener && props.screener.map(m => {
                    return (<MenuItem key={m} value={m} >{m}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl>
                <CustomInputLabel id='airline-label'>Airline</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='airline-label'
                  fullWidth
                  id='airline'
                  name='airline'
                  value={props.filter.airline}
                  onChange={props.onChangefilter}
                >
                  {props.airline && props.airline.map(a => {
                    return (<MenuItem key={a} value={a} >{a}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl>
                <CustomInputLabel id='flight-number-label'>Flight Number</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='flight-number-label'
                  fullWidth
                  id='flight'
                  name='flight'
                  value={props.filter.flight}
                  onChange={props.onChangefilter}
                >
                  {props.flight && props.flight.map(f => {
                    return (<MenuItem key={f} value={f}>{f}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl>
                <CustomInputLabel id='measure-applied-label'>Measure Applied</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='measure-applied-label'
                  fullWidth
                  id='measure'
                  name='measure'
                  value={props.filter.measure}
                  onChange={props.onChangefilter}
                >
                  {props.measure && props.measure.map(m => {
                    return (<MenuItem key={m} value={m} >{m}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item display='flex' className={styles.sqIconsContainer}>
                <Search fontSize='1.7rem' classes={{ root: styles.sqIcons }} onClick={() => props.fetchReport(false)} />
              </Grid>
              <Grid item display='flex'>
                <CustomButton variant='contained' className={styles.mt3} onClick={props.clear} >
                  Clear
                </CustomButton>
              </Grid>
            </Grid>
          </Stack>
        </StyledCard>
      </Grid>

      <Grid item xs={12}>
        <StyledCard>
          <div className={`${styles.fSpaceBtw} ${styles.mb3}`}>
            <StyledCardTitle gutterBottom variant='' component='div'>Screening Report</StyledCardTitle>
            <IconButton aria-label='download-excel' className={styles.greenSvg} onClick={props.handleExport}>
              <ExcelIcon />
            </IconButton>
          </div>
          <Box>
            <StyledTableContainer className={reportStyle.reportTable} ref={props.spanRef}>
              <Table aria-label='nested table' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>AWB</TableCell>
                    <TableCell align='left'>T PCS</TableCell>
                    <TableCell align='left'>T WGT</TableCell>
                    <TableCell align='left'>ORG</TableCell>
                    <TableCell align='left'>DES</TableCell>
                    <TableCell align='left'>Booked Flight</TableCell>
                    <TableCell align='left'>Forwarder</TableCell>
                    <TableCell align='left'>Shipper Profile</TableCell>
                    <TableCell align='left'>Pre-Screening</TableCell>
                    <TableCell align='left'>Remarks</TableCell>
                    <TableCell align='left'>Received</TableCell>
                    <TableCell align='left'>eCSD</TableCell>
                    <TableCell align='center' className={reportStyle.headerIcon}><WhiteCameraIcon /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.awbs && props.awbs.map(awb => {
                    return (<>
                      <TableRow>
                        <TableCell align='left' >{awb.code}-{awb.number}</TableCell>
                        <TableCell align='left'>{awb.pcs}</TableCell>
                        <TableCell align='left'>{awb.wgt}k</TableCell>
                        <TableCell align='left'>{awb.org}</TableCell>
                        <TableCell align='left'>{awb.dest}</TableCell>
                        <TableCell align='left'>{awb.airline.code}{awb.flight_no}/{moment(awb.flight_date).format('DDMMMYY').toUpperCase()}</TableCell>
                        <TableCell align='left'>{awb.forwarder}</TableCell>
                        <TableCell align='left'>{awb.shipper_profile}</TableCell>
                        <TableCell align='left'>{awb.pre_screened}</TableCell>
                        <TableCell align='left'>{awb.remark}</TableCell>
                        <TableCell align='left'></TableCell>
                        <TableCell align='left'>{awb.csd?.email && awb.csd?.print ? 'E-mail & Print' : awb.csd?.email ? 'E-mail' : awb.csd?.print ? 'Print' : ''}</TableCell>
                        <TableCell align='center'><Tooltip title='View Attachments'>{awb?.docs && awb?.docs?.length > 0 && (<TableBadge color='error' className={`${styles.pointer}`} badgeContent={awb?.docs?.length || 0} onClick={() => props.handlePopover(true, props.spanRef.current, awb.code + '-' + awb.number, awb.docs)} />)}</Tooltip></TableCell>
                      </TableRow>
                      <NestedTableCellContainer className={styles.nestedTableContainer}>
                        <TableCell style={{
                          paddingBottom: 0,
                          paddingTop: 0
                        }} colSpan={13}>
                          <Table size='small' aria-label=''>
                            <TableHead>
                              <TableRow>
                                <TableCell className={styles.minWidth135} align='left'>PCS Screened</TableCell>
                                <TableCell className={styles.minWidth145} align='left'>Measure</TableCell>
                                <TableCell className={styles.minWidth205} align='left'>Method</TableCell>
                                <TableCell align='left'>Screened For Flight</TableCell>
                                <TableCell align='left'>Screener</TableCell>
                                <TableCell align='left'>Screening Date/Time</TableCell>
                                <TableCell align='left'>Alarm?</TableCell>
                                <TableCell align='left'>Resolution</TableCell>
                                <TableCell align='left'>Resolved?</TableCell>
                                <TableCell align='left'>Remarks</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {awb.screens && awb.screens.map(screen => (
                                <TableRow key={screen.id} >
                                  <TableCell className={styles.minWidth135} align='left'>{screen?.pcs}</TableCell>
                                  <TableCell className={styles.minWidth145} align='left'>{screen?.measure?.type}</TableCell>
                                  <TableCell className={styles.minWidth205} align='left'>{screen?.method?.name}</TableCell>
                                  <TableCell align='left'>{screen?.flight_no}/{moment(screen?.flight_date).format('DDMMMYY').toUpperCase()}</TableCell>
                                  <TableCell align='left'>{screen.user ? screen?.user?.first_name + ' ' + screen?.user?.last_name : screen?.k9_company?.name}</TableCell>
                                  <TableCell align='left'>{moment(screen?.screen_date).format('DDMMMYY').toUpperCase()} {screen?.screen_time}</TableCell>
                                  <TableCell align='left'>{screen?.alarmed ? 'Yes' : 'No'}</TableCell>
                                  <TableCell align='left'>{screen?.alarm?.name}</TableCell>
                                  <TableCell align='left'>{screen?.resolved}</TableCell>
                                  <TableCell align='left'>{screen?.remark}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </NestedTableCellContainer>
                    </>);
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Box>
        </StyledCard>
        <Attachment
          handlePopover={props.handlePopover}
          anchorEl={props.anchorEl}
          attachment={props.attachment}
          spanRef={props.spanRef}
          awb={props.awb}
          docs={props.docs}
        />
      </Grid>
    </Grid>
  );
};

export default ScreeningReport;
