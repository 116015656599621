import React from 'react';
import { Drawer, Typography, Grid, Stack, Divider, Box } from '@mui/material';
import styles from '../../../../custom/style.module.css';
import { PopoverHead, PopoverContent, StyledCard } from '../../../../custom/components';
import { Close as CloseIcon, Error as ErrorIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import PDFImg from '../../../../assets/images/pdf.svg';
import { EditIcon, DeleteIcon, AddIcon, DateFormat } from '../../../../custom/constants';
import theme from '../../../../custom/theme';
import userStyles from './../../admin/tabs/manageusers/users.module.css';
import docStyles from './doc.module.css';
import AddDocModal from './AddDocModal';
import moment from 'moment';
import environment from '../../../../custom/environments';

const SectionDivider = styled(Divider)(() => ({
  borderColor: 'white',
  borderBottomWidth: '12px',
  marginLeft: 'calc(var(--splayout) * -1)',
  marginRight: 'calc(var(--splayout) * -1)',
}));

const detailFontSize = '0.7rem';

const DocModal = (props) => {
  const { drawer, closeDrawer, docs, name } = props;
  return (
    <div>
      <Drawer
        anchor='right'
        open={drawer}
        onClose={closeDrawer}
        className={userStyles.trainingDrawer}
        classes={{ paper: userStyles.trainingPaper }}
      >
        <PopoverHead>
          {drawer == 'user' && (<Typography variant='h6' component='h6' fontSize={'1.1rem'} fontWeight={600}>Add/View Training Records</Typography>)}
          {drawer == 'k9' && (<Typography variant='h6' component='h6' fontSize={'1.1rem'} fontWeight={600}>Attachments</Typography>)}
          <CloseIcon fontSize='small' className={styles.pointer} onClick={closeDrawer} />
        </PopoverHead>
        <PopoverContent>
          <Grid container spacing={2}>
            <Grid item xs={12} className={styles.fSpaceBtw}>
              {name}
            </Grid>
            <Grid container item xs={12}>
              <StyledCard className={`${styles.w100} ${docStyles.attListWrapper}`}>
                <Box className={styles.fSpaceBtw} sx={{
                  marginBottom: '0.6rem',
                  marginTop: '-0.8rem',
                  padding: '4px 0px', 
                }}>
                  <Typography variant='subtitle1' component='div' fontWeight={600} fontSize={'0.9rem'} 
                    color={theme.palette.primary.main} lineHeight={'normal'}>List of {drawer == 'user' && ('Training Records') ||
                    drawer == 'k9' && ('Attachments')}</Typography>
                  <AddIcon title={drawer == 'user' && ('Add New Training Record') ||
                drawer == 'k9' && ('Add Attachment')} className={`${styles.pointer} ${docStyles.docAddIcon}`} onClick={() => props.handleOpen(drawer, 'add')} />
                </Box>
                <SectionDivider className={styles.mb3} />
                {drawer == 'user' && docs?.map((list, i, self) => {
                  return <Stack key={list.id} direction={'column'} spacing={2}>
                    <Typography variant='body2' component='div' color='primary' fontWeight={600} textTransform={'capitalize'} paddingBottom={'12px'} borderBottom={'1px solid #dbdbdb'}>{list.name}</Typography>
                    <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                      <Stack direction={'column'} spacing={1}>
                        <Stack direction={'column'} spacing={0}>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Training Date</Typography>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold'>{moment(list.data.training_date).format(DateFormat)}</Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={0}>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Expiry</Typography>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold' className={styles.dInlineFlex}>
                            {moment(list.data.expiry_date).format(DateFormat)}
                            {moment(list.data.expiry_date).format(DateFormat) && new Date(list.data.expiry_date) < new Date() ? <ErrorIcon color='error' fontSize='small' className={docStyles.mIcon} /> : ''}
                          </Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={0}>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Record added by</Typography>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold'>
                            {list.added_by.first_name} {list.added_by.last_name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <div className={`${docStyles.pdfWrapper} ${styles.mb3}`}>
                        {list.src.includes('.pdf') && (<img src={PDFImg} alt='pdf' className={styles.w100} onClick={() => { window.open(environment.apiService + 'document?key=' + list.src); }} />)}
                        {!list.src.includes('.pdf') && (<img src={list.src} alt={'attachment'} className={styles.w100} />)}
                      </div>
                      <Stack direction='row' spacing={2} className={docStyles.trainingAction} alignItems={'flex-start'} justifyContent={'space-between'}>
                        <EditIcon onClick={() => props.handleOpen('user', 'edit', list)} />
                        <DeleteIcon onClick={(e) => props.handleDelete(list.id, e.target)} />
                      </Stack>
                    </Stack>

                    { i === self.length - 1  ? '' : <SectionDivider className={styles.mb3} sx={{
                      marginRight: '-24px !important',
                      marginLeft: '-24px !important' 
                    }} />}
                      
                  </Stack>;
                })}

                {drawer == 'k9' && docs?.map((list, i, self) => {
                  return <Stack key={list.id} direction={'column'} spacing={2}>
                    <Typography variant='body2' component='div' color='primary' fontWeight={600} textTransform={'capitalize'} paddingBottom={'12px'} borderBottom={'2px solid white'}>{list.name}</Typography>
                    <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                      <Stack direction={'column'} spacing={1}>
                        <Stack direction={'column'} spacing={0}>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Date Added:</Typography>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold'>
                            {moment(list.created).format('DD MMM YYYY')}</Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={0}>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Added By:</Typography>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold' className={styles.dInlineFlex}>
                            {list.added_by.first_name} {list.added_by.last_name}
                          </Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={0}>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} className={userStyles.trainingTitle}>Remarks</Typography>
                          <Typography variant='caption' component='span' fontSize={detailFontSize} color={theme.palette.primary.main} fontWeight='bold'>
                            {list.data.remark}
                          </Typography>
                        </Stack>
                      </Stack>
                      <div className={`${docStyles.pdfWrapper} ${styles.mb3}`}>
                        {list.src.includes('.pdf') && (<img src={PDFImg} alt='pdf' className={styles.w100} onClick={() => { window.open(environment.apiService + 'document?key=' + list.src); }} />)}
                        {!list.src.includes('.pdf') && (<img src={list.src} alt={'attachment'} className={styles.w100} />)}
                      </div>
                      <Stack direction='row' spacing={2} className={docStyles.trainingAction} alignItems={'flex-start'} justifyContent={'space-between'}>
                        <EditIcon onClick={() => props.handleOpen('k9', 'edit', list)} />
                        <DeleteIcon onClick={(e) => props.handleDelete(list.id, e.target)} />
                      </Stack>
                    </Stack>

                    { i === self.length - 1  ? '' : <SectionDivider className={styles.mb3} sx={{
                      marginRight: '-24px !important',
                      marginLeft: '-24px !important' 
                    }} />}
                      
                  </Stack>;
                })}
              </StyledCard>
            </Grid>
          </Grid>
        </PopoverContent>
        <AddDocModal
          handleOpen={props.handleOpen}
          onClose={props.onClose}
          modal={props.modal}
          action={props.action}

          userFields={props.userFields}
          userErrors={props.userErrors}
          onChangeUser={props.onChangeUser}
          addUser={props.addUser}
          editUser={props.editUser}

          k9Fields={props.k9Fields}
          k9Errors={props.k9Errors}
          onChangeK9={props.onChangeK9}
          addK9={props.addK9}
          editK9={props.editK9}
        />
      </Drawer>
    </div>
  );
};
export default DocModal;