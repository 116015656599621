import React from 'react';
import {
  Dialog, MenuItem,
  FormControl, FormHelperText,
  Grid,
} from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import admin from '../admin.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, StyledCard, CustomTextField, CustomInputLabel, CustomSelect, InputProps, CustomDialogContent, SelectInputProps } from '../../../../../custom/components';
import { CloseIconBtn, roles } from '../../../../../custom/constants';
import cookies from 'utils/cookies';


const AddUserModal = (props) => {
  const userRole = cookies.get('role');
  const allowedRoles = roles.filter((role) => {
    if (userRole === 'System Admin') {
      return true;
    } else if (userRole === 'Supervisor') {
      return ['Office Agent', 'Screener', 'TSA Inspector', 'Supervisor'].includes(role.value);
    } else if (userRole === 'Manager') {
      return ['Office Agent', 'Screener', 'TSA Inspector', 'Supervisor', 'Manager'].includes(role.value);
    }
    return false;
  });

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title'open={props.modal == 'user'} onClose={props.onClose} >
        <CustomDialogTitle id='form-dialog-title'className={styles.modalTitle}>
          {props.action === 'edit' ? 'Edit' : 'Add'} User
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form onSubmit={props.action == 'add' ? props.addUser : props.editUser} className={styles.w100} onReset={props.onClose}>
          <CustomDialogContent>
            <StyledCard>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='first_name'>First Name</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='first_name'
                    fullWidth
                    autoFocus
                    placeholder='First Name'
                    type='text'
                    onChange={props.onChange}
                    name='first_name'
                    value={props.userFields.first_name ?? ''}
                    error={props.userErrors.first_name}
                    helperText={props.userErrors.first_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='last_name'>Last Name</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='last_name'
                    fullWidth
                    className={admin.addTextField}
                    placeholder='Last Name'
                    type='text'
                    onChange={props.onChange}
                    name='last_name'
                    value={props.userFields.last_name ?? ''}
                    error={props.userErrors.last_name}
                    helperText={props.userErrors.last_name}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInputLabel htmlFor='username'>Email/User Name</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    fullWidth
                    id='username'
                    placeholder='Email/User Name'
                    type='email'
                    inputProps={{
                      autoComplete: 'new-password',
                      form: { autocomplete: 'off', },
                    }}
                    onChange={props.onChange}
                    name='username'
                    value={props.userFields.username ?? ''}
                    error={props.userErrors.username}
                    helperText={props.userErrors.username}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInputLabel id='role_label' aria-label='Enter Role'>Role</CustomInputLabel>
                  <FormControl
                    fullWidth error={props.userErrors.role ? true : false}>
                    <CustomSelect
                      {...SelectInputProps}
                      id='role'
                      labelId='role_label'
                      onChange={props.onChange}
                      name='role'
                      value={!props.userFields.role ? 'placeholder' : props.userFields.role }
                      error={props.userErrors.role}
                      classes={{ select: !props.userFields.role ? styles.colorPlaceholder : '' }}
                    >
                      <MenuItem value='placeholder' disabled>Select a Role</MenuItem>
                      {allowedRoles.map((name) => (
                        <MenuItem key={name.id} value={name.value}>
                          {name.value}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                    <FormHelperText>{props.userErrors.role}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' type='reset'> Close</CustomButton>
            <CustomButton variant='contained' type='submit'> {props.type == 'edit' ? 'Edit' : 'Submit'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddUserModal;