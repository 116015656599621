import React from 'react';
import Screening from './Screening';
import moment from 'moment';
import http from 'utils/http';
import dayjs from 'dayjs';
class ScreeningContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airline: [],
      flight: [],
      preScreen: [],
      search: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        awb: null,
      },
      filter: {
        airline: null,
        flight: null,
        preScreen: null
      },
      sort_by: { dept: false, },
      data: [],
      awbs: [],
      isLoading: false
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchAwb();
  }

  fetchAwb = (awb = false) => {
    let query = '';
    if (awb) {
      query = 'awb=' + this.state.search.awb;
    } else {
      query = `from=${this.state.search.from}&to=${this.state.search.to}`;
    }
    this.setState({ isLoading: true }, () => {
      http.get('/screening?' + query).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({ data: data.data }, () => {
            this.getFilter();
            this.filterData();
          });
        }
      });
      this.setState({ isLoading: false });
    });
  };

  getFilter = () => {
    let airline = [];
    let flight = [];
    let preScreen = [];
    this.state.data.map(a => {
      let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
      if (!flight.includes(f)) {
        flight.push(f);
      }
      if (a.airline) {
        if (!airline.includes(a.airline.code)) {
          airline.push(a.airline.code);
        }
      }
      if (a.pre_screened) {
        if (!preScreen.includes(a.pre_screened)) {
          preScreen.push(a.pre_screened);
        }
      }
    });
    this.setState({
      airline,
      flight,
      preScreen
    });
  };

  filterData = () => {
    let awbs = [...this.state.data];
    if (this.state.filter.airline && this.state.filter.airline != '') {
      awbs = awbs.filter((a) => a.airline.code === this.state.filter.airline);
    }
    if (this.state.filter.flight && this.state.filter.flight != '') {
      awbs = awbs.filter((a) => {
        let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
        return f === this.state.filter.flight;
      });
    }
    if (this.state.filter.preScreen && this.state.filter.preScreen != '') {
      awbs = awbs.filter((a) => a.pre_screened === this.state.filter.preScreen);
    }
    this.setState({ awbs });
  };

  onChangeSearch = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == 'awb') {
      value = this.handleChangeAwb(value);
    }
    if (e.target.type == 'date') {
      value = dayjs(e.target.value).format('YYYY-MM-DD');
    }
    this.setState({
      search: {
        ...this.state.search,
        [name]: value
      }
    }, () => {
      if (name != 'awb' || (name == 'awb' && !value)) this.fetchAwb();
    });
  };

  handleChangeAwb = (value) => {

    if (value.length === 3 && (this.state.search.awb?.length === 2 || this.state.addAwb?.length === 2)) {
      value = value + '-';
    }
    if (value.length === 11) {
      if (value.indexOf('-') < 0) {
        value = value.slice(0, 3) + '-' + value.slice(3);
      }
    }
    if (value.split('-').length > 2) {
      return;
    }

    return value;
  };

  onChangefilter = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value
      }
    }, () => { this.filterData(); });
  };

  onChangeSort = (e) => {
    this.setState({
      sort_by: {
        ...this.state.sort_by,
        [e.target.name]: e.target.type == 'checkbox' ? e.target.checked : e.target.value
      }
    }, () => { this.filterData(); });
  };

  clear = () => {
    this.setState({
      filter: {
        airline: null,
        flight: null,
        preScreen: null
      },
    }, () => { this.setState({ awbs: [...this.state.data] });
    });
  };

  render() {
    return (
      <>
        <Screening
          searchAwb={this.searchAwb}
          airline={this.state.airline}
          flight={this.state.flight}
          preScreen={this.state.preScreen}
          search={this.state.search}
          filter={this.state.filter}
          sort_by={this.state.sort_by}
          awbs={this.state.awbs}
          fetchAwb={this.fetchAwb}
          onChangefilter={this.onChangefilter}
          onChangeSearch={this.onChangeSearch}
          handleSort={this.handleSort}
          clear={this.clear}
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
}

export default ScreeningContainer;
