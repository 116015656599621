import React from 'react';
import DocModal from './DocModal';
import http from 'utils/http';
import { setDocAction } from 'store/actions/docAction';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';

class DocModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      action: null,
      k9Fields: {},
      k9Errors: {},
    };
  }

  handleOpen = (modal, action, data = {}) => {
    this.setState({
      modal,
      action,
      k9Fields: modal == 'transfer' ? {
        ...data,
        ...data.data || {}
      } : {},
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      k9Fields: {},
      k9Errors: {}
    });
  };

  onChangeK9 = (e) => {
    let value = e.target.value;
    this.setState({
      k9Fields: {
        ...this.state.k9Fields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  validatek9 = () => {
    const k9Fields = { ...this.state.k9Fields };
    const error = {};
    let formValid = true;
    if (!k9Fields.name || (k9Fields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    if (!k9Fields.remark || (k9Fields.remark.trim().length === 0)) {
      formValid = false;
      error.remark = 'Please enter remark.';
    }
    this.setState({ k9Errors: error });
    return formValid;
  };

  addTransfer = (e) => {
    e.preventDefault();
    if (this.validatek9()) {
      let formdata = {
        ...this.state.k9Fields,
        id: this.props.doc.id
      };
      http.post('/document/transfer', formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            k9Fields: {},
            k9FieldsErrors: {},
            action: null,
          });
        }
      });
    }
  };

  editK9 = (e) => {
    e.preventDefault();
    if (this.validatek9()) {
      let formdata = { ...this.state.k9Fields, };
      formdata.type = 'transfer';
      http.put('/document/k9/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            k9Fields: {},
            k9Errors: {},
            action: null,
          });
        }
      });
    }
  };

  delete = (id) => {
    http.delete(`/document/k9/${id}?type=transfer`).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleDelete = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: this.delete(id),
      data: this.props.doc,
      name: this.props.transfer.number
    });
  };


  closeDrawer = () => {
    this.props.closeDoc();
  };

  render() {
    return (
      <>
        <DocModal
          drawer={this.props.doc.modal}
          closeDrawer={this.closeDrawer}
          name={this.props.doc.name}
          docs={this.props.doc.data}
          k9Fields={this.state.k9Fields}
          k9Errors={this.state.k9Errors}
          onChangeK9={this.onChangeK9}
          addK9={this.addTransfer}
          editK9={this.editK9}
          handleDelete={this.handleDelete}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          modal={this.state.modal}
          action={this.state.action}
          transfer={this.props.transfer}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { doc: state.doc };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeDoc: () => dispatch(setDocAction()),
    setDelete: (data) => dispatch(setDeleteAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocModalContainer);