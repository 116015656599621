import React from 'react';
import {
  FormControl,
  Typography,
  MenuItem,
} from '@mui/material';
import {
  StyledCard, StyledCardTitle,
  CustomSelect,
  SectionDivider,
  SelectInputProps
} from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import docStyles from './docs.module.css';
import { Stack } from '@mui/system';
import AwbDoc from './forms/AwbDoc';
import FlightManifest from './forms/FlightManifest';
import UserTraining from './forms/TrainingRecord';
import OtherDoc from './forms/Other';

const docVerificationComponent = (props) => {

  const returnForm = (key) => {
    switch (key) {
      case 'AWB':
        return (
          <AwbDoc
            handleChangeForm={props.changeFields}
            formFields={props.formFields}
            submit={props.submit}
            airlines={props.airlines}
            docId={props.docId}
            tracking={props.tracking}
            searchAwb={props.searchAwb}
            handleSecurity={props.handleSecurity}
            checkTransporationNumber={props.checkTransporationNumber}
            disableButton={props.disableButton}
            checkElement={props.checkElement}
            flightDates={props.flightDates}
            getFlightDates={props.getFlightDates}
            isLoading={props.isLoading}
            flightSettings={props.flightSettings}
            scheduleList={props.scheduleList}
          />
        );
      case 'FFM':
        return <FlightManifest
          handleChangeForm={props.changeFields}
          formFields={props.formFields}
          docId={props.docId}
          submit={props.submit}
          airlines={props.airlines}
        />;
      case 'USER':
        return <UserTraining
          handleChangeForm={props.changeFields}
          formFields={props.formFields}
          docId={props.docId}
          submit={props.submit}
          userList={props.userList}
        />;
      case 'OTHER':
        return <OtherDoc
          handleChangeForm={props.changeFields}
          checkAwb={props.checkAwb}
          formFields={props.formFields}
          docId={props.docId}
          submit={props.submit}
          userList={props.userList}
          k9={props.k9}
          awbs={props.awbs}
        />;
      default:
        break;
    }
  };

  const docForm = returnForm(props.docType);

  return (
    <StyledCard>
      <div className={docStyles.incomingDocHead}>
        <StyledCardTitle gutterBottom variant='cardTitle'>Verify Document</StyledCardTitle>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' id='doc-type-label'>Select Document Type</Typography>
          <div className={docStyles.addTextField}>
            <FormControl variant='standard' fullWidth
              // error={}
            >
              <CustomSelect
                {...SelectInputProps}
                labelId='doc-type-label'
                id=''
                name='station'
                value={props.docType}
                onChange={(e) => props.handleChange(e, 'docType')}
              >
                <MenuItem value='AWB'>Airwaybill</MenuItem>
                <MenuItem value='FFM'>Flight Manifest</MenuItem>
                <MenuItem value='USER'>User Training Record</MenuItem>
                <MenuItem value='OTHER'>Other</MenuItem>
              </CustomSelect>
              {/* <FormHelperText>{}</FormHelperText> */}
            </FormControl>
          </div>
        </Stack>
        <SectionDivider className={styles.mt3} />
      </div>
      <div className={docStyles.verifyDocBody}>
        <div className={docStyles.px4}>
          {props.docId ? docForm : null}
        </div>
      </div>

    </StyledCard>
  );
};

export default docVerificationComponent;
