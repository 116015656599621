import React from 'react';
import DocModal from './DocModal';
import http from 'utils/http';
import { setDocAction } from 'store/actions/docAction';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
import moment from 'moment';
import dayjs from 'dayjs';

class DocModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      action: null,
      userFields: {},
      userErrors: {},
      k9Fields: {},
      k9Errors: {}
    };
  }

  handleOpen = (modal, action, data = {}) => {
    this.setState({
      modal,
      action,
      k9Fields: modal == 'k9' ? {
        ...data,
        ...data.data || {}
      } : {},
      userFields: modal == 'user' ? {
        ...data,
        ...data.data || {}
      } : {}
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      k9Fields: {},
      k9Errors: {},
      userFields: {},
      userErrors: {},
    });
  };

  onChangeUser = (e) => {
    let value = e.target.value;
    if (e.target.type == 'date') {
      value = dayjs(e.target.value).format('YYYY-MM-DD');
    }
    this.setState({
      userFields: {
        ...this.state.userFields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  validateUser = () => {
    const userFields = { ...this.state.userFields };
    const error = {};
    let formValid = true;
    if (!userFields.name || (userFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    // if (!userFields.training_date || (userFields.training_date.trim().length === 0)) {
    //   formValid = false;
    //   error.training_date = 'Please enter training date.';
    // }
    // if (!userFields.expiry_date || (userFields.expiry_date.trim().length === 0)) {
    //   formValid = false;
    //   error.expiry_date = 'Please enter expiry date.';
    // }
    this.setState({ userErrors: error });
    return formValid;
  };

  addUser = async (e, callback) => {
    e.preventDefault();
    if (this.validateUser()) {
      let formdata = {
        training_date: moment().format('YYYY-MM-DD'),
        expiry_date: moment().format('YYYY-MM-DD'),
        ...this.state.userFields,
        id: this.props.doc.id
      };
      http.post('/document/user', formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            userFields: {},
            userErrors: {},
            action: null,
          });
          if (typeof callback === 'function') {
            callback(true);
          }
        }
      });
    }
  };
  editUser = (e) => {
    e.preventDefault();
    if (this.validateUser()) {
      let formdata = { ...this.state.userFields, };
      http.put('/document/user/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            userFields: {},
            userErrors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteUser = async (id) => {
    http.delete('/document/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };


  onChangeK9 = (e) => {
    let value = e.target.value;
    this.setState({
      k9Fields: {
        ...this.state.k9Fields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  validatek9 = () => {
    const k9Fields = { ...this.state.k9Fields };
    const error = {};
    let formValid = true;
    if (!k9Fields.name || (k9Fields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    if (!k9Fields.remark || (k9Fields.remark.trim().length === 0)) {
      formValid = false;
      error.remark = 'Please enter remark.';
    }
    this.setState({ k9Errors: error });
    return formValid;
  };

  addK9 = (e, callback) => {
    e.preventDefault();
    if (this.validatek9()) {
      let formdata = {
        ...this.state.k9Fields,
        id: this.props.doc.id
      };
      http.post('/document/k9', formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            k9Fields: {},
            k9Errors: {},
            action: null,
          });
          if (typeof callback === 'function') {
            callback(true);
          }
        }
      });
    }
  };

  editK9 = (e) => {
    e.preventDefault();
    if (this.validatek9()) {
      let formdata = { ...this.state.k9Fields, };
      http.put('/document/k9/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            k9Fields: {},
            k9Errors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteK9 = (id) => {
    http.delete('/document/k9/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleDelete = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.props.doc.modal == 'user' ? this.deleteUser(id) : this.deleteK9(id),
    });
  };

  closeDrawer = () => {
    this.props.closeDoc();
  };

  render() {
    return (
      <>
        <DocModal
          drawer={this.props.doc.modal}
          closeDrawer={this.closeDrawer}
          name={this.props.doc.name}
          docs={this.props.doc.data}
          userFields={this.state.userFields}
          userErrors={this.state.userErrors}
          onChangeUser={this.onChangeUser}
          addUser={this.addUser}
          editUser={this.editUser}
          k9Fields={this.state.k9Fields}
          k9Errors={this.state.k9Errors}
          onChangeK9={this.onChangeK9}
          addK9={this.addK9}
          editK9={this.editK9}
          handleDelete={this.handleDelete}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          modal={this.state.modal}
          action={this.state.action}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { doc: state.doc };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeDoc: () => dispatch(setDocAction()),
    setDelete: (data) => dispatch(setDeleteAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocModalContainer);