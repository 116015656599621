import { SET_DELETE } from '../actions/actionTypes';

const initialState = {
  modal: false,
  id: false,
  anchorEl: false,
  onConfirm: () => { },
};

export const deleteReducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_DELETE:
      return { ...action.data };
    default:
      return state;
  }
};