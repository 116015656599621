
import React from 'react';
import { Box, IconButton, Typography, MenuItem, Divider } from '@mui/material';
import '@fontsource/nunito';
// import { CustomButton } from '../custom/components';
import Logo from '../../assets/images/logo.png';
import styles from '../../custom/style.module.css';
import headerStyles from './header.module.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { sideBarLogo, headerBox } from './headerconstants';
import { SelectLocation } from '../../custom/components';
import { appName } from 'custom/constants';
import cookies from 'utils/cookies';

const Header = (props) => {
  return (
    <div className={headerStyles.headerSidbarWrapper}>
      <header className={headerStyles.header}>
        <Box sx={{
          ...headerBox,
          display: 'flex',
          position: 'relative'
        }} className={`${headerStyles.leftHdBox}`}>
          <img src={Logo} alt='logo' 
            style={props.open ? sideBarLogo : {
              height: '0',
              opacity: '0',
              position: 'fixed',
              left: '0'
            }} 
          />
          <IconButton disableRipple aria-label='menu' sx={{ height: '100%' }} onClick={props.handleDrawerOpen} size='large' className={`${headerStyles.printSideLogo}`}>
            <img src={Logo} alt='logo' className={`${headerStyles.sideLogo}`} />
          </IconButton>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <Typography variant='h6' component='h6' fontSize={'1.1rem'} lineHeight='normal' textTransform='uppercase'>
              {appName}
            </Typography>
            <Typography variant='caption' component='span' lineHeight='normal' sx={{ textShadow: '0 0 white' }}>
              {props.pageTitle}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 'auto' }} className={headerStyles.midHdBox}></Box>
        <Box sx={headerBox} className={headerStyles.rgtHdBox}>
          <div className={headerStyles.leftHdDiv}>
            <p className={headerStyles.leftPTag} >{cookies.get('company_name')}</p>
            <FormControl
              fullWidth
              className={headerStyles.locationSelector}
            >
              <SelectLocation
                displayEmpty
                input={<OutlinedInput classes={{ input: headerStyles.outlinedInput }} />}
                value={props.station}
                onChange={(e)=> props.handleStation(e) }
                inputProps={{ 'aria-label': 'Without label' }}
                classes={{ select: headerStyles.locSelect }}
                className={`${styles.mauto} ${headerStyles.hidePrint}`}
              >
                {props.stations && props.stations.map((stn) => (
                  <MenuItem
                    key={stn.id}
                    value={stn.id}
                    className={headerStyles.selectOption}
                  >
                    {stn.code}
                  </MenuItem>
                ))}
              </SelectLocation>
            </FormControl>
            <span className={headerStyles.showPrint}>{cookies.get('station_code')}</span>
          </div>
        </Box>
      </header>
      <Divider className={headerStyles.showPrint} />
    </div>
  );
};

export default Header;
