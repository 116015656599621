import React from 'react';
import { Stack, Box } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, DataGridProps } from '../../../../../custom/components';
import { EditIcon, DeleteIcon, AddIcon, } from '../../../../../custom/constants';
import whiteListStyles from './whitelist.module.css';
import styles from '../../../../../custom/style.module.css';
import WhiteListModal from './WhiteListModal';

const WhiteList = (props) => {
  const columns = [
    {
      field: 'mail',
      headerName: 'e-Mail Address',
      type: 'email',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      // minWidth: 100, 
      flex: 1,
      width: 90,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <EditIcon onClick={() => { props.handleOpen('edit', row); }} />
            <DeleteIcon type='button' onClick={(e) => { props.handleDelete(row.id, e.target); }} />
          </Stack>

        );
      }
    },
  ];

  return (
    <div>
      <StyledCard>

        <WhiteListModal
          action={props.action}
          mailFields={props.mailFields}
          mailErrors={props.mailErrors}
          onChange={props.onChange}
          addMail={props.addMail}
          editMail={props.editMail}
          whiteListModal={props.whiteListModal}
          onClose={props.onClose}
        />

        <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
          <StyledCardTitle gutterBottom variant='cardTitle'>Email addresses allowed to send documents to docs stream</StyledCardTitle>
          <AddIcon onClick={() => props.handleOpen('add')} />
        </div>
        <div className={styles.tableWrapper}>
          <Box className={`${styles.tableOuter} ${whiteListStyles.listTableOuter}`}>
            <StyledDataGrid
              {...DataGridProps}
              rows={props.data}
              columns={columns}
            />
          </Box>
        </div>
      </StyledCard>
    </div>
  );
};

export default WhiteList;