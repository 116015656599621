import React from 'react';
import {
  Grid,
  FormControl,
  Typography,
  MenuItem,
} from '@mui/material';
import {
  CustomButton,
  CustomDatePicker,
  CustomSelect,
  CustomTextField,
  InputProps,
  SelectInputProps
} from '../../../.././custom/components';
import styles from '../../../../custom/style.module.css';
import docStyles from '../docs.module.css';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { DateFormat } from 'custom/constants';

const trainingRecord = (props) => {
  return (
    <Grid container spacing={2}>


      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' id='user-label'>Select User</Typography>
          <div className={docStyles.addTextField}>
            <FormControl variant='standard'
              fullWidth
            // error={}
            >
              <CustomSelect
                {...SelectInputProps}
                labelId='user-label'
                id=''
                name='userId'
                onChange={(e) => props.handleChangeForm(e, 'userId')}
                value={Number(props.formFields.userId)}
              >
                {props.userList.map((user, i) => (
                  <MenuItem key={i} value={user.id}>{`${user.first_name} ${user.last_name}`}</MenuItem>
                ))}


              </CustomSelect>
              {/* <FormHelperText>{}</FormHelperText> */}
            </FormControl>
          </div>

        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='awb_no'>Training Name</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='awb_no'
              autoFocus
              fullWidth
              placeholder='Training Name'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'name')}
              value={props.formFields.name}
              name='name'
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label'>Training Date</Typography>
          <div className={docStyles.addTextField}>
            <CustomDatePicker
              slotProps={{
                textField: {
                  ...InputProps,
                  fullWidth: true,
                  placeholder: 'Training Date'
                }, 
              }}
              format={DateFormat}
              name='training_date'
              className={styles.w100}
              onChange={(e) => props.handleChangeForm(e, 'training_date')}
              value={props.formFields.training_date ? dayjs(props.formFields.training_date, 'YYYY-MM-DD') : null}
            // error={}
            // helperText={}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label'>Training Expiry</Typography>
          <div className={docStyles.addTextField}>
            <CustomDatePicker
              slotProps={{
                textField: {
                  ...InputProps,
                  fullWidth: true,
                  placeholder: 'Expiry Date'
                }, 
              }}
              format={DateFormat}
              name='expiry_date'
              className={styles.w100}
              onChange={(e) => props.handleChangeForm(e, 'expiry_date')}
              value={props.formFields.expiry_date ? dayjs(props.formFields.expiry_date, 'YYYY-MM-DD') : null}
            // error={}
            // helperText={}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs className={`${docStyles.docBtn} ${docStyles.docBtnOnly}`}>
        <CustomButton variant='contained' onClick={props.submit}> Update </CustomButton>
      </Grid>
    </Grid>
  );
};

export default trainingRecord;