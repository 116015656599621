import { SET_DELETE, CLEAR_DELETE } from './actionTypes';

function clearDelete() {
  return { type: CLEAR_DELETE };
}

function setDelete(data) {
  return {
    type: SET_DELETE,
    data
  };
}

function setDeleteAction(data) {
  return (dispatch) => {
    dispatch(setDelete(data));
  };
}

function clearDeleteAction() {
  return (dispatch) => {
    dispatch(clearDelete());
  };
}

export { setDeleteAction, clearDeleteAction };