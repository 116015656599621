import React from 'react';
import { Dialog } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, CustomDialogContent, InputProps } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const DeactiveModal = (props) => {
  const { action, k9Fields, k9Errors, onChangeK9, active, deactive } = props;
  return (
    <div>
      <Dialog 
        aria-labelledby='form-dialog-title' 
        open={props.modal === 'deactive_k9'} 
        onClose={props.onClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'active' ? 'Activate' : 'Deactivate'} 3rd Party K9 Company
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100} onSubmit={action == 'active' ? active : deactive} onReset={props.onClose}>
          <CustomDialogContent>
            <StyledCard>
              <div>
                <CustomInputLabel htmlFor='deactivate'>Deactivate reason</CustomInputLabel>
                {action == 'active' && (<>{k9Fields.reason}</>)}
                {action == 'deactive' && (<CustomTextField
                  {...InputProps}
                  fullWidth
                  id='deactivate'
                  className={`${styles.mb3}`}
                  placeholder='Add Reason'
                  type='text'
                  rows={'3'}
                  onChange={onChangeK9}
                  name='reason'
                  value={k9Fields.reason}
                  error={k9Errors.reason}
                  helperText={k9Errors.reason}
                />)}
              </div>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset'>Close</CustomButton>
            <CustomButton variant='contained' type='submit'>{action == 'active' ? 'Activate' : 'Deactivate'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default DeactiveModal;