import React from 'react';
import MeasureMethods from './MeasureMethods';
import events from 'utils/evemts';
import http from 'utils/http';
import { setDeleteAction } from 'store/actions/deleteAction';
import { connect } from 'react-redux';
import cookies from 'utils/cookies';

class MeasureMethodsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      action: null,
      anchorEl: null,
      location: false,
      locationType: null,
      station: [],
      airline: [],
      measure: [],
      measureFields: {},
      measureErrors: {},
      method: [],
      methodFields: {},
      methodErrors: {},
      alarm: [],
      alarmFields: {},
      alarmErrors: {},
      active: null,
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchAirline();
    this.fetchMeasure();
    this.fetchAlarm();
    this.eventSource = events('admin', 'method');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      let station = parseInt(cookies.get('station'));
      //measure
      // if (msg.action == 'add') {
      //   this.setState({ measure: [...this.state.measure, msg.data] });
      // }
      if (msg.action == 'update' || msg.action == 'delete') {
        const measure = [...this.state.measure];
        const index = measure.findIndex(c => msg.data && c.id == msg.data.id);
        if (msg.action == 'update') {
          if (index > -1) {
            // if (msg.data.station) {
            //   measure[index] = {
            //     ...measure[index],
            //     stations: msg.data.stations
            //   };
            // } else {
            measure[index] = {
              ...msg.data,
              stations: measure[index].stations
            };
            // }
            // if (!measure[index].stations?.some(stn => stn.id === station)) {
            //   measure.splice(index, 1);
            // }
            this.setState({ measure });
          } else {
            if (msg.data?.stations && msg.data?.stations?.length > 0) {
              if (msg.data?.stations?.some(stn => stn.id === station)) {
                this.fetchMeasure();
              }
            }
          }
        }
        // if (msg.action == 'delete') {
        //   if (index > -1) {
        //     measure.splice(index, 1);
        //     if (this.state.active == msg.data.id) {
        //       this.setState({
        //         measure,
        //         active: null,
        //         method: []
        //       });
        //     } else {
        //       this.setState({ measure });
        //     }
        //   }
        // }
      }

      //method
      if (msg.action == 'add_method' && msg.data.measureId == this.state.active && msg.data?.stations?.some(stn => stn.id === station)) {
        this.setState({ method: [...this.state.method, msg.data] });
      }
      if ((msg.action == 'update_method' || msg.action == 'delete_method') && msg.data.measureId == this.state.active) {
        const method = [...this.state.method];
        const index = method.findIndex(c => msg.data && c.id == msg.data.id);
        if (msg.action == 'update_method') {
          if (index > -1) {
            if (msg.data.station) {
              method[index] = {
                ...method[index],
                stations: msg.data.stations
              };
            }
            method[index] = {
              ...msg.data,
              stations: method[index].stations
            };
            if (!method[index].stations?.some(stn => stn.id === station)) {
              method.splice(index, 1);
            }
            this.setState({ method });
          } else {
            if (msg.data?.stations && msg.data?.stations?.length > 0) {
              if (msg.data?.stations?.some(stn => stn.id === station)) {
                this.fetchMethod(this.state.active);
              }
            }
          }
        }
        if (msg.action == 'delete_method') {
          if (index > -1) {
            method.splice(index, 1);
            this.setState({ method });
          }
        }
      }
      //alarms
      if (msg.action == 'add_alarm' && msg.data?.stations?.some(stn => stn.id === station)) {
        this.setState({ alarm: [...this.state.alarm, msg.data] });
      }
      if (msg.action == 'update_alarm' || msg.action == 'delete_alarm') {
        const alarm = [...this.state.alarm];
        const index = alarm.findIndex(c => msg.data && c.id == msg.data.id);
        if (msg.action == 'update_alarm') {
          if (index > -1) {
            if (msg.data.station) {
              alarm[index] = {
                ...alarm[index],
                stations: msg.data.stations
              };
            }
            alarm[index] = {
              ...msg.data,
              stations: alarm[index].stations
            };
            if (!alarm[index].stations?.some(stn => stn.id === station)) {
              alarm.splice(index, 1);
            }
            this.setState({ alarm });
          } else {
            if (msg.data?.stations && msg.data?.stations?.length > 0) {
              if (msg.data?.stations?.some(stn => stn.id === station)) {
                this.fetchAlarm();
              }
            }
          }
        }
        if (msg.action == 'delete_alarm') {
          if (index > -1) {
            alarm.splice(index, 1);
            this.setState({ alarm });
          }
        }
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  fetchAirline = () => {
    http.get('/airline').then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ airline: data.data });
      }
    });
  };

  fetchMeasure = () => {
    http.get('/measure').then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ measure: data.data });
      }
    });
  };

  onChangeMeasure = (e) => {
    let airline = [];
    if (e.target.name == 'airline') {
      airline = [...this.state.measureFields.airline || []];
      if (e.target.checked) {
        airline.push(parseInt(e.target.value));
      } else {
        var index = airline.indexOf(parseInt(e.target.value));
        if (index !== -1) {
          airline.splice(index, 1);
        }
      }
    }
    this.setState({
      measureFields: {
        ...this.state.measureFields,
        [e.target.name]: e.target.name == 'airline' ? airline : e.target.value
      }
    });
  };


  validationMeasure = () => {
    const measureFields = { ...this.state.measureFields };
    const error = {};
    let formValid = true;
    if (!measureFields.type || (measureFields.type.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter measure.';
    }
    // if (!measureFields.code || (measureFields.code.trim().length === 0)) {
    //   formValid = false;
    //   error.code = 'Please enter code.';
    // }
    this.setState({ measureErrors: error });
    return formValid;
  };

  addMeasure = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.measureFields };
    if (this.validationMeasure()) {
      http.post('/measure', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            measureFields: {},
            measureErrors: {},
            action: null,
          });
        }
      });
    }
  };

  editMeasure = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.measureFields };
    delete formdata.station;
    if (this.validationMeasure()) {
      http.put('/measure/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            measureFields: {},
            measureErrors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteMeasure = (id) => {
    http.delete('/measure/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          active: null,
          method: []
        });
        this.props.setDelete();
      }
    });
  };

  fetchMethod = (id) => {
    this.setState({ active: id });
    http.get('/method/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ method: data.data });
      }
    })
      .catch(() => {
        this.setState({ active: null });
      });
  };

  onChangeMethod = (e) => {
    this.setState({
      methodFields: {
        ...this.state.methodFields,
        [e.target.name]: e.target.value
      }
    });
  };


  validationMethod = () => {
    const methodFields = { ...this.state.methodFields };
    const error = {};
    let formValid = true;
    if (!methodFields.name || (methodFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter method.';
    }
    if (!methodFields.code || (methodFields.code.trim().length === 0)) {
      formValid = false;
      error.code = 'Please enter code.';
    }
    this.setState({ methodErrors: error });
    return formValid;
  };

  addMethod = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.methodFields };
    if (this.validationMethod()) {
      http.post('/method/' + this.state.active, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            methodFields: {},
            methodErrors: {},
            action: null,
          });
        }
      });
    }
  };

  editMethod = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.methodFields };
    delete formdata.station;
    if (this.validationMethod()) {
      http.put('/method/' + this.state.active + '/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            methodFields: {},
            methodErrors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteMethod = (id) => {
    http.delete('/method/' + this.state.active + '/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  fetchAlarm = () => {
    http.get('/alarm').then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ alarm: data.data });
      }
    });
  };

  onChangeAlarm = (e) => {
    this.setState({
      alarmFields: {
        ...this.state.alarmFields,
        [e.target.name]: e.target.value
      }
    });
  };

  
  validationAlarm = () => {
    const alarmFields = { ...this.state.alarmFields };
    const error = {};
    let formValid = true;
    if (!alarmFields.name || (alarmFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter resolution.';
    }
    if (!alarmFields.code || (alarmFields.code.trim().length === 0)) {
      formValid = false;
      error.code = 'Please enter code.';
    }
    this.setState({ alarmErrors: error });
    return formValid;
  };

  addAlarm = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.alarmFields };
    if (this.validationAlarm()) {
      http.post('/alarm', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            alarmFields: {},
            alarmErrors: {},
            action: null,
          });
        }
      });
    }
  };

  editAlarm = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.alarmFields };
    delete formdata.station;
    if (this.validationAlarm()) {
      http.put('/alarm/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            alarmFields: {},
            alarmErrors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteAlarm = (id) => {
    http.delete('/alarm/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleLocation = (locationType, v, data = {}, anchorEl = null) => {
    this.setState({
      location: v,
      locationType,
      station: data,
      anchorEl
    });
  };

  handleOpen = (modal, action, data = {}) => {
    this.setState({
      modal,
      action,
      measureFields: modal == 'measure' ? {
        ...data,
        airline: data.airlines && data.airlines.map(a => a.id) || []
      } : {},
      methodFields: modal == 'method' ? data : {},
      alarmFields: modal == 'alarm' ? data : {}
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
      measureFields: {},
      measureErrors: {},
      methodFields: {},
      methodErrors: {},
      alarmFields: {},
      alarmErrors: {},
    });
  };

  handleDelete = (type, id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => {
        switch (type) {
          case 'measure':
            this.deleteMeasure(id);
            break;
          case 'method':
            this.deleteMethod(id);
            break;
          case 'alarm':
            this.deleteAlarm(id);
            break;
          default:
            break;
        }
      },
    });
  };

  render() {
    return (
      <>
        <MeasureMethods
          stations={this.props.stations}
          station={this.state.station}
          anchorEl={this.state.anchorEl}
          modal={this.state.modal}
          action={this.state.action}
          location={this.state.location}
          locationType={this.state.locationType}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          handleLocation={this.handleLocation}
          airline={this.state.airline}
          measure={this.state.measure}
          measureFields={this.state.measureFields}
          measureErrors={this.state.measureErrors}
          onChangeMeasure={this.onChangeMeasure}
          addMeasure={this.addMeasure}
          editMeasure={this.editMeasure}
          active={this.state.active}
          fetchMethod={this.fetchMethod}
          method={this.state.method}
          methodFields={this.state.methodFields}
          methodErrors={this.state.methodErrors}
          onChangeMethod={this.onChangeMethod}
          addMethod={this.addMethod}
          editMethod={this.editMethod}
          alarm={this.state.alarm}
          alarmFields={this.state.alarmFields}
          alarmErrors={this.state.alarmErrors}
          onChangeAlarm={this.onChangeAlarm}
          addAlarm={this.addAlarm}
          editAlarm={this.editAlarm}
          handleDelete={this.handleDelete}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { stations: state.stations };
};

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasureMethodsContainer);