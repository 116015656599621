
import { Button, Card, Tab, FormControlLabel, Typography, Badge, Select, InputLabel, DialogTitle, DialogActions, TextField, Divider, TableContainer, TableRow,  LinearProgress, linearProgressClasses, Autocomplete, DialogContent } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { styled, createTheme } from '@mui/material/styles';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import styles from '../custom/style.module.css';
import { grey } from '@mui/material/colors';

let theme = createTheme();

const inputFieldProps = {
  padding: '6px 14px',
  fontSize: 'var(--input-fs)',
  // backgroundColor: theme.palette.common.white,
  lineHeight: 'normal',
  borderRadius: 'var(--ip-br)',
};

const inputDisabledProps = { 
  border: 'none',
  backgroundColor: theme.palette.grey[300]
};

const inputProps= {
  borderRadius: 'var(--ip-br)',
  '& .MuiInputBase-input': { ...inputFieldProps, },
  // '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
  // '& .MuiOutlinedInput-root': { borderRadius: 'var(--input-fs)' },
  '&.Mui-disabled': {
    ...inputDisabledProps,
    borderRadius: 'var(--ip-br)', 
  },
  '& .Mui-disabled': {
    ...inputDisabledProps,
    borderRadius: 'var(--ip-br)', 
  },
};

export const DataGridProps = {
  columnHeaderHeight: 30,
  getRowHeight: () => '38px',
  rowHeight: 38,
  stickyHeader: true,
  hideFooter: true,
  disableRowSelectionOnClick: true,
};

export const InputProps = {
  variant: 'standard',
  size: 'small',
  InputLabelProps: { shrink: false },
  InputProps: { disableUnderline: true }
};

export const SelectInputProps = {
  variant: 'standard',
  disableUnderline: true,
  MenuProps:{ classes: { paper: styles.selectPaperIp } }
};

export const CloseIconProps = {
  position: 'absolute',
  right: 24,
  top: 12,
  color: (theme) => theme.palette.grey[500],
};


// CUSTOM BUTTON
export const CustomButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-contained:not(.Mui-disabled)': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderColor: 'transparent',
    // '&.Mui-disabled': { ...inputDisabledProps },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '0.8rem',
  fontWeight: 600,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  minWidth: '90px',
  textShadow: '0 0 white',
  '&.Mui-disabled': { ...inputDisabledProps },
}));

// CUSTOM LOCATION SELECTOR 
export const SelectLocation = styled(Select)(({ theme }) => ({
  maxHeight: theme.spacing(6),
  backgroundColor: 'var(--greybkg)',
  color: theme.palette.primary,
  borderRadius: '10px',
  fontSize: '0.8rem',
  fontWeight: 500,
}));

// TAB STRUCRURE WITH ICON
export const IconTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'capitalize',
  fontSize: '0.7rem',
  fontWeight: '600',
  justifyContent: 'space-between',
  minHeight: 'auto',
  lineHeight: 1.2,
  '&:not(.Mui-selected)': {
    filter: 'var(--disabledfilter)',
    opacity: 'var(--disabledop)',
  },
  '& .MuiTab-iconWrapper': {
    width: 'auto',
    marginBottom: '8px',
    maxHeight: '25px',
  }
}));

export const CustomTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  textTransform: 'capitalize',
  fontSize: '0.75rem',
  fontWeight: '600',
  justifyContent: 'space-between',
  borderRadius: '10px 10px 0 0',
  width: 'calc(100% - 1px)',
  marginRight: '1px',
  minHeight: '30px',
  '&.Mui-selected': { color: theme.palette.primary.contrastText, },
  '&:not(.Mui-selected)': {
    // filter: 'var(--disabledfilter)',
    // opacity: 'var(--disabledop)',
    backgroundColor: 'var(--greybkg)',
    color: 'black',
    fontWeight: 'normal'
  },
}));

// CUSTOM CARD
export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'var(--greybkg)',
  padding: theme.spacing(3),
  borderRadius: 'var(--headerbr)',
  boxShadow: 'none'
  // maxHeight: '200px',
  // overflowY: 'auto' 
}));

export const StyledWhiteCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3),
  borderRadius: '20px',
  boxShadow: 'none'
  // maxHeight: '200px',
  // overflowY: 'auto' 
}));

export const StyledBlueCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
  borderRadius: '20px'
  // maxHeight: '200px',
  // overflowY: 'auto' 
}));

// CUSTOM CARD TITLE
export const StyledCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3,
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginBottom: 0,
  paddingBottom: theme.spacing(1),
  textShadow: '0 0 rgb(0 0 0 / 20%)'
}));

export const StyledBlueCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3,
  color: theme.palette.primary.contrastText,
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: 0
}));

// CUSTOM TABLE
// export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
//   width: 'auto',
//   marginRight: `-${theme.spacing(6)}`
// }));
export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: 'auto',
  marginRight: `-${theme.spacing(6)}`,
  '& > .MuiTable-root': {
    borderCollapse: 'initial',
    borderSpacing: '0 20px',
    marginTop: '-20px',
    paddingRight: theme.spacing(6),
    backgroundColor: 'transparent',
    '& > .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: '12px',
        whiteSpace: 'nowrap',
        padding: theme.spacing(1, 1),
        fontWeight: 600,
        lineHeight: 'normal'
      }
    },
    '& > .MuiTableBody-root ': {
      '& > .MuiTableRow-root': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        whiteSpace: 'nowrap',
        // padding: theme.spacing(1, 2),
        // minHeight: '38px',
        // height: '38px',
        // borderRadius: 'var(--tbbr)',
        '& > .MuiTableCell-root': {
          padding: theme.spacing(1, 1),
          fontSize: '12px',
          '&:first-of-type': { borderRadius: 'var(--tbbr) 0 0 var(--tbbr)' },
          '&:last-of-type': { borderRadius: '0 var(--tbbr) var(--tbbr) 0' },
          '&:only-child': { borderRadius: 'var(--tbbr)' }
        }
        // '&:first-of-type': { borderRadius: 'var(--tbbr) 0 0 var(--tbbr)' }
      }
    },
  },
  // [`&.${tableCellClasses.body}`]: { fontSize: 14, },
  color: theme.palette.common.black
}));

export const NestedTableCellContainer = styled(TableRow)(({ theme }) => ({
  '&.MuiTableRow-root': {
    backgroundColor: 'transparent !important',
    // '& .MuiTableCell-root': {
    //   // padding: 0,
    //   // fontSize: '12px !important',
    //   borderBottom: 'none'
    // },
    '& > .MuiTableCell-root': {
      padding: '0 !important',
      // border: `2px solid ${theme.palette.primary.main}`,
      // borderRadius: 'var(--tbbr)',
      // fontSize: '12px',
      '& .MuiTable-root': {
        borderCollapse: 'inherit',
        borderRadius: 'var(--tbbr)',
        // borderSpacing: '0 20px',
        // marginTop: '-20px',
        marginLeft: theme.spacing(8),
        width: `calc(100% - ${theme.spacing(8)})`,
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'transparent',
        fontSize: '12px',
        '& .MuiTableHead-root': {
          backgroundColor: 'transparent',
          '& .MuiTableCell-root': {
            color: theme.palette.primary.main,
            fontSize: '12px',
            whiteSpace: 'nowrap',
            padding: theme.spacing(1, 1.5),
            fontWeight: 600,
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            lineHeight: 'normal'
          }
        },
        '& .MuiTableBody-root ': {
          '& .MuiTableRow-root': {
            color: theme.palette.common.black,
            fontSize: '12px',
            whiteSpace: 'nowrap',
            // padding: theme.spacing(1, 1.5),
            // minHeight: '38px',
            // height: '38px',
            // borderRadius: 'var(--tbbr)',
            '& > .MuiTableCell-root': {
              borderBottom: 'none',
              padding: theme.spacing(1, 1.5),
              fontSize: '12px',
            }
            // '&:first-of-type': { borderRadius: 'var(--tbbr) 0 0 var(--tbbr)' }
          }
        },
      },
    }
  }
}));

// export const StyledTable = styled(Table)(({ theme }) => ({
//   borderCollapse: 'initial !important',
//   borderSpacing: '0 20px !important',
//   marginTop: '-20px',
//   paddingRight: theme.spacing(6),
//   backgroundColor: 'transparent'
// }));

// export const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   '&.MuiTableCell-head': {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     fontSize: '12px',
//     whiteSpace: 'nowrap',
//     padding: theme.spacing(1, 2),
//     fontWeight: 600,
//   },
//   '&.MuiTableCell-body': {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.common.black,
//     fontSize: '12px',
//     whiteSpace: 'nowrap',
//     padding: theme.spacing(1, 2),
//     minHeight: '38px',
//     height: '38px',
//     borderRadius: 'var(--tbbr)'
//     // '&:first-of-type': { borderRadius: 'var(--tbbr) 0 0 var(--tbbr)' }
//   },

//   // [`&.${tableCellClasses.body}`]: { fontSize: 14, },
//   color: theme.palette.common.black
// }));

// export const StyledNestedTableCell = styled(TableCell)(({ theme }) => ({
//   '&.MuiTableCell-head': {
//     color: theme.palette.primary.main,
//     fontSize: '12px',
//     whiteSpace: 'nowrap',
//     padding: theme.spacing(1, 2),
//     fontWeight: 600,
//     borderBottom: '2px solid theme.palette.primary.main',
//     lineHeight: 'normal',
//   },
//   '&.MuiTableCell-body': {
//     // backgroundColor: theme.palette.common.white,
//     color: theme.palette.common.black,
//     // fontSize: '12px',
//     // whiteSpace: 'nowrap',
//     // padding: theme.spacing(1, 2),
//     // minHeight: '38px',
//     // height: '38px',
//     // borderRadius: 'var(--tbbr)'
//     // '&:first-of-type': { borderRadius: 'var(--tbbr) 0 0 var(--tbbr)' }
//   },

//   // [`&.${tableCellClasses.body}`]: { fontSize: 14, },
//   color: theme.palette.common.black
// }));

// export const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover, },
//   // hide last border
//   '&:last-of-type td, &:last-of-type th': { border: 0, },
// }));

export const TableBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    fontSize: '8px',
    fontWeight: 'bolder',
    minWidth: '14px',
    height: '14px',
    padding: '0 4px',
  }
}));

// CUSTOM POPOVER
export const PopoverHead = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1.5, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const PopoverHeadWhite = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  padding: theme.spacing(1.5, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid lightgrey'
}));

// export const { PopoverContent, PopoverFooter } = styled('div')(({ theme }) => ({ padding: theme.spacing(2), }))
export const PopoverContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  // borderBottom: '1px solid lightgrey'
}));

export const PopoverFooter = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  paddingTop: '8px'
}));

// CUSTOM DATAGRID
export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  // marginRight: '-24px',//DND
  // '& .MuiDataGrid-virtualScrollerRenderZone': {//DND
  //   width: '98%',//DND
  //   overflow: 'auto'//DND
  // },//DND
  // '& .MuiDataGrid-virtualScrollerContent': { width: 'calc(100% - 0px) !important',  },//DND
  marginRight: '24px',
  '& .MuiDataGrid-overlayWrapperInner': { height: '200px !important' },
  '& .MuiDataGrid-main': { 
    rowGap: theme.spacing(2),
    overflow: 'visible'
  },
  '& .MuiDataGrid-virtualScrollerRenderZone': { rowGap: theme.spacing(2), },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'visible',
    overflowX: 'visible'
  },
  '& .MuiDataGrid-cell:focus-within': { outline: 'none !important' },
  borderColor: 'transparent',
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px'
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    textShadow: '0 0 white',
    opacity: 0.9 
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '12px',
    whiteSpace: 'nowrap',
    // marginBottom: theme.spacing(3),
    fontWeight: 700,
    borderRadius: '4px',
    overflow: 'visible',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    // marginRight: 'var(--splayout)', //DND
    '&:hover .MuiSvgIcon-root': { color: 'white', },
  },
  '& .MuiDataGrid-columnHeader': {
    padding: 0,
    '&:last-child .MuiDataGrid-columnHeaderTitleContainer': { flex: 0.9 },
  },
  '& .MuiDataGrid-row': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'nowrap',
    // marginBottom: theme.spacing(3),
    rowGap: theme.spacing(3),

    lineHeight: 1,
    borderRadius: 'var(--tbbr)',
    maxHeight:' initial !important',
    '& .MuiDataGrid-cellCheckbox svg': { transform: 'scale(0.8)' },
    '& .MuiDataGrid-cell': {
      padding: theme.spacing(1, 2),
      maxHeight:' initial !important',
      '&:last-child.MuiDataGrid-withBorderColor:empty' : { display: 'none', } //extra column at the end of the table 
    },
    '&.Mui-selected, &:hover, &.Mui-hovered': { 
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& .MuiFormControlLabel-root:not(.Mui-disabled)':{
        color: theme.palette.common.white,
        '& .MuiCheckbox-root:not(.Mui-disabled)': { color: 'white' }, 
      },
      '& .MuiFormControlLabel-root': {
        '& .MuiFormControlLabel-label.Mui-disabled' : { color: grey[500], },
        '&.Mui-disabled':  { '& .MuiCheckbox-root': { '&.Mui-disabled': { color: grey[500] } }, }, 
      },
      '& img': { filter: 'invert(98%) sepia(2%) saturate(0%) hue-rotate(174deg) brightness(102%) contrast(102%)', },
      '& .MuiRadio-root' :{ color: 'white', } 
    },
  },
  // [`&.${tableCellClasses.body}`]: { fontSize: 14, },
  color: theme.palette.common.black
}));

// CUSTOM INPUT
export const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '11px',
  fontWeight: '600',
  paddingLeft: '2px',
  paddingBottom: '2px',
  whiteSpace: 'normal',
  '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': { // these are the classes used from material-ui library for the asterisk element
    color: 'var(--redText)',
  },
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    ...inputProps
  }
}));
export const CustomSearch = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '50px',
    paddingRight: '10px',
    ...inputProps
  }
}));
export const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    // borderRadius: 'var(--ip-br)',
    paddingRight: '14px',
    ...inputProps,
    '& .MuiInputBase-input': {
      ...inputFieldProps,
      paddingRight: 0,
      minWidth: '70px', 
    },
    // '& .MuiButtonBase-root': {
    //   // paddingRight: '14px',
    //   // paddingLeft: '0', DND
    //   // '& .MuiSvgIcon-root': { fontSize: '1rem', } 
    // },
    '& .MuiInputAdornment-root': {
      // paddingRight: '14px',
      '& .MuiSvgIcon-root': { fontSize: '1rem', } 
    }
  }
}));

export const CustomTimePicker = styled(TimePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    paddingRight: '14px',
    ...inputProps,
    '& .MuiInputBase-input': {
      ...inputFieldProps,
      paddingRight: 0,
      // minWidth: '70px', 
    },
    '& .MuiInputAdornment-root': { '& .MuiSvgIcon-root': { fontSize: '1rem' } }
  }
}));

export const CustomDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    ...inputProps,
    '& .MuiInputAdornment-root': { '& .MuiSvgIcon-root': { fontSize: '1rem' } }
  }
}));

export const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.common.white,
    ...inputProps
  }
}));

export const CustomSelect = styled(Select)(() => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    ...inputProps
  },
  // '& .MuiOutlinedInput-root': { lineHeight: 'normal', },
  ...inputProps,
  '& .MuiInputBase-input': {
    ...inputFieldProps,
    backgroundColor: theme.palette.common.white,
    lineHeight: 'normal',
    [`&.${styles.colorPlaceholder}`]: { color: grey[500], }
  },
}));

export const CustomSelectRounded = styled(Select)(({ theme }) => ({
  '& .MuiInputBase-root': {
    ...inputProps,
    // borderRadius: '20px',
  },
  '& .MuiInputBase-input': {
    ...inputFieldProps,
    backgroundColor: theme.palette.common.white,
    borderRadius: '20px'
  },
  borderRadius: '20px',
}));

export const CustomCheckbox = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': { fontSize: '0.8rem' },
  '& .MuiCheckbox-root': { '& .MuiSvgIcon-root ': { fontSize: '1rem' }, },
}));
export const CustomLabelCheckbox = styled(FormControlLabel)(() => ({
  marginTop: '-10px',
  marginBottom: '-10px',
  marginRight: 0,
  '& .MuiFormControlLabel-label': { fontSize: '0.8rem' },
  '& .MuiCheckbox-root': { '& .MuiSvgIcon-root ': { fontSize: '1rem' }, },
}));

export const CustomRadio = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': { fontSize: '0.8rem' },
  '& .MuiRadio-root': { '& .MuiSvgIcon-root ': { fontSize: '1rem' }, }
}));


export const SectionDivider = styled(Divider)(() => ({
  borderColor: 'lightgrey',
  borderBottomWidth: '16px',
  marginLeft: '-24px',
  marginRight: '-24px',
}));

export const GreySectionDivider = styled(Divider)(() => ({
  borderColor: 'var(--greybkg)',
  borderBottomWidth: '16px',
  marginLeft: '-24px',
  marginRight: '-24px',
}));

// CUSTOM DIALOG
export const DialogHR = styled('hr')(() => ({ marginBottom: 0 }));
export const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  // fontSize: '11px',
  fontWeight: '600',
  // '& .MuiDialogContent-root': { paddingTop: '24px !important'  },
}));

export const CustomDialogContent = styled(DialogContent)(() => ({
  padding: '24px',
  paddingTop: '24px !important' 
}));
export const CustomDialogAction = styled(DialogActions)(() => ({
  padding: '24px',
  paddingTop: 0
}));

// CUSTOM PROGRESS BAR
export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: { backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800], },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.main,
  },
}));

export const CustomStyle = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    // borderColor: state.isFocused ? 'black' : 'none',
    ...state.isDisabled ? inputDisabledProps : '',
    fontSize: '13px',
    minHeight: '29px !important',
    height: '29px !important',
    boxShadow: 'none',
    border: 'none',
    borderRadius: 'var(--ip-br)', // NOT WORKING
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 'var(--ip-br)',
    height: '29px !important',
    backgroundColor: 'white',
  }),
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    height: '29px !important',
  }),
  valueContainer: () => ({ 
    padding: '6px 8px',
    height: '29px !important',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  // singleValue: (baseStyles) => ({
  //   ...baseStyles,
  //   maxWidth: 'calc(100% - 75px)',
  // }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isDisabled
      ? 'transparent'
      : state.isSelected
        ? '#092b4a14'
        : state.isFocused ? '#092b4a14'
          : 'fff',
    color: state.isDisabled
      ? 'lightgrey'
      : state.isSelected
        ? 'black'
        : state.isFocused ? 'black'
          : 'black',
    fontSize: '13px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'transform 0s',
    // transform: state.isFocused ? 'rotate(180deg)' : null,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
};

// export const DropdownIndicator = (props) => {
//   return (
//     <components.DropdownIndicator {...props}>
//       <ArrowDropDownIcon />
//     </components.DropdownIndicator>
//   );
// };

