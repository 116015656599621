import React from 'react';
import http from 'utils/http';
import { connect } from 'react-redux';
import AwbInfo from './AwbInfo';
import moment from 'moment';
import flightHttp from 'utils/flightHttp';
import cookies from 'utils/cookies';
import events from 'utils/evemts';
import trypodHttp from 'utils/trypodhttp';
import { Check, Close } from '@mui/icons-material';

class AwbInfoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      count: 0,
      modal: false,
      dataError: {},
      editRecord: {},
      airlines: [],
      scheduleList: [],
      flightSettings: {
        mandatory_number: false,
        limit_input: false
      },
      flightDates: [],
      minDate: null,
      maxDate: null,
      ccsfModal: false,
      anchorEl: null,
      carrierList: [],
      interline_transfer_program: {
        carrier: '',
        transfer_programs: ['', '', '', '']
      }
    };
    this.station_code = cookies.get('station_code') || null;
  }

  componentDidMount() {
    this.fetchAirlines();
    this.getConfigValues();
    this.getCarrierList();
    this.eventSource = events('docs', 'stream');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      let data = { ...this.state.data };
      if (msg.action == 'add_image' && msg.data.awbId == data.id) {
        data.docs.push(msg.data);
        this.setState({ data }, () => this.checkDoc());
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.screen !== this.props.screen) {
      const interline_transfer_program = { ...this.state.interline_transfer_program };
      interline_transfer_program.transfer_programs = this.props.screen.interline_transfer_program ? this.props.screen.interline_transfer_program.transfer_programs : [];
      interline_transfer_program.carrier = this.props.screen.interline_transfer_program ? this.props.screen.interline_transfer_program.carrier : {};
      this.setState({
        modal: false,
        editRecord: {},
        interline_transfer_program,
        data: {
          ...this.props.screen || {},
          flight: this.props.screen?.airline?.code + this.props.screen.flight_no + '/' + moment(this.props.screen.flight_date || null).format('DDMMMYY').toUpperCase()
        }
      }, () => this.checkDoc());
    }
  }

  getConfigValues = async () => {
    http.get('/settings?key=flight_schedule')
      .then((response) => {
        // handle success
        if (response.data.data) {
          this.setState({ flightSettings: response.data.data.value }, () => {
            if (response.data.data.value.limit_input) {
              this.fetchSchedules();
            }
          });
        }
      });
  };

  fetchSchedules = () => {
    flightHttp.get('/flight_schedule?org=' + this.station_code)
      .then((response) => {
        response.data.data ? this.setState({ scheduleList: response.data.data }, () => this.handleFligtDate()) : {};
      });
  };

  fetchAirlines = () => {
    http.get('/airline').then(({ data }) => {
      this.setState({ airlines: data.data });
    });
  };

  checkDoc = () => {
    let count = 0;
    this.state.data && this.state.data.docs && this.state.data.docs.map((d) => {
      if (d.sub_type == 'SCREEN') {
        count++;
      }
    });
    this.setState({ count });
  };

  handleModal = (modal) => {
    this.setState({ modal });
  };

  closeModal = (type) => {
    if (type == 'ccsf') {
      this.setState({ ccsfModal: false });
    } else {
      this.setState({ modal: false });
    }
  };

  handleEditRecord = (record = null) => {
    this.setState({
      editRecord: {
        ...this.state.editRecord,
        [record]: true,
      }
    });
  };

  handleOpen = (anchorEl) => {
    this.setState({
      ccsfModal: true,
      anchorEl
    });
  };

  handleFligtDate = () => {
    let airline = this.state.airlines.find(a => a.id == this.state.data.airlineId);
    let flight_no = this.state.data.flight_no;
    let schdl = this.state.scheduleList.find(s => s.flight_no == flight_no && airline && s.airline == airline.code);
    if (airline && airline.code) {
      this.setState({
        data: {
          ...this.state.data,
          airline: { code: airline.code },
          flight: this.state.data?.airline?.code + this.state.data.flight_no + '/' + moment(this.state.data.flight_date || null).format('DDMMMYY').toUpperCase(),
        }
      });
    }
    if (schdl) {
      let flightDates = [];
      for (
        var m = moment(schdl.from);
        m.diff(schdl.to, 'days') <= 0;
        m.add(1, 'days')
      ) {
        if (schdl[m.format('ddd').toLowerCase()])
          flightDates.push(m.format('YYYY-MM-DD'));
      }
      this.setState({
        flightDates: flightDates,
        minDate: schdl.from,
        maxDate: schdl.to,
      });
    } else {
      this.setState({
        flightDates: [],
        minDate: null,
        maxDate: null,
      });
    }
  };

  handleSecurity = async (event, name) => {
    const { value } = event.target || event;
    let { data } = this.state;

    let security = data.security ? { ...data.security } : {};

    // if(name ==)
    switch (name) {
      default:
        security[name] = value;
        break;
    }

    this.setState({
      data: {
        ...data,
        security: { ...security }
      }
    });
  };

  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
    if (name === 'org' || name === 'dest') {
      value = value.toUpperCase();
    }
    let empty = {};
    if (name === 'airlineId') {
      empty = {
        flight_no: '',
        flight_date: null,
      };
    }
    if (name === 'flight_no') {
      empty = { flight_date: null, };
    }
    this.setState({
      data: {
        ...this.state.data,
        ...empty,
        [name]: value
      }
    }, () => {
      if (name === 'flight_no' || name === 'airlineId' || name === 'flight_date') {
        this.handleFligtDate();
      }
    });
  };

  handleUpload = (e) => {
    if (e.target.files[0] && e.target.files[0].type && e.target.files[0].type.includes('image')) {
      http.post('/document/awb', {
        id: this.state.data.id,
        awb_no: this.state.data.code + '-' + this.state.data.number,
        file: e.target.files[0]
      }, { headers: { 'Content-Type': 'multipart/form-data' } });
    }
  };

  getCarrierList = () => {

    trypodHttp.get('/carrier')
      .then((response) => {
        response.data.result ? this.setState({ carrierList: response.data.result }) : {};
      });
  };

  checkTransporationNumber = async () => {
    let awb = { ...this.state.data };
    let security = this.state.data.security;
    if (security) {
      const data = await this.validateSecurity(security);
      security = {
        ...security,
        ...data.result
      };
      awb.security = security;
      this.setState({ data: awb });
    }
  };

  validateSecurity = async (formData) => {
    try {
      const response = await trypodHttp.post('security_checks', JSON.stringify(formData));
      return response.data;
    } catch (error) {
      console.error('Error in validateSecurity:', error);
      throw error; // Rethrow the error to handle it in the caller function
    }
  };

  handleSetCarrier = async (event) => {

    const interline_transfer_program = { ...this.state.interline_transfer_program };
    interline_transfer_program.carrier = event;
    const response = await trypodHttp.post('transfer_program', JSON.stringify(interline_transfer_program));
    if (response.data.result) {
      interline_transfer_program.transfer_programs = response.data.result.transfer_programs;
    }

    this.setState({
      interline_transfer_program,
      data: {
        ...this.state.data,
        ['interline_transfer_program']: interline_transfer_program
      }
    });

  };

  checkElement = (check) => {

    if (check) {
      return (
        <Check style={{ color: 'green' }} />
      );
    }

    return (
      <Close style={{ color: 'red' }} />
    );
  };

  validateUpdate = () => {
    let error = false;
    let dataError = {};
    const mandatory_fields = cookies.get('mandatory_fields') || [];
    if (mandatory_fields.includes('pcs') && (!this.state.data.pcs || this.state.data.pcs <= 0)) {
      error = true;
      dataError.pcs = 'Pcs is required';
    }
    if (mandatory_fields.includes('wgt') && (!this.state.data.wgt || this.state.data.wgt <= 0)) {
      error = true;
      dataError.wgt = 'Wgt is required';
    }
    if (mandatory_fields.includes('commodity') && (!this.state.data.commodity)) {
      error = true;
      dataError.commodity = 'Commodity is required';
    }
    if (mandatory_fields.includes('flight') && (!this.state.data.flight_date || !this.state.data.flight_no || !this.state.data.airlineId)) {
      error = true;
      dataError.flight = 'Flight is required';
    }
    this.setState({ dataError });
    return error;
  };

  validatePreScreened = () => {
    const { data } = this.state;
    if (data.pre_screened === 'notscreened') {
      data.pre_screened = false;
      return true;
    }

    if (data.pre_screened === 'ccsf') {
      return data.security?.valid_transportation || false;
    }

    if (data.pre_screened === 'interline') {
      return !!data.interline_transfer_program; // Use !! to convert to boolean
    }

    return false;
  };

  updateScreened = async () => {
    if (this.validatePreScreened()) {
      try {
        await http.put('/awb/' + this.state.data.id, this.state.data);
        this.setState({ ccsfModal: false });
      } catch (error) {
        // Handle the error appropriately (e.g., logging, displaying a message)
        console.error('Error updating screened:', error);
      }
    }
  };

  handleUpdate = (name) => {
    if (!this.validateUpdate())
      http.put('/awb/' + this.state.data.id, this.state.data).then(() => {
        this.closeEditRecord(name);
      });
  };

  closeEditRecord = (record) => {
    this.setState({
      editRecord: {
        ...this.state.editRecord,
        [record]: false,
      }
    });
  };

  render() {
    return (
      <>
        <AwbInfo
          airlines={this.state.airlines || []}
          count={this.state.count}
          data={this.state.data}
          handleModal={this.handleModal}
          closeModal={this.closeModal}
          modal={this.state.modal}
          handleEditRecord={this.handleEditRecord}
          closeEditRecord={this.closeEditRecord}
          handleUpdate={this.handleUpdate}
          handleUpload={this.handleUpload}
          onChange={this.onChange}
          editRecord={this.state.editRecord}
          dataError={this.state.dataError}
          flightSettings={this.state.flightSettings}
          flightDates={this.state.flightDates}
          scheduleList={this.state.scheduleList}
          ccsfModal={this.state.ccsfModal}
          handleOpen={this.handleOpen}
          anchorEl={this.state.anchorEl}
          carrierList={this.state.carrierList}
          carrierNumber={this.state.interline_transfer_program}
          handleSecurity={this.handleSecurity}
          checkElement={this.checkElement}
          checkTransporationNumber={this.checkTransporationNumber}
          handleSetCarrier={this.handleSetCarrier}
          updateScreened={this.updateScreened}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { screen: state.screen };
};

export default connect(mapStateToProps)(AwbInfoContainer);
