import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import theme from '../custom/theme';
import HeaderContainer from '../components/header/HeaderContainer';
import styles from '../custom/style.module.css';
import cookies from 'utils/cookies';
import PropTypes from 'prop-types';

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, isLoggedIn, requiredRoles, ...rest } = this.props;
    const userRole = cookies.get('role');
    const password = cookies.get('password');
    const hasRequiredRole = requiredRoles ? requiredRoles.includes(userRole) : false;

    return (
      <div>
        <HeaderContainer theme={theme} />
        <div className={styles.contentContainer}></div>
        <Route
          {...rest}
          render={props =>
            isLoggedIn && hasRequiredRole && !password? (
              <Component {...props} />
            ) : 
              isLoggedIn && hasRequiredRole && password? (
                <Redirect to='/change_first_time_password' />
              ) : (
                <Redirect to='/login' />
              )
          }
        />
      </div>
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  requiredRoles: PropTypes.arrayOf(PropTypes.string), // Add PropTypes validation for requiredRoles
};

export default PrivateRoute;