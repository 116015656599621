import React from 'react';
import {
  Stack,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledDataGrid, StyledCard, StyledCardTitle, TableBadge, DataGridProps } from '../../../custom/components';
import { EditIcon, DocIcon, ViewIcon, AddIcon, ViewGreenIcon } from '../../../custom/constants';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import styles from '../../../custom/style.module.css';
import k9styles from './k9transfer.module.css';

// import theme from 'custom/theme';
const CustomStyledDataGrid = styled(StyledDataGrid)(() => ({ '& .flightRow': { [`&.Mui-selected .${k9styles.icon}, &:hover .${k9styles.icon}, &.Mui-hovered .${k9styles.icon}`]: { '& img': { filter: 'none', }, }, }, }));

const K9Transfer = (props) => {
  const columns = [
    {
      field: 'number',
      headerName: 'Transfer ID',
      type: 'text',
      minWidth: 130,
      flex: 1
    },
    {
      field: 'k9_company.name',
      headerName: 'K9 Company',
      type: 'text',
      minWidth: 140,
      flex: 1,
      valueGetter: (params) => params.row.k9_company.name
    },
    {
      field: 'issued_by',
      headerName: 'Issued By',
      type: 'text',
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        const issuedBy = params.row.issued_by;
        if (issuedBy) {
          const firstName = issuedBy.first_name || '';
          const lastName = issuedBy.last_name || '';
          return `${firstName} ${lastName}`;
        }
        return '';
      }
    },
    {
      field: 'issued',
      headerName: 'Date/Time',
      type: 'text',
      minWidth: 160,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
      }
    },
    {
      field: 'pieces',
      headerName: '# Pieces Txfrd',
      type: 'text',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        const awbs = params.row.transfer_awbs;
        const totalPcs = awbs.reduce((sum, awb) => sum + awb.pcs, 0);
        return totalPcs;
      }
    },
    {
      field: 'status',
      headerName: '',
      type: 'text',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const awbs = params.row.transfer_awbs;
        const transferredPieces = awbs.reduce((sum, awb) => sum + awb.pcs, 0);
        const screenedPieces = awbs.reduce((sum, awb) => sum + awb.screen_pcs, 0);

        if (transferredPieces > screenedPieces) {
          return (
            <div className={`${k9styles.status} ${styles.colorRed}`}> &gt; </div>
          );
        } else if (transferredPieces === screenedPieces) {
          return (
            <div className={`${k9styles.status} ${styles.colorSecondary}`}> = </div>
          );
        } else {
          return null;
        }
      }
    },
    {
      field: 'pcs_screened',
      headerName: '# Pieces Screened',
      type: 'text',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        const awbs = params.row.transfer_awbs;
        const totalPcs = awbs.reduce((sum, awb) => sum + awb.screen_pcs, 0);
        return totalPcs;
      }
    },
    {
      field: 'handler_name',
      headerName: 'Handler Name',
      type: 'text',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'name',
      headerName: 'K9 Name',
      type: 'text',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'attachment',
      headerName: 'Attachment',
      type: 'text',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <div className='d-flex justify-content-between align-items-center' style={{ cursor: 'pointer' }}>
            <TableBadge color='error' badgeContent={params.row.docs && params.row.docs.length} >
              <AttachFileIcon fontSize='small' onClick={() => props.handleDoc(params.row)} />
            </TableBadge>
          </div>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      // minWidth: 100, 
      flex: 1,
      headerAlign: 'center',
      minWidth: 140,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => props.handleOpen('completionWorksheet', params.row)} >
              {params.row.verified_by ? <span className={k9styles.icon}><ViewGreenIcon /></span> : <ViewIcon />}
            </div>
            <div onClick={() => props.printPdf(params.row.id)}>
              <DocIcon />
            </div>
            <EditIcon onClick={() => props.handleOpen('transferDocEdit', params.row)} />
          </Stack>
        );
      }
    },
  ];


  return (
    <div>
      <StyledCard>
        <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
          <StyledCardTitle gutterBottom>List of Transfers</StyledCardTitle>
          <AddIcon onClick={() => props.handleOpen('transferDoc')} />
        </div>
        <div className={styles.tableWrapper}>
          <Box className={`${styles.tableOuter} ${k9styles.transferTableOuter}`}>
            <CustomStyledDataGrid
              {...DataGridProps}
              rows={props.k9TransferList}
              columns={columns}
              getRowClassName={() => 'flightRow'}
            />
          </Box>
        </div>
      </StyledCard>
    </div>
  );
};

export default K9Transfer;