import React from 'react';
import { Stack, Grid, InputAdornment, IconButton, MenuItem, Box } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, CustomSelect, CustomSearch, CustomButton, StyledDataGrid, DataGridProps, SelectInputProps, InputProps } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import awbStyles from './airwaybill.module.css';
import { AttachFile as AttachFileIcon, Search } from '@mui/icons-material';
import { DateFormat, ViewIcon } from 'custom/constants';
import dayjs from 'dayjs';
import cookies from 'utils/cookies';

const Airwaybill = (props) => {
  const userRole = cookies.get('role');
  const today = dayjs();
  const thirtyOneDaysAgo = today.subtract(31, 'days');

  const isDateBeforeThirtyOneDaysAgo = (date) => {
    return dayjs(date).isBefore(thirtyOneDaysAgo);
  };
  const columns = [
    {
      field: 'awb',
      headerName: 'Airwaybill',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row.code + '-' + row.number;
      }
    },
    {
      field: 'docs',
      headerName: 'Docs Stream Time',
      type: 'text',
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {

        return row.docs && row.docs.length ? `${dayjs(row.docs[0].created, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YY HH:mm') }` : '';
      }
    },
    {
      field: 'pcs',
      headerName: 'Pcs',
      type: 'text',
      minWidth: 80,
      flex: 1
    },
    {
      field: 'wgt',
      headerName: 'Wgt',
      type: 'text',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'org',
      headerName: 'Org',
      type: 'text',
      minWidth: 60,
      flex: 1
    },
    {
      field: 'dest',
      headerName: 'Dest',
      type: 'text',
      minWidth: 60,
      flex: 1
    },
    {
      field: 'commodity',
      headerName: 'Commodity',
      type: 'text',
      minWidth: 200,
      className: styles.minWidth100
    },
    {
      field: 'forwarder',
      headerName: 'Forwarder',
      type: 'text',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'shipper_profile',
      headerName: 'Shipper Profile',
      type: 'text',
      minWidth: 110,
      flex: 1,
    },
    {
      field: 'pcs_screened',
      headerName: 'Pcs Screened?',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      width: 90,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => props.handlePopover('screeningdetails', true, params.row.id)}>
              <ViewIcon />
            </div>
            <div onClick={() => props.handlePopover('attached_docs', true, params.row.id)}>
              <AttachFileIcon fontSize='small' className={`${styles.pointer} ${awbStyles.attIcon}`} />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledCard>
          <StyledCardTitle gutterBottom variant='' component='div'>Filter Airwaybill List</StyledCardTitle>
          <Stack direction='row'>
            <Grid container spacing={2} columnSpacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel>From</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      fullWidth: true,
                      placeholder: 'From Date'
                    },
                  }}
                  className={styles.w100}
                  format={DateFormat}
                  name='from'
                  value={dayjs(props.searchOptions.from)}
                  onChange={(e) => props.handleChange(e, 'from')}
                  shouldDisableDate={userRole === 'TSA Inspector' ? isDateBeforeThirtyOneDaysAgo : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel>To</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      fullWidth: true,
                      placeholder: 'To Date'
                    },
                  }}
                  className={styles.w100}
                  format={DateFormat}
                  name='to'
                  value={dayjs(props.searchOptions.to)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'to')}
                />
              </Grid>
              <Grid item xs>
                <CustomInputLabel id='airline-label'>Airline</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='airline-label'
                  fullWidth
                  id='airline'
                  name='airline'
                  value={props.filter.airline}
                  onChange={props.onChangefilter}
                >
                  {props.airline && props.airline.map(a => {
                    return (<MenuItem key={a} value={a} >{a}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel id='flight-number-label'>Flight Number</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='flight-number-label'
                  fullWidth
                  id='flight'
                  name='flight'
                  value={props.filter.flight}
                  onChange={props.onChangefilter}
                >
                  {props.flight && props.flight.map(f => {
                    return (<MenuItem key={f} value={f}>{f}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel id='measure-label'>Measure</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='meaasure-label'
                  fullWidth
                  id='measure'
                  name='measure'
                  value={props.filter.measure}
                  onChange={props.onChangefilter}
                >
                  {props.measure && props.measure.map(m => {
                    return (<MenuItem key={m} value={m} >{m}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel id='method-label'>Method</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='method-label'
                  fullWidth
                  id='method'
                  name='method'
                  value={props.filter.method}
                  onChange={props.onChangefilter}
                >
                  {props.method && props.method.map(m => {
                    return (<MenuItem key={m} value={m} >{m}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <CustomInputLabel className={styles.colorTransparent}>-</CustomInputLabel>
              <Grid item display='flex' className={styles.sqIconsContainer} >
                <IconButton className={styles.p0} disableRipple onClick={() => props.search()} >
                  <Search fontSize='1.7rem' classes={{ root: styles.sqIcons }} />
                </IconButton>
              </Grid>
              <Grid item display='flex'>
                <CustomButton variant='contained' className={styles.mt3} onClick={props.clear} >
                  Clear
                </CustomButton>
              </Grid>
            </Grid>
          </Stack>
        </StyledCard>
      </Grid>

      <Grid item xs={12}>
        <StyledCard className={styles.positionRelative}>
          <div className={`${styles.fSpaceBtw} ${styles.mb3}`}>
            <StyledCardTitle gutterBottom variant='' component='div'>Airwaybills</StyledCardTitle>
            <CustomSearch
              {...InputProps}
              id='search'
              name='awb'
              value={props.searchOptions.awb}
              placeholder='Enter Awb Number'
              onChange={(e) => props.handleChange(e, 'awb')}
              type='search'
              className={styles.brInput}
              InputProps={{
                disableUnderline: true,
                endAdornment: <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    aria-label='search'
                    edge='end'
                    onClick={() => props.search(true)}
                  >
                    <Search fontSize='inherit' />
                  </IconButton>
                </InputAdornment>,
              }}
              InputLabelProps={{ shrink: false }}
            />
          </div>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter} ${awbStyles.awbTableOuter}`}>
              <StyledDataGrid
                {...DataGridProps}
                rows={props.awbs}
                columns={columns}
              />
            </Box>
          </div>
        </StyledCard>
      </Grid>

    </Grid>
  );
};

export default Airwaybill;
