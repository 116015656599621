import React, { Component } from 'react';
import { Dialog, IconButton, Grid, Typography, InputAdornment, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import styles from '../../../../custom/style.module.css';
import docStyles from './doc.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, CustomDatePicker, InputProps, CustomDialogContent } from '../../../../custom/components';
import CloseIcon from '@mui/icons-material/Close';
import { BrowseIcon, CloseIconBtn, DateFormat } from 'custom/constants';

class AddDocModal extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      selectedFile: null,
      fileError:'',
    };
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({ 
      selectedFile: file, 
      fileError:'',
    });
    this.props.onChangeUser(event);
    this.props.onChangeK9(event);
  };

  clearFile = () => {
    const fileK9 = document.getElementById('k9-file');
    const fileUser = document.getElementById('user-file');
    if (fileK9) {
      fileK9.value = '';
    }
    if (fileUser) {
      fileUser.value = '';
    }
    this.setState({ selectedFile: null });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { action, modal, addUser, editUser, addK9, editK9 } = this.props;
    const { selectedFile } = this.state;
    if (selectedFile) {
      if (action === 'add') {
        if (modal === 'user') {
          addUser(event, (success) => {
            if (success) {
              this.handleClose(event);
            }  
          });
        } else {
          addK9(event, (success) => {
            if (success) {
              this.handleClose(event);
            }
          });
        }
      } 
    } else {
      this.setState({ fileError: ' Please select a file.' });
    } 
    if (action === 'edit'){
      if (modal === 'user') {
        editUser(event);
      } else {
        editK9(event);
      }
    } 
  };

  handleClose = (event) => {
    this.clearFile();
    this.setState({ 
      selectedFile: null, 
      fileError:'' 
    });
    this.props.onClose(event);
  };

  render() {
    const { action, modal, userFields, userErrors, addUser, editUser, onChangeUser, k9Fields, k9Errors, addK9, editK9, onChangeK9 } = this.props;
    const { selectedFile, fileError } = this.state;
    
    return (
      <div>
        <Dialog 
          aria-labelledby='form-dialog-title'
          open={modal} 
          onClose={this.handleClose}
          maxWidth={'sm'}
          fullWidth={true} 
        >
          <CustomDialogTitle id='form-dialog-title'>
            {action == 'add' ? 'Add New' : 'Edit'}  {modal == 'user' ? 'Training' : 'Attachment'}
            <CloseIconBtn onClick={this.handleClose} />
          </CustomDialogTitle>
          {modal == 'user' && (
            <form className={styles.w100} onSubmit={action == 'add' ? addUser : editUser}>
              <CustomDialogContent>
                <StyledCard>
                  <Grid container spacing={2}>
                    <Grid item xs={12} >
                      <CustomInputLabel htmlFor='training'>Training</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        fullWidth
                        id='training'
                        placeholder='Training'
                        type='text'
                        name='name'
                        className={`${styles.w100}`}
                        onChange={onChangeUser}
                        value={userFields.name}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position='end'>
                              {selectedFile && (<Tooltip title={userFields?.file?.name}>
                                <IconButton size='small' variant='contained' color='error' onClick={this.clearFile}>
                                  <CloseIcon fontSize='inherit' />
                                </IconButton>
                                <span className={docStyles.fileName}>{userFields?.file?.name}</span>
                              </Tooltip>)}
                              {action == 'add' && (
                                <IconButton size='small'>
                                  <input
                                    accept='application/pdf,image/*'
                                    style={{ display: 'none' }}
                                    id='user-file'
                                    type='file'
                                    name='file'
                                    onChange={this.handleFileChange}
                                  />
                                  <label htmlFor='user-file' className={`${styles.fs0} ${styles.pointer}`}>
                                    <BrowseIcon />
                                  </label>
                                 
                                </IconButton> 
                              )}
                            </InputAdornment>
                          )
                        }}
                        error={userErrors.name}
                        helperText={userErrors.name}
                      />
                      {action == 'add' && (
                        fileError && (
                          <Typography variant='caption' color='error'>
                            {fileError}
                          </Typography>
                        ))}
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputLabel htmlFor='code'>Training Date</CustomInputLabel>
                      <CustomDatePicker 
                        slotProps={{
                          textField: {
                            ...InputProps,
                            fullWidth: true,
                            placeholder: 'Training Date'
                          }, 
                        }}
                        onChange={(v) => onChangeUser({
                          target: {
                            type: 'date',
                            name: 'training_date',
                            value: v
                          }
                        })}
                        format={DateFormat}
                        name='training_date'
                        className={`${styles.w100}`}
                        value={dayjs(userFields.training_date)}
                        error={userErrors.training_date}
                        helperText={userErrors.training_date}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputLabel htmlFor='code'>Expiry Date</CustomInputLabel>
                      <CustomDatePicker
                        slotProps={{
                          textField: {
                            ...InputProps,
                            fullWidth: true,
                            placeholder: 'Expiry Date'
                          }, 
                        }}
                        onChange={(v) => onChangeUser({
                          target: {
                            type: 'date',
                            name: 'expiry_date',
                            value: v
                          }
                        })}
                        format={DateFormat}
                        name='expiry_date'
                        className={`${styles.w100}`}
                        value={dayjs(userFields.expiry_date)}
                        error={userErrors.expiry_date}
                        helperText={userErrors.expiry_date}
                      />
                    </Grid>
                    {/* {action == 'add' && (<Grid item>
                      <input
                        accept='application/pdf,image/*'
                        style={{ display: 'none' }}
                        id='user-file'
                        type='file'
                        name='file'
                        onChange={this.handleFileChange}
                      />
                      <label htmlFor='user-file'>
                        <Button variant='contained' component='span'>
                          Choose file
                        </Button>
                      </label>
                      {selectedFile && (
                        <div className={`${styles.fileContainer}`}>
                          <p className={`${styles.fileName}`}>{selectedFile.name}</p>
                          <IconButton variant='contained' color='secondary' onClick={this.clearFile}>
                            <CloseIcon fontSize='small' />
                          </IconButton>
                        </div>
                      )}
                      {fileError && (
                        <Typography variant='caption' color='error'>
                          {fileError}
                        </Typography>
                      )}
                    </Grid>)} */}
                  </Grid>
                </StyledCard>
              </CustomDialogContent>
              <CustomDialogAction className={`${styles.fSpaceBtw}`}>
                <CustomButton variant='contained' type='reset' onClick={this.handleClose}> Close </CustomButton>
                <CustomButton variant='contained' type='submit' onClick={this.handleSubmit}> Submit </CustomButton>
              </CustomDialogAction>
            </form>
          )}
          {modal == 'k9' && (
            <form className={styles.w100} onSubmit={action == 'add' ? addK9 : editK9}>
              <CustomDialogContent>
                <StyledCard>
                  <Grid container spacing={2}>
                    <Grid item xs={12} >
                      <CustomInputLabel htmlFor='name'>Name</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        fullWidth
                        id='name'
                        placeholder='Name'
                        type='text'
                        name='name'
                        onChange={onChangeK9}
                        value={k9Fields.name}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position='end'>
                              {selectedFile && (<Tooltip title={k9Fields?.file?.name}>
                                <IconButton size='small' variant='contained' color='text.secondary' onClick={this.clearFile}>
                                  <CloseIcon fontSize='inherit' />
                                </IconButton>
                                <span className={docStyles.fileName}>{k9Fields?.file?.name}</span>
                              </Tooltip>)}
                              {action == 'add' && (
                                <IconButton size='small'>
                                  <input
                                    accept='application/pdf,image/*'
                                    style={{ display: 'none' }}
                                    id='k9-file'
                                    type='file'
                                    name='file'
                                    onChange={this.handleFileChange}
                                  />
                                  <label htmlFor='k9-file' className={`${styles.fs0} ${styles.pointer}`}>
                                    <BrowseIcon />
                                  </label>
                                 
                                </IconButton> 
                              )}
                            </InputAdornment>
                          )
                        }}
                        error={k9Errors.name}
                        helperText={k9Errors.name}
                      />
                      {action == 'add' && (
                        fileError && (
                          <Typography variant='caption' color='error'>
                            {fileError}
                          </Typography>
                        )
                      )}
                      {/* {k9Fields?.file?.size && k9Fields?.file?.size > 4000  ? <small className={styles.colorRed}>File size cannot be more than 4kb</small> : ''} */}
                    </Grid>
                    <Grid item xs={12} >
                      <CustomInputLabel htmlFor='remark'>Remark</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        fullWidth
                        id='remark'
                        placeholder='Remark'
                        type='text'
                        name='remark'
                        onChange={onChangeK9}
                        value={k9Fields.remark}
                        error={k9Errors.remark}
                        helperText={k9Errors.remark}
                      />
                    </Grid>
                    {/* {action == 'add' && (<Grid item>
                      <input
                        accept='application/pdf,image/*'
                        style={{ display: 'none' }}
                        id='k9-file'
                        type='file'
                        name='file'
                        onChange={this.handleFileChange}
                      />
                      <label htmlFor='k9-file'>
                        <Button variant='contained' component='span'>
                          Choose file
                        </Button>
                      </label>
                      {selectedFile && (
                        <div className={`${styles.fileContainer}`}>
                          <p className={`${styles.fileName}`}>{selectedFile.name}</p>
                          <IconButton variant='contained' color='secondary' onClick={this.clearFile}>
                            <CloseIcon fontSize='small' />
                          </IconButton>
                        </div>
                      )}
                      {fileError && (
                        <Typography variant='caption' color='error'>
                          {fileError}
                        </Typography>
                      )}                      
                    </Grid>)} */}
                  </Grid>
                </StyledCard>
              </CustomDialogContent>
              <CustomDialogAction className={`${styles.fSpaceBtw}`}>
                <CustomButton variant='contained' type='reset' onClick={this.handleClose}> Close </CustomButton>
                <CustomButton variant='contained' type='submit' onClick={this.handleSubmit}> Submit </CustomButton>
              </CustomDialogAction>
            </form>
          )}
        </Dialog>
      </div>
    );
  }
}
export default AddDocModal;