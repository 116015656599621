import React from 'react';
import {
  Grid, 
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio
} from '@mui/material';
import { CustomInputLabel, CustomCheckbox, CustomRadio, CustomTimePicker, CustomStyle, InputProps } from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import analyticsStyle from './analytics.module.css';
import { ArrowDropDown } from '@mui/icons-material';
import dayjs from 'dayjs';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Select, { components } from 'react-select';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, ChartDataLabels);

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDown />
    </components.DropdownIndicator>
  );
};

const ReportFilter = (props) => {
  return (
    <div className={`${analyticsStyle.filterWrapperOuter}`}>
      <Grid container spacing={2} className={`${analyticsStyle.customerGridItems} ${analyticsStyle.filterContainer}`}>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${analyticsStyle.filterWrapper}`}>
          <p className={`${styles.my0}`}>Report Filters</p>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${analyticsStyle.filterWrapper}`}>
          <p className={`${analyticsStyle.filterTitle} ${styles.fSpaceBtw} ${styles.gap10}`}>Handled Carriers
            <CustomCheckbox
              control={
                <Checkbox
                  name='carrCheck'
                  checked={props.carrSelect}
                  classes={{
                    root: analyticsStyle.filterCheckbox,
                    checked: analyticsStyle.filterCheckbox
                  }}
                        
                  onChange={(e) => {
                    props.handleChangeCarrier && props.handleChangeCarrier(e.target.checked, null);
                  }}
                  inputProps={{ 'aria-label': 'handled carriers', }}
                  size='small'
                />
              }
              className={`${styles.mr0} ${styles.mbNeg12} ${styles.mtNeg12}`}
              label='ALL'
            />
          </p>
          <FormGroup aria-label='airlines' row className={`${analyticsStyle.customerFormGroup}`}>
            {props.airlineList.length>0 && props.airlineList.map((list) => {
              return <CustomCheckbox key={list.id}
                control={
                  <Checkbox
                    name={list.code}
                    checked={ props.checkedAirlines[list.code] || false}
                    classes={{
                      root: analyticsStyle.filterCheckbox,
                      checked: analyticsStyle.filterCheckbox
                    }}
                    size='small'
                    onChange={(e) => {
                      props.handleChangeCarrier && props.handleChangeCarrier(e.target.checked, list.code);
                    }}
                  />
                }
                label={list.code}
                className={analyticsStyle.carrierCheckbox}
              />;
            }
            )}
          </FormGroup>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={4} xs={12} className={`${analyticsStyle.filterWrapper}`}>
          <p className={`${analyticsStyle.filterTitle}`}>Generate Report Based On</p>
          <RadioGroup
            row={false}
            aria-label='Generate Report Based On'
            onChange={props.handleReport}
            className={`${analyticsStyle.entityRadioGroup}`}
            defaultValue='pcs'
          >
            <CustomRadio
              control={
                <Radio
                  value='pcs'
                  size='small'
                  classes={{
                    root: analyticsStyle.filterCheckbox,
                    checked: analyticsStyle.filterCheckbox
                  }}
                />
              }
              label='Pieces'
            />
            <CustomRadio
              control={
                <Radio
                  value='wgt'
                  size='small'
                  classes={{
                    root: analyticsStyle.filterCheckbox,
                    checked: analyticsStyle.filterCheckbox
                  }}
                />
              }
              label='Weight'
            />
            <CustomRadio
              control={
                <Radio
                  value='awb'
                  size='small'
                  classes={{
                    root: analyticsStyle.filterCheckbox,
                    checked: analyticsStyle.filterCheckbox
                  }}
                />
              }
              label='Air Way Bills'
            />
          </RadioGroup>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={4} xs={12} className={`${analyticsStyle.filterWrapper}`}>
          <p className={`${analyticsStyle.filterTitle}`}>Specific Screener</p>
          <div className={`${analyticsStyle.gridItem} ${styles.inputLabelWrapper}`}>
            <CustomInputLabel className={`${styles.whiteLabel}`}>Enter Screener Name</CustomInputLabel>
            <Select
              className='basic-single'
              classNamePrefix='select'
              onChange={(selectedOption) => {
                props.handleScreener({
                  target: {
                    name: 'screener',
                    value: selectedOption.value
                  }
                });
              }}
              options={props.screenerOptions?.map(u => ({
                value: u?.user?.id,
                label: `${u?.user?.first_name} ${u?.user?.last_name}`
              }))}
              // isClearable
              components={{ DropdownIndicator }}
              styles={CustomStyle}
            />
          </div>
                
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={4} xs={12} className={`${analyticsStyle.filterWrapper}`}>
          <p className={`${analyticsStyle.filterTitle}`}>Time of Day (Shifts)</p>
          <div className={`${styles.dFlex} ${styles.gap10}`}>
            <div className={`${analyticsStyle.gridItem} ${styles.inputLabelWrapper}`}>
              <CustomInputLabel className={`${styles.whiteLabel}`}>From</CustomInputLabel>
              <CustomTimePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    fullWidth: true,
                    placeholder: 'From Time'
                  }, 
                }}
                className={styles.w100}
                ampm={false}
                onChange={(v) => {
                  props.handleTimeChange({
                    target: {
                      type: 'time',
                      name: 'f_time',
                      value: v ? v.format('HH:mm') : null
                    }
                  });
                }}
                format='HH:mm'
                name='f_time'
                value={dayjs(props.timeFilter.f_time || moment().startOf('day').format('HH:mm'), 'HH:mm')}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
              />
            </div>
            <div className={`${analyticsStyle.gridItem} ${styles.inputLabelWrapper}`}>
              <CustomInputLabel className={`${styles.whiteLabel}`}>To</CustomInputLabel>
              <CustomTimePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    fullWidth: true,
                    placeholder: 'To Time'
                  }, 
                }}
                className={styles.w100}
                ampm={false}
                onChange={(v) => {
                  props.handleTimeChange({
                    target: {
                      type: 'time',
                      name: 't_time',
                      value: v ? v.format('HH:mm') : null
                    }
                  });
                }}
                format='HH:mm'
                name='t_time'
                value={dayjs(props.timeFilter.t_time || moment().endOf('day').format('HH:mm'), 'HH:mm')}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportFilter;
