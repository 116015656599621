import React from 'react';
import {
  Grid,
  Link,
} from '@mui/material';
import { CustomButton } from '../../../../../../custom/components';
import { Check } from '@mui/icons-material';
import userStyle from './../users.module.css';
import styles from '../../../../../../custom/style.module.css';
import environments from '../../../../../../custom/environments';

const PrintPassword = (props) => {

  return (
    <div className= {`${styles.mt5} ${userStyle.printOuter}`}>
      <Grid container>
        <Grid item container className={userStyle.gridContainer}>
          <Grid item xs={6} className={userStyle.titleColor}>
                First Name:
          </Grid>
          <Grid item xs={6} className={userStyle.gridData}>
            {props.userdetails.first_name} 
          </Grid>
        </Grid>
        <Grid item container className={userStyle.gridContainer}>
          <Grid item xs={6} className={userStyle.titleColor}>
                Last Name:
          </Grid>
          <Grid item xs={6} className={userStyle.gridData}>
            {props.userdetails.last_name}
          </Grid>
        </Grid>
        <Grid item container className={userStyle.gridContainer}>
          <Grid item xs={6} className={userStyle.titleColor}>
                Email/Username:
          </Grid>
          <Grid item xs={6} className={userStyle.gridData}>
            {props.userdetails.username}
          </Grid>
        </Grid>
        <Grid item container className={userStyle.gridContainer}>
          <Grid item xs={6} className={userStyle.titleColor}>
                Password:
          </Grid>
          <Grid item xs={6} className={userStyle.gridData}>
            {props.userdetails.password}
          </Grid>
        </Grid>
        <Grid item container className={userStyle.gridContainer}>
          <Grid item xs={6} className={userStyle.titleColor}>
                Role:
          </Grid>
          <Grid item xs={6} className={userStyle.gridData}>
            {props.userdetails.role} 
          </Grid>
        </Grid>
      </Grid>
      <br />

      <span>The password assigned to you is a temporary password and you will be prompted to change it after
          your first login at &nbsp;<Link href={environments.appUrl}>{environments.appUrl}</Link>. Please follow the password policy listed below to create a strong password.</span>

      <div className={userStyle.passwordPolicyContainer}>
        <br />
        <div className={userStyle.passwordPolicyHeader}>
              Password Policy:
        </div>
        <div className={userStyle.passwordPolicyConditions}>
          <Check style={{
            color: 'green',
            fontSize: '14px'
          }} />
          <span className={userStyle.greyText}>Must be at least 8 characters</span>
        </div>
        <div className={userStyle.passwordPolicyConditions}>
          <Check style={{
            color: 'green',
            fontSize: '14px'
          }} />
          <span className={userStyle.greyText}>Must contain one upper case letter, one number and a special character</span>
        </div>
      </div>
      <CustomButton variant='contained' className={`${userStyle.hidePrint} ${styles.mt3}`}
        onClick={() => window.print()}
      >Print</CustomButton>
    </div>
  );
};
export default PrintPassword;
