import React from 'react';
import { Stack, Grid, MenuItem, Box } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, CustomSelect, StyledDataGrid, DataGridProps, SelectInputProps, InputProps } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import fltStyles from './flightmanifest.module.css';
import { AttachFile as AttachFileIcon, Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DateFormat } from 'custom/constants';
import moment from 'moment';

const FlightManifest = (props) => {
  const columns = [
    {
      field: 'flight_no',
      headerName: 'Flight No.',
      type: 'text',
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => `${params.row.airline}${params.row.flight_no}`
    },
    {
      field: 'flight_date',
      headerName: 'Flight Date',
      type: 'text',
      minWidth: 100,
      flex: 1,
      renderCell: (data) => {
        return (
          <div>
            {`${moment(data.row.flight_date).format(DateFormat)}`}
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      width: 90,
      sortable: false,
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => props.handlePopover('attached_docs', true, params.row.id)}>
              <AttachFileIcon fontSize='small' className={`${styles.pointer} ${fltStyles.attIcon}`} />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledCard>
          <StyledCardTitle gutterBottom variant='' component='div'>Filter Flight List</StyledCardTitle>
          <Stack direction='row'>
            <Grid container spacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel>From</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      fullWidth: true,
                      placeholder: 'From Date'
                    }, 
                  }}
                  className={styles.w100}
                  format={DateFormat}
                  name='from'
                  value={dayjs(props.searchOptions.from)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'from')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2} >
                <CustomInputLabel>To</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      fullWidth: true,
                      placeholder: 'To Date'
                    }, 
                  }}
                  className={styles.w100}
                  format={DateFormat}
                  name='to'
                  value={dayjs(props.searchOptions.to)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'to')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel id='airline-label'>Airline</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='airline-label'
                  fullWidth
                  id='airline'
                  name='airline'
                  value={props.filter.airline}
                  onChange={props.onChangefilter}
                >
                  {props.airline && props.airline.map(a => {
                    return (<MenuItem key={a} value={a} >{a}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel id='flight_number-label'>Flight Number</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='flight-number-label'
                  fullWidth
                  id='flight'
                  name='flight'
                  value={props.filter.flight}
                  onChange={props.onChangefilter}
                >
                  {props.flight && props.flight.map(f => {
                    return (<MenuItem key={f} value={f}>{f}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item className={styles.sqIconsContainer}>
                <CustomInputLabel className={styles.colorTransparent}>-</CustomInputLabel>
                <div onClick={() => props.search()} >
                  <Search classes={{ root: styles.sqIcons }} />
                </div>
              </Grid>
            </Grid>
          </Stack>
        </StyledCard>
      </Grid>

      <Grid item xs={12}>
        <StyledCard className={styles.positionRelative}>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter} ${fltStyles.fltTableOuter}`}>
              <StyledDataGrid
                {...DataGridProps}
                rows={props.data}
                columns={columns}
              />
            </Box>
          </div>
        </StyledCard>
      </Grid>

    </Grid>
  );
};

export default FlightManifest;
