import React from 'react';
import { Typography, Popover, Stack } from '@mui/material';
import styles from '../../../../custom/style.module.css';
import { CustomButton } from '../../../../custom/components';

const ConfirmModal = (props) => {
  return (
    <div>
      <Popover
        id={props.id}
        open={props.modal}
        anchorEl={props.anchorEl}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      ><div className={styles.p3}>
          <Typography variant='caption' >Are you sure you want to delete this record?</Typography>
          <Stack className={styles.pt3} spacing={1} direction='row'>
            <CustomButton variant='contained' size='small' onClick={props.onConfirm} className={styles.width80}> <Typography variant='caption' lineHeight='normal' fontWeight='bold'>Yes</Typography></CustomButton>
            <CustomButton variant='outlined' size='small' onClick={props.onClose} className={styles.width80}> <Typography variant='caption' lineHeight='normal'>No</Typography></CustomButton>
          </Stack>
        </div>
      </Popover>
    </div>
  );
};
export default ConfirmModal;