import React from 'react';
// import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ManageUsersContainer from './tabs/manageusers/ManageUserContainer';
import HandledAirlinesContainer from './tabs/handledairlines/HandledAirlinesContainer';
import FlightScheduleContainer from './tabs/flightschedule/FlightScheduleContainer';
import WhiteListContainer from './tabs/whitelist/WhiteListContainer';
import K9CompaniesContainer from './tabs/k9companies/K9CompaniesContainer';
import ManageUsersIcon from './../../../assets/images/adminTabIcons/manageusers.svg';
import HandledAirlinesIcon from './../../../assets/images/adminTabIcons/handledairlines.svg';
import FlightScheduleIcon from './../../../assets/images/adminTabIcons/flightschedule.svg';
import MeasureMethodsIcon from './../../../assets/images/adminTabIcons/measuremethods.svg';
import CompTranferIcon from './../../../assets/images/adminTabIcons/comptransfer.png';
import WhiteListIcon from './../../../assets/images/adminTabIcons/whitelist.svg';
import { adminTabs } from '../../../custom/constants';
import { IconTab } from '../../../custom/components';
import MeasureMethodsContainer from './tabs/measuremethods/MeasureMethodsContainer';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';
import adminStyles from '../admin/tabs/admin.module.css';
import { IconButton } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import theme from 'custom/theme';
import cookies from 'utils/cookies';

const Admin = (props) => {
  const role = cookies.get('role');
  const { value } = props;
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 0 }}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Tabs value={value} onChange={(_, v) => props.handleChange(v)}
            indicatorColor='red'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
            classes={{ flexContainer: adminStyles.fJcAround }}
            className={`${adminStyles.headerTab} ${adminStyles.adminTabs}`}
            ScrollButtonComponent={(props) => {
              if (
                props.direction === 'left'
              ) {
                return (
                  <IconButton {...props} className={adminStyles.tabArrows} disableRipple>
                    <ArrowLeftIcon
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else if (
                props.direction === 'right'
              ) {
                return (
                  <IconButton {...props} className={adminStyles.tabArrows} disableRipple>
                    <ArrowRightIcon
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else {
                return null;
              }
            }}>
            {['Supervisor', 'Manager', 'System Admin'].includes(role) && (<IconTab disableRipple={true} icon={<img src={ManageUsersIcon} alt='manage users' />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.manage_users} {...a11yProps('manage_users')} />)}
            {['Manager', 'System Admin'].includes(role) && (<IconTab disableRipple={true} icon={<img src={HandledAirlinesIcon} alt='handled airlines' />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.handled_airlines} {...a11yProps('handled_airlines')} />)}
            {['Manager', 'System Admin'].includes(role) && (<IconTab disableRipple={true} icon={<img src={FlightScheduleIcon} alt='flight schedule' />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.flight_schedule} {...a11yProps('flight_schedule')} />)}
            {['System Admin'].includes(role) && (<IconTab disableRipple={true} icon={<img src={MeasureMethodsIcon} alt='measure & methods' />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.measure_methods} {...a11yProps('measure_methods')} />)}
            {['System Admin'].includes(role) && (<IconTab disableRipple={true} icon={<img src={CompTranferIcon} alt='company & transfer' />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.comp_transfers} {...a11yProps('comp_transfers')} />)}
            {['Supervisor', 'Manager', 'System Admin'].includes(role) && (<IconTab disableRipple={true} icon={<img src={WhiteListIcon} alt='white list' />} classes={{ iconWrapper : `${adminStyles.menuIcon} ${adminStyles.whiteListcon}` }}  label={adminTabs.white_list} {...a11yProps('white_list')} />)}
          </Tabs>
        </Box>
        <TabPanel value={value} index={'manage_users'}>
          <ManageUsersContainer />
        </TabPanel>
        <TabPanel value={value} index={'handled_airlines'}>
          <HandledAirlinesContainer />
        </TabPanel>
        <TabPanel value={value} index={'flight_schedule'}>
          <FlightScheduleContainer />
        </TabPanel>
        <TabPanel value={value} index={'measure_methods'}>
          <MeasureMethodsContainer />
        </TabPanel>
        <TabPanel value={value} index={'comp_transfers'}>
          <K9CompaniesContainer />
        </TabPanel>
        <TabPanel value={value} index={'white_list'}>
          <WhiteListContainer />
        </TabPanel>
      </Box>
      <ConfirmModalContainer />
    </>
  );
};

export default Admin;
