import React from 'react';
import {
  IconButton,
  Tooltip as MuiTooltip
} from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { StyledCard, } from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import analyticsStyle from './analytics.module.css';
import {  StarOutline, ArrowRight, ArrowLeft } from '@mui/icons-material';
import {  ExcelIcon } from 'custom/constants';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, ChartDataLabels);

const NextArrow = (props) => {
  const { newClass, onClick } = props;
  return (
    <IconButton
      aria-label='Next'
      onClick={onClick}
      className={newClass}
      classes={{ root: analyticsStyle.tsrightArrow }}
    >
      <ArrowRight color='primary' />
    </IconButton>
  );
};

const PrevArrow = (props) => {
  const { newClass, onClick } = props;
  return (
    <IconButton
      aria-label='Prev'
      onClick={onClick}
      className={newClass}
      classes={{ root: analyticsStyle.tsleftArrow }}
    >
      <ArrowLeft color='primary' />
    </IconButton>
  );
};

const settings = {
  infinite: false,
  dots: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
  ]
};


const TopScreener = (props) => {
  return (
    <StyledCard>
      <div className={styles.fSpaceBtw}>
        <h5 className={analyticsStyle.heading}>Top Screeners</h5>
        <IconButton aria-label='download-excel' className={`${analyticsStyle.fileImg}`} onClick={() => props.handleExport('top_screener')}>
          <ExcelIcon />
        </IconButton>
      </div>
      <div>
        <Slider {...settings} className={`${analyticsStyle.tcslider}`}>
          {props.screenedTotal?.map((arr, index)=> {
            return (<div key={index} className={`${analyticsStyle.tsWrapper}`}>
              <div className={`${analyticsStyle.topScreener}`}>
                <span className={`${analyticsStyle.tsStarWrapper}`}>
                  <StarOutline
                    classes={{ root: analyticsStyle.tsStar }}
                  />
                  <span className={`${analyticsStyle.tsNo}`}>{index + 1}</span>
                </span>
                <MuiTooltip title={arr.user}>
                  <small className={`${analyticsStyle.tsName}`}>{`${arr.user}`}</small>
                </MuiTooltip>
              </div>
              <div className={`${analyticsStyle.tsDataWrapper} ${styles.fMiddle} ${styles.fColumn}`}>
                <p className={analyticsStyle.tsData}><b>{props.report == 'awb' ? arr.awb : props.report == 'wgt' ? arr.wgt : arr.pcs }</b></p>
                <p className={analyticsStyle.tsDataUnit}>{props.report == 'awb' ? 'awbs' : props.report == 'wgt' ? 'kilos' : 'Pieces'}</p>
              </div>
            </div>
            );
          })}
        </Slider>
      </div>
    </StyledCard>
  );
};

export default TopScreener;
