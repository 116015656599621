import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

let theme = createTheme();
theme.spacing(4);
theme.palette.primary.main= '#092b4a';
theme.palette.primary.contrastText= '#fff';
theme.palette.secondary.main= '#59b362';
theme.palette.secondary.contrastText= '#fff';
theme.palette.error.main= red['700'];
theme.palette.disabled ={
  dark: grey,
  // light: lightGreen
};
theme.palette.common = {
  black: 'rgba(0, 0, 0, 0.85)',
  white: '#fff' 
};
// theme.typography.htmlFontSize= 12;
// A custom theme for this app
// theme = createTheme(theme, {
//   palette: {
//     primary: { main: '#092b4a', },
//     secondary: {
//       main: '#59b362',
//       contrastText: '#fff'
//     },
//     // grey: { main: '#c9c9c9' },
//     error: { main: red['700'], },
//     disabled: {
//       dark: grey,
//       light: lightGreen
//     }
//   },

//   spacing: 4
// });

export default theme;
