import React from 'react';
import styles from '../../../../custom/style.module.css';
import docStyle from '../docs.module.css';
import moment from 'moment';

const DataTable = (props) => {
  const getHeader = () => {
    if (props.type == 'AWB') {
      return (<tr>
        <th colSpan={props.input ? 2 : 1}>Awb no.</th>
        <th>Org</th>
        <th>Dest</th>
        <th>Flt no.</th>
      </tr>
      );
    }
  };
  const getRow = (data) => {
    if (props.type == 'AWB') {
      return (<tr key={data.id} onClick={() => {
        if (props.input) {
          props.onChange(data.id, 'awbId');
        }
      }}>
        {props.input && (<td><input type='radio' name='awbId' checked={data.id == props.value}  className={docStyle.htmlRadio} /></td>)}
        <td className={styles.textCenter}>{data.code + '-' + data.number}</td>
        <td className={styles.textCenter}>{data.org}</td>
        <td className={styles.textCenter}>{data.dest}</td>
        <td className={styles.textCenter}>{data?.airline?.code+data.flight_no+'/'+moment(data.flight_date).format('DDMMMYYYY').toUpperCase()}</td>
      </tr>);
    }
  };
  return (
    <table className={`${styles.customTable} ${styles.w100}`}>
      <thead>
        {getHeader()}
      </thead>
      <tbody>
        {props.data.map(awb => {
          return getRow(awb);
        })}
      </tbody>
    </table>
  );
};
export default DataTable;