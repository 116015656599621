import React from 'react';
import cookies from '../../../utils/cookies';
import Login from './Login';
import http from '../../../utils/http';
import auth from '../../../utils/auth';
class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        username: '',
        company: '',
        password: ''
      },
      errors: {
        company: false,
        username: false,
        password: false
      },
      submit: false,
    };
  }

  componentDidMount() {
    if (auth()) {
      window.location.href = '/';
    }
  }

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value
      }
    });
  };

  validation = () => {

    const fields = { ...this.state.fields };
    const errors = {
      company: false,
      username: false,
      password: false
    };
    let formIsValid = true;

    if (!fields.company || (fields.company).trim().length === 0) {
      formIsValid = false;
      errors.company = 'Please enter your company.';
    }

    if (!fields.username || (fields.username).trim().length === 0) {
      formIsValid = false;
      errors.username = 'Please enter your username.';
    }

    if (!fields.password || (fields.password).trim().length === 0) {
      formIsValid = false;
      errors.password = 'Please enter your password.';
    }

    this.setState({ errors: errors });

    return formIsValid;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.fields, };
    if (this.validation()) {
      this.setState({ submit: true });
      await http.post('/user/login', formdata).then(({ data, status }) => {
        if (status == 200 && data.status == 'success') {
          const station = data.data.station;
          const company = data.data.company;
          const currentDate = new Date();
          const expires = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
          cookies.set('login', true, {
            path: '/',
            expires
          });
          data.data.user.login === false ?
            cookies.set('password', data.data.user.login, {
              path: '/',
              expires
            }) : null;
          cookies.set('name', data.data.user.first_name + ' ' + data.data.user.last_name, {
            path: '/',
            expires
          });
          cookies.set('user', data.data.user.id, {
            path: '/',
            expires
          });
          cookies.set('company', data.data.user.companyId, {
            path: '/',
            expires
          });
          cookies.set('role', data.data.user.role, {
            path: '/',
            expires
          });
          cookies.set('station', station && station[0] && station[0].id, {
            path: '/',
            expires
          });
          cookies.set('tz', station && station[0] && station[0].tz, {
            path: '/',
            expires
          });
          cookies.set('station_code', station && station[0] && station[0].master, {
            path: '/',
            expires
          });
          cookies.set('stations', station, {
            path: '/',
            expires
          });
          cookies.set('mandatory_fields', company && company.mandatory_fields, {
            path: '/',
            expires
          });
          cookies.set('company_name', company.company_name, {
            path: '/',
            expires
          });
          window.location.href = '/';
        }
        else {
          this.setState({ submit: false });
        }
      })
        .catch(() => {
          this.setState({ submit: false });
        });
    }
  };

  render() {
    return (
      <Login
        fields={this.state.fields}
        errors={this.state.errors}
        submit={this.state.submit}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default LoginContainer;