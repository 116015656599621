import React from 'react';
import { Dialog } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import admin from '../admin.module.css';
import { CustomButton, CustomDialogAction, CustomDialogContent, CustomDialogTitle, CustomInputLabel, CustomTextField, InputProps, StyledCard } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const K9Modal = (props) => {
  const { action, k9Fields, k9Errors, onChangeK9, addK9, editK9 } = props;
  return (
    <div>
      <Dialog 
        aria-labelledby='form-dialog-title'
        open={props.modal === 'k9'} 
        onClose={props.onClose}
        maxWidth={'sm'}
        fullWidth={true} 
      >
        <CustomDialogTitle id='form-dialog-title'className={styles.modalTitle}>
          {action == 'add' ? 'Add' : 'Edit'} K9 Company
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form onSubmit={action == 'add' ? addK9 : editK9} onReset={props.onClose} className={styles.w100}>
          <CustomDialogContent className={admin.inputForm}>
            <StyledCard>
              <CustomInputLabel htmlFor='k9'>Company Name</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                fullWidth
                id='k9'
                className={`${admin.addTextField} ${styles.mb3}`}
                placeholder='Company name'
                type='text'
                onChange={onChangeK9}
                name='name'
                value={k9Fields.name}
                error={k9Errors.name}
                helperText={k9Errors.name}
              />
            </StyledCard>
          </CustomDialogContent>
          
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' type='reset'>Close</CustomButton>
            <CustomButton variant='contained' type='submit'> Submit </CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default K9Modal;