import React from 'react';
import { Divider, Dialog, Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import admin from '../admin.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, CustomDialogContent } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const AddMeasuresModal = (props) => {
  const { action, airline, measureFields, onChangeMeasure, addMeasure, editMeasure } = props;
  return (
    <div>
      <Dialog 
        aria-labelledby='form-dialog-title'
        open={props.modal === 'measure'} 
        onClose={props.onClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add' : 'Edit'} Measures
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <Divider />
        <form className={styles.w100} onSubmit={action == 'add' ? addMeasure : editMeasure} onReset={props.onClose}>
          <CustomDialogContent className={admin.inputForm}>
            <StyledCard>
              {/* <div>
                <CustomInputLabel htmlFor='component-error'>Measures</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='measures'
                  className={`${admin.addTextField} ${styles.mb3}`}
                  placeholder='Measures'
                  type='text'
                  name='type'
                  onChange={onChangeMeasure}
                  value={measureFields.type}
                  error={measureErrors.type}
                  helperText={measureErrors.type}
                />

              </div> */}
              <Stack direction='column' alignItems='flex-start'>
                <CustomInputLabel>Airlines </CustomInputLabel>
                <FormGroup aria-label='airlines' row className={`${styles.fvMiddle} ${styles.checkboxWrapper}`}>
                  {airline && airline.map(a => {
                    return (<FormControlLabel key={a.id} control={<Checkbox size='small' />} value={a.id} name='airline' checked={measureFields.airline && measureFields.airline.includes(a.id)} label={a.code} onChange={onChangeMeasure} />);
                  })}
                </FormGroup>
              </Stack>
            </StyledCard>

          </CustomDialogContent>
          
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' type='reset'>Close</CustomButton>
            <CustomButton variant='contained' type='submit'> Submit </CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddMeasuresModal;