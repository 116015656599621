import React from 'react';
import { Stack, IconButton, Typography, Dialog } from '@mui/material';
import { CloseIconProps, CustomDialogContent, CustomDialogTitle } from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import reportStyle from './report.module.css';
import { DownloadIcon } from 'custom/constants';
import CloseIcon from '@mui/icons-material/Close';
import environment from 'custom/environments';
import PDFViewer from '../common/PDFViewer';

const Attachment = (props) => {

  return (
    <div>
      <Dialog
        open={props.attachment}
        anchorEl={props.anchorEl}
        onClose={() => props.handlePopover(false)}
        fullWidth={true}
        maxWidth={false}
        PaperProps={{ style: { width: '660px' } }}
      >
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          <Typography variant='subtitle2' component='span'>Images: {props.awb}</Typography>
          <IconButton
            aria-label='close'
            onClick={() => props.handlePopover(false)}
            sx={{ ...CloseIconProps }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </CustomDialogTitle>
        <CustomDialogContent>
          <Stack direction='column' spacing={5} >
            {props.docs && props.docs.map(doc => (
              <div direction='row' key={doc.id} spacing={2} >
                <div className={reportStyle.attImgContainer}>
                  {doc.src && doc.src?.includes('.pdf') ? (
                    <PDFViewer src={doc.src} alt='attachment' className={styles.w100} crossOrigin='use-credentials' />
                  ) : (
                    <img src={environment.apiService + 'document?key=' + doc.src} alt='attachment' style={{ width: '340%' }} crossOrigin='use-credentials' />
                  )}
                </div>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <a href={environment.apiService + 'document?key=' + doc.src} target='new'>
                    <DownloadIcon />
                  </a>
                </div>
                <hr style={{ width: '102%' }} />
              </div>
            ))}
          </Stack>
        </CustomDialogContent>
      </Dialog>
    </div>
  );
};

export default Attachment;