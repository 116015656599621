import React from 'react';
import DocStreamsList from './DocStreamList';
import DocViewer from './DocViewer';
import DocVerification from './DocVerification';
import { Grid } from '@mui/material';
import http from '../../../utils/http';
import flightHttp from '../../../utils/flightHttp';
import trypodHttp from '../../../utils/trypodhttp';
import dayjs from 'dayjs';
import events from '../../../utils/evemts';
import { pdfjs } from 'react-pdf';
import AddDocModal from './AddDocModal';
import docStyles from './docs.module.css';
import { Check, Close } from '@mui/icons-material';
import cookies from 'utils/cookies';
import AwbModal from './AwbModal';
import { validateAwb } from 'utils/awb';
import { toast } from 'react-toastify';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const awbRegex = /^[0-9]{3}-[0-9]{8}$/;

class DocsStreamContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      exModal: false,
      docs: [],
      data: [],
      exData: {},
      searchOptions: {
        start: new Date(),
        end: new Date(),
        verified: false,
        identity: 'all',
        awb: ''
      },
      awbs: [],
      formFields: {},
      docType: 'AWB',
      docId: 0,
      airlineList: [],
      selectedDoc: {},
      docData: [],
      userList: [],
      k9: [],
      tracking: false,
      awbData: {},
      docFields: {},
      docErrors: {},
      updateButton: false,
      flightDates: [],
      scheduleList: [],
      isLoading: false,
      flightSettings: {}
    };
    this.station_code = cookies.get('station_code') || null;
  }

  componentDidMount() {
    this.fetchDocs(false);
    this.fetchAirlines();
    this.fetchUsers();
    this.fetchK9();
    this.getConfigValues();
    this.eventSource = events('docs', 'stream');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      this.handleEvent(msg);
    };
  }

  getConfigValues = async () => {
    http.get('/settings?key=flight_schedule')
      .then((response) => {
        // handle success
        if (response.data.data) {
          this.setState({ flightSettings: response.data.data.value });
          if (response.data.data.value.limit_input) {
            this.fetchSchedules();
          }
        }
      });
  };

  handleEvent = (msg) => {
    if ((msg.action == 'add' || msg.action == 'update') && msg.data.created) {
      const start = dayjs(this.state.searchOptions.start).format('YYYYMMDD');
      const end = dayjs(this.state.searchOptions.end).format('YYYYMMDD');
      const created = dayjs(msg.data.created).format('YYYYMMDD');
      if (created >= start && created <= end) {
        if (msg.action == 'add') {
          this.setState({ docs: [...this.state.docs, msg.data] }, () => {
            this.filterDocs();
          });
        }
        if (msg.action == 'update') {
          const docs = [...this.state.docs];
          const index = docs.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            docs[index] = { ...msg.data };
            this.setState({ docs }, () => {
              if (this.state.docId == msg.data.id) {
                this.selectDoc(docs[index]);
              }
              this.filterDocs();
            });
          } else {
            this.fetchDocs(false);
          }
        }
      }
    }
    if (msg.action == 'delete') {
      const docs = [...this.state.docs];
      const index = docs.findIndex(c => msg.data && c.id == msg.data.id);
      if (index > -1) {
        docs.splice(index, 1);
        this.setState({ docs }, () => {
          this.filterDocs();
        });
      }
    }
  };

  fetchUsers = () => {
    http.get('/user')
      .then((response) => {
        // handle success
        this.setState({ userList: response.data.data });
      });
  };

  handleChange = (event, type) => {
    let value = event.target ? event.target.value : event;

    if (type === 'awb') {
      value = this.handleChangeAwb(event.target.value);
      this.setState((prevState) => ({
        searchOptions: {
          ...prevState.searchOptions,
          [type]: value
        }
      }), () => {
        if (!value) {
          this.fetchDocs(false);
        }
      });
    } else if (type === 'docType') {
      this.setState({
        docType: value,
        awbs: [],
        updateButton: false,
        formFields: {}
      });
    } else if (type === 'verified') {
      this.setState((prevState) => ({
        searchOptions: {
          ...prevState.searchOptions,
          [type]: !prevState.searchOptions.verified
        }
      }), () => {
        this.fetchDocs(false);
      });
    } else if (type === 'identity') {
      this.setState((prevState) => ({
        searchOptions: {
          ...prevState.searchOptions,
          [type]: value
        }
      }), () => {
        this.filterDocs();
      });
    } else {
      this.setState((prevState) => ({
        searchOptions: {
          ...prevState.searchOptions,
          [type]: value
        }
      }), () => {
        this.fetchDocs(false);
      });
    }
  };

  handleChangeAwb = (value) => {
   

    if (value.length === 3 && (this.state.formFields.awb_no?.length === 2 || this.state.searchOptions.awb?.length === 2)) {
      value = value + '-'; 
    }
    if (value.length === 11) {
      if (value.indexOf('-') < 0) {
        value = value.slice(0 , 3) + '-' + value.slice(3);
      }
    }
    if (value.split('-').length > 2) {
      return;
    }
    
    return value;
  };

  handleChangeForm = (event, name) => {
    let value = event.target ? event.target.value : event;
    if (name === 'awb_no') {
      value = this.handleChangeAwb(value); // Call handleChangeAwb function
    }
    if (name === 'org' || name === 'dest') {
      value = value.toUpperCase();
    }
    let formFieldsValue = {
      ...this.state.formFields,
      [name]: value
    };

    if (name == 'airlineId') {
      const foundObject = this.state.airlineList.find(item => item.id === value);
      formFieldsValue = {
        ...this.state.formFields,
        [name]: value,
        ['airline']: foundObject.code
      };
    }
    this.setState({ formFields: formFieldsValue });
  };

  handleSecurity = async (event, name) => {
    const { value } = event.target || event;
    let { formFields, updateButton } = this.state;

    let security = formFields.security ? { ...formFields.security } : {};

    // if(name ==)
    switch (name) {
      default:
        security[name] = value;
        break;
    }

    this.setState({
      formFields: {
        ...formFields,
        security: { ...security }
      },
      updateButton
    });
  };

  checkElement = (check) => {

    if (check) {
      return (
        <Check style={{ color: 'green' }} />
      );
    }

    return (
      <Close style={{ color: 'red' }} />
    );
  };


  getFlightDates = () => {
    if(this.state.flightSettings?.limit_input) {
      const formFields = this.state.formFields;
      const airlineCode = this.getCodeById(formFields.airlineId);
      let url = `/flight?org=${this.station_code}&flight_no=${formFields.flight_no}&airline=${airlineCode}`;
      flightHttp.get(url)
        .then((response) => {
          this.setState({ flightDates: response.data.data });
        });
    }

  };

  fetchSchedules = () => {
    flightHttp.get('/flight_schedule?org=' + this.station_code)
      .then((response) => {
        response.data.data ? this.setState({ scheduleList: response.data.data }) : {};
      });
  };

  checkTransporationNumber = async () => {
    let formFields = { ...this.state.formFields };
    let security = this.state.formFields.security;
    if (security?.transportation_profile === 'ds') {
      security.airline = this.getCodeById(formFields.airlineId);
    }
    let updateButton = this.state.updateButton;
    const data = await this.validateSecurity(security);
    security = {
      ...security,
      ...data.result
    };
    if (security?.transportation_profile || security?.ccsf_approval_number) {
      updateButton = !security.valid_transportation;
    }

    formFields.security = security;
    this.setState({
      formFields,
      updateButton
    });

  };

  validateSecurity = async (formData) => {
    try {
      const response = await trypodHttp.post('security_checks', JSON.stringify(formData));
      return response.data;
    } catch (error) {
      console.error('Error in validateSecurity:', error);
      throw error; // Rethrow the error to handle it in the caller function
    }
  };

  componentWillUnmount() {
  }

  submit = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.formFields };
    formdata.type = this.state.docType;
    if(this.state.docType === 'OTHER' && !formdata.sub_type) {
      formdata.sub_type = 'OTHER';
    }
    formdata.docId = this.state.docId;
    formdata.flight_date = formdata.flight_date ? dayjs(formdata.flight_date).format('YYYY-MM-DD') : formdata.flight_date;
    if (this.state.docType == 'AWB') {
      formdata.awbId = this.state.selectedDoc.awb ? this.state.selectedDoc.awb.id : null;
    } else if (this.state.docType == 'FFM') {
      formdata.flightId = this.state.selectedDoc.flight ? this.state.selectedDoc.flight.id : null;
    }
    await http.put(`/doc_stream/${this.state.docType?.toLowerCase()}/${this.state.docId}`, formdata)
      .then(() => {
        this.setState({ docId: 0 });
        this.setState({ docData: [] });
        toast.success('Document updated successfully!');
        if (!this.state.searchOptions.verified) {
          const data = this.state.docs.filter((d) => d.verified === false);
          const currentIndex = data.findIndex(d => d.id === this.state.docId);
          let nextIndex = currentIndex + 1;
          if (nextIndex >= data.length) {
            nextIndex = 0;
            if (nextIndex == currentIndex) {
              nextIndex = -1;
            }
          }
          if (data.length > 0 && nextIndex !== -1) {
            this.selectDoc(data[nextIndex]);
          } 
        }
      });
  };

  fetchAirlines = () => {
    http.get('/airline')
      .then((response) => {
        this.setState({ airlineList: response.data.data });
      });
  };

  checkAwb = () => {
    const { awb_no } = this.state.formFields;
    if (awb_no && validateAwb(awb_no)) {
      http.get(`/awb/check/${awb_no}`).then(({ data }) => {
        this.setState({ awbs: data.data });
      });
    } else {
      this.setState({ awbs: [] });
    }
  };

  searchAwb = () => {
    const { awb_no } = this.state.formFields;

    if (awb_no && awbRegex.test(awb_no)) {
      this.setState({ isLoading: true }, () => {
        http.get(`/search_awb/${awb_no}`).then(({ data }) => {
          if (data.data.found) {
            this.setState({
              exModal: true,
              exData: data.data.awb
            });
          } else {
            this.setState({
              formFields: {
                ...this.state.formFields,
                ...data.data.awb
              }
            });
          }
          this.setState({ isLoading: false });
        });

      });
    } else {
      this.setState({ awbs: [] });
    }

  };

  attachAwb = () => {
    http.get(`/doc_stream/attach_awb/${this.state.docId}/${this.state.exData.id}`).then(() => {
      this.setState({
        exModal: false,
        exData: {}
      });
    });
  };

  newAWB = () => {
    const { awb_no } = this.state.formFields;
    http.get(`/awb_info/${awb_no}`).then(({ data }) => {
      this.setState({
        formFields: {
          ...this.state.formFields,
          version: this.state.exData.version + 1 || 1,
          ...data.data
        },
        exModal: false,
        exData: {}
      });
    });
  };

  fetchK9 = () => {
    http.get('/k9_company').then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ k9: data.data });
      }
    });
  };

  fetchDocs = (search = false) => {
    let query = '';
    if (search) {
      query = `awb=${this.state.searchOptions.awb}&type=stream`;
    } else {
      query = `start=${dayjs(this.state.searchOptions.start).format('YYYYMMDD')}&end=${dayjs(this.state.searchOptions.end).format('YYYYMMDD')}&type=stream`;
    }
    let url = `/docs?${query}`;
    // if (id)
    //   url += `&docId=${id}`;
    http.get(url, this.state.searchOptions)
      .then((response) => {
        // handle success
        this.setState({ docs: response.data.data }, () => {
          this.filterDocs();
        });
      });
  };

  filterDocs = () => {
    let data = [...this.state.docs || []];
    if (this.state.searchOptions.verified) {
      data = data.filter((d) => d.verified === true);
    }
    if (this.state.searchOptions.identity === 'all') {
      if (this.state.searchOptions.verified) {
        data = data.filter((d) => d.verified === true);
      } else {
        data = data.filter((d) => d.verified === false);
      }
    }
    if (this.state.searchOptions.identity == 'unknown') {
      data = data.filter((d) => d.name == null);
    }
    if (this.state.searchOptions.identity === 'identified') {
      if (this.state.searchOptions.verified) {
        data = data.filter((d) => d.verified === true);
      } else {
        data = data.filter((d) => d.verified === false);
        data = data.filter((d) => d.name != null);
      }
    }
    this.setState({ data });
  };

  getCodeById(id) {
    const airline = this.state.airlineList.find(airline => airline.id === id);
    return airline ? airline.code : null;
  }

  handleOpen = () => {
    this.setState({ modal: true });
  };

  validateDoc = () => {
    const docFields = { ...this.state.docFields };
    const error = {};
    let formValid = true;
    if (!docFields.name || (docFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    if (!docFields.file) {
      formValid = false;
      error.remark = 'Please select file.';
    }
    this.setState({ docErrors: error });
    return formValid;
  };

  addDoc = (e) => {
    e.preventDefault();
    if (this.validateDoc()) {
      let formdata = { ...this.state.docFields };
      http.post('/doc_stream/add?doc_type=stream', formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            docFields: {},
            docFieldsErrors: {},
          });
        }
      });
    }
  };

  selectDoc = (doc) => {
    const docId = doc.id;
    const docData = [doc];
    let formFields = { ...doc.data || {} };
    let docType = doc.type || 'AWB';

    if (doc.awb && doc.type == 'AWB') {
      formFields = {
        ...doc.awb,
        awb_no: `${doc.awb.code}-${doc.awb.number}`
      };
    } else if (doc.flight && doc.type == 'FFM') {
      formFields = { ...doc.flight };
    } else if (doc.type == 'USER') {
      formFields = {
        userId: doc.userId,
        ...doc.data,
        name: doc.name
      };
    } else if (doc.type === 'OTHER') {
      formFields = {
        ...doc.data,
        sub_type: doc.sub_type,
        awb_no: doc.awb ? `${doc.awb.code}-${doc.awb.number}` : '',
        userId: doc.userId,
        name: doc.name,
        k9_companyId: doc.k9_companyId,
        ...(doc.flight && {
          flight_date: doc.flight.flight_date,
          flight_no: doc.flight.flight_no,
          airline: doc.flight.airline
        })
      };
    }
    this.setState({
      docId,
      selectedDoc: doc,
      formFields,
      docType,
      docData,
      awbs: []
    });
  };

  onChangeDoc = (e) => {
    let value = e.target.value;
    this.setState({
      docFields: {
        ...this.state.docFields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  clearFile = () => {
    this.setState({
      docFields: {
        ...this.state.docFields,
        ['file']: ''
      }
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      exModal: false
    });
  };

  deleteDoc = (id) => {
    http.delete(`/document/${id}`).then(() => {
    });
  };

  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item className={`${docStyles.docSec} ${docStyles.docSecList}`}>
            <DocStreamsList
              changeAwb={this.handleChange}
              searchOptions={this.state.searchOptions}
              docs={this.state.data}
              selectDoc={this.selectDoc}
              docId={this.state.docId}
              search={this.fetchDocs}
              deleteDoc={this.deleteDoc}
              handleOpen={this.handleOpen}
            />
          </Grid>
          <Grid item xs className={`${docStyles.docView}`}>
            <DocViewer
              docData={this.state.docData}
            />
          </Grid>
          <Grid item className={`${docStyles.docSec} ${docStyles.docSecVerify}`}>
            <DocVerification
              docType={this.state.docType}
              handleChange={this.handleChange}
              changeFields={this.handleChangeForm}
              formFields={this.state.formFields}
              submit={this.submit}
              airlines={this.state.airlineList}
              docId={this.state.docId}
              userList={this.state.userList}
              k9={this.state.k9}
              tracking={this.state.tracking}
              awbs={this.state.awbs}
              checkAwb={this.checkAwb}
              searchAwb={this.searchAwb}
              handleSecurity={this.handleSecurity}
              checkTransporationNumber={this.checkTransporationNumber}
              disableButton={this.state.updateButton}
              checkElement={this.checkElement}
              flightDates={this.state.flightDates}
              getFlightDates={this.getFlightDates}
              isLoading={this.state.isLoading}
              flightSettings={this.state.flightSettings}
              scheduleList={this.state.scheduleList}
            />
          </Grid>
        </Grid>
        <AddDocModal
          onClose={this.onClose}
          modal={this.state.modal}
          fields={this.state.docFields}
          errors={this.state.docErrors}
          onChange={this.onChangeDoc}
          onSubmit={this.addDoc}
          clearFile={this.clearFile}
        />
        <AwbModal
          onClose={this.onClose}
          modal={this.state.exModal}
          attachAwb={this.attachAwb}
          newAWB={this.newAWB}
        />
      </div>
    );
  }
}

export default DocsStreamContainer;