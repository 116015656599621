import React from 'react';
import { Grid, IconButton, Stack, Box } from '@mui/material';
import awbStyle from './attachment.module.css';
import styles from '../../../../../custom/style.module.css';
import { CloseIconProps, StyledCardTitle, StyledWhiteCard } from '../../../../../custom/components';
import { Close } from '@mui/icons-material';
import { DocIcon, DownloadIcon } from 'custom/constants';
import http from 'utils/http';
import env from '../../../../../custom/environments';
import PDFSlider from './PDFSlider';
import dayjs from 'dayjs';

class AttachmentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: [],
      displayedDocIndex: 0
    };
  }

  componentDidMount() {
    this.fetchDocs();
  }

  fetchDocs = () => {
    http.get(`/archive/doc?id=${this.props.id}&type=${this.props.type}`).then(({ data }) => {
      if (data.status === 'success') {
        this.setState({ docs: data.data });
      }
    });
  };


  handleDownloadPDF = (name, src) => {
    // Perform the download logic here
    // For example, you can use the `fetch` API to download the PDF
    fetch(`${env.apiService}document?key=${src}`)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        // Create a temporary anchor element to trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = name || 'UNKNOWN'; // Set the file name for the download
        downloadLink.click();
      })
      .catch((error) => {
        console.error('Error downloading PDF:', error);
      });
  };

  handlePrintPDF = (src) => {
    // Perform the print logic here
    const pdfUrl = `${env.apiService}document?key=${src}`;
    const pdfWindow = window.open(pdfUrl);
    pdfWindow.onload = () => {
      pdfWindow.print();
    };
  };

  render() {
    const { docs } = this.state;
    return (
      <Box sx={{
        position: 'absolute',
        top: '190px',
        width: '100%',
        zIndex:'3'
      }}>
        <StyledWhiteCard>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack direction='row' spacing={2} justifyContent='space-between' flexGrow={1} flexWrap='wrap'>
                <StyledCardTitle gutterBottom variant=''>
                  Attached Documents
                </StyledCardTitle>
                <Stack direction='row' spacing={4} justifyContent='space-around' alignItems='center' sx={{ maxWidth: 'clamp(350px , 70%, 70%)' }}>
                  <IconButton aria-label='close' onClick={() => this.props.handlePopover(false)} sx={{ ...CloseIconProps }}>
                    <Close />
                  </IconButton>
                </Stack>
              </Stack>
              <hr />
            </Grid>
            {docs.map(doc => {
              const extension = doc.src?.split('.').pop().toLowerCase();
              const image = ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension);
              return (<Grid key={doc.id} item container xs={12}>
                <Grid item xs={3}>
                  <Stack className={styles.pl5} spacing={1}>
                    <div>
                      <small className={awbStyle.docDetails}>Document Type: </small>
                      <small>{doc?.type}</small>
                    </div>
                    {/* <div>
                      <small className={awbStyle.docDetails}>Document Name</small>
                      <small>{doc?.name}</small>
                    </div> */}
                    <div>
                      <small className={awbStyle.docDetails}>Verified By:</small>
                      <small>{doc?.verified_by ? `${doc.verified_by.first_name} ${doc.verified_by.last_name}` : ''}</small>
                    </div>
                    {/* <div>
                      <small className={awbStyle.docDetails}>Date/Time:</small>
                      <small>{doc?.verified_date ? moment(
                        doc?.verified_date || null
                      )
                        .format('DDMMMYY HH:MM')
                        .toUpperCase() : ''}</small>
                    </div> */}

                    <div>
                      <small className={awbStyle.docDetails}>Verified Date/Time:</small>
                      <small>{dayjs(
                        doc?.verified_date || null
                      )
                        .format('YYYY-MM-DD HH:mm')}</small>
                    </div>
                    <div>
                      <small className={awbStyle.docDetails}>Pages:</small>
                      <small>{doc?.numPages || 1}</small>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  {image ? <img src={env.apiService + 'document?key=' + doc.src} alt='attachment' className={styles.w100} crossOrigin='use-credentials' /> : <PDFSlider file={env.apiService + 'document?key=' + doc.src} />}
                </Grid>
                <Grid item xs={3}>
                  <Stack direction='row' spacing={5} justifyContent='center'>
                    <IconButton onClick={() => this.handleDownloadPDF(doc.name, doc.src)} >
                      <DownloadIcon />
                    </IconButton>
                    <IconButton onClick={() => this.handlePrintPDF(doc.src)}>
                      <DocIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>);
            })}
          </Grid>
        </StyledWhiteCard>
      </Box>
    );
  }
}

export default AttachmentContainer;
