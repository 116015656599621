import React from 'react';
import Screen from './Screen';
import http from 'utils/http';
import events from 'utils/evemts';
import { connect } from 'react-redux';
import { setScreenAction } from 'store/actions/scrreenAction';

class ScreenContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      id: false,
      tab: 1,
      events: [],
      modal: false,
      scrapperModal: false,
      addAwb: null,
      isProcessing: false,
      is_screened: false,
      is_printed: false
    };
    this.eventSource = null;
  }
  componentDidMount() {
    this.fetchAwb();
    this.eventSource = events('awb');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.action == 'update' && msg.data.id == this.state.id) {
        this.setState({
          data: {
            ...msg.data,
            airline: { ...(msg.data?.airline || this.state.data.airline || {}) },
            screens: this.state.data.screens
          }
        }, () => {
          this.props.setScreen(this.state.data || {});
        });
      }
      if (msg.action == 'awb_screen' && msg.data.id == this.state.id) {
        this.setState({ is_screened: msg.data.is_screened });
      }
      if (msg.action == 'add_screen' && msg.data.awbId == this.state.id) {
        this.setState({
          data: {
            ...this.state.data,
            screens: [...this.state.data.screens || [], msg.data]
          }
        }, () => {
          this.props.setScreen(this.state.data || {});
        });
      }
      if ((msg.action == 'update_screen' || msg.action == 'delete_screen') && msg.data.awbId == this.state.id) {
        const data = [...this.state.data.screens || []];
        const index = data.findIndex(d => msg.data && d.id == msg.data.id);
        if (msg.action == 'update_screen') {
          if (index > -1) {
            data[index] = {
              ...data[index],
              ...msg.data
            };
            this.setState({
              data: {
                ...this.state.data,
                screens: data
              }
            }, () => {
              this.props.setScreen(this.state.data || {});
            });
          } else {
            this.setState({
              data: {
                ...this.state.data,
                screens: [...this.state.data.screens || [], msg.data]
              }
            }, () => {
              this.props.setScreen(this.state.data || {});
            });
          }
        }
        if (msg.action == 'delete_screen') {
          if (index > -1) {
            data.splice(index, 1);
            this.setState({
              data: {
                ...this.state.data,
                screens: data
              }
            }, () => {
              this.props.setScreen(this.state.data || {});
            });
          }
        }
      }
      if (msg.action == 'consignment' && msg.data.id == this.state.id) {
        this.setState({
          is_printed: msg.data && typeof msg.data?.is_printed == 'boolean' ? msg.data?.is_printed : this.state.is_printed,
          data: {
            ...this.state.data,
            ...msg.data,
            screens: this.state.data.screens
          }
        }, () => {
          this.props.setScreen(this.state.data || {});
        });
      }
    };
  }

  componentDidUpdate(prevProps) {
    console.log(this.props.location, prevProps.location);
    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      if (!this.props.match.params.id) {
        this.props.setScreen({});
      }
    }
  }

  componentWillUnmount() {
    this.eventSource.close();
    this.props.setScreen({});
  }

  fetchAwb = (id = false) => {
    this.setState({
      id: id ? id : this.props.match.params.id,
      modal: false,
      scrapperModal: false,
    });
    if (this.props.match.params.id || id) {
      http.get('/screen/' + (id ? id : this.props.match.params.id)).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            data: data.data || {},
            is_printed: data.data?.is_printed || data.data?.is_mailed || false,
            is_screened: data.data?.is_screened || false
          });
          this.props.setScreen(data.data || {});
        }
      });
    } else {
      this.props.setScreen({});
    }
  };

  handleTabChange = (tab) => {
    this.setState({ tab });
  };


  render() {
    return (
      <>
        <Screen
          addAwb={this.state.addAwb}
          is_printed={this.state.is_printed}
          is_screened={this.state.is_screened}
          tab={this.state.tab}
          handleTabChange={this.handleTabChange}
          fetchAwb={this.fetchAwb}
        />
      </>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return { setScreen: (data) => dispatch(setScreenAction(data)) };
};

export default connect(null, mapDispatchToProps)(ScreenContainer);
