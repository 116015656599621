import React from 'react';
import { Dialog } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import admin from '../admin.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomDialogContent } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const AddMethodsModal = (props) => {
  const { action, methodFields, methodErrors, onChangeMethod, addMethod, editMethod } = props;
  return (
    <div>
      <Dialog 
        aria-labelledby='form-dialog-title'
        open={props.modal === 'method'}
        onClose={props.onClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add' : 'Edit'} Methods
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100} onSubmit={action == 'add' ? addMethod : editMethod} onReset={props.onClose}>
          <CustomDialogContent className={admin.inputForm}>
            <StyledCard>
              <div>
                <CustomInputLabel htmlFor='methods'>Methods</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='methods'
                  className={`${admin.addTextField} ${styles.mb3}`}
                  placeholder='Methods'
                  type='text'
                  name='name'
                  onChange={onChangeMethod}
                  value={methodFields.name}
                  error={methodErrors.name}
                  helperText={methodErrors.name}
                />
              </div>
              <div>
                <CustomInputLabel htmlFor='code'>Code</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='code'
                  className={`${admin.addTextField} ${styles.mb3}`}
                  placeholder='Code'
                  type='text'
                  name='code'
                  onChange={onChangeMethod}
                  value={methodFields.code}
                  error={methodErrors.code}
                  helperText={methodErrors.code}
                />
              </div>
            </StyledCard>
          </CustomDialogContent>
          
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' type='reset'>Close</CustomButton>
            <CustomButton variant='contained' type='submit'> Submit </CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddMethodsModal;