import React from 'react';
import Header from './Header';
import SideBar from './Sidebar';
import { sidebarwidth } from './headerconstants';
import cookies from '../../utils/cookies';
import { Common } from 'custom/common';
import http from 'utils/http';

class HeaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      pageTitle: 'Doc Stream',
    };
    this.stations = cookies.get('stations') || [];
    this.station = cookies.get('station') || null;
  }

  componentDidMount = () => {
    Common(this);
  };

  componentDidUpdate() {
    () => Common(this);
  }

  handleStation = (e) => {
    let stn = this.stations.filter(s => s.id === e.target.value)?.[0];
    cookies.set('station', stn.id);
    cookies.set('station_code', stn.master);
    cookies.set('tz', stn.tz);
    window.location.reload();
  };

  handleDrawerOpen = () => {
    this.setState({ open: !this.state.open }, () => { Common(this); });
    if (!this.state.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  signout = () => {
    http.get('/user/logout').then(() => {
      document.cookie.split(';').forEach(function (c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });
      window.location.href = '/login';
    });
  };

  render() {
    return (
      <>
        <Header
          station={this.station}
          stations={this.stations}
          handleDrawerOpen={this.handleDrawerOpen}
          open={this.state.open}
          handleStation={this.handleStation}
          pageTitle={this.state.pageTitle}
        />
        <SideBar
          handleDrawerOpen={this.handleDrawerOpen}
          open={this.state.open}
          sidebarwidth={sidebarwidth}
          theme={this.props.theme}
          signout={this.signout} />
      </>
    );
  }
}

export default HeaderContainer;