import axios from 'axios';
import cookies from './cookies';
import { toast } from 'react-toastify';
import environment from '../custom/environments';
// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.headers.company = cookies.get('company') || null;
    config.headers.station = cookies.get('station') || null;
    config.headers.timezone = cookies.get('tz') || null;
    config.headers.user = cookies.get('user') || null;
    config.headers.user = cookies.get('user') || null;
    config.headers.station_code = cookies.get('station_code') || null;
    config.baseURL = environment.apiService;
    config.withCredentials = true;
    return config;
  }
);

axios.interceptors.response.use((response) => {
  // if (response && response.config && response.config.method != 'get') {
  //   toast.success(response.data.message);
  // }
  return response;
}, error => {
  console.log('API ERROR=====>', error);
  const statusCode = error.response ? error.response.status : null;
  // if (error.code && error.code == 'ERR_NETWORK') {
  //   toast.error(error.message);
  // }
  if (error) {
    toast.error(error.response && error.response.data.message || error.message);
  }
  if (statusCode === 999 && cookies.get('login')) {
    // logout user
    cookies.remove('login', { path: '/' });
    setTimeout(() => {
      window.location.href = '/login';
    }, 2000);
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
};