import React from 'react';
import { IconButton, } from '@mui/material';
import { StyledCard, } from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import analyticsStyle from './analytics.module.css';
import {  ExcelIcon } from 'custom/constants';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';


const columns = [
  {
    headerName: 'AWB',
    field: 'awb',
    minWidth: 120,
    flex: 1.1,
    sortable: false,      
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      return (
        <div>{`${row.code}-${row.number}`}</div>
      );
    }
  },
  {
    headerName: 'Org',
    field: 'org' ,
    flex: 0.5,
    sortable: false,      
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerName: 'Des',
    field: 'dest' ,
    flex: 0.5,
    sortable: false,      
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerName: 'Pcs',
    field: 'pcs' ,
    flex: 0.5,
    sortable: false,      
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerName: 'Wgt',
    field: 'wgt' ,
    minWidth: 80,
    flex: 0.5,
    sortable: false,      
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerName: 'Out Flt',
    field: 'out_flt',
    minWidth: 100,
    flex: 1,
    sortable: false,      
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      return (
        <div>{`${row.flight_no}/${moment(row.flight_date).format('DD MMM').toUpperCase()}`}</div>
      );
    }
  },
  {
    headerName: 'FWD/INT/RFS',
    field: 'forwarder' ,
    minWidth: 100,
    flex: 1.6,
    sortable: false,      
    align: 'center',
    headerAlign: 'center',
  },
  {
    headerName: 'PRE-SCREEN',
    field: 'rcvd_by' ,
    minWidth: 100,
    flex: 1,
    sortable: false,      
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      let pre_screen = '';
      if(row.pre_screened) {
        pre_screen = 'Yes';
      }
      else{
        pre_screen = 'No';
      }
      return (
        <div>{pre_screen}</div>
      );
    }
  },
  {
    headerName: 'Rcv Time',
    field: 'rcvd_time' ,
    minWidth: 50,
    flex: 1.3,
    sortable: false,      
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      return (
        <div>
          {moment(row.received_date, 'YYYY-MM-DD HH:mm:ss').format('DDMMMYY HH:mm')}
        </div>
      );
    },
  },
];

const Unscreened = (props) => {
  return (
    <StyledCard>
      <div className={`${styles.fSpaceBtw} ${styles.flexWrap}`}>
        <h5 className={analyticsStyle.heading}>Unscreened Shipments</h5>
        <IconButton aria-label='download-excel' className={`${analyticsStyle.fileImg}`} onClick={() => props.handleExport('unscreened')}>
          <ExcelIcon />
        </IconButton>
      </div>
      <div style={{ height: '300px' }}>
        <DataGrid
          rows={props.unsecreened}
          columns={columns}
          pageSizeOptions={[]}
          disableRowSelectionOnClick
          className={`${styles.anayTable}`}
          columnHeaderHeight={34}
          getRowHeight={() => '34px'}
          rowHeight={34}
          showCellVerticalBorder
          showColumnVerticalBorder	
        />
      </div>
    </StyledCard>
  );
};

export default Unscreened;
