import React from 'react';
import { Popover, Typography, RadioGroup, Radio, Stack, FormControl, Checkbox } from '@mui/material';
import { PopoverContent, PopoverFooter, CustomRadio, InputProps, CustomTextField, CustomCheckbox, CustomStyle, CustomButton, PopoverHeadWhite } from 'custom/components';
import { Close as CloseIcon, ArrowDropDown } from '@mui/icons-material';
import Select, { components } from 'react-select';
import styles from 'custom/style.module.css';
import screeningStyles from '../../screen.module.css';

const transferProgram = [
  'AOSSP',
  'FACAOSSP',
  'MSP',
  'ACISP'
];

const popoverStyles = {
  width: '100%', // Set your desired width
  overflowY: 'auto',
  height: '1000px'
};

const locationModal = (props) => {
  const { handleClose, anchorEl, id, open } = props;
  const carrierOptions = props.carrierList.map((item) => (
    {
      value: item.name,
      label: item.name
    }
  ));

  const checksForm = transferProgram.map((item, index) => (
    <CustomCheckbox
      control={
        <Checkbox checked={props.carrierNumber.transfer_programs[index] === item.toLocaleLowerCase()} disabled name='limit_input' />
      }
      label={item}
      key={index}
      id={`${index}`}
    />
  ));

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDown />
      </components.DropdownIndicator>
    );
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose('ccsf')}
      sx={popoverStyles}
      classes={{ paper: screeningStyles.ccsfPopover }}
    >
      <PopoverHeadWhite>
        <Typography variant='subtitle2' component='span'>Prescreen Details</Typography>
        <CloseIcon fontSize='small' onClick={() => handleClose('ccsf')} className={styles.pointer} />
      </PopoverHeadWhite>
      <PopoverContent className={screeningStyles.ccsfPopoverContent}>
        <RadioGroup aria-labelledby='pre-screened' name='pre_screened' defaultValue='ALL' value={props.awb.pre_screened} onChange={props.onChange} row className={`${styles.ml0} ${styles.mb4}`}>
          <Stack direction='row' spacing={2} className={`${styles.fMiddle}`} flexWrap='wrap'>
            <CustomRadio control={<Radio size='small' />} value='notscreened' label='Not Screened' />
            <CustomRadio control={<Radio size='small' />} value='ccsf' label='CCSF' />
            <CustomRadio control={<Radio size='small' />} value='interline' label='Interline Transfer' />
          </Stack>
        </RadioGroup>
        {props.awb.pre_screened == 'ccsf' && <Stack direction='row' justifyContent='space-between' alignItems='flex-start' useFlexGap flexWrap='wrap'>
          <div className={styles.w100}>
            <Stack direction='row'alignItems={'center'} justifyContent={'flex-start'}>
              <div className={styles.w100}>
                <Typography variant='caption' component='label' htmlFor='ccsf_nbr' textAlign={'left'} display={'block'} marginBottom={'0.3rem'}>CCSF Approval Nbr</Typography>
                <FormControl variant='standard' fullWidth>
                  <CustomTextField
                    {...InputProps}
                    variant='outlined'
                    label={false}
                    id='ccsf_nbr'
                    disabled={props.isLoading}
                    fullWidth
                    placeholder=''
                    value={props.awb.security?.ccsf_approval_number || ''}
                    type='text'
                    onChange={(e) => props.handleSecurity(e, 'ccsf_approval_number')}
                    onBlur={props.checkTransporationNumber}
                    name=''
                  />
                </FormControl>
              </div>
              <div className={`${styles.fvMiddle} ${styles.mt4} ${styles.ml2}`}>
                {props.awb?.security?.ccsf_approval_number &&
                      <div className={`${styles.fvMiddle} ${styles.check}`}>{props.checkElement(props.awb?.security?.valid_transportation)}</div>
                }
              </div>
            </Stack>
            {props.awb.security?.ccsf_approval_number && props.awb.security?.ccsf_name ? <Typography variant='caption' component='span' color='primary' fontWeight={600} className={`${screeningStyles.iacName} ${styles.mt2}`}> CCSF Name: {props.awb.security?.ccsf_name} </Typography> : <Typography variant='caption' component='span' color='red' fontWeight={600} className={`${screeningStyles.iacName} ${styles.mt2}`} > Enter Valid CCSF </Typography>}
          </div>
        </Stack>
        }
        {props.awb.pre_screened == 'interline' && <Stack direction='row' justifyContent='space-between' alignItems='flex-start' useFlexGap flexWrap='wrap'>
          <div>
            <Stack direction='column' spacing={3}>
              <div>
                <Typography variant='caption' component='label' htmlFor='ccsf_nbr'  textAlign={'left'} display={'block'} marginBottom={'0.3rem'}>Transferring Carrier</Typography>
                <FormControl variant='standard' fullWidth>
                  <Select
                    className={screeningStyles.select}
                    options={carrierOptions}
                    value={props.carrierNumber.carrier}
                    onChange={(event) => props.handleSetCarrier(event)}
                    placeholder='Enter Transferring Carrier'
                    error={props.carrierNumber.carrier !== ''}
                    components={{ DropdownIndicator }}
                    styles={{
                      ...CustomStyle,
                      control: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '13px',
                        minHeight: '29px !important',
                        height: '29px !important',
                        boxShadow: 'none',
                        border: '1px solid lightgrey',
                      }),
                    }}
                  />
                </FormControl>
              </div>
              <div>
                <Typography variant='caption' component='label'  textAlign={'left'} display={'block'}>Approved Transfer Program</Typography>
                <div className={styles.textFieldWrapper}>

                  {checksForm}

                </div>
              </div>
            </Stack>
          </div>
        </Stack>}
      </PopoverContent>
      <PopoverFooter className={styles.textRight}>
        <CustomButton
          variant='contained'
          size='small'
          onClick={props.updateScreened}
          disabled={props.awb.pre_screened === 'ccsf' ? !(props.awb?.security?.ccsf_approval_number && props.awb?.security?.valid_transportation) : false}
        >
          Update
        </CustomButton>
      </PopoverFooter>
    </Popover>
  );
};

export default locationModal;