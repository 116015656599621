

const local = {
  // name: 'UNISEC',
  name: 'UNISEC DEV',
  apiService: 'http://localhost:8080/',
  s3Url: 'http://localhost:8080/',
  // flightScheduleService: 'http://localhost:4040/',
  // api_key: 'a573b052-2040-43ea-aff8-d7bb596f8dc4',
  // trypodGateway: 'http://localhost:4000/v1/',
  // trypod_api_key: '96e9cd5c-305d-11ee-be56-0242ac120002'
  flightScheduleService: 'https://api.dev.fltschdl.fdsvcs.com/',
  api_key: '2a87aa33-971e-43b4-97a8-5b1375b2542b',
  trypodGateway: 'https://gw-dev.tryfd.com/v1/',
  trypod_api_key: '96e9cd5c-305d-11ee-be56-0242ac120002',
  appUrl: 'https://dev.unisec.fdsvcs.com/',
};

const dev = {
  name: 'UNISEC DEV',
  apiService: 'https://api.dev.unisec.fdsvcs.com/',
  s3Url: 'https://tp-dev-gw-images.s3.us-east-2.amazonaws.com/',
  flightScheduleService: 'https://api.dev.fltschdl.fdsvcs.com/',
  api_key: '2a87aa33-971e-43b4-97a8-5b1375b2542b',
  trypodGateway: 'https://gw-dev.tryfd.com/v1/',
  trypod_api_key: '96e9cd5c-305d-11ee-be56-0242ac120002',
  appUrl: 'https://dev.unisec.fdsvcs.com/',
};

const stage = {
  apiService: 'http://localhost:8080/',
  s3Url: 'http://localhost:8080/'
};

const prod = {
  name: 'UNISEC PROD',
  apiService: 'https://api.unisec.tryfd.com/',
  s3Url: 'https://tp-dev-gw-images.s3.us-east-2.amazonaws.com/',
  flightScheduleService: 'https://api.dev.fltschdl.fdsvcs.com/',
  api_key: '30dd2a5a-0fd8-4161-8983-803172b8ba48',
  trypodGateway: 'https://gw-stage.tryfd.com/v1/',
  trypod_api_key: 'c043f736-3e35-44d6-88c1-d57c44a9db99',
  appUrl: 'https://unisec.tryfd.com/',
};

let processEnv = local;

switch (process.env.REACT_APP_STAGE) {
  case 'prod':
    processEnv = prod;
    break;
  case 'dev':
    processEnv = dev;
    break;
  case 'stage':
    processEnv = stage;
    break;
  default: break;
}

export default processEnv;