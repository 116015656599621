
/* ===== Header ===== */
/* ===== Sidebar ===== */

/* ===== Header ===== */
export const openedMixin = (theme, sidebarwidth) => ({
  backgroundColor: theme.palette.primary.main,
  width: sidebarwidth,
  borderRadius: 'var(--headerbr) 0 0 0',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    backgroundColor: '#07243f',
    // height: 'calc(100% - var(--splayout) - var(--headerheight))',
    top: 0,
    bottom: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    borderRadius: '0 50% 50% 0',
    marginLeft: '-128%',
    zIndex: -1,
    // border: '1px solid #51a65f6e',
  }
});

/* ===== Sidebar ===== */
export const sidebarwidth = '310px';

export const headerBox = {
  p: 2,
  height: 'inherit',
  width: sidebarwidth,
  color: 'white',
};
export const sideBarLogo = {
  left: '50%',
  transform: 'translate(-50%, 100px)',
  transition: '1s',
  width: '70px',
  height: '70px',
  zIndex: 11362, // change zindex
  borderRadius: '50%',
  backgroundColor: 'var(--primaryblue)',
  padding: '10px',
  paddingRight: '4px',
  boxShadow: '0px 2px 2px white',
  position: 'absolute',
  opacity: '1'
};
