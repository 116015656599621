import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import environment from 'custom/environments';
import styles from '../../../custom/style.module.css';

class PdfViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      currentPage: 0,
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handlePageClick = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  render() {
    const { numPages } = this.state;

    return (
      <div>
        <Document
          file={environment.apiService + 'document?key=' + this.props.src}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
              className={styles.pdfClass}
            />
          ))}
        </Document>
      </div>
    );
  }
}

export default PdfViewer;