import React from 'react';
import { Stack, Grid, Checkbox, FormGroup, Typography, Box } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, TableBadge, DataGridProps, CustomCheckbox } from '../../../../../custom/components';
import k9Styles from './k9.module.css';
import styles from '../../../../../custom/style.module.css';
import { EditIcon, LockIcon, UnlockIcon, LocationIcon, DeleteIcon, AddIcon } from '../../../../../custom/constants';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import ChecklistModal from './ChecklistModal';
import K9Modal from './K9Modal';
import DeactiveModal from './DeactiveModal';
import LocationContainer from 'components/pages/common/location/LocationContainer';
import DocModalContainer from 'components/pages/common/docModal/DocModalContainer';

const K9Companies = (props) => {
  const companyColumns = [
    {
      field: 'name',
      headerName: 'Company Name',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'att',
      headerName: 'Attachments',
      type: 'text',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <div className='d-flex justify-content-between align-items-center' style={{ cursor: 'pointer' }}>
            <TableBadge color='error' badgeContent={row.docs && row.docs.length}>
              <AttachFileIcon fontSize='small' onClick={() => props.handleDoc(row)} />
            </TableBadge>
          </div>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      // minWidth: 100, 
      flex: 1,
      width: 90,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            {row.active ? (<LockIcon title='Deactivate K9 Company' onClick={() => props.handleOpen('deactive_k9', 'deactive', row)} />) : (<UnlockIcon title='Reactivate K9 Company' onClick={() => props.handleOpen('deactive_k9', 'active', row)} />)}
            <TableBadge color='error' badgeContent={row.stations && row.stations.length}>
              <LocationIcon onClick={(e) => props.handleLocation('k9_company', true, row, e.target)} />
            </TableBadge>
            <EditIcon onClick={() => { props.handleOpen('k9', 'edit', row); }} />
          </Stack>

        );
      }
    },
  ];

  const checkListColumns = [
    {
      field: 'txt',
      headerName: 'Check Description ',
      type: 'email',
      minWidth: 100,
      flex: 1,
    },
    {
      // field: 'check_options',
      headerName: 'Check Options ',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      renderCell: (data) => {
        return (
          <Stack direction='row' justifyContent='center' spacing={2} className={`${styles.fvMiddle}`}>
            <FormGroup aria-label='check-options' row className={styles.textCenter}>
              <CustomCheckbox control={<Checkbox size='small' checked={data.row.opt_yes} className={styles.py0} />} label='Yes' />
              <CustomCheckbox control={<Checkbox size='small' checked={data.row.opt_no} className={styles.py0} />} label='No' />
              <CustomCheckbox control={<Checkbox size='small' checked={data.row.opt_na} className={styles.py0} />} label='N/A' />
            </FormGroup>
          </Stack>);
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      // minWidth: 100, 
      flex: 1,
      width: 90,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <TableBadge color='error' badgeContent={row.stations && row.stations.length}>
              <LocationIcon onClick={(e) => props.handleLocation('checklist', true, row, e.target)} />
            </TableBadge>
            <EditIcon onClick={() => { props.handleOpen('checklist', 'edit', row); }} />
            <DeleteIcon onClick={(e) => props.handleDelete(row.id, e.target)} />
          </Stack>
        );
      }
    },
  ];


  return (
    <div>
      <LocationContainer
        anchorEl={props.anchorEl}
        open={props.location}
        type={props.locationType}
        handleClose={props.handleLocation}
        stations={props.stations}
        name={props.station.txt || props.station.name}
        data={props.station}
      />
      <DocModalContainer />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <K9Modal
              modal={props.modal}
              action={props.action}
              k9Fields={props.k9Fields}
              k9Errors={props.k9Errors}
              addK9={props.addK9}
              editK9={props.editK9}
              onChangeK9={props.onChangeK9}
              onClose={props.onClose}
            />
            <DeactiveModal
              modal={props.modal}
              action={props.action}
              k9Fields={props.k9Fields}
              k9Errors={props.k9Errors}
              active={props.active}
              deactive={props.deactive}
              onChangeK9={props.onChangeK9}
              onClose={props.onClose}
            />
            <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
              <StyledCardTitle gutterBottom sx={{ marginBottom: '2rem' }}>3rd Party K9 Companies</StyledCardTitle>
              <AddIcon onClick={() => props.handleOpen('k9', 'add')} className={styles.mbauto} />
            </div>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${k9Styles.companyTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.k9}
                  columns={companyColumns}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <ChecklistModal
              modal={props.modal}
              action={props.action}
              checklistFields={props.checklistFields}
              checklistErrors={props.checklistErrors}
              addChecklist={props.addChecklist}
              editChecklist={props.editChecklist}
              onChangeChecklist={props.onChangeChecklist}
              onClose={props.onClose}
            />
            <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
              <StyledCardTitle variant='cardTitle'>
                <span>Define checklist for transfer document</span>
                <Typography fontSize={'0.65rem'} >
                  Note: The checks that you define here will appear on every K9 transfer document for the user to complete when finalizing the transfer document.
                </Typography>
              </StyledCardTitle>
              <AddIcon onClick={() => props.handleOpen('checklist', 'add')} className={styles.mbauto} />
            </div>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${k9Styles.k9TableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.checklist}
                  columns={checkListColumns}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default K9Companies;