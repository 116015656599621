import React from 'react';
import { Divider, Dialog } from '@mui/material';
import styles from '../../../custom/style.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, CustomDialogContent } from '../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const AwbModal = (props) => {
  return (
    <div>
      <Dialog 
        aria-labelledby='form-dialog-title'
        open={props.modal} 
        onClose={props.onClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <CustomDialogTitle id='form-dialog-title'>
          AWB Already Exists!
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <Divider />
        <CustomDialogContent>
            Do you wish to create a duplicate record of this airwaybill or add/edit the existing record?
        </CustomDialogContent>
        <CustomDialogAction className={`${styles.fSpaceBtw}`}>
          <CustomButton variant='contained' onClick={props.attachAwb}>Add/Edit Existing</CustomButton>
          <CustomButton variant='contained' onClick={props.newAWB}> Create Duplicate</CustomButton>
        </CustomDialogAction>
      </Dialog>
    </div>
  );
};
export default AwbModal;