import React from 'react';
import { Box, Typography, TextField, Stack, InputAdornment } from '@mui/material';
import FDLogo from '../../../../assets/images/FD_Logo.png';
import { CustomButton } from '../../../../custom/components';
import loginStyles from './../login.module.css';
import styles from '../../../../custom/style.module.css';
import { Check, Clear, Lock, Phone } from '@mui/icons-material';
import theme from 'custom/theme';
import { appName } from 'custom/constants';

const successIcon = {
  color: 'green',
  fontSize: '14px',
  paddingTop: '1px',
  marginRight: '4px',
};

const errorIcon = {
  color: 'red',
  fontSize: '14px',
  paddingTop: '1px',
  marginRight: '4px',
};

const ChangeDefaultPassword = (props) => {
  return(
    <Box className={loginStyles.loginWrapper}>
      <img src={FDLogo} alt='logo' className={`${loginStyles.loginLogo} ${styles.mb3}`} />
      <Stack spacing={3} alignItems='center' className={`${styles.w100} ${styles.mb4}`}>
        <Typography fontSize={'1.8rem'} component='div' lineHeight='normal' fontWeight={500} 
          className={`${loginStyles.appName} ${loginStyles.loginHeader} ${styles.mb0}`} color='primary'> 
        Welcome to  {appName}      
          {/* <Typography fontSize={'inherit'} component='span' lineHeight='normal' fontWeight={'inherit'} 
            className={`${loginStyles.loginHeader} ${styles.mb3}`} color={theme.palette.secondary.main}> 
            {appName}
          </Typography> */}
        </Typography>
        <Typography fontSize={'0.85rem'} component='div' lineHeight='normal' letterSpacing={'0.4px'}
          className={`${loginStyles.loginHeader} ${styles.mb3}`} color={theme.palette.common.black}> 
       This is your first login and you must change your default password
        </Typography>
      </Stack>
      
      <form onSubmit={props.onSubmit} className={styles.w100}>
        <Stack spacing={5} alignItems='center'>
          <TextField
            hiddenLabel
            id='new_password'
            variant='standard'
            className={styles.w100}
            placeholder='Password'
            type='password'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='new_password'
            value={props.fields.new_password}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Lock color='primary' className={ loginStyles.materialIcon } />
                </InputAdornment>
              ),
            }}
            error={props.errors.new_password}
            helperText={props.errors.new_password}
          />
          <TextField
            hiddenLabel
            id='confirm_password'
            variant='standard'
            className={styles.w100}
            placeholder='Confirm Password'
            type='password'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='password'
            value={props.fields.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Lock color='primary' className={ loginStyles.materialIcon } />
                </InputAdornment>
              ),
            }}
            error={props.errors.password}
            helperText={props.errors.password}
          />
          <TextField
            hiddenLabel
            id='phone'
            variant='standard'
            className={styles.w100}
            placeholder='Phone number (Optional)'
            type='number'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            // onChange={props.onChange}
            // name='password'
            // value={props.fields.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Phone color='primary' className={ loginStyles.materialIcon } />
                </InputAdornment>
              ),
            }}
            // error={props.errors.password}
            // helperText={props.errors.password}
          />
          <CustomButton variant='contained' className={`${styles.w100} ${loginStyles.submitBtn}`} type='submit' disabled={props.submit} >Submit</CustomButton>
          <div className={loginStyles.passwordPolicyContainer}>
            <div className={loginStyles.passwordPolicyHeader}>
              Password Policy:
            </div>
            <div className={loginStyles.passwordPolicyConditions}>
              {props.fields.passcheck1 ?
                <Check style={successIcon} /> :
                <Clear style={errorIcon} />
              }
              <span className={loginStyles.greyText}>Must be at least 8 characters</span>
            </div>
            <div className={loginStyles.passwordPolicyConditions}>
              {props.fields.passcheck2 ?
                <Check style={successIcon} />
                :
                <Clear style={errorIcon} />}
              <span className={loginStyles.greyText}>Must contain one upper case letter, one number and a special character</span>
            </div>
          </div>
        </Stack>
      </form>
    </Box>
  );
};

export default ChangeDefaultPassword;