import { appName } from './constants';

export function Common(component) {
  let titleIncludes = (page) => page === window.location.pathname;
  // let titleIncludes = (page) => window.location.pathname.indexOf(page) !== -1;

  let pageTitle = (title) => {
    component.setState({ pageTitle: title });
    document.title = title ? title + ' | ' + appName : appName;
  };  
  let title = (title) => {
    document.title = title ? title + ' | ' + appName : appName;
  };  

  let text = window.location.pathname;
  let pattern = /admin/;
  let result = pattern.test(text);

  switch(true) {
    case titleIncludes('/login'):
      title('Login');
      break;
    case titleIncludes('/'):
      pageTitle('Screening Input');
      break;
    case result === true:
      pageTitle('Admin');
      break;
    case titleIncludes('/docs_stream'):
      pageTitle('Docs Stream');
      break;
    case titleIncludes('/screening'):
      pageTitle('Screening');
      break;
    case titleIncludes('/transfer_k9'):
      pageTitle('Transfer to K9');
      break;
    case titleIncludes('/screening_reports'):
      pageTitle('Screening Reports');
      break;
    case titleIncludes('/doc_archive'):
      pageTitle('Doc Archive');
      break;
    case titleIncludes('/analytics'):
      pageTitle('Analytics');
      break;
    default:
      pageTitle('');
  }
}