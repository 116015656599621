import { SET_SCREEN } from '../actions/actionTypes';

const initialState = {};

export const screenReducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_SCREEN:
      return { ...action.data };
    default:
      return state;
  }
};