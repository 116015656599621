import { SET_COMPANY_STATIONS } from '../actions/actionTypes';

const initialState = [];

export const companyReducer = (state = initialState, action) => {

  switch (action.type) {

    case SET_COMPANY_STATIONS:
      return [...action.data];
    default:
      return state;
  }
};