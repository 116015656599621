import React from 'react';
import { Box, Typography, TextField, Stack, InputAdornment } from '@mui/material';
import FDLogo from '../../../assets/images/FD_Logo.png';
import { CustomButton } from '../../../custom/components';
import loginStyles from './login.module.css';
import styles from '../../../custom/style.module.css';
import { Apartment, Lock, PersonRounded } from '@mui/icons-material';
import { appName } from 'custom/constants';

const Login = (props) => {
  return(
    <Box className={loginStyles.loginWrapper}>
      <img src={FDLogo} alt='logo' className={`${loginStyles.loginLogo} ${styles.mb2}`} />
      <Stack spacing={3} alignItems='center' className={`${styles.w100} ${styles.mb4}`}>
        <Typography variant='h5' component='span' lineHeight='normal' className={`${loginStyles.appName} ${styles.mb2}`} color='text.secondary'>{appName}</Typography>
        {/* <Typography variant='h6' component='span' lineHeight='normal'>LOGIN</Typography> */}
      </Stack>
      
      <form onSubmit={props.onSubmit} className={styles.w100}>
        <Stack spacing={5} alignItems='center'>
          <TextField
            hiddenLabel
            id='company'
            variant='standard'
            className={styles.w100}
            placeholder='Company Name'
            type='text'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='company'
            value={props.fields.company}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Apartment color='primary' className={ loginStyles.materialIcon } />
                </InputAdornment>
              ),
            }}
            error={props.errors.company}
            helperText={props.errors.company}
          />
          <TextField
            hiddenLabel
            id='username'
            variant='standard'
            className={styles.w100}
            placeholder='User Name'
            type='text'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='username'
            value={props.fields.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonRounded color='primary' className={ loginStyles.materialIcon } />
                </InputAdornment>
              ),
            }}
            error={props.errors.username}
            helperText={props.errors.username}
          />
          <TextField
            hiddenLabel
            id='password'
            variant='standard'
            className={styles.w100}
            placeholder='Password'
            type='password'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='password'
            value={props.fields.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Lock color='primary' className={ loginStyles.materialIcon } />
                </InputAdornment>
              ),
            }}
            error={props.errors.password}
            helperText={props.errors.password}
          />
          <CustomButton variant='contained' className={`${styles.w100} ${loginStyles.submitBtn}`} type='submit' disabled={props.submit} >Submit</CustomButton>
          <Typography variant='caption' component='span' lineHeight='normal' className={styles.link}>Forgot Password</Typography>
        </Stack>
      </form>
    </Box>
  );
};

export default Login;