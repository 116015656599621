import React from 'react';
import { FormControl, Grid, Stack, Checkbox, FormHelperText, MenuItem, Box } from '@mui/material';
import { CustomCheckbox, CustomLabelCheckbox, CustomDatePicker, CustomTimePicker, CustomInputLabel, CustomSelect, CustomTextField, StyledCard, StyledCardTitle, StyledDataGrid, CustomButton, DataGridProps, InputProps, CustomStyle, SelectInputProps } from 'custom/components';
import { EditIcon, BellIcon, DeleteIcon, DateFormat } from '../../../../../custom/constants';
import screenStyles from './screeeningdetails.module.css';
import styles from '../../../../../custom/style.module.css';
import dayjs from 'dayjs';
import AlarmDetails from './AlarmDetails';
import moment from 'moment';
import Select, { components } from 'react-select';
import { ArrowDropDown } from '@mui/icons-material';
import cookies from 'utils/cookies';
import { getData } from '../awbinfo/AwbInfo';

const isDisabled = !['Manager', 'System Admin', 'Supervisor'].includes(cookies.get('role'));

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDown />
    </components.DropdownIndicator>
  );
};

const ScreeningDetails = (props) => {
  const columns = [
    {
      field: 'pcs',
      headerName: 'Pieces',
      type: 'text',
      minWidth: 60,
      flex: 1
    },
    {
      field: 'measure',
      headerName: 'Measure',
      type: 'text',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row.measure.type;
      }
    },
    {
      field: 'method',
      headerName: 'Method',
      type: 'text',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row.method.name;
      }
    },
    {
      field: 'screener',
      headerName: 'Screener',
      type: 'text',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.user ? `${row?.user?.first_name} ${row?.user?.last_name}` : row?.k9_company?.name;
      }
    },
    {
      field: 'screen_date',
      headerName: 'Screening Date / Time',
      type: 'text',
      minWidth: 140,
      flex: 1,
      renderCell: ({ row }) => {
        return `${moment(row.screen_date).format('DD MMM YY').toUpperCase()} ${row.screen_time}`;
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            {row.alarmed ? (
              <div className={styles.redSvg} onClick={(e) => props.handleAlert(true, row, e.target)}>
                <BellIcon />
              </div>
            ) : (<div className={styles.greySvg}> <BellIcon /> </div>)}
            <EditIcon onClick={() => props.onEdit(row.id)} disabled={isDisabled && row.alarmed} />
            <DeleteIcon onClick={() => props.deleteScreen(row.id)} disabled={ isDisabled && row.alarmed} />
          </Stack>
        );
      }
    },
  ];

  const getMeasure = (id) => {
    if (id) {
      let value = null;
      props.measures && props.measures.map((m) => {
        if (m.id == props.screenFields.measureId) {
          value = {
            label: m.type,
            valueL: m.id
          };
        }
      });
      return value;
    } else {
      return null;
    }
  };

  const getMethod = (id) => {
    if (id) {
      let value = null;
      props.methods && props.methods.map((m) => {
        if (m.id == props.screenFields.methodId) {
          value = {
            value: m.id,
            label: m.name
          };
        }
      });
      return value;
    } else {
      return null;
    }
  };

  const getUser = (id) => {
    if (id) {
      let value = null;
      props.users && props.users.map((m) => {
        if (m.id == props.screenFields.user) {
          value = {
            value: m.id,
            label: m.name
          };
        }
      });
      return value;
    } else {
      return null;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
        <StyledCard>
          <StyledCardTitle gutterBottom className={styles.mb2}>
            Screening Input</StyledCardTitle>
          <form onSubmit={props.addScreen}>
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xl={4} lg={4} md={3} sm={6} xs={12}>
                <CustomInputLabel htmlFor='pcs'>Pcs Screened</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  id='pcs'
                  autoFocus
                  fullWidth
                  placeholder='Pcs'
                  type='number'
                  disabled={!getData()}
                  onChange={props.onChange}
                  name='pcs'
                  value={props.screenFields.pcs || 0}
                  error={props.screenErrors.pcs}
                  helperText={props.screenErrors.pcs}
                />
              </Grid>

              <Grid item xl={8} lg={8} md={3} sm={6} xs={12} >
                <CustomInputLabel>Security Measure Applied</CustomInputLabel>
                <FormControl variant='standard' fullWidth error={props.screenErrors.measureId}>
                  <Select
                    className='basic-single'
                    classNamePrefix='select'
                    onChange={(selectedOption) => {
                      props.onChange({
                        target: {
                          name: 'measureId',
                          value: selectedOption.value
                        }
                      });
                    }}
                    isDisabled={!getData()}
                    name='measureId'
                    value={getMeasure(props.screenFields.measureId)}
                    options={props.measures && props.measures.map(m => ({
                      value: m.id,
                      label: m.type
                    }))}
                    components={{ DropdownIndicator }}
                    styles={CustomStyle}
                  />
                  <FormHelperText>{props.screenErrors.measureId}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={3} sm={12} xs={12} >
                <CustomInputLabel>Method</CustomInputLabel>
                <FormControl variant='standard'
                  fullWidth
                  error={props.screenErrors.methodId}
                >
                  <Select
                    className='basic-single'
                    classNamePrefix='select'
                    onChange={(selectedOption) => {
                      props.onChange({
                        target: {
                          name: 'methodId',
                          value: selectedOption.value
                        }
                      });
                    }}
                    isDisabled={!getData()}
                    name='methodId'
                    value={getMethod(props.screenFields.methodId)}
                    options={props.methods && props.methods.map(m => ({
                      value: m.id,
                      label: m.name
                    }))}
                    components={{ DropdownIndicator }}
                    styles={CustomStyle}
                  />
                  <FormHelperText>{props.screenErrors.methodId}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={3} sm={6} xs={12}>
                <Stack direction='row' justifyContent='space-between' className={styles.labelCheckboxContainer}>
                  <CustomInputLabel htmlFor='screened_flts'>Screened for flight</CustomInputLabel>
                  <CustomLabelCheckbox name='book' checked={!!props.screenFields.book} onChange={props.onChange} control={<Checkbox size='small' />} label='As Booked' />
                </Stack>
                <CustomTextField
                  {...InputProps}
                  id='screened_flts'
                  autoFocus
                  fullWidth
                  placeholder=''
                  type=''
                  onChange={props.onChange}
                  name='flight_no'
                  value={props.screenFields.flight_no || ''}
                  error={props.screenErrors.flight_no}
                  helperText={props.screenErrors.flight_no}
                  disabled={props.screenFields.book || !getData()}
                />
              </Grid>

              <Grid item container xl={6} lg={6} md={3} sm={6} xs={12} display='flex' direction='column' justifyContent='space-between'>
                <CustomInputLabel>Flight Date</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      fullWidth: true,
                      placeholder: 'Flight Date'
                    }, 
                  }}
                  className={styles.w100}
                  onChange={(v) => props.onChange({
                    target: {
                      type: 'date',
                      name: 'flight_date',
                      value: v?.$d ? moment(v.$d).format('YYYY-MM-DD') : null
                    }
                  })}
                  format={DateFormat}
                  name='flight_date'
                  value={dayjs(props.screenFields.flight_date) || ''}
                  error={props.screenErrors.flight_date}
                  helperText={props.screenErrors.flight_date}
                  disabled={props.screenFields.book || !getData()}
                />
              </Grid>

              <Grid item xl={12} lg={12} md={6} sm={12} xs={12} >
                <Stack direction='row' justifyContent='space-between' className={styles.labelCheckboxContainer}>
                  <CustomInputLabel>Screener</CustomInputLabel>
                  <CustomLabelCheckbox name='else' onChange={props.onChange} checked={props.editUser ? props.screenFields.user?.id != cookies.get('user') : !!props.screenFields.else} control={<Checkbox size='small' />} label='Screened by someone else' />
                </Stack>
                <FormControl variant='standard' fullWidth>
                  <Select
                    className='basic-single'
                    classNamePrefix='select'
                    onChange={(selectedOption) => {
                      props.onChange({
                        target: {
                          name: 'user',
                          value: selectedOption.value
                        }
                      });
                    }}
                    type='select'
                    name='user'
                    isDisabled={!props.screenFields.else || !getData()}
                    value={getUser(props.screenFields?.user)}
                    options={props.users && props.users.map(m => ({
                      value: m.id,
                      label: m.name
                    }))}
                    components={{ DropdownIndicator }}
                    styles={CustomStyle}
                  />
                  <FormHelperText>{props.screenErrors.user}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={3} sm={6} xs={12}>
                <CustomInputLabel>Screening Date</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      fullWidth: true,
                      placeholder: 'Screening Date'
                    }, 
                  }}
                  className={styles.w100}
                  onChange={(v) => props.onChange({
                    target: {
                      type: 'date',
                      name: 'screen_date',
                      value: v?.$d ? dayjs(v.$d).format('YYYY-MM-DD') : null
                    }
                  })}
                  format={DateFormat}
                  name='screen_date'
                  disabled={!getData()}
                  value={dayjs(props.screenFields.screen_date, 'YYYY-MM-DD')}
                  error={props.screenErrors.screen_date}
                  helperText={props.screenErrors.screen_date}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={3} sm={6} xs={12} >
                <CustomInputLabel>Screening Time</CustomInputLabel>
                <CustomTimePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      fullWidth: true,
                      placeholder: 'Screening Time'
                    }, 
                  }}
                  className={styles.w100}
                  ampm={false}
                  onChange={(v) => {
                    props.onChange({
                      target: {
                        type: 'time',
                        name: 'screen_time',
                        value: v ? v.format('HH:mm') : null
                      }
                    });
                  }}
                  format='HH:mm'
                  name='screen_time'
                  disabled={!getData()}
                  value={dayjs(props.screenFields.screen_time || moment().format('HH:mm'), 'HH:mm')}
                  error={props.screenErrors.screen_time}
                  helperText={props.screenErrors.screen_time}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={3} sm={6} xs={12} display='flex' alignItems='center'>
                <CustomCheckbox name='alarmed' onChange={props.onChange} checked={!!props.screenFields.alarmed} control={<Checkbox size='small' />} label='Screening Alarm' />
              </Grid>

              <Grid item xl={6} lg={6} md={3} sm={6} xs={12} >
                <CustomInputLabel id='alarm-resolution-label'>Alarm Resolution Attempt</CustomInputLabel>
                <FormControl variant='standard'
                  fullWidth
                  error={props.screenErrors.alarmId}
                >
                  <CustomSelect
                    {...SelectInputProps}
                    labelId='alarm-resolution-label'
                    name='alarmId'
                    onChange={props.onChange}
                    value={props.screenFields.alarmId || ''}
                    disabled={!props.screenFields.alarmed || !getData()}
                  >
                    {props.alarms && props.alarms.map((m) => (
                      <MenuItem
                        key={m.name}
                        value={m.id}
                      >
                        {m.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <FormHelperText>{props.screenErrors.alarmId}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={3} sm={6} xs={12} >
                <CustomInputLabel id='is-alarm-resolved-label'>Alarm Resolved?</CustomInputLabel>
                <FormControl variant='standard'
                  fullWidth
                  error={props.screenErrors.resolved}
                >
                  <CustomSelect
                    {...SelectInputProps}
                    labelId='is-alarm-resolved-label'
                    name='resolved'
                    onChange={props.onChange}
                    value={props.screenFields.resolved || ''}
                    disabled={!props.screenFields.alarmed || !getData()}
                  >
                    <MenuItem value={'Yes'}>Yes</MenuItem>
                    <MenuItem value={'No'}>No</MenuItem>
                  </CustomSelect>
                  <FormHelperText>{props.screenErrors.resolved}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={3} sm={6} xs={12}>
                <CustomInputLabel htmlFor='remarks'>Remarks</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  id='remarks'
                  autoFocus
                  fullWidth
                  placeholder='Remarks'
                  onChange={props.onChange}
                  name='remark'
                  disabled={!getData()}
                  value={props.screenFields.remark || ''}
                  error={props.screenErrors.remark}
                  helperText={props.screenErrors.remark}
                />
              </Grid>
              <Grid item xs={12}>
                {props.screenFields.id ?
                  (<Stack spacing={2}>
                    <CustomButton type='button' variant='contained' className={styles.w100} onClick={props.editScreen} >Update Screened Pieces</CustomButton>
                    <CustomButton type='button' variant='contained' className={styles.w100} onClick={() => props.onEdit(false)} >Cancel</CustomButton>
                  </Stack>)
                  :
                  <CustomButton type='submit' variant='contained' className={styles.w100} disabled={(!props.add && !props.screenFields.id) || !getData()}>Add to Screened Pieces</CustomButton>
                }
              </Grid>
            </Grid>
          </form>
        </StyledCard>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
        <AlarmDetails
          alarms={props.alarms}
          anchorEl={props.anchorEl}
          handleClose={props.handleAlert}
          alarmModal={props.alarmModal}
          alarmFields={props.alarmFields}
          onChangeAlarm={props.onChangeAlarm}
          updateAlarm={props.updateAlarm}
        />
        <StyledCard>
          <StyledCardTitle gutterBottom className={styles.mb2}>Screened Pieces</StyledCardTitle>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter} ${screenStyles.screensTableOuter}`}>
              <StyledDataGrid
                {...DataGridProps}
                rows={props.screens}
                columns={columns}
                className={`${styles.borderedRow}`}
              />
            </Box>
          </div>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default ScreeningDetails;