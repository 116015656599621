import React from 'react';
import { Stack, Grid, Radio, Box } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, TableBadge, DataGridProps } from '../../../../../custom/components';
import measureStyle from './measure.module.css';
import styles from '../../../../../custom/style.module.css';
import { EditIcon, LocationIcon, DeleteIcon, AddIcon } from '../../../../../custom/constants';
import AddMeasuresModal from './AddMeasuresModal';
import AddMethodsModal from './AddMethodsModal';
import AlarmResModal from './AlarmResModal';
import LocationContainer from 'components/pages/common/location/LocationContainer';

const MeasureMethods = (props) => {
  const measuresCol = [
    {
      headerName: '',
      width: 50,
      renderCell: ({ row }) => {
        return (
          <Radio size='small' className={styles.p0}
            checked={props.active === row.id}
          />
        );
      }
    },
    {
      field: 'type',
      headerName: 'Measure',
      // minWidth: 100,
      flex: 1,
    },
    {
      field: 'airlines',
      headerName: 'Applicable Airline(s)',
      // minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <span>{row.airlines && row.airlines.map(a => a.code + ',')}</span>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      // minWidth: 100, 
      flex: 1,
      // width: 90,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <EditIcon onClick={() => { props.handleOpen('measure', 'edit', row); }} />
            {/* <DeleteIcon onClick={(e) => { props.handleDelete('measure', row.id, e.target); }} /> */}
          </Stack>
        );
      }
    },
  ];

  const screenedCol = [
    {
      field: 'name',
      headerName: 'Methods',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'code',
      headerName: 'Code',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      // minWidth: 100, 
      flex: 1,
      width: 90,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <TableBadge color='error' badgeContent={row.stations && row.stations.length}>
              <LocationIcon onClick={(e) => props.handleLocation('method/' + props.active, true, row, e.target)} />
            </TableBadge>
            <EditIcon onClick={() => { props.handleOpen('method', 'edit', row); }} />
            <DeleteIcon onClick={(e) => { props.handleDelete('method', row.id, e.target); }} />
          </Stack>
        );
      }
    },
  ];

  const alarmResCols = [
    {
      field: 'name',
      headerName: 'Resolution',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'code',
      headerName: 'Code',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      // minWidth: 100, 
      flex: 1,
      width: 90,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <TableBadge color='error' badgeContent={row.stations && row.stations.length}>
              <LocationIcon onClick={(e) => props.handleLocation('alarm', true, row, e.target)} />
            </TableBadge>
            <EditIcon onClick={() => { props.handleOpen('alarm', 'edit', row); }} />
            <DeleteIcon onClick={(e) => { props.handleDelete('alarm', row.id, e.target); }} />
          </Stack>
        );
      }
    },
  ];


  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <StyledCard>
            <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
              <StyledCardTitle gutterBottom variant='cardTitle'>Define Measures</StyledCardTitle>
              {/* <IconButton className={styles.addIcon} onClick={() => props.handleOpen('measure', 'add')}>
                <AddIcon />
              </IconButton> */}
            </div>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${measureStyle.meausreTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.measure}
                  columns={measuresCol}
                  onRowClick={({ row }) => props.fetchMethod(row.id)}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <StyledCard>
            <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
              <StyledCardTitle gutterBottom variant='cardTitle'> Define Methods-{props.active === 1 ? 'Screened' : props.active === 2 ? 'Exemption PAX' : props.active === 3 ?  'Exemption Freighter': 'Freighter Screening'}</StyledCardTitle>
              {props.active && (
                <AddIcon title='Add methods' onClick={() => props.handleOpen('method', 'add')} />
              )}
            </div>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${measureStyle.screenedTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.method}
                  columns={screenedCol}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <StyledCard>
            <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
              <StyledCardTitle gutterBottom variant='cardTitle'>Alarm Resolutions</StyledCardTitle>
              <AddIcon onClick={() => props.handleOpen('alarm', 'add')} />
            </div>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${measureStyle.alarmTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.alarm}
                  columns={alarmResCols}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
      </Grid>

      <LocationContainer
        anchorEl={props.anchorEl}
        open={props.location}
        type={props.locationType}
        handleClose={props.handleLocation}
        stations={props.stations}
        name={props.station.name || props.station.type}
        data={props.station}
      />

      <AddMeasuresModal
        modal={props.modal}
        action={props.action}
        handleOpen={props.handleOpen}
        onClose={props.onClose}
        airline={props.airline}
        measureFields={props.measureFields}
        measureErrors={props.measureErrors}
        onChangeMeasure={props.onChangeMeasure}
        addMeasure={props.addMeasure}
        editMeasure={props.editMeasure}
      />

      <AddMethodsModal
        modal={props.modal}
        action={props.action}
        onClose={props.onClose}
        methodFields={props.methodFields}
        methodErrors={props.methodErrors}
        onChangeMethod={props.onChangeMethod}
        addMethod={props.addMethod}
        editMethod={props.editMethod}
      />

      <AlarmResModal
        modal={props.modal}
        action={props.action}
        onClose={props.onClose}
        alarmFields={props.alarmFields}
        alarmErrors={props.alarmErrors}
        onChangeAlarm={props.onChangeAlarm}
        addAlarm={props.addAlarm}
        editAlarm={props.editAlarm}
      />
    </div>
  );
};

export default MeasureMethods;