import React from 'react';
import WhiteList from './WhiteList';
import events from 'utils/evemts';
import http from 'utils/http';
import { setDeleteAction } from 'store/actions/deleteAction';
import { connect } from 'react-redux';
class WhiteListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      whiteListModal: false,
      mailFields: {},
      mailErrors: {},
      action: null,
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchMail();
    this.eventSource = events('admin', 'mail');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.action == 'add') {
        this.setState({ data: [...this.state.data, msg.data] });
      }
      if (msg.action == 'update' || msg.action == 'delete') {
        const data = [...this.state.data];
        const index = data.findIndex(d => msg.data && d.id == msg.data.id);
        if (msg.action == 'update') {
          if (index > -1) {
            data[index] = msg.data;
            this.setState({ data });
          } else {
            this.setState({ data: [...this.state.data, msg.data] });
          }
        }
        if (msg.action == 'delete') {
          if (index > -1) {
            data.splice(index, 1);
            this.setState({ data });
          }
        }
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  fetchMail = () => {
    http.get('/doc_mail').then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ data: data.data });
      }
    });
  };

  onChange = (e) => {
    this.setState({
      mailFields: {
        ...this.state.mailFields,
        [e.target.name]: e.target.value
      }
    });
  };

  validation = () => {
    const mailFields = { ...this.state.mailFields };
    const error = {};
    let formValid = true;
    if (!mailFields.mail || !(/\S+@\S+\.\S+/).test(mailFields.mail)) {
      formValid = false;
      error.first_name = 'Please enter valid mail.';
    }
    this.setState({ mailErrors: error });
    return formValid;
  };

  addMail = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.mailFields };
    if (this.validation()) {
      http.post('/doc_mail', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            whiteListModal: false,
            mailFields: {},
            mailErrors: {},
            action: null,
          });
        }
      });
    }
  };

  editMail = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.mailFields };
    if (this.validation()) {
      http.put('/doc_mail/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            whiteListModal: false,
            mailFields: {},
            mailErrors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteMail = (id) => {
    http.delete('/doc_mail/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete({
          modal: false,
          id: false,
          anchorEl: false,
          onConfirm: () => { },
        });
      }
    });
  };
  handleDelete = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteMail(id),
    });
  };

  handleOpen = (action, data = {}) => {
    this.setState({
      action,
      mailFields: data,
      whiteListModal: true
    });
  };

  onClose = () => {
    this.setState({ whiteListModal: false });
  };

  render() {
    return (
      <>
        <WhiteList
          data={this.state.data}
          whiteListModal={this.state.whiteListModal}
          action={this.state.action}
          mailFields={this.state.mailFields}
          mailErrors={this.state.mailErrors}
          onChange={this.onChange}
          addMail={this.addMail}
          editMail={this.editMail}
          handleDelete={this.handleDelete}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
        />
      </>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(null, mapDispatchToProps)(WhiteListContainer);