import React from 'react';
import { Box, Checkbox, FormLabel, Stack, TextField, RadioGroup, Radio, Grid, } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, TableBadge, CustomCheckbox, CustomRadio, DataGridProps } from '../../../../../custom/components';
import AddUserModal from './AddUserModal';
import { EditIcon, LockIcon, KeyIcon, LocationIcon, UnlockIcon, AddIcon, DateFormat } from '../../../../../custom/constants';
import { Error as ErrorIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';
import userStyles from './users.module.css';
import styles from '../../../../../custom/style.module.css';
import LocationModal from '../../../common/location/LocationContainer';
import ChangePassword from './ChangePassword';
import DocModalContainer from 'components/pages/common/docModal/DocModalContainer';
import DeactiveModal from './DeactiveUserModal';
import moment from 'moment';
import RemoveExpiryModal from './RemoveExpiryModal';

const ManageUser = (props) => {
  const { anchorEl, handleDoc, handleTraining, training, doc, filter, onChangeFilter, generatePassword, action, modal, userFields, userErrors, handleOpen, onClose, addUser, editUser, stations, onChange, handleChangePassword } = props;

  const columns = [
    {
      field: 'first_name',
      headerName: 'First Name',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'username',
      headerName: 'Email / User Name',
      type: 'email',
      minWidth: 150,
      flex: 1,

    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 120,
      flex: 1,

    },
    {
      field: 'trainingRecords',
      headerName: 'Training Records',
      // description: 'This column has a value getter and is not sortable.',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <div className='d-flex justify-content-between align-items-center' style={{ cursor: 'pointer' }}>
            <TableBadge color='error' badgeContent={row.docs && row.docs.length}>
              <AttachFileIcon fontSize='small' onClick={() => handleDoc(row)} />
            </TableBadge></div>
        );
      }

    },
    {
      field: 'earliestExpiry',
      headerName: 'Earliest Expiry',
      align: 'left',
      minWidth: 150,
      flex: 1,
      sortable: false,
      // width: 90,
      renderCell: ({ row }) => {
        let arr = row.docs || [];
        let minRow = arr.length > 0 ? arr.reduce((min, current) => {
          return (new Date(current.data.expiry_date) < new Date(min.data.expiry_date)) ? current : min;
        }) : false;
        let alert = minRow && new Date(minRow.data.expiry_date) < new Date() ? true : false;
        return (
          <Stack direction='row' justifyContent='center' alignContent='start' spacing={1} style={{ cursor: 'pointer' }}>
            {minRow ?
              <>
                <span className={styles.mt1}>{moment(minRow.data.expiry_date, 'YYYY-MM-DD').format(DateFormat)}</span>
                {alert && (<ErrorIcon color='error' fontSize='small' onClick={(e) => handleTraining(true, minRow, e.target)} />)}
              </>
              : 'NA'}
          </Stack>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150, 
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            {row.active ? (<LockIcon title='Deactivate User' onClick={() => props.handleOpen('deactive_user', 'deactive', row)} />) 
              : (<div onClick={() => props.handleOpen('deactive_user', 'active', row)}><UnlockIcon title='Reactivate User' /></div>)}
            <div onClick={() => props.handleOpen('user_pass', 'edit', row)}>
              <KeyIcon />
            </div>
            <TableBadge color='error' badgeContent={row.stations && row.stations.length}>
              <LocationIcon onClick={(e) => props.handleLocation('user', true, row, e.target)} />
            </TableBadge>
            <div onClick={() => props.handleOpen('user', 'edit', row)} >
              <EditIcon />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <div>
      <StyledCard>
        <Box className={`${styles.mb2} ${styles.fSpaceBtw}`} >
          <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={6} md={10} lg={1} order={{
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
            }}>
              <StyledCardTitle gutterBottom className={styles.pt1} >User List</StyledCardTitle>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5} order={{
              xs: 2,
              sm: 3,
              md: 3,
              lg: 2,
            }}>
              <Stack direction='row' spacing={2} justifyContent={{
                lg: 'center',
                md: 'left' 
              }}
              alignItems={'center'} flexWrap='wrap'>
                <FormLabel component='small' className={`${styles.xsW100} ${styles.pr2}`}><small>Filter:</small> </FormLabel>
                <RadioGroup aria-labelledby='user-status' name='type' value={filter.type} defaultValue='ALL' onChange={onChangeFilter} row className={styles.ml0}>
                  <Stack direction='row' spacing={2} className={`${styles.fMiddle}`} flexWrap='wrap'>
                    <CustomRadio control={<Radio size='small' />} value='ALL' label='All Users' />
                    <CustomRadio control={<Radio size='small' />} value='ACTIVE' label='Active Users' />
                    <CustomRadio control={<Radio size='small' />} value='INACTIVE' label='Inactive Users' />
                  </Stack>
                </RadioGroup>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5} order={{
              xs: 3,
              sm: 4,
              md: 4,
              lg: 3,
            }} justifyContent={{
              lg: 'center',
              md: 'flex-start' 
            }} display={'flex'}>
              <CustomCheckbox name='expiry' checked={filter.expiry}
                control={<Checkbox inputProps={{ 'aria-label': 'Filter users in days', }} size='small'  onChange={onChangeFilter} />}
                label={<span style={{ marginTop: '4px' }}>Show users with training expiring in &nbsp;
                  <TextField
                    className={userStyles.tableTextField}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }}
                    onChange={onChangeFilter}
                    name='day'
                    value={filter.day}
                  /> days</span>} />
            </Grid>
            <Grid item container justifyContent='flex-end' xs={12} sm={6} md={2} lg={1} order={{
              xs: 4,
              sm: 2,
              md: 2,
              lg: 4,
            }}>
              <AddIcon onClick={() => props.handleOpen('user', 'add', {})} title='Add User' />
            </Grid>
          </Grid>

        </Box>
        <div className={styles.tableWrapper}>
          <Box className={`${styles.tableOuter} ${userStyles.userTableOuter}`}>
            <StyledDataGrid
              {...DataGridProps}
              rows={props.filteredData}
              columns={columns}
            />
          </Box>
        </div>
      </StyledCard>

      <AddUserModal
        modal={modal}
        action={action}
        handleOpen={handleOpen}
        onClose={onClose}
        userFields={userFields}
        userErrors={userErrors}
        addUser={addUser}
        editUser={editUser}
        onChange={onChange}
        stations={stations}
      />

      <ChangePassword
        modal={modal}
        handleOpen={handleOpen}
        onClose={onClose}
        userFields={userFields}
        userErrors={userErrors}
        handleChangePassword={handleChangePassword}
        onChange={onChange}
        generatePassword={generatePassword}
        passwordVal={props.passwordVal}
        successModal={props.successModal}
      />

      <DeactiveModal
        modal={modal}
        action={action}
        userFields={userFields}
        userErrors={userErrors}
        active={props.active}
        deactive={props.deactive}
        onChange={onChange}
        onClose={onClose}
      />

      <LocationModal
        anchorEl={anchorEl}
        open={props.location}
        type={props.locationType}
        handleClose={props.handleLocation}
        name={props.station?.first_name + ' ' + props.station?.last_name}
        stations={props.stations}
        data={props.station}
      />

      <RemoveExpiryModal
        anchorEl={anchorEl}
        handleTraining={handleTraining}
        training={training}
        doc={doc}
        handleRetraining={props.handleRetraining}
        onChangeUser={props.onChangeUser}
      />

      <DocModalContainer />

    </div>
  );
};

export default ManageUser;
