import React from 'react';
import Screening from './ScreeningReport';
import moment from 'moment';
import http from 'utils/http';
import exportFromJSON from 'export-from-json';
import dayjs from 'dayjs';

class ScreeningReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      attachment: false,
      awb: null,
      docs: [],
      data: [],
      awbs: [],
      airline: [],
      flight: [],
      measure: [],
      screener: [],
      search: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        f_time: '00:00',
        t_time: '23:59',
        awb: null,
      },
      filter: {
        airline: null,
        flight: null,
        measure: null,
        screener: null
      },
      isLoading: false,
    };
    this.spanRef = React.createRef();
  }

  componentDidMount() {
  }

  fetchReport = (awb = false) => {
    let query = '';
    if (awb) {
      query = 'awb=' + this.state.search.awb;
    } else {
      query = `from=${this.state.search.from}&to=${this.state.search.to}&f_time=${this.state.search.f_time}&t_time=${this.state.search.t_time}`;
    }
    this.setState({ isLoading: true }, () => {
      http.get('/reports?' + query).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            data: data.data,
            filter: {
              airline: null,
              flight: null,
              measure: null,
              screener: null
            }
          }, () => {
            this.getFilter();
            this.filterData();
          });
        }
      });
      this.setState({ isLoading: false });
    });
  };

  getFilter = () => {
    let airline = [];
    let flight = [];
    let measure = [];
    let screener = [];
    this.state.data.map(a => {
      let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
      if (!flight.includes(f)) {
        flight.push(f);
      }
      if (a.airline) {
        if (!airline.includes(a.airline.code)) {
          airline.push(a.airline.code);
        }
      }
      a.screens.map(s => {
        if (s.measure) {
          if (!measure.includes(s.measure.type)) {
            measure.push(s.measure.type);
          }
        }
      });
      a.screens.map(s => {
        if (s.user) {
          if (!screener.includes(s.user.first_name + ' ' + s.user.last_name)) {
            screener.push(s.user.first_name + ' ' + s.user.last_name);
          }
        } 
        if (s.k9_company) {
          if (!screener.includes(s.k9_company.name)) {
            screener.push(s.k9_company.name);
          }
        }
      }); 
    });
    this.setState({
      airline,
      flight,
      measure,
      screener
    });
  };

  handleChangeAwb = (value) => {

    if (value.length === 3 && (this.state.search.awb?.length === 2)) {
      value = value + '-'; 
    }
    if (value.length === 11) {
      if (value.indexOf('-') < 0) {
        value = value.slice(0 , 3) + '-' + value.slice(3);
      }
    }
    if (value.split('-').length > 2) {
      return;
    }
    
    return value;
  };

  filterData = () => {
    let awbs = [...this.state.data || []];
    if (this.state.filter.airline && this.state.filter.airline != '') {
      awbs = awbs.filter((a) => a.airline.code === this.state.filter.airline);
    }
    if (this.state.filter.flight && this.state.filter.flight != '') {
      awbs = awbs.filter((a) => {
        let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
        return f === this.state.filter.flight;
      });
    }
    if (this.state.filter.measure && this.state.filter.measure != '') {
      let filterAwbs = [];
      awbs.map(a => {
        let screens = [];
        a.screens.map(s => {
          if (s.measure.type == this.state.filter.measure) {
            screens.push(s);
          }
        });
        if (screens.length > 0) {
          filterAwbs.push({
            ...a,
            screens
          });
        }
      });
      awbs = filterAwbs;
    }
    if (this.state.filter.screener && this.state.filter.screener != '') {
      let filterAwbs = [];
      awbs.map(a => {
        let screens = [];
        a.screens.map(s => {
          if (s.user) {
            if ((s.user.first_name + ' ' + s.user.last_name) == this.state.filter.screener) {
              screens.push(s);
            }
          } 
          if (s.k9_company) {
            if (s.k9_company.name == this.state.filter.screener) {
              screens.push(s);
            }
          }
        });
        if (screens.length > 0) {
          filterAwbs.push({
            ...a,
            screens
          });
        }
      });
      awbs = filterAwbs;
    }
    this.setState({ awbs });
  };

  onChangeSearch = (e) => {
    let name = e.target.name;
    let value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.type == 'date') {
      value = dayjs(e.target.value).format('YYYY-MM-DD');
    }
    if (name === 'awb') {
      value = this.handleChangeAwb(value); // Call handleChangeAwb function
    }
    this.setState({
      search: {
        ...this.state.search,
        [name]: value
      }
    });
  };

  onChangefilter = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value
      }
    }, () => { this.filterData(); });
  };

  clear = () => {
    this.setState({
      filter: {
        airline: null,
        flight: null,
        measure: null,
        screener: null
      },
      search: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        f_time: '00:00',
        t_time: '23:59',
        awb: null,
      },
    }, () => {
      this.getFilter();
      this.filterData();
      this.fetchReport();
    });
  };

  handlePopover = (attachment, anchorEl, awb, docs) => {
    this.setState({
      attachment,
      anchorEl,
      awb,
      docs
    });
  };

  handlePopoverClose = () => {
    this.setState({
      attachment: false,
      anchorEl: false
    });
  };

  handleExport = () => {
    // let options = {
    //   fieldSeparator: ',',
    //   quoteStrings: '"',
    //   decimalSeparator: '.',
    //   showLabels: true,
    //   showTitle: false,
    //   filename: 'Screnning Report',
    //   useTextFile: false,
    //   useBom: false,
    //   useKeysAsHeaders: false,
    //   headers: ['AWB', 'PCS', 'ORG', 'DEST', 'Measure', 'Method', 'Screened For Flight', 'Screener', 'Screening Date/Time', 'Alarm', 'Resolution', 'Resolved', 'Remarks']
    // };
    let data = [];
    this.state.awbs.map(a => {
      a.screens.map(s => {
        data.push({
          awb: a.code + '-' + a.number,
          org: a.org,
          dest: a.dest,
          pcs: s.pcs,
          total_wgt: a.wgt,
          measure: s?.measure?.type,
          method: s?.method?.name,
          flight: s.flight_no + '/' + moment(screen.s).format('DDMMMYY').toUpperCase(),
          screener: s.user ? s.user.first_name + ' ' + s.user.last_name : s?.k9_company?.name,
          date: moment(s.screen_date).format('DDMMMYY').toUpperCase() + ' ' + s.screen_time,
          alarm: s.alarmed ? 'Yes' : 'No',
          resolution: s?.alarm?.name,
          resolved: s.resolved,
          remark: s.remark
        });
      });
    });
    exportFromJSON({
      data,
      fileName: 'Screening Report',
      exportType: 'csv' 
    });
  };

  render() {
    return (
      <Screening
        handlePopover={this.handlePopover}
        handlePopoverClose={this.handlePopoverClose}
        anchorEl={this.state.anchorEl}
        attachment={this.state.attachment}
        spanRef={this.spanRef}
        search={this.state.search}
        filter={this.state.filter}
        airline={this.state.airline}
        flight={this.state.flight}
        measure={this.state.measure}
        screener={this.state.screener}
        awbs={this.state.awbs}
        awb={this.state.awb}
        docs={this.state.docs}
        fetchReport={this.fetchReport}
        onChangeSearch={this.onChangeSearch}
        onChangefilter={this.onChangefilter}
        clear={this.clear}
        handleExport={this.handleExport}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default ScreeningReportContainer;
