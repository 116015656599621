import React from 'react';
import { Grid, IconButton, Stack, Typography, FormGroup, Checkbox, Box } from '@mui/material';
import styles from '../../../custom/style.module.css';
import k9styles from './k9transfer.module.css';
import { StyledCard, StyledWhiteCard, StyledDataGrid, StyledCardTitle, CustomCheckbox, CustomButton, CustomTextField, CustomDatePicker, DataGridProps, InputProps, GreySectionDivider } from '../../../custom/components';
import { Close } from '@mui/icons-material';
import { DateFormat, EditIcon } from '../../../custom/constants';
import moment from 'moment';
import dayjs from 'dayjs';

const CompletionWorksheet = (props) => {

  const totalPcs = props.fields.transfer_awbs.reduce((acc, awb) => acc + awb.pcs, 0);
  const totalWgt = props.fields.transfer_awbs.reduce((acc, awb) => acc + awb.wgt, 0);

  const columns = [
    {
      field: 'airwaybill',
      headerName: 'Awb',
      type: 'text',
      minWidth: 110,
      flex: 1,
      valueGetter: (params) => `${params.row.code}-${params.row.number}`
    },
    {
      field: 'pcs',
      headerName: 'Pcs',
      type: 'text',
      minWidth: 50,
      flex: 1
    },
    {
      field: 'wgt',
      headerName: 'Wgt',
      type: 'text',
      minWidth: 70,
      flex: 1
    },
    {
      field: 'org',
      headerName: 'Org',
      type: 'text',
      minWidth: 40,
      flex: 1
    },
    {
      field: 'dest',
      headerName: 'Dest',
      type: 'text',
      minWidth: 40,
      flex: 1
    },
    {
      field: 'commodity',
      headerName: 'Commodity',
      type: 'text',
      className: styles.minWidth100
    },
    {
      field: 'status',
      headerName: '',
      type: 'text',
      minWidth: 40,
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        const awb = params.row;
        // const transferredPieces = awbs.reduce((sum, awb) => sum + awb.pcs, 0);
        // const screenedPieces = awbs.reduce((sum, awb) => sum + awb.screen_pcs, 0);

        if (awb.pcs > (awb.screen_pcs + awb.not_screen_pcs)) {
          return (
            <div className={`${k9styles.status} ${styles.colorRed}`}> &gt; </div>
          );
        } else if (awb.pcs == (awb.screen_pcs + awb.not_screen_pcs)) {
          return (
            <div className={`${k9styles.status} ${styles.colorSecondary}`}> = </div>
          );
        } else {
          return null;
        }
      }
    },
    {
      field: 'pcs_screened',
      headerName: 'Pcs Screened',
      type: 'text',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return (
          <CustomTextField
            {...InputProps}
            id=''
            autoFocus
            fullWidth
            placeholder=''
            type='text'
            name='screen_pcs'
            variant='outlined'
            className={styles.tableIp}
            value={params.row.screen_pcs}
            onChange={(e) => props.handleInputChange(e, params.row)}
            inputProps={{ 'aria-label': 'pcs screened' }}
          />
        );
      }
    },
    {
      field: 'pcs_not_screened',
      headerName: 'Pcs Not Screened',
      type: 'text',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return (
          <CustomTextField
            {...InputProps}
            id=''
            autoFocus
            fullWidth
            placeholder=''
            type='text'
            name='not_screen_pcs'
            variant='outlined'
            className={styles.tableIp}
            value={params.row.not_screen_pcs}
            onChange={(e) => props.handleInputChange(e, params.row)}
            inputProps={{ 'aria-label': 'pcs not screened' }}
          />
        );
      }
    },
    {
      field: 'pcs_alarmed',
      headerName: 'Pcs Alarmed',
      type: 'text',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return (
          <CustomTextField
            {...InputProps}
            id=''
            autoFocus
            fullWidth
            placeholder=''
            type='text'
            name='alarm_pcs'
            variant='outlined'
            className={styles.tableIp}
            value={params.row.alarm_pcs}
            onChange={(e) => props.handleInputChange(e, params.row)}
            inputProps={{ 'aria-label': 'Pcs Alarmed' }}
          />
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      // minWidth: 100, 
      flex: 1,
      width: 90,
      sortable: false,
      renderCell: () => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <EditIcon />
          </Stack>
        );
      }
    },
  ];
  const checklist = props.fields.checklist && props.fields.checklist.map((check, i) => (
    <Grid item container xs={12} md={12} xl={12} spacing={2} key={i}>
      <Grid item xs={6} lg={12} xl={6}>
        <Typography variant='subtitle2' component='div'>{check.txt}</Typography>
      </Grid>
      <Grid item xs={6} lg={12} xl={6}>
        <FormGroup aria-label='checklist' row>
          {check.opt_yes && <CustomCheckbox name='' control={<Checkbox name='opt_yes' size='small' checked={check.answer == 'Yes'} onChange={(e) => props.handleCheck(e, check)} />} label='Yes' />}
          {check.opt_no && <CustomCheckbox name='' control={<Checkbox name='opt_no' size='small' checked={check.answer == 'No'} onChange={(e) => props.handleCheck(e, check)} />} label='No' />}
          {check.opt_na && <CustomCheckbox name='' control={<Checkbox name='opt_na' size='small' checked={check.answer == 'N/A'} onChange={(e) => props.handleCheck(e, check)} />} label='N/A' />}
        </FormGroup>
      </Grid>
    </Grid>
  ));

  return (
    <Box sx={{
      position: 'absolute',
      top: '0',
      width: '100%',
      zIndex: '2',
    }}>
      {
        props.modal === 'completionWorksheet' &&
        <StyledCard sx={{ minHeight: 'calc(100vh - var(--headerheight) - 72px)' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={styles.positionRelative}>
              <Stack direction='row' spacing={2} justifyContent='flex-start' flexWrap='wrap' sx={{ mr: '50px' }}>
                <StyledCardTitle gutterBottom variant=''>K9 Transfer Completion Worksheet</StyledCardTitle>
                <Stack direction='row' spacing={2} justifyContent='space-around' flexGrow={1} alignItems='flex-start' sx={{ maxWidth: 'clamp(350px , 70%, 70%)' }}>
                  <div className={k9styles.transferDetails}>
                    <Typography variant='caption' component='span' fontWeight={900} className={k9styles.details}>{props.fields.transfer_awbs.length}
                    </Typography>
                    <Typography variant='caption' component='span' fontWeight={900} className={k9styles.title}>Awbs
                    </Typography>
                  </div>

                  <div className={k9styles.transferDetails}>
                    <Typography variant='caption' component='span' fontWeight={900} className={k9styles.details}>{totalPcs}
                    </Typography>
                    <Typography variant='caption' component='span' fontWeight={900} className={k9styles.title}>Pieces
                    </Typography>
                  </div>

                  <div className={k9styles.transferDetails}>
                    <Typography variant='caption' component='span' fontWeight={900} className={k9styles.details}>{totalWgt}
                    </Typography>
                    <Typography variant='caption' component='span' fontWeight={900} className={k9styles.title}>Kilos
                    </Typography>
                  </div>
                </Stack>
                <IconButton
                  aria-label='close'
                  onClick={props.onClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 16,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item container justifyContent={{
              xs: 'flex-start',
              lg: 'flex-start',
            }} spacing={3} className={`${k9styles.wtGridContainer} ${k9styles.avgContainer}`} >
              <Grid item xs className={`${k9styles.wtGridItems}`}>
                <div className={`${k9styles.block}`}>
                  <span className={`${k9styles.blockTitle}`}>Transfer ID</span>
                  <span className={`${k9styles.blockData}`}>{props.fields.number}</span>
                </div>
              </Grid>
              <Grid item xs className={`${k9styles.wtGridItems}`}>
                <div className={`${k9styles.block}`}>
                  <span className={`${k9styles.blockTitle}`}>Issued To</span>
                  <span className={`${k9styles.blockData}`}>{props.fields.k9_company ? `${props.fields.k9_company.name}` : ''}</span>
                </div>
              </Grid>
              <Grid item xs className={`${k9styles.wtGridItems}`}>
                <div className={`${k9styles.block}`}>
                  <span className={`${k9styles.blockTitle}`}>Issued By</span>
                  <span className={`${k9styles.blockData}`}>{props.fields.issued_by ? `${props.fields.issued_by.first_name} ${props.fields.issued_by.last_name}` : ''}</span>
                </div>
              </Grid>

              <Grid item xs className={`${k9styles.wtGridItems}`}>
                <div className={`${k9styles.block}`}>
                  <span className={`${k9styles.blockTitle}`}>Issue Date/Time</span>
                  <span className={`${k9styles.blockData}`}>{moment(props.fields.issued || null).format('DDMMMYY').toUpperCase()}</span>
                </div>
              </Grid>

              <Grid item xs className={`${k9styles.wtGridItems}`}>
                <div className={`${k9styles.block}`}>
                  <span className={`${k9styles.blockTitle}`} htmlFor='k9_handler_name'>Enter K9 Handler Name</span>
                  <div className={`${k9styles.blockData} ${styles.p0} ${k9styles.editable}`}>
                    <CustomTextField
                      {...InputProps}
                      id='k9_handler_name'
                      variant='standard'
                      autoFocus
                      fullWidth
                      placeholder=''
                      type='text'
                      size='small'
                      InputLabelProps={{ shrink: false }}
                      name='handler_name'
                      value={props.fields.handler_name}
                      onChange={(e) => props.handleChange(e, 'completion')}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs className={`${k9styles.wtGridItems}`}>
                <div className={`${k9styles.block}`}>
                  <span className={`${k9styles.blockTitle}`} htmlFor='k9_name'>Enter K9 Name</span>
                  <div className={`${k9styles.blockData} ${styles.p0} ${k9styles.editable}`}>
                    <CustomTextField
                      {...InputProps}
                      id='k9_name'
                      autoFocus
                      fullWidth
                      placeholder=''
                      type='text'
                      size='small'
                      InputLabelProps={{ shrink: false }}
                      name='name'
                      value={props.fields.name}
                      onChange={(e) => props.handleChange(e, 'completion')}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs className={`${k9styles.wtGridItems}`}>
                <div className={`${k9styles.block}`}>
                  <span className={`${k9styles.blockTitle}`}>Date / Time of Completion</span>
                  <div className={`${k9styles.blockData} ${styles.p0} ${k9styles.editable}`}>
                    <CustomDatePicker
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'Flight Date',
                          variant: 'standard'
                        }
                      }}
                      format={DateFormat}
                      className={styles.w100}
                      variant='standard'
                      name='completed'
                      value={dayjs(props.fields.completed) || ''}
                      onChange={(e) => props.handleChange(e, 'completed')}
                    /></div>
                </div>
              </Grid>
            </Grid>

            <Grid item sx={{
              width: 'calc((100% /7) * 5)',
              ['@media screen and (max-width: 1024px)']: { width: '100%', }, 
            }}>
              <StyledWhiteCard>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <StyledCardTitle gutterBottom variant=''>K9 Screening Results</StyledCardTitle>
                  </Grid>
                  <Grid item xs>
                    <div className={styles.tableWrapper}>
                      <Box className={`${styles.tableOuter} ${k9styles.transAwbTableOuter}`}>
                        <StyledDataGrid
                          {...DataGridProps}
                          rows={props.fields.transfer_awbs}
                          columns={columns}
                          className={`${styles.borderedRow} ${styles.smTblSpacing}`}
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </StyledWhiteCard>
            </Grid>

            <Grid item sx={{
              width: 'calc((100% /7) * 2)',
              ['@media screen and (max-width: 1024px)']: { width: '100%', }, 
            }}>
              <StyledWhiteCard className={styles.h100}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={styles.dFlex}>
                    <StyledCardTitle gutterBottom variant='' className={styles.minWidth150}>Transfer Checklist</StyledCardTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <GreySectionDivider />
                  </Grid>
                  {checklist}
                </Grid>
              </StyledWhiteCard>
            </Grid>

            <Grid item container xs={12} className={styles.fSpaceBtw} spacing={3}>
              <Grid item xs order={{
                xs: 2,
                sm: 2,
                md: 1,
                lg: 1,
              }}>
                <CustomButton variant='contained' disabled={!props.managerRole || !props.fields.finalized} className={styles.minWidth205} onClick={(e) => props.update(e, 'open')} > Reopen (Manager Access Only)</CustomButton>
              </Grid>
              <Grid item xs={12} md order={{
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
              }}>
                <Stack direction='row' spacing={2} flexWrap='wrap' justifyContent='center' >
                  <Typography variant='subtitle2' component='div'>Checked for accuracy and finalized by:</Typography>
                  <Typography variant='subtitle2' component='div' color='var(--primaryblue)' sx={{ textDecoration: 'underline' }}>{props.fields.verified_by
                    ? `${props.fields.issued_by.first_name} ${props.fields.issued_by.last_name} ${dayjs(
                      props.fields.finalized
                    )
                      .format('DDMMMYY hh:mm').toUpperCase()}`
                    : ''}</Typography>
                </Stack>
              </Grid>
              <Grid item xs order={{
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3,
              }} className={styles.fJustifyEnd}>
                <CustomButton variant='contained' disabled={props.fields.finalized} className={styles.minWidth205} onClick={(e) => props.update(e, 'update')}> Update </CustomButton>
              </Grid>
              <Grid item xs order={{
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3,
              }} className={styles.fJustifyEnd}>
                <CustomButton variant='contained' disabled={props.fields.finalized || !props.isFinalButtonEnabled} className={styles.minWidth205} onClick={(e) => props.update(e, 'final')}> Finalize </CustomButton>
              </Grid>
            </Grid>
          </Grid>

        </StyledCard>
      }
    </Box>
  );
};
export default CompletionWorksheet;