import { SET_COMPANY_STATIONS } from './actionTypes';


function setStations(stations) {
  return {
    type: SET_COMPANY_STATIONS,
    data: stations
  };
}

function setStationAction(stations) {
  return (dispatch) => {

    dispatch(setStations(stations));
  };
}

export { setStationAction };