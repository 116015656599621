import React from 'react';
import { Checkbox, Stack, Grid, MenuItem, InputAdornment, Box, IconButton, CircularProgress } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, CustomSelect, CustomCheckbox, CustomSearch, DataGridProps, SelectInputProps, InputProps, CustomButton } from '../../../custom/components';
import { ArrowTopIcon, DateFormat, preScreened } from '../../../custom/constants';
import { Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import styles from '../../../custom/style.module.css';
import screeningStyles from './screening.module.css';
import moment from 'moment';
// import theme from '../../../../../custom/theme';

const Screening = (props) => {
  const { sort_by, awbs } = props;

  const columns = [
    {
      field: 'awb',
      headerName: 'Airwaybill',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row.code + '-' + row.number;
      }
    },
    {
      field: 'pcs',
      headerName: 'Pcs',
      minWidth: 100,
      flex: 1,
      // width: 130
    },
    {
      field: 'wgt',
      headerName: 'Weight',
      minWidth: 100,
      flex: 1,
      // editable: true,
      // width: 130 
    },
    {
      field: 'commodity',
      headerName: 'Commodity',
      // type: 'number',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'flight_no',
      headerName: 'Booked Flight Number',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.airline?.code + row.flight_no + '/' + moment(row.flight_date || null).format('DDMMMYY').toUpperCase();
      }
    },
    {
      field: 'shipper_profile',
      headerName: 'Shipper Profile',
      minWidth: 100,
      flex: 1,
      // editable: true,
      // width: 130 
    },
    {
      field: 'pre_screened',
      headerName: 'Prescreened',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return preScreened.find(p => p.value === row.pre_screened)?.label || '';
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      // minWidth: 100, 
      flex: 1,
      sortable: false,
      // width: 90,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <Link to={'/screen/' + row.id} > <ArrowTopIcon /> </Link>
          </Stack>
        );
      }
    },
  ];

  // const rowSpacing = '1em';

  return (
    <div>
      <Stack direction='row' spacing={3} justifyContent='center'>
        <Stack direction='row' alignItems='center' className={styles.headerFieldContainer}>
          <CustomSearch
            {...InputProps}
            id='search'
            type='search'
            placeholder='Search AWB'
            name='awb'
            value={props.search.awb}
            onChange={props.onChangeSearch}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <>
                  {props.isLoading ? null : (
                    <InputAdornment position='end'>
                      <IconButton
                        size='small'
                        aria-label='search'
                        onClick={() => props.fetchAwb(true)}
                        edge='end'
                      >
                        <Search fontSize='inherit' />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            className={`${styles.brInput}`}
          />
        </Stack>
      </Stack>
      <StyledCard>
        <Stack rowGap={2}>
          <Grid container alignItems='center'>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <div className={`${styles.fSpaceBtw}`}>
                <StyledCardTitle gutterBottom variant='' component='div'>Shipments Pending Screening</StyledCardTitle>
              </div>
            </Grid>
            <Grid item xs className={screeningStyles.loader} >
              {props.isLoading ? (
                <CircularProgress size={24} />
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <CustomInputLabel className={screeningStyles.ipLabel}>From</CustomInputLabel>
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    fullWidth: true,
                    placeholder: 'From Date'
                  }, 
                }}
                format={DateFormat}
                className={styles.w100}
                onChange={(v) => props.onChangeSearch({
                  target: {
                    type: 'date',
                    name: 'from',
                    value: v
                  }
                })}
                name='from'
                value={dayjs(props.search.from)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <CustomInputLabel className={screeningStyles.ipLabel}>To</CustomInputLabel>
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    fullWidth: true,
                    placeholder: 'To Date'
                  }, 
                }}
                className={styles.w100}
                onChange={(v) => props.onChangeSearch({
                  target: {
                    type: 'date',
                    name: 'to',
                    value: v
                  }
                })}
                format={DateFormat}
                name='to'
                value={dayjs(props.search.to)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={1}>
              <CustomInputLabel id='airline-label' className={screeningStyles.ipLabel}>Airline</CustomInputLabel>
              <CustomSelect
                {...SelectInputProps}
                labelId='airline-label'
                fullWidth
                id='airline'
                name='airline'
                value={props.filter.airline}
                onChange={props.onChangefilter}
              >
                {props.airline && props.airline.map(a => {
                  return (<MenuItem key={a} value={a} >{a}</MenuItem>);
                })}

              </CustomSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              <CustomInputLabel id='flight-number-label' className={screeningStyles.ipLabel}>Flight Number</CustomInputLabel>
              <CustomSelect
                {...SelectInputProps}
                labelId='flight-number-label'
                fullWidth
                id='flight'
                name='flight'
                value={props.filter.flight}
                onChange={props.onChangefilter}
              >
                {props.flight && props.flight.map(f => {
                  return (<MenuItem key={f} value={f}>{f}</MenuItem>);
                })}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              <CustomInputLabel id='exclude-pe-screened-label' className={screeningStyles.ipLabel}>Exclude Pre-screened</CustomInputLabel>
              <CustomSelect
                {...SelectInputProps}
                labelId='exclude-pe-screened-label'
                fullWidth
                id='preScreen'
                name='preScreen'
                value={props.filter.preScreen}
                onChange={props.onChangefilter}
              >
                {props.preScreen && props.preScreen.map(m => {
                  return (<MenuItem key={m} value={m} >{m}</MenuItem>);
                })}
              </CustomSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
              <CustomCheckbox className={styles.mt3} checked={sort_by.dept} onChange={props.onChangeSort} control={<Checkbox size='small' />} label=' Sort list by scheduled departure time' />
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={2} xl={1}>
              <CustomButton variant='contained' className={styles.mt3} onClick={ props.clear } >
                Clear
              </CustomButton>
            </Grid>
          </Grid>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter} ${screeningStyles.screenTableOuter}`}>
              <StyledDataGrid
                {...DataGridProps}
                rows={awbs}
                columns={columns}
              />
            </Box>
          </div>
        </Stack>

      </StyledCard>
    </div>
  );
};

export default Screening;
