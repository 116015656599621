import React from 'react';
import { Divider, Dialog } from '@mui/material';
import styles from 'custom/style.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, CustomDialogContent } from 'custom/components';
import { CloseIconBtn } from 'custom/constants';

const ScrapperModal = (props) => {
  return (
    <div>
      <Dialog 
        aria-labelledby='form-dialog-title' 
        maxWidth={'sm'}
        fullWidth={true}
        open={props.modal} 
        onClose={props.onClose}>
        <CustomDialogTitle id='form-dialog-title'>
          AWB Scrapper
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <Divider />
        <CustomDialogContent>
          Awb not found, do you wish to manually enter the data
        </CustomDialogContent>
        <CustomDialogAction className={`${styles.fSpaceBtw}`}>
          <CustomButton variant='contained' onClick={props.onClose}>Close</CustomButton>
          <CustomButton variant='contained' onClick={() => props.searchAwb(true, true, props.exAwbs[0] ? props.exAwbs[0]?.version + 1 : 1)}>Enter Manually</CustomButton>
        </CustomDialogAction>
      </Dialog>
    </div>
  );
};
export default ScrapperModal;