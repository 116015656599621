import React, { Component } from 'react';
import { Dialog, IconButton, Grid, Typography, InputAdornment, Tooltip } from '@mui/material';
import styles from 'custom/style.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomDialogContent } from 'custom/components';
import CloseIcon from '@mui/icons-material/Close';
import { BrowseIcon, CloseIconBtn } from 'custom/constants';

class AddDocModal extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      selectedFile: null,
      fileError:'',
    };
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({ 
      selectedFile: file, 
      fileError:'',
    });
    this.props.onChange(event);
  };

  clearFile = () => {
    const file = document.getElementById('file');
    file.value = '';
    this.setState({ selectedFile: null });
    this.props.clearFile();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { selectedFile } = this.state;
    const { fields } = this.props;
    if (fields && fields.name && fields.name.trim() !== '') {
      if (selectedFile) {
        this.props.onSubmit(event);
        this.handleClose(event);
      } else {
        this.setState({ fileError: ' Please select a file.' });
      }
    } else {
      this.props.onChange({ 
        target: { 
          name: 'name', 
          value: '' 
        } 
      });
      this.props.errors.name = 'Please enter a name.'; 
    }
  };

  handleClose = (event) => {
    this.setState({ 
      selectedFile: null, 
      fileError:'' 
    });
    this.props.onChange({ 
      target: { 
        name: 'name', 
        value: '' 
      } 
    });
    this.props.errors.name = '';
    this.props.onClose(event);
  };

  render() {
    const { modal, fields, errors, onSubmit, onChange } = this.props;
    const { selectedFile, fileError } = this.state;
    return (
      <div>
        <Dialog 
          aria-labelledby='form-dialog-title'
          open={modal} 
          onClose={this.handleClose}
          maxWidth={'sm'}
          fullWidth={true}
        >
          <CustomDialogTitle id='form-dialog-title'>
            {'Add New Attachment'}
            <CloseIconBtn onClick={this.handleClose} />
          </CustomDialogTitle>
          <form className={styles.w100} onSubmit={onSubmit}>
            <CustomDialogContent>
              <StyledCard>
                <Grid container spacing={2}>
                  <Grid item xs={12} >
                    <CustomInputLabel htmlFor='name'>Name</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='name'
                      placeholder='Name'
                      type='text'
                      name='name'
                      onChange={onChange}
                      value={fields.name}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position='end'>
                            {selectedFile && (<Tooltip title={fields?.file?.name}>
                              <IconButton size='small' variant='contained' color='text.secondary' onClick={this.clearFile}>
                                <CloseIcon fontSize='inherit' />
                              </IconButton>
                              <span className={styles.docFileName}>{fields?.file?.name}</span>
                            </Tooltip>)}
                            
                            <IconButton size='small'>
                              <input
                                accept='application/pdf,image/*'
                                style={{ display: 'none' }}
                                id='file'
                                type='file'
                                name='file'
                                onChange={this.handleFileChange}
                              />
                              <label htmlFor='file' className={`${styles.fs0} ${styles.pointer}`}>
                                <BrowseIcon />
                              </label>
                               
                            </IconButton> 
                          </InputAdornment>
                        )
                      }}
                      error={errors.name}
                      helperText={errors.name}
                    />
                    {fileError && (
                      <Typography variant='caption' color='error'>
                        {fileError}
                      </Typography>
                    )}

                  </Grid>
                  {/* <Grid item>
                    <input
                      accept='application/pdf,image/*'
                      style={{ display: 'none' }}
                      id='file'
                      type='file'
                      name='file'
                      onChange={this.handleFileChange}
                    />
                    <label htmlFor='file'>
                      <Button variant='contained' component='span'>
                        Choose file
                      </Button>
                    </label>
                    {selectedFile && (
                      <div className={`${styles.fileContainer}`}>
                        <p className={`${styles.fileName}`}>{selectedFile.name}</p>
                        <IconButton variant='contained' color='secondary' onClick={this.clearFile}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      </div>
                    )}
                    {fileError && (
                      <Typography variant='caption' color='error'>
                        {fileError}
                      </Typography>
                    )}
                  </Grid> */}
                </Grid>
              </StyledCard>
            </CustomDialogContent>
            <CustomDialogAction className={`${styles.fSpaceBtw}`}>
              <CustomButton variant='contained' type='reset' onClick={this.handleClose}> Close </CustomButton>
              <CustomButton variant='contained' type='submit' onClick={this.handleSubmit}> Submit </CustomButton>
            </CustomDialogAction>
          </form>
        </Dialog>
      </div>
    );
  }
}
export default AddDocModal;