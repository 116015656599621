import React from 'react';
import { IconButton, } from '@mui/material';
import { StyledCard, } from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import analyticsStyle from './analytics.module.css';
import {  Notifications } from '@mui/icons-material';
import {  ExcelIcon } from 'custom/constants';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, ChartDataLabels);

const Alarms = (props) => {
  const alarmdata = {
    labels: props.alarm.alarmCode,
    datasets: [
      {
        label: 'Security Methods Applied',
        data: props.alarm.alarmArray,
        backgroundColor: '#00325E',
      },
    ],
  };

  const alarmgraphOptions = {
    plugins: {
      legend: {
        fullSize: true,
        display: false,
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            if (typeof data == 'number')
              sum += data;
          });
          let result = value * 100 / sum;
          let percentage = (result).toFixed(0) + '%';
          if (result > 0) {
            return percentage;
          } else {
            return '';
          }
        },
        color: '#fff',
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { grid: { drawOnChartArea: false, }, },
      y: {
        title: {
          display: true,
          text: props.report == 'awb' ? 'Air Way Bills' : props.report == 'wgt' ? 'Weight' : 'Pieces'
        }
      }
    }
  };

  return (
    <StyledCard>
      <div className={`${analyticsStyle.wtOuterGridItems}`}>
        <div className={`${styles.fSpaceBtw} ${styles.flexWrap}`}>
          <h5 className={analyticsStyle.heading}>Screening Alarms</h5>
          <IconButton aria-label='download-excel' className={`${analyticsStyle.fileImg}`} onClick={() => props.handleExport('alarms')}>
            <ExcelIcon />
          </IconButton>
        </div>
        <div className={`${styles.dFlex} ${styles.flexWrap} ${styles.gap20}`}>
          <div className={`${styles.mxauto}`}>
            <div className={`${styles.dFlex} ${styles.positionRelative}`}>
              <Notifications classes={{ root: analyticsStyle.saBell }} />
              <span className={analyticsStyle.totalAlarms}>{props.screenDetails?.screens?.alarmCount}</span>
            </div>
            <div className={analyticsStyle.totalAlarmsText}>Total Alarms</div>
          </div>
          {/* <div className={analyticsStyle.alarmWrapper}>
                            <div className={analyticsStyle.totalAlarmsText}>Alarm Resolution</div> */}
          <div className={`${analyticsStyle.graphWrapper} ${analyticsStyle.saGrapgWrap}`}>
            <div className={analyticsStyle.barWrapper}>
              <Bar data={alarmdata} options={alarmgraphOptions} />    
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </StyledCard>

  );
};

export default Alarms;
