
import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class PDFSlider extends Component {
  state = {
    numPages: 1,
    currentPage: 1,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handlePageChange = newPage => {
    this.setState({ currentPage: newPage });
  };

  render() {
    const { numPages, currentPage } = this.state;
    const settings = {
      arrows: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (oldIndex, newIndex) => {
        this.handlePageChange(newIndex + 1);
      },
    };

    return (
      <div className='pdf-slider-container'>
        <Slider {...settings}>
          {Array.from({ length: numPages }, (_, pageIndex) => (
            <div key={pageIndex} className='pdf-slide'>
              <Document
                file={this.props.file}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pageIndex + 1} />
              </Document>
            </div>
          ))}
        </Slider>
        <div className='pagination'>
          <p>
            Page {currentPage} of {numPages}
          </p>
        </div>
      </div>
    );
  }
}

export default PDFSlider;