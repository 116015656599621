import React from 'react';
import screeningStyles from '../../screen.module.css';
import styles from '../../../../../custom/style.module.css';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { CustomButton, CustomInputLabel, CustomStyle, CustomTextField, InputProps } from 'custom/components';
import { ClearXIcon, EditIcon, SaveTickIcon } from 'custom/constants';
import Select, { components } from 'react-select';
import { ArrowDropDown } from '@mui/icons-material';
import moment from 'moment';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDown />
    </components.DropdownIndicator>
  );
};

const options = [
  {
    value: 'SPX',
    label: 'SPX'
  },
  {
    value: 'SHR',
    label: 'SHR'
  }
];

const screeningMethods = [
  {
    value: 'Not Applicable',
    label: 'Not Applicable'
  },
  {
    value: 'CCSF',
    label: 'CCSF'
  },
  {
    value: 'EDS',
    label: 'EDS'
  },
  {
    value: 'ADD',
    label: 'ADD'
  }
];

const Consignments = (props) => {
  const { screen, consignmentFields, consignmentFieldErrors, onSave, onEmail, onPrint, onChange } = props;
  return (
    <Box sx={{ pt: '0', }}>
      <Grid container className={`${screeningStyles.csdGridWrapper}`} spacing={0}>
        <Grid item className={`${screeningStyles.csdGridItem}`} xs={12} sm={6}>
          <CustomInputLabel htmlFor='indentifer'>Regulated Entity Category (RA KC or AO) and Identifier (Of the regulated party issuing the security status)</CustomInputLabel>
          <div className={screeningStyles.identityClass}>
            <CustomTextField
              id='identifier'
              fullWidth={true}
              value={screen.pre_screened == 'interline' ? `Received screened from interline - ${screen?.interline_transfer_program?.carrier?.value} \n ${screen?.airline?.code}` : (screen.pre_screened == 'ccsf' ? `Received screened from CCSF- ${screen?.security?.ccsf_name} \n ${screen?.security?.ccsf_approval_number}` : `RA- ${screen?.company?.name}`)}
              disabled={true}
              multiline={true}
              rows={2}
              InputProps={{ classes: { input: styles.p0, } }}
            />
          </div>
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem}`} xs={12} sm={6}>
          <div className={screeningStyles.identifierWrapper}>
            <CustomInputLabel htmlFor='cons_identifier'>Unique Consignment Identifier</CustomInputLabel>
            <CustomTextField
              // label=""
              id='cons_identifier'
              fullWidth={true}
              disabled={true}
              value={screen.code + '-' + screen.number}
              InputProps={{ classes: { input: screeningStyles.fs34, } }}
              //value={ props.consignment.awb }
            />
          </div>
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.fvMiddle} ${styles.gap20}`} xs={12} sm={6}>
          <div className={`${styles.dFlexGrow1} ${props.editRecord.contents ? styles.borderedInput : ''}`}>
            <CustomInputLabel htmlFor='cons_content'>Contents of consignment</CustomInputLabel>
            <CustomTextField
              {...InputProps}
              id='cons_content'
              fullWidth={true}
              name='contents_consignment'
              value={consignmentFields.contents_consignment}
              onChange={onChange}
              error={consignmentFieldErrors.contents_consignment}
              disabled={!props.editRecord.contents}
            />
          </div>
          {props.editRecord.contents ?
            <Stack direction='row' spacing={1} alignItems='center' className={styles.mt3}>
              <div className={screeningStyles.remarksActionIcon} onClick={() => props.closeEditRecord('contents')}>
                <SaveTickIcon />
              </div>
              <div onClick={() => props.closeEditRecord('contents')}>
                <ClearXIcon />
              </div>
            </Stack> :
            <EditIcon className={`${styles.mt3} ${screeningStyles.hidePrint}`}
              onClick={() => props.handleEditRecord('contents')}
            />
          }
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.mt0}`} xs={12} sm={6} >
          <div className={`${styles.fSpaceBtw} ${styles.gap10}`}>
            <div>
              <CustomInputLabel htmlFor='pcs'>Pieces</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                id='pcs'
                disabled={true}
                value={screen.pcs}
                fullWidth={true}
              />
            </div>
            <div>
              <CustomInputLabel htmlFor='wgt'>Weight</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                id='wgt'
                disabled={true}
                value={screen.wgt}
                fullWidth={true}
              />
            </div>
          </div>
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.mt0}`} xs={6} sm={3}>
          <CustomInputLabel htmlFor='org'>Origin</CustomInputLabel>
          <CustomTextField
            {...InputProps}
            id='org'
            fullWidth={true}
            disabled={true}
            value={screen.org}
          />
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.mt0}`} xs={6} sm={3}>
          <CustomInputLabel htmlFor='dest'>Destination</CustomInputLabel>
          <CustomTextField
            {...InputProps}
            id='dest'
            disabled={true}
            fullWidth={true}
            value={screen.dest}
          />
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.fvMiddle} ${styles.gap20}`} xs={12} sm={6}>
          <div className={`${styles.dFlexGrow1} ${styles.mt0} ${props.editRecord.transit ? styles.borderedInput : ''}`}>
            <CustomInputLabel htmlFor='transit'>Transfer / Transit Points (If Known)</CustomInputLabel>
            <CustomTextField
              {...InputProps}
              id='transit'
              fullWidth={true}
              name='transfer'
              value={consignmentFields.transfer}
              onChange={onChange}
              error={consignmentFieldErrors.transfer}
              disabled={!props.editRecord.transit}
            />
          </div>
          {props.editRecord.transit ?
            <Stack direction='row' spacing={1} alignItems='center' className={styles.mt3}>
              <div className={screeningStyles.remarksActionIcon} onClick={() => props.closeEditRecord('transit')}>
                <SaveTickIcon />
              </div>
              <div onClick={() => props.closeEditRecord('transit')}>
                <ClearXIcon />
              </div>
            </Stack> :
            <EditIcon className={`${styles.mt3} ${screeningStyles.hidePrint}`}
              onClick={() => props.handleEditRecord('transit')}
            />
          }
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.mt0}`} xs={12} sm={2}>
          <CustomInputLabel>Security Status (Please select)</CustomInputLabel>
          <Select
            className='basic-single'
            classNamePrefix='select'
            name='security_status'
            value={{
              value: consignmentFields.security_status || '',
              label: consignmentFields.security_status || ''
            }}
            error={consignmentFieldErrors.security_status}
            options={options}
            onChange={({ value }) => {
              props.onChange({
                target: {
                  name: 'security_status',
                  value
                }
              });
            }}
            components={{ DropdownIndicator }}
            styles={{
              ...CustomStyle,
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? 'none' : 'none',
                minHeight: 'auto !important',
                height: 'auto !important',
                border: 'none',
              }),
              singleValue: () => ({
                // fontSize: '24px',
                marginTop: '-3px',
              }),
            }}
          />
        </Grid>
        <Grid item container spacing={0} className={`${styles.p0}`} xs={12} sm={10}>
          <Grid item xs={12} className={`${screeningStyles.csdGridItem} ${styles.textCenter} ${styles.mt0}`}>
            <p className={styles.my0}>Reason for issuing security status</p>
          </Grid>
          <Grid item xs={4} className={`${screeningStyles.csdGridItem}`}>
            <CustomInputLabel className={`${styles.mb2}`}>Received from (Codes)</CustomInputLabel>
            <Typography variant='caption' component='div' lineHeight='normal' fontWeight={400}>{screen.pre_screened == 'ccsf' ? `${screen?.security?.ccsf_name} \n ${screen?.security?.ccsf_approval_number}` : screen?.security?.transportation_profile}</Typography>
          </Grid>
          <Grid item xs={4} className={`${screeningStyles.csdGridItem}`}>
            <CustomInputLabel className={`${styles.mb2}`}>Screening Method</CustomInputLabel>
            {screen.screens?.map(s => {
              if (s.measure && s.measure?.type == 'Screened') {
                return (<Typography key={s.id} variant='caption' component='div' lineHeight='normal' fontWeight={400}>{s?.method?.code} - {s.pcs} Pieces</Typography>);
              }
            })}
          </Grid>
          <Grid item xs={4} className={`${screeningStyles.csdGridItem}`}>
            <CustomInputLabel className={`${styles.mb2}`}>Grounds For Exemption (Codes)</CustomInputLabel>
            {screen.screens?.map(s => {
              if (s.measure && s.measure?.type.includes('Exemption')) {
                return (<Typography key={s.id} variant='caption' component='div' lineHeight='normal' fontWeight={400}>{s?.method?.name} - {s.pcs} Pieces</Typography>);
              }
            })}
          </Grid>
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.mt0}`} xs={12} sm={12}>
          <CustomInputLabel>Other Screening Methods (If Applicable)</CustomInputLabel>
          <Select
            className='basic-single'
            classNamePrefix='select'
            name='other_security_method'
            value={{
              value: consignmentFields.other_security_method || '',
              label: consignmentFields.other_security_method || ''
            }}
            onChange={({ value }) => {
              props.onChange({
                target: {
                  name: 'other_security_method',
                  value
                }
              });
            }}
            error={consignmentFieldErrors.other_security_method}
            options={screeningMethods}
            components={{ DropdownIndicator }}
            styles={{
              ...CustomStyle,
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? 'none' : 'none',
                minHeight: 'auto !important',
                height: 'auto !important',
                border: 'none',
              }),
              singleValue: () => ({
                // fontSize: '24px',
                marginTop: '-3px',
              }),
            }}
          />
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.mt0}`} xs={12} sm={6}>
          <CustomInputLabel className={styles.mb2}>Security Status Issued By</CustomInputLabel>
          <Typography variant='caption' component='div' lineHeight='normal' fontWeight={400}>Name of Employee:  {screen.csd ? screen.csd?.user?.first_name + ' ' + screen.csd?.user?.last_name : ''}
          </Typography>
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.mt0}`} xs={12} sm={6}>
          <CustomInputLabel className={styles.mb2}>Security Status Issued On:</CustomInputLabel>
          <Typography variant='caption' component='div' lineHeight='normal' fontWeight={400}>Date and Time:   {screen?.csd?.date ? moment(screen?.csd?.date).format('DD M YY HH:mm') : ''}
          </Typography>
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.mt0}`} xs={12}>
          <CustomInputLabel>
            Regulated Entity Category (RA, KC, AO) and IDentifier<br />
            (of any regulated party who has accepted the security status given to a consignment by another regulated party)
            <br />
            RA- {screen?.station?.info?.name}\n
            {screen?.station?.info?.address}
          </CustomInputLabel>
        </Grid>
        <Grid item className={`${screeningStyles.csdGridItem} ${styles.fvMiddle} ${styles.gap20}`} xs={12}>
          <div className={`${styles.dFlexGrow1} ${styles.mt0} ${props.editRecord.additional_info ? styles.borderedInput : ''}`}>
            <CustomInputLabel htmlFor='security_info'>Additional Security Information</CustomInputLabel>
            <CustomTextField
              {...InputProps}
              id='security_info'
              fullWidth={true}
              name='security_info'
              value={consignmentFields.security_info}
              onChange={onChange}
              error={consignmentFieldErrors.security_info}
              disabled={!props.editRecord.additional_info}
              multiline={true}
              rows={3}
              minRows={3}
            />
          </div>
          {props.editRecord.additional_info ?
            <Stack direction='row' spacing={1} alignItems='center' className={styles.mt3}>
              <div className={screeningStyles.remarksActionIcon} onClick={() => props.closeEditRecord('additional_info')}>
                <SaveTickIcon />
              </div>
              <div onClick={() => props.closeEditRecord('additional_info')}>
                <ClearXIcon />
              </div>
            </Stack> :
            <EditIcon className={`${styles.mt3} ${screeningStyles.hidePrint}`}
              onClick={() => props.handleEditRecord('additional_info')}
            />
          }
        </Grid>
      </Grid>

      <div className={`${styles.fSpaceBtw} ${styles.mt4}`}>
        <div className={styles.maxWidth200}>
          <CustomButton color='primary' variant='contained' className={styles.minWidth100} onClick={onSave} disabled={!screen?.screens || screen?.screens?.length == 0}>
            Save
          </CustomButton>
          <div className={`${screeningStyles.fs11} ${screeningStyles.logP}`}>
            <p>Log:</p>
            {screen.csd_log && screen.csd_log
              .filter(l => l.type === 'SAVE')
              .map((l, index, arr) => (
                index === arr.length - 1 && (
                  <p key={l}>{l.user.first_name + ' ' + l.user.last_name} {l.date}</p>
                )
              ))}
          </div>
        </div>
        <div className={styles.maxWidth200}>
          <CustomButton color='primary' variant='contained' className={styles.minWidth100} onClick={onEmail} disabled={!screen.csd}>
            Send Email
          </CustomButton>
          <div className={`${screeningStyles.fs11} ${screeningStyles.logP}`}>
            <p>Log:</p>
            {screen.csd_log && screen.csd_log
              .filter(l => l.type === 'MAIL')
              .map((l, index, arr) => (
                index === arr.length - 1 && (
                  <p key={l}>{l.user.first_name + ' ' + l.user.last_name} {l.date}</p>
                )
              ))}
          </div>
        </div>
        <div className={styles.maxWidth200}>
          <CustomButton color='primary' variant='contained' className={styles.minWidth100} onClick={onPrint} disabled={!screen.csd}>
            Print
          </CustomButton>
          <div className={`${screeningStyles.fs11} ${screeningStyles.logP}`}>
            <p>Log:</p>
            {screen.csd_log && screen.csd_log
              .filter(l => l.type === 'PRINT')
              .map((l, index, arr) => (
                index === arr.length - 1 && (
                  <p key={l}>{l.user.first_name + ' ' + l.user.last_name} {l.date}</p>
                )
              ))}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Consignments;