import React from 'react';
import { Grid, IconButton, Stack, Box } from '@mui/material';
import awbStyles from './airwaybill.module.css';
import styles from '../../../../../custom/style.module.css';
import { StyledCard, StyledDataGrid, StyledCardTitle, DataGridProps } from '../../../../../custom/components';
import { Close } from '@mui/icons-material';
import { DocIcon, DownloadIcon } from 'custom/constants';
import env from '../../../../../custom/environments';
import http from 'utils/http';
import moment from 'moment';
import dayjs from 'dayjs';

const columns = [
  {
    field: 'awb',
    headerName: 'Airwaybill',
    type: 'text',
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) => {
      return row.code + '-' + row.number;
    }
  },
  {
    field: 'docs',
    headerName: 'Docs Stream Time',
    type: 'text',
    minWidth: 130,
    flex: 1,
    renderCell: ({ row }) => {
      return row.docs  && row.docs.length ? `${dayjs(row.docs[0].created, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YY HH:mm') }` : '';
    }
  },
  {
    field: 'pcs',
    headerName: 'Pcs',
    type: 'text',
    minWidth: 80,
    flex: 1
  },
  {
    field: 'wgt',
    headerName: 'Wgt',
    type: 'text',
    minWidth: 100,
    flex: 1
  },
  {
    field: 'org',
    headerName: 'Org',
    type: 'text',
    minWidth: 60,
    flex: 1
  },
  {
    field: 'dest',
    headerName: 'Dest',
    type: 'text',
    minWidth: 60,
    flex: 1
  },
  {
    field: 'commodity',
    headerName: 'Commodity',
    type: 'text',
    minWidth: 200,
    className: styles.minWidth100
  },
  {
    field: 'forwarder',
    headerName: 'Forwarder',
    type: 'text',
    minWidth: 200,
    flex: 1,
  },
  {
    field: 'shipper_profile',
    headerName: 'Shipper Profile',
    type: 'text',
    minWidth: 110,
    flex: 1,
  },
  {
    field: 'pcs_screened',
    headerName: 'Pcs Screened?',
    type: 'text',
    minWidth: 100,
    flex: 1,
  },
];

const screeningColumns = [
  {
    field: 'pcs',
    headerName: 'Pieces',
    type: 'text',
    minWidth: 60,
    flex: 1
  },
  {
    field: 'measure',
    headerName: 'Measure',
    type: 'text',
    minWidth: 100,
    flex: 1,
    renderCell: ({ row }) => {
      return row.measure.type;
    }
  },
  {
    field: 'method',
    headerName: 'Method',
    type: 'text',
    minWidth: 100,
    flex: 1,
    renderCell: ({ row }) => {
      return row.method.name;
    }
  },
  {
    field: 'screener',
    headerName: 'Screener',
    type: 'text',
    minWidth: 100,
    flex: 1,
    renderCell: ({ row }) => {
      return row?.user ? `${row?.user?.first_name} ${row?.user?.last_name}` : row?.k9_company?.name;
    }
  },
  {
    field: 'screen_date',
    headerName: 'Screening Date / Time',
    type: 'text',
    minWidth: 140,
    flex: 1,
    renderCell: ({ row }) => {
      return `${moment(row.screen_date).format('DD MMM YY').toUpperCase()} ${row.screen_time}`;
    }
  },
  {
    field: 'alarm',
    headerName: 'Alarm?',
    type: 'text',
    className: styles.minWidth100,
    renderCell: ({ row }) => {
      return row.alarm ? 'Yes' : 'No';
    }
  },
  {
    field: 'resolved',
    headerName: 'Resolved?',
    type: 'text',
    minWidth: 80,
    flex: 1,
  },
  {
    field: 'remark',
    headerName: 'Remarks',
    type: 'text',
    minWidth: 80,
    flex: 1,
    renderCell: ({ row }) => {
      return row.remark || ' ';
    }
  },
];

class ScreeningDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      awbData: {},
      screeningData: []
    };
  }
  componentDidMount() {
    this.fetchAwb();
  }

  fetchAwb = () => {
    http.get('/screen/' + this.props.id).then(({ data }) => {
      if (data.status == 'success') {
        const awbData = data.data;
        const screeningData = awbData && awbData.screens ? awbData.screens : [];
        this.setState({
          awbData: awbData || {},
          screeningData
        });
      }
    });
  };

  handleDownloadPDF = (type) => {
    const pdfUrl = `${env.apiService}awb_screening/${this.state.awbData.id}?type=${type}`;
    const pdfWindow = window.open(pdfUrl);
    pdfWindow.onload = () => {
      pdfWindow.print();
    };
  };

  render() {
    const ROW_HEIGHT = 38;
    const ROW_MARGIN = 16;
    const containerHeight = (this.state.screeningData.length) * (ROW_HEIGHT + ROW_MARGIN);
    return (
      <Box className={awbStyles.details}>
        <StyledCard className={styles.h100}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center' flexGrow={1} flexWrap='wrap'>
                <StyledCardTitle variant='cardTitle' gutterBottom={false} paddingBottom={0}>Screening Details</StyledCardTitle>
                <Stack direction='row' spacing={3} justifyContent='space-around' alignItems='center' sx={{ maxWidth: 'clamp(350px , 70%, 70%)' }}>
                  <IconButton onClick={() => this.handleDownloadPDF('download')} >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton onClick={() => this.handleDownloadPDF('print')} className={awbStyles.actionIcon}>
                    <DocIcon title='Print' />
                  </IconButton>
                  <IconButton
                    aria-label='close'
                    onClick={() => this.props.handlePopover('screeningdetails', false)}
                    sx={{ color: (theme) => theme.palette.grey[500], }}
                  >
                    <Close />
                  </IconButton>
                </Stack>
              </Stack>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <StyledCard className={styles.py0}>
                <StyledCardTitle gutterBottom variant='cardTitle' display='block'>Air Waybill Info</StyledCardTitle>
                <div className={styles.tableWrapper}>
                  <Box className={`${styles.tableOuter} ${awbStyles.screenAwbTableOuter}`}>
                    <StyledDataGrid
                      {...DataGridProps}
                      rows={this.state.awbData.id ? [this.state.awbData] : []}
                      columns={columns}
                    />
                  </Box>
                </div>
              </StyledCard>
            </Grid>
            <Grid item xs={12}>
              <StyledCard className={styles.py0}>
                <StyledCardTitle gutterBottom>Screening Details</StyledCardTitle>
                <div className={styles.tableWrapper}>
                  <Box className={`${styles.tableOuter} ${awbStyles.screenAwbTableOuter}`}>
                    <StyledDataGrid
                      {...DataGridProps}
                      rows={this.state.screeningData}
                      columns={screeningColumns}
                      sx={{ '& .MuiDataGrid-virtualScrollerContent': { height: `${containerHeight}px !important`, }, }}
                    />
                  </Box>
                </div>
              </StyledCard>
            </Grid>
          </Grid>
        </StyledCard>
      </Box>
    );
  }


}
export default ScreeningDetails;