import React from 'react';
import { Dialog, Checkbox, FormGroup } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import admin from '../admin.module.css';
import { CustomButton, CustomCheckbox, CustomDialogAction, CustomDialogContent, CustomDialogTitle, CustomInputLabel, CustomTextField, InputProps, StyledCard } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const ChecklistModal = (props) => {
  const { action, checklistFields, checklistErrors, onChangeChecklist, addChecklist, editChecklist } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'checklist'} onClose={props.onClose}  maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'className={styles.modalTitle}>
          {action == 'add' ? 'Add' : 'Edit'} Checklist
          <CloseIconBtn onClick={props.onClose} />         
        </CustomDialogTitle>
        <form onSubmit={action == 'add' ? addChecklist : editChecklist} onReset={props.onClose} className={styles.w100}>
          <CustomDialogContent>
            <StyledCard>
              <CustomInputLabel htmlFor='checklist'>Check Description</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                fullWidth
                id='checklist'
                className={`${admin.addTextField} ${styles.mb3}`}
                placeholder='Check Description'
                type='text'
                onChange={onChangeChecklist}
                name='txt'
                value={checklistFields.txt}
                error={checklistErrors.txt}
                helperText={checklistErrors.txt}
              />
              <FormGroup aria-label='check-description' title='Check Options' row>
                <CustomCheckbox control={<Checkbox size='small' />} label='Yes' name='opt_yes' checked={checklistFields.opt_yes} onChange={onChangeChecklist} />
                <CustomCheckbox control={<Checkbox size='small' />} label='No' name='opt_no' checked={checklistFields.opt_no} onChange={onChangeChecklist} />
                <CustomCheckbox control={<Checkbox size='small' />} label='N/A' name='opt_na' checked={checklistFields.opt_na} onChange={onChangeChecklist} />
              </FormGroup>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' type='reset'>Close</CustomButton>
            <CustomButton variant='contained' type='submit'> Submit </CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default ChecklistModal;