import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import headerStyles from './header.module.css';
import styles from '../../custom/style.module.css';
import { menulist } from '../../custom/constants';
import { openedMixin } from './headerconstants';
import { Link } from 'react-router-dom';
import cookies from 'utils/cookies';
import { ExitToApp } from '@mui/icons-material';

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: 0,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 2),
  height: '200px',
  marginBottom: '10px',
  flexDirection: 'column',
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, sidebarwidth }) => ({
    width: sidebarwidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    margin: 0,
    marginTop: `calc(-${theme.spacing(3)} + 1px)`,
    ...(open && {
      ...openedMixin(theme, sidebarwidth),
      '& .MuiDrawer-paper': openedMixin(theme, sidebarwidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
  }),
);

const ListItemStyle = () => ({
  display: 'flex',
  alignItems: 'center',
  height: '70px',
  color: 'var(--grey)',
  borderBottom: '1px solid #c9c9c93d',
  px: 3
});

const ListStyle = () => ({
  height: 'calc(100vh - 270px - var(--splayout) - var(--headerheight))',
  overflow: 'auto',
});
const SideBar = (props) => {
  const { sidebarwidth, open, theme, handleDrawerOpen } = props;
  const role = cookies.get('role');
  return (
    <div className={headerStyles.headerSidbarWrapper}>
      <Box sx={{
        display: 'flex',
        position: 'fixed',
        zIndex: 100
      }}>
        <CssBaseline />
        <div className={open ? headerStyles.overlay : ''} onClick={handleDrawerOpen}></div>
        <Drawer variant='permanent' anchor='left' open={open} sidebarwidth={sidebarwidth} className={headerStyles.sidebar}>
          <DrawerHeader>
            <Typography gutterBottom variant='h6' component='h6' marginBottom={0} color={'white'}>{cookies.get('name')}</Typography>
            <Typography variant='subtitle1' component='span' fontSize={'0.9rem'} color={theme.palette.common.white}>{cookies.get('role')}</Typography>
          </DrawerHeader>
          <button className={ headerStyles.signOutBtn } onClick={ props.signout } >           
            <ExitToApp className={headerStyles.signOut} />
                Sign Out 
          </button>
          <Divider />
          <Box className={headerStyles.menuTitle}>MENU</Box>
          <List sx={ListStyle} className={headerStyles.menuList}>
            {menulist.map((menu) => {
              if (menu.roles.includes(role)) {
                return <ListItem as={Link} to={menu.link} onClick={handleDrawerOpen} key={menu.key} disablePadding sx={ListItemStyle}>
                  <ListItemButton
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      color: 'white'
                    }}
                    disableRipple
                  >
                    <ListItemIcon
                      className={`${styles.whiteSvg} ${headerStyles.menuIcon}`}
                      sx={{
                        minWidth: '24px',
                        width: '24px',
                        marginRight: theme.spacing(6),
                      }}
                    >
                      {menu.icon}
                    </ListItemIcon>
                    <Typography variant='subtitle2' component='span' color={theme.palette.common.white}>
                      {menu.title}
                    </Typography>
                  </ListItemButton>
                </ListItem>;
              }
            })}
          </List>
        </Drawer>
      </Box>
    </div>
  );
};

export default SideBar;
