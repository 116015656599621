import { SET_SCREEN } from './actionTypes';

function setScreen(data) {
  return {
    type: SET_SCREEN,
    data
  };
}

function setScreenAction(data) {
  return (dispatch) => {
    dispatch(setScreen(data));
  };
}


export { setScreenAction };