import React from 'react';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TickIcon, UnTickIcon, screeningTabs } from '../../../custom/constants';
import { CustomTab } from '../../../custom/components';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';
import ConsignmentContainer from './tabs/consignmentsecuritydeclaration/ConsignmentContainer';
import ScreeningDetailsContainer from './tabs/screeningdetails/ScreeningDetailsContainer';
import { Grid } from '@mui/material';
import styles from '../../../custom/style.module.css';
import screeningStyles from './screen.module.css';
import AwbInfoContainer from './tabs/awbinfo/AwbInfoContainer';
import SearchContainer from './search/SearchContainer';

const Screen = (props) => {
  const { tab, handleTabChange } = props;
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={tab !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 2 }}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <SearchContainer fetchAwb={props.fetchAwb} />
      <Grid container spacing={3} columnSpacing={3}>
        <Grid item xs={12}>
          <AwbInfoContainer />
        </Grid>
        <Grid item xs className={`${screeningStyles.methodCol}`}>
          <Box className={screeningStyles.hidePrint}>
            <Tabs
              value={tab}
              onChange={(_, v) => handleTabChange(v)}
              variant='fullWidth'
              aria-label='basic tabs example'
              indicatorColor='transparent'
            >
              <CustomTab className={styles.positionRelative} label={<div>{screeningTabs.screening_details} <span className={screeningStyles.tabIcon}>{props.is_screened ? <TickIcon /> : <UnTickIcon />}</span> </div>} {...a11yProps(1)} />
              <CustomTab className={styles.positionRelative} label={<div>{screeningTabs.consignment_security} <span className={screeningStyles.tabIcon}>{props.is_printed ? <TickIcon /> : <UnTickIcon />}</span> </div>}  {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={1}>
            <ScreeningDetailsContainer />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <ConsignmentContainer />
          </TabPanel>
        </Grid>
      </Grid>
      <ConfirmModalContainer />
    </>
  );
};

export default Screen;
