const imgExtensions = ['jpg', 'png', 'jpeg'];
const docExtensions = ['pdf'];

function getExtension(fileName) {
  return fileName.split('.')[fileName.split('.').length - 1];
}

function isImg(fileName) {
  return imgExtensions.indexOf(getExtension(fileName)) !== -1;
}

function isDoc(fileName) {
  return docExtensions.indexOf(getExtension(fileName)) !== -1;
}

export { isImg, isDoc, getExtension };