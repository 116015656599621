import React from 'react';
import { ClearXIcon, DateFormat, Required, SaveTickIcon, WhiteCameraIcon, WhiteDocIcon, SearchIcon } from 'custom/constants';
import { CustomButton, CustomDatePicker, CustomSelect, CustomTextField, InputProps, StyledBlueCard, StyledBlueCardTitle, TableBadge } from 'custom/components';
import { Grid, IconButton, InputAdornment, MenuItem, Stack, TextField, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from 'custom/style.module.css';
import screeningStyles from '../../screen.module.css';
import moment from 'moment';
import AttachmentModal from '../../AttachmentModal';
import dayjs from 'dayjs';
import cookies from 'utils/cookies';
import CcsfModal from './CcsfModal';

export const WhiteCustomButton = styled(CustomButton)(() => ({
  '&.MuiButton-contained:not(.Mui-disabled)': {
    backgroundColor: '#c8c8c8',
    color: 'black',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: '#c8c8c8',
      color: 'black',
    },
  },
  border: '1px solid #c8c8c8',
  color: '#c8c8c8',
  '&:hover': {
    border: '1px solid #c8c8c8',
    color: '#c8c8c8',
  },
}));

export const CustomWhiteDatePicker = styled(CustomDatePicker)(() => ({
  '& .MuiInputBase-root': {
    borderRadius: '0px',
    backgroundColor: 'transparent',
    '& .MuiInputBase-input': {
      color: 'white',
      fontSize: '13px',
      minWidth: '80px',
    },
    '& .MuiInputAdornment-root': { '& .MuiSvgIcon-root': { fontSize: '1rem', } }
  }
}));

export const CustomWhiteSelect = styled(CustomSelect)(() => ({
  '&.MuiInputBase-root': { '&:hover:not(.Mui-disabled, .Mui-error)::before, &:not(.Mui-disabled, .Mui-error):before': { borderBottom: '1px solid white' }, },
  '& .MuiInputBase-input': {
    color: 'white',
    backgroundColor: 'transparent',
  },
}));

let mandatoryFieldsChecker = false;

export function getData() {
  return mandatoryFieldsChecker;
}

export function setData(value) {
  mandatoryFieldsChecker = value;
}

let hasUpdated = false;

const AwbInfo = (props) => {
  const { data, onChange, handleUpdate, dataError, handleUpload, airlines, ccsfModal } = props;
  const mandatory_fields = cookies.get('mandatory_fields') || [];

  const mfc = mandatory_fields.slice(1).map((field) => data[field]);
  if (mfc.every((value) => value != null && value != 0)) {
    setData(true);
    if (!hasUpdated) {
      hasUpdated = true;
      // handleUpdate();
    }
  } else {
    setData(false);
    hasUpdated = false;
  }

  return (<>
    <AttachmentModal
      modal={props.modal}
      handleModal={props.handleModal}
      docs={data.docs || []}
    />
    <CcsfModal
      anchorEl={props.anchorEl}
      open={ccsfModal}
      handleClose={props.closeModal}
      awb={props.data}
      carrierList={props.carrierList}
      carrierNumber={props.carrierNumber}
      onChange={props.onChange}
      handleSecurity={props.handleSecurity}
      checkElement={props.checkElement}
      checkTransporationNumber={props.checkTransporationNumber}
      handleUpdate={props.handleUpdate}
      handleSetCarrier={props.handleSetCarrier}
      updateScreened={props.updateScreened}
    />

    <StyledBlueCard className={`${screeningStyles.documentCol} ${screeningStyles.hidePrint}`}>
      <Stack direction='row' className={styles.mb2} spacing={4}>
        <StyledBlueCardTitle className={styles.pr2}> Airwaybill Info</StyledBlueCardTitle>
        <Stack spacing={3} direction='row' alignItems='center' justifyContent='space-between' margin={'0 6px'} flex={100} className={screeningStyles.screeningInfoIcon}>
          <Stack direction='row' alignItems='center' className={styles.fAuto}>
            <span
              className={data.remark ? screeningStyles.remarksIconGreen : screeningStyles.remarksIcon}
              onClick={() => props.handleEditRecord('remark')}
            >
              R
            </span>
            <Zoom direction='right' in={props.editRecord.remark}>
              <CustomTextField
                {...InputProps}
                id='remark'
                fullWidth
                focused
                autoFocus
                placeholder='Remarks: If you enter any remarks here, click the green check mark to save'
                type='text'
                name='remark'
                value={data.remark}
                onChange={onChange}
                className={`${styles.brInput} ${styles.smInput} ${screeningStyles.remarksInput}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' >
                      <Stack direction='row' spacing={1} alignItems='center' sx={{ height: '0.8rem' }}>
                        <div className={screeningStyles.remarksActionIcon} onClick={() => handleUpdate('remark')}>
                          <SaveTickIcon />
                        </div>
                        <div onClick={() => props.closeEditRecord('remark')} className={styles.fs0}>
                          <ClearXIcon />
                        </div>
                      </Stack>
                    </InputAdornment>
                  )
                }}
                inputProps={{ 'aria-label': 'Remarks' }}
              />
            </Zoom>
          </Stack>

          <div className={styles.fAuto}>
            <input
              accept='image/*'
              style={{ display: 'none' }}
              id='awb-file'
              type='file'
              name='file'
              onChange={handleUpload}
            />
            <label htmlFor='awb-file'>
              <WhiteCameraIcon />
            </label>
          </div>

          <div className={`${styles.fAuto} ${styles.fJustifyEnd}`} onClick={() => props.handleModal('attachment')}>
            <TableBadge color='error' badgeContent={(props.data.docs?.length)}>
              <WhiteDocIcon />
            </TableBadge>
          </div>
        </Stack>
      </Stack>
      <Grid container spacing={2} columnSpacing={{
        xs: 2,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4
      }}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} order={1}>
          <div className={screeningStyles.docLabel}>
            <div className={screeningStyles.docName}>awb {mandatory_fields?.includes('awb_no') && Required}</div>
            <div className={screeningStyles.docWrapper}>
              <div className={screeningStyles.docVal}>{data.code}-{data.number}</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} order={2}>
          <div className={screeningStyles.docLabel} onClick={() => props.handleEditRecord('pcs')} onFocus={() => props.handleEditRecord('pcs')}>
            <div className={`${screeningStyles.docName} ${!data.pcs && screeningStyles.textDanger}`}>pcs {mandatory_fields?.includes('pcs') && Required}</div>
            <div className={screeningStyles.docWrapper}>
              {props.editRecord.pcs ?
                <TextField
                  id=''
                  variant='standard'
                  fullWidth
                  autoFocus
                  placeholder=''
                  type='text'
                  focused
                  name='pcs'
                  onChange={onChange}
                  error={dataError.pcs}
                  helperText={dataError.pcs}
                  value={data.pcs}
                  className={`${styles.whiteIp} ${styles.fs14}`}
                />
                : <div className={screeningStyles.docVal}>
                  <span>{data.pcs}</span>
                  <IconButton>
                  </IconButton>
                </div>}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}xl={2} order={3}>
          <div className={screeningStyles.docLabel} onClick={() => props.handleEditRecord('wgt')} onFocus={() => props.handleEditRecord('wgt')}>
            <div className={`${screeningStyles.docName} ${!data.wgt && screeningStyles.textDanger}`}>wgt {mandatory_fields?.includes('wgt') && Required}</div>
            <div className={screeningStyles.docWrapper}>
              {props.editRecord.wgt ?
                <TextField
                  id=''
                  variant='standard'
                  fullWidth
                  autoFocus
                  placeholder=''
                  type='text'
                  focused
                  name='wgt'
                  onChange={onChange}
                  error={dataError.wgt}
                  helperText={dataError.wgt}
                  value={data.wgt}
                  className={`${styles.whiteIp} ${styles.fs14}`}
                />
                : <div className={screeningStyles.docVal}>
                  <span>{data.wgt} K</span>
                  <IconButton>
                  </IconButton>
                </div>}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} order={{
          xs: 4,
          sm: 5,
          lg: 5,
          xl: 4,
        }}>
          <div className={screeningStyles.docLabel} onClick={() => props.handleEditRecord('flight')} onFocus={() => props.handleEditRecord('flight')}>
            <div className={`${screeningStyles.docName} ${(!data.airlineId || !data.flight_no || !data.flight_date) && screeningStyles.textDanger}`}>flight {mandatory_fields?.includes('flight') && Required}</div>
            <div className={screeningStyles.docWrapper}>
              {props.editRecord.flight ?
                (
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <CustomWhiteSelect
                      variant='standard'
                      labelId='flight-label'
                      id=''
                      name='airlineId'
                      onChange={onChange}
                      value={Number(data.airlineId) || ''}
                      disableUnderline
                      MenuProps={{ classes: { paper: styles.selectPaperIp } }}
                    >
                      {airlines && airlines.map((airline, i) => {
                        if (props.flightSettings?.limit_input) {
                          let find = props.scheduleList?.find((schdl) => schdl.airline == airline.code);
                          if (find) {
                            return (<MenuItem key={i} value={airline.id}>{airline.code}</MenuItem>);
                          }
                        } else {
                          return (<MenuItem key={i} value={airline.id}>{airline.code}</MenuItem>);
                        }
                      })}

                    </CustomWhiteSelect>
                    {props.flightSettings?.limit_input && (<CustomWhiteSelect
                      variant='standard'
                      id=''
                      name='flight_no'
                      onChange={onChange}
                      value={data?.flight_no}
                      disableUnderline
                      MenuProps={{ classes: { paper: styles.selectPaperIp } }}
                    >
                      {props.scheduleList && props.scheduleList.map((schdl, i) => {
                        let airline = airlines?.find((airline) => airline.id == Number(data.airlineId));
                        if (airline?.code == schdl.airline)
                          return (<MenuItem key={i} value={schdl.flight_no}>{schdl.flight_no}</MenuItem>);
                      })}

                    </CustomWhiteSelect>)}
                    {!props.flightSettings?.limit_input && (<TextField
                      id=''
                      variant='standard'
                      autoFocus
                      placeholder='Flight No'
                      type='text'
                      name='flight_no'
                      onChange={onChange}
                      value={data?.flight_no}
                      className={`${styles.whiteIp} ${styles.fs14} ${styles.mx2}`}
                      focused
                      error={dataError.flight}
                      helperText={dataError.flight}
                      sx={{ marginTop: '2px' }}
                    />)}
                    <CustomWhiteDatePicker
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'Flight Date',
                          variant: 'standard',
                        },
                      }}
                      format={DateFormat}
                      name='flight_date'
                      onChange={(v) => props.onChange({
                        target: {
                          type: 'date',
                          name: 'flight_date',
                          value: v?.$d ? moment(v.$d).format('YYYY-MM-DD') : null
                        }
                      })}
                      value={data.flight_date ? dayjs(data.flight_date, 'YYYY-MM-DD') : null}
                      // error={ }
                      // helperText={ }
                      minDate={props.flightSettings?.limit_input ? props.minDate : null}
                      maxDate={props.flightSettings?.limit_input ? props.maxDate : null}
                      shouldDisableDate={(date) => {
                        if (props.flightSettings?.limit_input) {
                          let disable = true;
                          if (props.flightDates?.includes(moment(date.$d).format('YYYY-MM-DD'))) {
                            disable = false;
                          }
                          return disable;
                        } else {
                          return false;
                        }
                      }
                      }
                    />
                    {/* <div className={`${screeningStyles.remarksActionIcon} ${styles.ml3}`} onClick={() => handleUpdate('flight')}>
                    <SaveTickIcon />
                  </div> */}
                  </Stack>
                )
                : <div className={screeningStyles.docVal}>
                  <span>{data?.airline?.code}{data.flight_no}/{moment(data.flight_date || null).format('DDMMMYY').toUpperCase()}</span>
                  <IconButton>
                  </IconButton>
                </div>}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} order={{
          xs: 5,
          sm: 4,
          lg: 4,
          xl: 5,
        }}>
          <div className={screeningStyles.docLabel} onClick={() => props.handleEditRecord('commodity')} onFocus={() => props.handleEditRecord('commodity')}>
            <div className={`${screeningStyles.docName} ${!data.commodity && screeningStyles.textDanger}`}>comm {mandatory_fields?.includes('commodity') && Required}</div>
            <div className={screeningStyles.docWrapper}>
              {props.editRecord.commodity ?
                <TextField
                  id=''
                  variant='standard'
                  fullWidth
                  autoFocus
                  placeholder=''
                  type='text'
                  className={`${styles.whiteIp} ${styles.fs14}`}
                  focused
                  name='commodity'
                  value={data.commodity}
                  onChange={onChange}
                  error={dataError.commodity}
                  helperText={dataError.commodity}
                />
                : <div className={screeningStyles.docVal}>
                  <span>{data.commodity}</span>
                  <IconButton>
                  </IconButton>
                </div>}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} order={6}>
          <div className={screeningStyles.docLabel} onClick={() => props.handleEditRecord('org')} onFocus={() => props.handleEditRecord('org')}>
            <div className={`${screeningStyles.docName} ${!data.org && screeningStyles.textDanger}`}>org</div>
            <div className={screeningStyles.docWrapper}>
              {props.editRecord.org ?
                <TextField
                  id=''
                  variant='standard'
                  fullWidth
                  autoFocus
                  placeholder=''
                  type='text'
                  focused
                  name='org'
                  onChange={onChange}
                  // onBlur={() => props.handleUpdate('org')}
                  value={data.org}
                  className={`${styles.whiteIp} ${styles.fs14}`}
                />
                : <div className={screeningStyles.docVal}>
                  <span>{data.org}</span>
                  <IconButton>
                  </IconButton>
                </div>}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} order={7}>
          <div className={screeningStyles.docLabel} onClick={() => props.handleEditRecord('dest')} onFocus={() => props.handleEditRecord('dest')}>
            <div className={`${screeningStyles.docName} ${!data.dest && screeningStyles.textDanger}`}>dest</div>
            <div className={screeningStyles.docWrapper}>
              {props.editRecord.dest ?
                <TextField
                  id=''
                  variant='standard'
                  fullWidth
                  autoFocus
                  placeholder=''
                  type='text'
                  focused
                  name='dest'
                  onChange={onChange}
                  // onBlur={() => props.handleUpdate('dest')}
                  value={data.dest}
                  className={`${styles.whiteIp} ${styles.fs14}`}
                />
                : <div className={screeningStyles.docVal}>
                  <span>{data.dest}</span>
                  <IconButton>
                  </IconButton>
                </div>}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} order={{
          xs: 8,
          sm: 9,
          lg: 9,
          xl: 8,
        }}>
          <div className={screeningStyles.docLabel} onClick={() => props.handleEditRecord('pre_screened')} onFocus={() => props.handleEditRecord('pre_screened')}>
            <div className={`${screeningStyles.docName} ${styles.pr3} ${styles.minWidth100} ${!data.pre_screened && screeningStyles.textDanger}`}>pre-screen</div>
            <div className={`${screeningStyles.docWrapper} ${screeningStyles.prescreen} ${styles.pl0} `} >
              {(
                <div className={screeningStyles.docVal}>
                  <span>{data.pre_screened == 'interline' ? 'Interline' : data.pre_screened == 'ccsf' ? 'CCSF' : 'Not Screened'}</span>
                  <div className={screeningStyles.iconButtonWrapper}>
                    <IconButton onClick={(event) => props.handleOpen(event.target)} size='small' className={`${styles.whiteSvg} ${screeningStyles.searchIconAwb}`}>
                      <SearchIcon />
                    </IconButton>
                  </div>
                </div>
              )}

            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} order={{
          xs: 9,
          sm: 8,
          lg: 8,
          xl: 9,
        }}>
          <div className={screeningStyles.docLabel} onClick={() => props.handleEditRecord('forwarder')} onFocus={() => props.handleEditRecord('forwarder')} >
            <div className={`${screeningStyles.docName} ${!data.forwarder && screeningStyles.textDanger}`}>fwdr</div>
            <div className={screeningStyles.docWrapper}>
              {props.editRecord.forwarder ?
                <TextField
                  id=''
                  variant='standard'
                  fullWidth
                  autoFocus
                  placeholder=''
                  type='text'
                  className={`${styles.whiteIp} ${styles.fs14}`}
                  focused
                  name='forwarder'
                  onChange={onChange}
                  // onBlur={() => props.handleUpdate('forwarder')}
                  value={data.forwarder}
                />
                : <div className={screeningStyles.docVal}>
                  <span>{data.forwarder}</span>
                  <IconButton>
                  </IconButton>
                </div>}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2} order={10} >
          <div className={screeningStyles.docLabel} onClick={() => props.handleEditRecord('shipper_profile')} onFocus={() => props.handleEditRecord('shipper_profile')} >
            <div className={`${screeningStyles.docName} ${!data.shipper_profile && screeningStyles.textDanger}`}>shpr{mandatory_fields?.includes('shipper_profile') && Required}</div>
            <div className={screeningStyles.docWrapper}>
              {props.editRecord.shipper_profile ?
                <TextField
                  id=''
                  variant='standard'
                  fullWidth
                  autoFocus
                  placeholder=''
                  type='text'
                  className={`${styles.whiteIp} ${styles.fs14}`}
                  focused
                  name='shipper_profile'
                  onChange={onChange}
                  onBlur={() => props.handleUpdate()}
                  value={data.shipper_profile}
                />
                : <div className={screeningStyles.docVal}>
                  <span>{data.shipper_profile}</span>
                  <IconButton>
                  </IconButton>
                </div>}
            </div>
          </div>
        </Grid>
        <Grid item xs order={11}>
          <WhiteCustomButton variant='outlined' size='small' className={`${styles.mlauto} ${styles.dBlock}`} onClick={props.handleUpdate}>Update</WhiteCustomButton>
        </Grid>
      </Grid>
    </StyledBlueCard>
  </>);
};

export default AwbInfo;