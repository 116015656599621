import React from 'react';
import K9Transfer from './K9Transfer';
import AddTransferDoc from './AddTransferDocModal';
import { Box } from '@mui/material';
import TransferCompletionWorksheet from './TransferCompletionWorksheet';
import DateFilter from './DateFilter';
import http from 'utils/http';
import cookies from 'utils/cookies';
import dayjs from 'dayjs';
import { setDeleteAction } from 'store/actions/deleteAction';
import { setDocAction } from 'store/actions/docAction';
import env from '../../../custom/environments';
import { connect } from 'react-redux';
import DocModalContainer from './doc/DocModalContainer';
import events from 'utils/evemts';
const userRoles = [
  'System Admin',
  'Manager',
  'Supervisor'
];

class K9TransferContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      k9Companies: [],
      pendingAwbs: [],
      fetchedOriginalAwbs: [],
      searchOptions: {
        start: new Date(),
        end: new Date(),
      },
      k9TransferList: [],
      completionFields: { transfer_awbs: [] },
      checklist: [],
      filterOptions: {
        searchText: '',
        airlineCode: '',
        preScreened: false
      },
      airlineList: [],
      managerRole: false,
      transfer: {}
    };
  }

  componentDidMount() {
    this.fetchK9();
    this.fetchAwbs();
    this.fetchTransferList();
    this.fetchChecklist();
    this.fetchAirlines();
    this.eventSource = events('k9_transfer', null);
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.action == 'add_transfer') {
        this.setState({ k9TransferList: [...this.state.k9TransferList, msg.data] });
      }
      if (msg.action == 'update_transfer') {
        const k9TransferList = [...this.state.k9TransferList];
        const index = k9TransferList.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          k9TransferList[index] = {
            ...msg.data,
            docs: k9TransferList[index].docs
          };
          this.setState({
            k9TransferList,
            completionFields: msg.data
          });
        } else {
          this.setState({ k9TransferList: [...this.state.k9TransferList, msg.data] });
        }
      }

      if (msg.action == 'add_transfer_doc' || msg.action == 'update_transfer_doc' || msg.action == 'delete_transfer_doc') {
        const k9TransferList = [...this.state.k9TransferList];
        const index = k9TransferList.findIndex(c => msg.data && c.id == msg.data.transferId);
        if (msg.action == 'add_transfer_doc' && index > -1) {
          k9TransferList[index] = {
            ...k9TransferList[index],
            docs: [...k9TransferList[index].docs, msg.data]
          };
          this.setState({ k9TransferList });
        }
        if ((msg.action == 'update_transfer_doc' || msg.action == 'delete_transfer_doc') && index > -1) {
          let docs = [...k9TransferList[index].docs];
          const i = docs.findIndex(c => c.id == msg.data.id);
          if (msg.action == 'update_transfer_doc') {
            if (i > -1) {
              docs[i] = {
                ...msg.data,
                added_by: docs[i].added_by
              };
              k9TransferList[index].docs = docs;
              this.setState({ k9TransferList });
            } else {
              docs.push({
                ...msg.data,
                added_by: {}
              });
              k9TransferList[index].docs = docs;
              this.setState({ k9TransferList });
            }
          }
          if (msg.action == 'delete_transfer_doc') {
            if (i > -1) {
              docs.splice(i, 1);
              k9TransferList[index].docs = docs;
              this.setState({ k9TransferList });
            }
          }
        }
        if (this.props.doc.modal == 'transfer' && this.props.doc.id == msg.data.transferId && index > -1) {
          this.props.setDoc({
            modal: 'transfer',
            id: k9TransferList[index].id,
            name: k9TransferList[index].name,
            data: k9TransferList[index].docs
          });
        }
      }
    };
    if (userRoles.includes(cookies.get('role'))) {
      this.setState({ managerRole: true });
    }
  }

  fetchAirlines = () => {
    http.get('/airline')
      .then((response) => {
        this.setState({ airlineList: response.data.data });
      });
  };

  componentWillUnmount() {
  }

  fetchAwbs = () => {
    let url = `/awbs?start=${dayjs(this.state.searchOptions.start).format('YYYYMMDD')}&end=${dayjs(this.state.searchOptions.end).format('YYYYMMDD')}`;
    http.get(url, this.state.searchOptions)
      .then((response) => {
        // handle success
        const completionFields = { ...this.state.completionFields };
        completionFields.transfer_awbs = [];
        this.setState({
          pendingAwbs: response.data.data,
          fetchedOriginalAwbs: response.data.data,
          completionFields
        });
      });
  };

  transferItem = (type, index) => {
    // Define your arrays
    const pendingAwbs = [...this.state.pendingAwbs];
    const completionFields = { ...this.state.completionFields };

    // Transfer item from Array 1 to Array 2
    if (type === 'pending') {
      if (index >= 0 && index < pendingAwbs.length) {
        const item = pendingAwbs.splice(index, 1)[0];
        completionFields.transfer_awbs.push(item);
      } else {
        console.log('Invalid index for Array 1');
      }
    }

    // Transfer item from Array 2 to Array 1
    else if (type === 'list') {
      if (index >= 0 && index < completionFields.transfer_awbs.length) {
        const item = completionFields.transfer_awbs.splice(index, 1)[0];
        pendingAwbs.push(item);
      } else {
        console.log('Invalid index for Array 2');
      }
    }

    // Print the updated arrays
    this.setState({
      pendingAwbs,
      completionFields
    });
  };

  handleChange = (e, input) => {

    const name = e.target ? e.target.name : input;
    let value = e.target ? e.target.value : e;
    this.setState({
      completionFields: {
        ...this.state.completionFields,
        [name]: value
      }
    });
  };

  handleInputChange = (e, row) => {
    const updatedValue = e.target.value;
    const updatedField = e.target.name;
    const updatedRow = {
      ...row,
      [updatedField]: updatedValue
    };

    this.setState((prevState) => {
      const updatedRows = prevState.completionFields.transfer_awbs.map((r) => (r.id === row.id ? updatedRow : r));
      return {
        ...prevState,
        completionFields: {
          ...prevState.completionFields,
          transfer_awbs: updatedRows
        },
      };
    });
  };


  handleOpen = (modal, transferData) => {

    let completionFields = { ...this.state.completionFields };
    if (modal == 'completionWorksheet' || modal == 'transferDocEdit') {
      completionFields = transferData;
      if (!completionFields.checklist) {
        completionFields.checklist = this.state.checklist;
      }
    }
    this.setState({
      modal,
      completionFields
    });
    console.log(this.state.modal, modal, this.state.pendingAwbs);
  };


  fetchChecklist = () => {
    http.get(`/checklist?station=${cookies.get('station')}`).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ checklist: data.data });
      }
    });
  };

  handleCheck = (e, check) => {
    const { name } = e.target;

    // Update the answer and description based on the selected option
    let answer = '';
    let description = '';

    if (name === 'opt_yes') {
      answer = 'Yes';
      description = 'User provided description for Yes option';
    } else if (name === 'opt_no') {
      answer = 'No';
      description = 'User provided description for No option';
    } else if (name === 'opt_na') {
      answer = 'N/A';
      description = 'User provided description for N/A option';
    }


    // Update the checklist item with the new answer and description
    const checklist = this.state.completionFields.checklist.map((item) => {
      if (item === check) {
        return {
          ...item,
          answer,
          description,
        };
      }
      return item;
    });

    // Call a function to update the state with the updated checklist
    this.setState({
      completionFields: {
        ...this.state.completionFields,
        checklist
      }
    });
  };

  applyFilters = () => {
    let filteredData = [...this.state.fetchedOriginalAwbs];
    const { searchText, airlineCode, preScreened } = this.state.filterOptions;
    // Apply search filter

    filteredData = filteredData.filter((item) => {
      const { code, number, commodity, airline } = item;
      const searchTerm = searchText.toLowerCase();
      const awbNumber = `${code}-${number}`; // Combine code and number
      return (
        awbNumber.toLowerCase().includes(searchTerm) ||
        commodity.toLowerCase().includes(searchTerm) ||
        airline?.code.toLowerCase().includes(searchTerm)
      );
    });

    // Apply Departing Airline Code filter
    if (airlineCode) {
      filteredData = filteredData.filter((item) => {
        return item.airline?.code === airlineCode;
      });
    }

    // Apply Exclude Pre-screened filter
    if (preScreened) {
      filteredData = filteredData.filter((item) => {
        return item.pre_screened !== 'yes';
      });
    }

    this.setState({ pendingAwbs: filteredData });
  };

  clearSearch = () => {
    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        searchText: ''
      }
    });
  };


  handleFilterChange = (event, type) => {
    let value = event.target ? event.target.value : event;
    const name = event.target ? event.target.name : event;

    if (type == 'filter') {
      if (name == 'preScreened') {
        value = !this.state.filterOptions.preScreened;
      }
      this.setState((prevState) => ({
        filterOptions: {
          ...prevState.filterOptions,
          [name]: value
        }
      }), () => {
        if ((value === '' || value === null || value === false)) {
          // Reset the pendingAwbs array to its original state
          this.fetchAwbs();
        } else {
          if (name != 'searchText') {
            this.applyFilters();
          }
        }

      });
    } else {
      this.setState((prevState) => ({
        searchOptions: {
          ...prevState.searchOptions,
          [type]: value
        }
      }), () => {
        this.fetchAwbs();
        this.fetchTransferList();
      });
    }
  };

  handleDoc = (row) => {
    this.setState({ transfer: row });
    this.props.setDoc({
      modal: 'transfer',
      id: row.id,
      name: row.number,
      data: row.docs
    });
  };

  fetchK9 = () => {
    http.get('/k9_company').then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ k9Companies: data.data });
      }
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      selectK9: 0,
      completionFields: { transfer_awbs: [] }
    });
    this.fetchAwbs();
  };

  fetchTransferList = () => {
    http.get(`/k9_transfer?start=${dayjs(this.state.searchOptions.start).format('YYYYMMDD')}&end=${dayjs(this.state.searchOptions.end).format('YYYYMMDD')}`).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ k9TransferList: data.data });
      }
    });
  };

  isFormValid() {
    const { completionFields } = this.state;
    const checklist = completionFields.checklist;
    const isChecklistDone = checklist && checklist.length > 0;
    const allQuestionsAnswered = isChecklistDone && checklist.every((question) => {
      // Check if at least one of the options (opt_yes, opt_no, opt_na) is true
      return question.answer;
    });
    return (
      completionFields.handler_name &&
      completionFields.name &&
      completionFields.completed &&
      allQuestionsAnswered
    );
  }

  validation = (formdata) => {
    const error = {};
    let formValid = true;
    if (!formdata.k9_companyId) {
      formValid = false;
      error.k9_companyId = 'Please select a k9 comapny';
    }

    if (!formdata.transfer_awbs || formdata.transfer_awbs.length == 0) {
      formValid = false;
      error.transfer_awbs = 'Please Transfer awbs';
    }
    return formValid;
  };

  createTransfer = async (e) => {
    e.preventDefault();
    if (this.validation(this.state.completionFields)) {
      if (this.state.completionFields.id) {
        this.updateK9Transfer(e);
      } else {
        await http.post('/k9_transfer', this.state.completionFields)
          .then(() => {
            this.onClose();
          });
      }

    }
  };

  generatePdf = (id) => {
    const url = `${env.apiService}transfer/${id}/file`;
    window.open(url);
  };

  updateK9Transfer = async (e, type) => {
    e.preventDefault();
    // let formData = 
    await http.put('/k9_transfer/' + this.state.completionFields.id + `?type=${type}`, this.state.completionFields)
      .then(() => {

      });
  };

  render() {
    const isFinalButtonEnabled = this.isFormValid();

    return (
      <>
        <DateFilter
          handleChange={this.handleFilterChange}
          searchOptions={this.state.searchOptions}
        />
        <Box sx={{ position: 'relative' }}>
          <DocModalContainer transfer={ this.state.transfer } />
          <K9Transfer
            modal={this.state.modal}
            handleOpen={this.handleOpen}
            onClose={this.onClose}
            k9TransferList={this.state.k9TransferList}
            printPdf={this.generatePdf}
            handleDoc={this.handleDoc}
          />
          <AddTransferDoc
            modal={this.state.modal}
            handleOpen={this.handleOpen}
            onClose={this.onClose}
            k9Companies={this.state.k9Companies}
            handleFilterChange={this.handleFilterChange}
            pendingAwbs={this.state.pendingAwbs}
            transferAwb={this.transferItem}
            fields={this.state.completionFields}
            handleChange={this.handleChange}
            createTransfer={this.createTransfer}
            applyFilters={this.applyFilters}
            filterOptions={this.state.filterOptions}
            clearSearch={this.clearSearch}
            airlines={this.state.airlineList}
          />

          <TransferCompletionWorksheet
            modal={this.state.modal}
            handleOpen={this.handleOpen}
            onClose={this.onClose}
            handleChange={this.handleChange}
            fields={this.state.completionFields}
            handleInputChange={this.handleInputChange}
            update={this.updateK9Transfer}
            handleCheck={this.handleCheck}
            managerRole={this.state.managerRole}
            isFinalButtonEnabled={isFinalButtonEnabled}
          />
        </Box>

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { doc: state.doc };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDelete: (data) => dispatch(setDeleteAction(data)),
    setDoc: (data) => dispatch(setDocAction(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(K9TransferContainer);