
import React, { Component } from 'react';
import '@fontsource/nunito';
// import Header from '../components/header';
// import AdminContainer from '../components/pages/admin/index';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoginContainer from '../components/pages/login/LoginContainer';
import { Common } from 'custom/common';
import auth from 'utils/auth';
import PrivateRoute from './PrivateRoute';
import DocsStreamContainer from 'components/pages/docsStream/DocsStreamContainer';
import AdminContainer from '../components/pages/admin/AdminContainer';
import k9TransferContainer from '../components/pages/k9transfer/K9TransferContainer';
import ScreeningContainer from 'components/pages/screening/ScreeningContainer';
import ScreenContainer from 'components/pages/screen/ScreenContainer';
import ScreeningReportContainer from 'components/pages/reports/ScreeningReportContainer';
import AnalyticsContainer from 'components/pages/analytics/AnalyticsContainer';
import DocArchiveContainer from 'components/pages/docArchive/DocArchiveContainer';
import PrintPasswordContainer from '../components/pages/admin/tabs/manageusers/printpassword/PrintPasswordContainer';
import ChangeDefaultPasswordContainer from '../components/pages/login/changepassword/ChangeDefaultPasswordContainer';
import cookies from 'utils/cookies';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoggedIn: auth() };
  }

  componentDidMount() {
    Common(this);
    this.setState({ isLoggedIn: auth() });
  }

  getUserRole() {
    const userRole = cookies.get('role');
    return userRole; 
  }

  render() {
    const userRole = this.getUserRole(); // Get the user's role

    let Home = null;

    if (userRole === 'TSA Inspector') {
      Home = DocArchiveContainer;
    } else {
      Home = ScreenContainer; // A default component for unknown roles
    }

    return (
      <BrowserRouter>
        <Switch>
          <Route path='/login' component={LoginContainer} />
          <Route path='/change_first_time_password' component={ChangeDefaultPasswordContainer} />
          <PrivateRoute exact path='/admin/:tab?' requiredRoles={['Supervisor', 'Manager', 'System Admin']} component={AdminContainer} isLoggedIn={this.state.isLoggedIn} />
          <PrivateRoute exact path='/docs_stream' requiredRoles={['Office Agent', 'Screener', 'Supervisor', 'Manager', 'System Admin']} component={DocsStreamContainer} isLoggedIn={this.state.isLoggedIn} />
          <PrivateRoute exact path='/transfer_k9' requiredRoles={['Screener', 'Supervisor', 'Manager', 'System Admin']} component={k9TransferContainer} isLoggedIn={this.state.isLoggedIn} />
          <PrivateRoute exact path='/screening' requiredRoles={['Screener', 'Supervisor', 'Manager', 'System Admin']} component={ScreeningContainer} isLoggedIn={this.state.isLoggedIn} />
          <PrivateRoute exact path='/screen/:id(\d+)?' requiredRoles={['Screener', 'Supervisor', 'Manager', 'System Admin']} component={ScreenContainer} isLoggedIn={this.state.isLoggedIn} />
          <PrivateRoute exact path='/screening_reports' requiredRoles={['Supervisor', 'Manager', 'System Admin']} component={ScreeningReportContainer} isLoggedIn={this.state.isLoggedIn} />
          <PrivateRoute exact path='/analytics' requiredRoles={['Office Agent', 'Screener', 'Supervisor', 'Manager', 'System Admin']} component={AnalyticsContainer} isLoggedIn={this.state.isLoggedIn} />
          <PrivateRoute exact path='/doc_archive/:tab?' requiredRoles={['Supervisor', 'Manager', 'System Admin', 'Screener', 'Office Agent', 'TSA Inspector']} component={DocArchiveContainer} isLoggedIn={this.state.isLoggedIn} />
          <PrivateRoute exact path='/print_user_details' requiredRoles={['Office Agent', 'Screener', 'Supervisor', 'Manager', 'System Admin']} component={PrintPasswordContainer} isLoggedIn={this.state.isLoggedIn} />
          <PrivateRoute path='/' requiredRoles={['Office Agent', 'Screener', 'Supervisor', 'Manager', 'System Admin', 'TSA Inspector']} component={Home} isLoggedIn={this.state.isLoggedIn} />
        </Switch>
      </BrowserRouter>
    );
  }
}


export default App;